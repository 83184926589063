import { Loan, LoanState } from '../types';

type NewLoan = (array: LoanState, caseId: number) => Loan;

const newLoan: NewLoan = (array, caseId) => {
  const loan: Loan = {
    actionId: null,
    caseId,
    data: null,
    isDirty: false,
    isError: false,
    isLoading: false,
  };
  array.push(loan);
  return loan;
};

export default newLoan;
