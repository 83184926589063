import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { statusBarInitAction, statusBarUpdateAction } from './actions';
import { statusBarPluginInit, statusBarPluginUpdate } from './plugin';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { RouterActionType } from 'modules/router';
import { StatusBarAction } from './constants';
import { StatusBarActionType } from './types';
import { ROUTE } from 'modules/router';

let initialized = false;

const statusBarMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | RouterActionType | StatusBarActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT: {
        nextAction = next(action);
        !initialized &&
          getState().platform.isCapacitor &&
          dispatch(statusBarInitAction(getState().router.location));
        break;
      }

      case LOCATION_CHANGE: {
        nextAction = next(action);
        initialized &&
          getState().platform.isCapacitor &&
          dispatch(statusBarUpdateAction(action.payload.location));
        break;
      }

      case StatusBarAction.INIT:
        await statusBarPluginInit(action.location?.pathname || ROUTE.LOGIN);
        initialized = true;
        break;

      case StatusBarAction.UPDATE:
        statusBarPluginUpdate(action.location.pathname);
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default statusBarMiddleware;

//TODO maybe needs iOS / Android folder like Onfido

//needs action for when splashscreen hidden
