import { platform } from 'modules/services/platform';
import { OrientationStatus } from '../constants';

!window.myfluent && (window.myfluent = { listeners: {} });

const { isAndroid, isCapacitor, isDevelopment, isIOS } = platform;

const getOrientationAPI = () =>
  window.screen.orientation?.type?.includes(OrientationStatus.LANDSCAPE)
    ? OrientationStatus.LANDSCAPE
    : OrientationStatus.PORTRAIT;

const getOrientationIOS = () =>
  (Math.abs(window.orientation) || 0) === 90
    ? OrientationStatus.LANDSCAPE
    : OrientationStatus.PORTRAIT;

const getOrientationWindow = () =>
  (Math.abs(window.orientation) || 0) === 90 &&
  window.screen.width > window.screen.height
    ? OrientationStatus.LANDSCAPE
    : OrientationStatus.PORTRAIT;

export const getOrientation = () =>
  !isCapacitor && isIOS
    ? getOrientationIOS()
    : !isCapacitor && isAndroid
      ? window.screen.orientation
        ? getOrientationAPI()
        : getOrientationWindow()
      : OrientationStatus.PORTRAIT;

const removeListener = () => {
  if (window.myfluent?.listeners?.orientation) {
    window.myfluent.listeners.orientation();
    delete window.myfluent.listeners.orientation;
  }
};

export const addListener = (
  handler: (orientation: OrientationStatus) => void,
) => {
  const listener = () => handler(getOrientation());
  removeListener();
  switch (true) {
    case !isCapacitor && isAndroid && window.screen.orientation:
      window.screen.orientation.addEventListener('change', listener);
      if (isDevelopment)
        window.myfluent.listeners.orientation = () =>
          window.screen.orientation.removeEventListener('change', listener);
      break;

    case !isCapacitor && (isAndroid || isIOS):
      window.addEventListener('orientationchange', listener);
      if (isDevelopment)
        window.myfluent.listeners.orientation = () =>
          window.removeEventListener('orientationchange', listener);
      break;
  }
};
