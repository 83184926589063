import { StatusGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';
import findStatus from './findStatus';

type IsPresent = (props: {
  actionId: string;
  request: StatusGetActionApiRequest;
  state: RootState;
}) => boolean;

const isPresent: IsPresent = ({ actionId, request, state }) =>
  actionId === findStatus(state.status, request.caseId)?.actionId;

export default isPresent;
