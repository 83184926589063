import updateImageMultiplier from './updateImageMultiplier';
import { ConfigApiResponse, ConfigData } from '../types';
import updateImageQuality from './updateImageQuality';

type UpdateConfig = (data: ConfigApiResponse['data']) => ConfigData;

const updateConfig: UpdateConfig = data => ({
  appMaintenanceMessage: data.appMaintenanceMessage
    ? data.appMaintenanceMessage
    : null,
  appMinimumVersion: data.appMinimumVersion ? data.appMinimumVersion : null,
  imageMultiplier: updateImageMultiplier(data.documentUploadImageDPIMultiplier),
  imageQuality: updateImageQuality(data.documentUploadImageQuality),
  onfidoUI: !!data.onfidoUI,
});

export default updateConfig;
