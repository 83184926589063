import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import { defaultValues, Model, schema } from './model';
import { InputAutocomplete, InputType } from 'components/shared/Inputs';
import { QuestionText } from 'components/shared/Questions';
import Button, {
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from 'components/shared/Button';
import Fieldset from 'components/shared/Fieldset';
import Form from 'components/shared/Form';
import logger from 'modules/logger';

import styles from 'routes/debug/styles.module.scss';

const QuestionTextDebug: React.FunctionComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const active = useRef(true);

  const handleSubmit = useCallback(
    async (data: Model, reset: UseFormReset<Model>) => {
      logger.debug('Submit:', data);
      setIsSubmitting(true);
      setTimeout(() => {
        if (active.current) {
          setIsSubmitting(false);
          reset();
        }
      }, 2000);
    },
    [],
  );

  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);

  return (
    <Form<Model>
      className={styles['question-container']}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}>
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <Fieldset title="Question Text">
            <QuestionText
              autoComplete={InputAutocomplete.EMAIL}
              control={control}
              label="Email address"
              name="debugEmailInput"
              readOnly={isSubmitting}
              type={InputType.EMAIL}
            />
            <QuestionText
              autoComplete={InputAutocomplete.EMAIL}
              control={control}
              label="Email address"
              name="debugEmailInputEmpty"
              readOnly={isSubmitting}
              type={InputType.EMAIL}
            />
            <QuestionText
              autoComplete={InputAutocomplete.CURRENT_PASSWORD}
              control={control}
              label="Password"
              name="debugPasswordInput"
              readOnly={isSubmitting}
              type={InputType.PASSWORD}
            />
            <QuestionText
              autoComplete={InputAutocomplete.CURRENT_PASSWORD}
              control={control}
              label="Password"
              name="debugPasswordInputEmpty"
              readOnly={isSubmitting}
              type={InputType.PASSWORD}
            />
          </Fieldset>

          <Button
            disabled={isFormSubmitting || isSubmitting}
            label="Submit"
            size={ButtonSize.LARGE}
            style={ButtonStyle.PRIMARY_INVERTED}
            type={ButtonType.SUBMIT}
          />

          <Fieldset title="Question Text - Disabled">
            <QuestionText
              autoComplete={InputAutocomplete.EMAIL}
              control={control}
              disabled={true}
              label="Email address"
              name="debugEmailInputDisabled"
              readOnly={isSubmitting}
              type={InputType.EMAIL}
            />
            <QuestionText
              autoComplete={InputAutocomplete.EMAIL}
              control={control}
              disabled={true}
              label="Email address"
              name="debugEmailInputDisabledEmpty"
              readOnly={isSubmitting}
              type={InputType.EMAIL}
            />
            <QuestionText
              autoComplete={InputAutocomplete.CURRENT_PASSWORD}
              control={control}
              disabled={true}
              label="Password"
              name="debugPasswordInputDisabled"
              readOnly={isSubmitting}
              type={InputType.PASSWORD}
            />
            <QuestionText
              autoComplete={InputAutocomplete.CURRENT_PASSWORD}
              control={control}
              disabled={true}
              label="Password"
              name="debugPasswordInputDisabledEmpty"
              readOnly={isSubmitting}
              type={InputType.PASSWORD}
            />
          </Fieldset>
          <Fieldset title="Question Text - Read Only">
            <QuestionText
              autoComplete={InputAutocomplete.EMAIL}
              control={control}
              label="Email address"
              name="debugEmailInputReadOnly"
              readOnly={true}
              type={InputType.EMAIL}
            />
            <QuestionText
              autoComplete={InputAutocomplete.EMAIL}
              control={control}
              label="Email address"
              name="debugEmailInputEmptyReadOnly"
              readOnly={true}
              type={InputType.EMAIL}
            />
            <QuestionText
              autoComplete={InputAutocomplete.CURRENT_PASSWORD}
              control={control}
              label="Password"
              name="debugPasswordInputReadOnly"
              readOnly={true}
              type={InputType.PASSWORD}
            />
            <QuestionText
              autoComplete={InputAutocomplete.CURRENT_PASSWORD}
              control={control}
              label="Password"
              name="debugPasswordInputEmptyReadOnly"
              readOnly={true}
              type={InputType.PASSWORD}
            />
          </Fieldset>
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(QuestionTextDebug);
