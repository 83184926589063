import { AuthBiometricError } from '../../../constants';
import { isError } from 'modules/utils';

type ErrorCode = Error & { code: string };

const isErrorCode = (error: unknown) =>
  error instanceof Error && 'code' in error;

type GetError = (error: unknown) => Error;

const getError: GetError = error => {
  switch (true) {
    case isError(error) &&
      Object.values(AuthBiometricError).includes(
        error.message as AuthBiometricError,
      ):
      return error as Error;
    case isErrorCode(error):
      switch ((error as ErrorCode).code) {
        case '0':
          return new Error(AuthBiometricError.REJECTED);
        case '11':
        case '15':
        case '16':
          return new Error(AuthBiometricError.CANCELLED);
        default:
          return new Error(AuthBiometricError.UNAVAILABLE);
      }
    default:
      return new Error(AuthBiometricError.UNAVAILABLE);
  }
};

export default getError;
