export enum NotificationsAction {
  POST_STARTED = 'NOTIFICATIONS_POST_STARTED',
  POST_SUCCESS = 'NOTIFICATIONS_POST_SUCCESS',
  POST_FAILURE = 'NOTIFICATIONS_POST_FAILURE',

  REGISTER_STARTED = 'NOTIFICATIONS_REGISTER_STARTED',
  REGISTER_SUCCESS = 'NOTIFICATIONS_REGISTER_SUCCESS',
  REGISTER_FAILURE = 'NOTIFICATIONS_REGISTER_FAILURE',

  RECEIVED = 'NOTIFICATIONS_RECEIVED',

  LOGOUT = 'NOTIFICATIONS_LOGOUT',
}

export enum NotificationsDeviceType {
  ANDROID = 'Android',
  IOS = 'iOS',
}

export enum NotificationsEndpoint {
  ANDROID = 'arn:aws:sns:eu-west-1:261765455358:app/GCM/AndroidPush',
  IOS = 'arn:aws:sns:eu-west-1:261765455358:app/GCM/iOSPushNew',
}

export enum NotificationsStatus {
  DENIED = 'denied',
  GRANTED = 'granted',
  PROMPT = 'prompt',
  PROMPT_WITH_RATIONALE = 'prompt-with-rationale',
}

export enum NotificationsType {
  NOTE = 'Note',
  STATUS = 'Status',
}

// {
//   "default": "You have a new note pending",
//   "APNS": "{\"aps\":{ \"alert\": \"Notes Updated\", \"messageType\": \"Note\", \"messageCategory\": \"Note\", \"sound\": \"default\", \"badge\": 1 } }",
//   "GCM": "{ \"notification\": { \"title\": \"MyFluent\", \"body\": \"You have a new note pending\", \"sound\": \"default\", \"badge\": 1 }, \"data\": { \"messageType\": \"Note\", \"messageCategory\": \"Note\", \"caseId\": \"XXXXXXXXXX\" } }"
// }

// {
//   "default": "You have a status update",
//   "APNS": "{\"aps\":{ \"alert\": \"Status Updated\", \"messageType\": \"Status\", \"messageCategory\": \"Status\", \"sound\": \"default\" } }",
//   "GCM": "{ \"notification\": { \"title\": \"MyFluent\", \"body\": \"You have a status update\", \"sound\": \"default\" }, \"data\": { \"messageType\": \"Status\", \"messageCategory\": \"Status\", \"caseId\": \"XXXXXXXXXX\" } }"
// }
