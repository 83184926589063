import { Modal } from 'modules/modals/types';
import { ModalType } from 'modules/modals';
import { RootState } from 'modules/redux';

type GetVideoModal = (state: RootState) => Modal | undefined;

const getVideoModal: GetVideoModal = state =>
  state.modals.find(modal => modal.type === ModalType.VIDEO);

export default getVideoModal;
