import { Keyboard as CapacitorKeyboard } from '@capacitor/keyboard';

import { allowedInputs, allowedTags } from './constants';
import { platform } from 'modules/services/platform';
import { KeyboardStatus } from '../constants';
import {
  HideAccessoryBar,
  RemoveListeners,
  HandleFocusOut,
  HandleWillHide,
  HandleWillShow,
  IsInputFocused,
  HandleDidHide,
  HandleDidShow,
  HandleFocusIn,
  AddListeners,
} from './types';

!window.myfluent && (window.myfluent = { listeners: {} });

const { isCapacitor, isDevelopment, isIOS } = platform;

const handleFocusIn: HandleFocusIn = (handler, event) =>
  isInputFocused(event) &&
  handler({
    active: true,
    offset: 0,
    status: KeyboardStatus.OPEN,
  });

const handleFocusOut: HandleFocusOut = (handler, event) =>
  setTimeout(
    () =>
      !isInputFocused(event) &&
      handler({
        active: false,
        offset: 0,
        status: KeyboardStatus.CLOSED,
      }),
    0,
  );

const handleDidHide: HandleDidHide = handler =>
  handler({
    active: false,
    offset: 0,
    status: KeyboardStatus.CLOSED,
  });

const handleDidShow: HandleDidShow = (handler, info) =>
  handler({
    active: true,
    offset: info.keyboardHeight,
    status: KeyboardStatus.OPEN,
  });

const handleWillHide: HandleWillHide = handler =>
  handler({
    active: false,
    offset: 0,
    status: KeyboardStatus.WILL_CLOSE,
  });

const handleWillShow: HandleWillShow = (handler, info) =>
  handler({
    active: true,
    offset: info.keyboardHeight,
    status: KeyboardStatus.WILL_OPEN,
  });

export const hideAccessoryBar: HideAccessoryBar = hide =>
  isIOS &&
  CapacitorKeyboard.setAccessoryBarVisible({
    isVisible: hide ? false : true,
  });

const isInputFocused: IsInputFocused = event =>
  !!document.activeElement &&
  allowedTags.includes(document.activeElement.tagName.toLowerCase()) &&
  allowedInputs.includes((event.target as HTMLInputElement)?.type);

export const addListeners: AddListeners = async handler => {
  await removeListeners();
  if (isCapacitor) {
    CapacitorKeyboard.addListener('keyboardDidShow', info =>
      handleDidShow(handler, info),
    );

    CapacitorKeyboard.addListener('keyboardDidHide', () =>
      handleDidHide(handler),
    );

    CapacitorKeyboard.addListener('keyboardWillShow', info =>
      handleWillShow(handler, info),
    );

    CapacitorKeyboard.addListener('keyboardWillHide', () =>
      handleWillHide(handler),
    );
  } else {
    const focusOutListener = (event: Event) => handleFocusOut(handler, event);
    document.addEventListener('focusout', focusOutListener);

    const focusInListener = (event: Event) => handleFocusIn(handler, event);
    document.addEventListener('focusin', focusInListener);

    if (isDevelopment)
      window.myfluent.listeners.keyboard = () => {
        document.removeEventListener('focusout', focusOutListener);
        document.removeEventListener('focusin', focusInListener);
      };
  }
};

const removeListeners: RemoveListeners = async () => {
  if (isCapacitor) {
    await CapacitorKeyboard.removeAllListeners();
  } else if (isDevelopment && !!window.myfluent?.listeners?.keyboard) {
    window.myfluent.listeners.keyboard();
    delete window.myfluent.listeners.keyboard;
  }
};
