import { useCallback, useEffect } from 'react';

import { useAccountSelector } from 'modules/states/account';
import { useNavigateCase, ROUTE } from 'modules/router';
import useTeamSelector from './useTeamSelector';
import { Team } from '../types';

export type UseTeamRoute = () => {
  data: Team['data'];
  handleError: () => void;
  handleMessage: () => void;
  isError: Error | false;
  isLoading: boolean;
};

const useTeamRoute: UseTeamRoute = () => {
  const {
    caseId,
    handleError: handleAccountError,
    isError: isAccountError,
    isLoading: isAccountLoading,
  } = useAccountSelector();
  const { data, handleRequest, isDirty, isError } = useTeamSelector(caseId);
  const navigate = useNavigateCase(caseId);

  const handleError = useCallback(
    () => (isAccountError ? handleAccountError() : handleRequest()),
    [handleAccountError, handleRequest, isAccountError],
  );

  const handleMessage = useCallback(
    () => navigate(ROUTE.MESSAGES, { replace: false }),
    [navigate],
  );

  useEffect(() => {
    !isAccountLoading && handleRequest();
  }, [handleRequest, isAccountLoading]);

  return {
    data,
    handleError,
    handleMessage,
    isError: isAccountError
      ? isAccountError
      : !isDirty && isError
        ? isError
        : false,
    isLoading: isAccountLoading || !isDirty,
  };
};

export default useTeamRoute;
