import { useCallback } from 'react';

import { docuploadShowAction } from '../../actions';
import { UseDocuploadRoute } from './types';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

const useDocuploadRoute: UseDocuploadRoute = caseId => {
  const dispatch: Dispatch = useDispatch();
  const { messageId: selected } = useSelector(
    state => state.docupload,
    shallowEqual,
  );

  const handleDocupload: ReturnType<UseDocuploadRoute> = useCallback(
    (messageId, uploadURL) => {
      if (!!caseId && !selected)
        dispatch(docuploadShowAction({ caseId, messageId, uploadURL }));
    },
    [caseId, dispatch, selected],
  );

  return handleDocupload;
};

export default useDocuploadRoute;
