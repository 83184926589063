import { Onfido } from '../types';

const initialState = (): Onfido => {
  return {
    actionId: null,
    caseId: null,
    finishURL: null,
    isError: false,
    isLoading: false,
    isSending: false,
    isSendingError: false,
    messageId: null,
    modalId: null,
    startURL: null,
    token: null,
    variant: null,
  };
};

export default initialState;
