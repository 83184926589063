import getMessageDateFooter from './getMessageDateFooter';
import getMessageDateHeader from './getMessageDateHeader';
import getMessageDateTime from './getMessageDateTime';

type GetMessageDates = (date: Date) => {
  dateFooter: string;
  dateHeader: string;
  dateTime: string;
};

const getMessageDates: GetMessageDates = date => ({
  dateFooter: getMessageDateFooter(date),
  dateHeader: getMessageDateHeader(date),
  dateTime: getMessageDateTime(date),
});

export default getMessageDates;
