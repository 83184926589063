import { useCallback } from 'react';

import { onfidoResetErrorAction } from '../../../../../actions';
import { UseHandleDefaultError } from './types';

const useHandleDefaultError: UseHandleDefaultError = ({
  showDialogConfirm,
  showDialogAlert,
  dispatch,
}) => {
  const handleError = useCallback(() => {
    if (showDialogAlert && showDialogConfirm)
      showDialogAlert({
        message: 'An error has occurred. Please try again later.',
        onConfirm: () => dispatch(onfidoResetErrorAction()),
        title: 'MyFluent',
      });
  }, [dispatch, showDialogAlert, showDialogConfirm]);

  return handleError;
};

export default useHandleDefaultError;
