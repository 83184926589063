import { v4 as uuidv4 } from 'uuid';

import { ModalAction } from './constants';
import { newModal } from './utils';
import {
  ModalDestroyAction,
  ModalHideAction,
  ModalInsertAction,
  ModalLogoutAction,
  ModalShowAction,
} from './types';

export const modalShowAction: ModalShowAction = modal => {
  return (dispatch, getState) => {
    const modalId = uuidv4();
    dispatch(modalInsertAction(newModal(dispatch, getState, modalId, modal)));
    return modalId;
  };
};

export const modalDestroyAction: ModalDestroyAction = modalId => ({
  type: ModalAction.DESTROY,
  modalId,
});

export const modalHideAction: ModalHideAction = modalId => ({
  type: ModalAction.HIDE,
  modalId,
});

export const modalInsertAction: ModalInsertAction = modal => ({
  type: ModalAction.INSERT,
  modal,
});

export const modalLogoutAction: ModalLogoutAction = () => ({
  type: ModalAction.LOGOUT,
});
