import { MessagesGetActionApiResponseItemCTA } from '../../types';
import { MessageActionOnfido } from '../../../../constants';
import { MessageOnfido } from '../../../../types';

type NewMessageOnfido = (
  data: MessagesGetActionApiResponseItemCTA,
  message: MessageOnfido,
) => void;

const newMessageOnfido: NewMessageOnfido = (data, message) => {
  message.callToAction = {
    action: data.action as MessageActionOnfido,
    firstName: data.firstName || '',
    startURL: data.startURL || '',
    surname: data.surname || '',
  };
};

export default newMessageOnfido;
