import { v4 as uuidv4 } from 'uuid';

import { DocuploadAction, DocuploadFileType } from '../../../../constants';
import { docuploadPluginFile } from '../../../../plugin';
import { timeout } from 'modules/redux/factories/utils';
import { isPresent } from '../../../../utils';
import {
  DocuploadPluginFileFailureAction,
  DocuploadPluginFileStartedAction,
  DocuploadPluginFileSuccessAction,
  DocuploadPluginFileAction,
} from './types';

export const docuploadPluginFileAction: DocuploadPluginFileAction = files => {
  return async (dispatch, getState) => {
    const { messageId } = getState().docupload;
    const config = getState().config;
    for (const file of Array.from(files)) {
      const startTime = new Date().getTime();
      try {
        if (!getState().auth.data?.token) return;

        dispatch(docuploadPluginFileStartedAction());

        const result = await docuploadPluginFile(config, file);

        await timeout(startTime, 1000);

        isPresent({ messageId, state: getState() }) &&
          dispatch(
            docuploadPluginFileSuccessAction({
              id: uuidv4(),
              name: file.name,
              pages: result,
              type: file.type as DocuploadFileType,
            }),
          );
      } catch (error) {
        await timeout(startTime, 1000);

        if (isPresent({ messageId, state: getState() })) {
          dispatch(
            docuploadPluginFileFailureAction(
              new Error(`File Error: ${file.name}`),
            ),
          );
          throw error;
        }
      }
    }
  };
};

const docuploadPluginFileStartedAction: DocuploadPluginFileStartedAction =
  () => ({
    type: DocuploadAction.PLUGIN_FILE_STARTED,
  });

const docuploadPluginFileSuccessAction: DocuploadPluginFileSuccessAction =
  file => ({
    type: DocuploadAction.PLUGIN_FILE_SUCCESS,
    file,
  });

const docuploadPluginFileFailureAction: DocuploadPluginFileFailureAction =
  error => ({
    type: DocuploadAction.PLUGIN_FILE_FAILURE,
    error,
  });
