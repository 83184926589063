import { useEffect } from 'react';

import { UseAnimation } from './types';

const useAnimation: UseAnimation = ({ listener, ref, type }) => {
  useEffect(() => {
    const element = ref.current;
    element?.addEventListener(type, listener);
    return () => {
      element?.removeEventListener(type, listener);
    };
  }, [listener, ref, type]);
};

export default useAnimation;
