import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { modalDestroyAction, modalHideAction } from 'modules/modals/actions';
import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { onfidoPluginDestroy, onfidoPluginInit } from './plugin';
import { onfidoLogoutAction, onfidoPostAction } from './actions';
import { AuthAction, AuthActionType } from 'modules/auth';
import { RouterActionType } from 'modules/router';
import { OnfidoActionType } from './types';
import { OnfidoAction } from './constants';
import {
  analyticsTrackOnfido,
  getSuccessPayload,
  shouldClose,
  closeModal,
} from './utils';

const onfidoMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | OnfidoActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE: {
        nextAction = next(action);
        shouldClose(getState) && closeModal(dispatch, getState);
        break;
      }

      case AuthAction.LOGOUT: {
        nextAction = next(action);
        dispatchAbort(getState().onfido);
        dispatch(onfidoLogoutAction());
        break;
      }

      case OnfidoAction.PLUGIN_SUCCESS: {
        nextAction = next(action);
        const payload = getSuccessPayload(action, getState);
        !!payload && dispatch(onfidoPostAction(payload));
        closeModal(dispatch, getState, false);
        break;
      }

      case OnfidoAction.PLUGIN_FAILURE: {
        nextAction = next(action);
        closeModal(dispatch, getState, false);
        break;
      }

      case OnfidoAction.PLUGIN_CANCEL: {
        nextAction = next(action);
        closeModal(dispatch, getState, false);
        break;
      }

      case OnfidoAction.SHOW_SUCCESS: {
        nextAction = next(action);
        onfidoPluginInit(action, dispatch);
        break;
      }

      case OnfidoAction.CANCEL: {
        nextAction = next(action);
        closeModal(dispatch, getState);
        break;
      }

      case OnfidoAction.CLOSE: {
        const modalId = getState().onfido.modalId;
        nextAction = next(action);
        !!modalId && dispatch(modalHideAction(modalId));
        break;
      }

      case OnfidoAction.DESTROY: {
        action.destroy && onfidoPluginDestroy();

        const modalId = getState().onfido.modalId;
        nextAction = next(action);
        !!modalId && dispatch(modalDestroyAction(modalId));
        break;
      }

      case OnfidoAction.LOGOUT: {
        !!getState().onfido.modalId && onfidoPluginDestroy(false);
        break;
      }
    }

    nextAction = analyticsTrackOnfido({
      action,
      getState,
      next,
      nextAction,
    });

    return nextAction ? nextAction : next(action);
  };

export default onfidoMiddleware;
