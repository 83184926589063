import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import { ButtonStyle, ButtonType } from 'components/shared/Button/constants';
import MessagesInput from 'components/mobile/Messages/MessagesInput';
import { useKeyboard } from 'modules/services/keyboard';
import { defaultValues, Model, schema } from './model';
import Fieldset from 'components/shared/Fieldset';
import Button from 'components/shared/Button';
import Form from 'components/shared/Form';
import logger from 'modules/logger';

import styles from 'routes/debug/styles.module.scss';

const MessageInputDebug: React.FunctionComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { active: focused } = useKeyboard();
  const active = useRef(true);

  const handleSubmit = useCallback(
    async (data: Model, reset: UseFormReset<Model>) => {
      logger.debug('Submit:', data);
      setIsSubmitting(true);
      setTimeout(() => {
        if (active.current) {
          setIsSubmitting(false);
          reset();
        }
      }, 2000);
    },
    [],
  );

  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);

  return (
    <Form<Model>
      className={styles['messages-input-container']}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}>
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <Fieldset title="Message Input">
            <MessagesInput<Model>
              control={control}
              isFocused={focused}
              name="debugTextInput"
              isSending={isSubmitting}
            />
            <MessagesInput<Model>
              control={control}
              isFocused={focused}
              name="debugTextInputEmpty"
              isSending={isSubmitting}
            />
          </Fieldset>

          <Button
            disabled={isFormSubmitting || isSubmitting}
            label="Submit"
            style={ButtonStyle.PRIMARY}
            type={ButtonType.SUBMIT}
          />

          <Fieldset title="Message Input - Disabled">
            <MessagesInput<Model>
              control={control}
              disabled={true}
              isFocused={focused}
              name="debugTextInputDisabled"
              isSending={isSubmitting}
            />
            <MessagesInput<Model>
              control={control}
              disabled={true}
              isFocused={focused}
              name="debugTextInputDisabledEmpty"
              isSending={isSubmitting}
            />
          </Fieldset>
          <Fieldset title="Message Input - Read Only">
            <MessagesInput<Model>
              control={control}
              isFocused={focused}
              name="debugTextInputReadOnly"
              isSending={true}
            />
            <MessagesInput<Model>
              control={control}
              isFocused={focused}
              name="debugTextInputEmptyReadOnly"
              isSending={true}
            />
          </Fieldset>
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(MessageInputDebug);
