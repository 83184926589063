import React from 'react';

import { MessageActionOnfido } from 'modules/states/messages';

type GetMessageOnfidoLabel = (action: MessageActionOnfido) => JSX.Element;

const getMessageOnfidoLabel: GetMessageOnfidoLabel = action => {
  switch (action) {
    case MessageActionOnfido.APPROVED:
      return (
        <React.Fragment>
          Your ID&V check has
          <br />
          been completed
        </React.Fragment>
      );
    case MessageActionOnfido.FAILED:
      return (
        <React.Fragment>
          Your ID&V check
          <br />
          has expired
        </React.Fragment>
      );
    case MessageActionOnfido.INPROGRESS:
      return (
        <React.Fragment>
          Your ID&V check has
          <br />
          been submitted
        </React.Fragment>
      );
    case MessageActionOnfido.REQUESTED:
    default:
      return <React.Fragment>Complete Check</React.Fragment>;
  }
};

export default getMessageOnfidoLabel;
