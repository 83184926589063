import { Middleware } from 'redux';

import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { PlatformActionType } from './types';
import { setupPlatform } from './utils';
import { platform } from './platform';

const platformMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | PlatformActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        setupPlatform(platform);
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default platformMiddleware;
