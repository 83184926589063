import React from 'react';
import clsx from 'clsx';

import {
  AvatarsDebug,
  ButtonsDebug,
  DialogsDebug,
  MessageInputDebug,
  ModalsDebug,
  QuestionTextDebug,
} from 'routes/debug/components';

import mainStyles from 'components/mobile/Main/styles.module.scss';
import mobileStyles from './styles.module.scss';
import styles from '../styles.module.scss';

type TemplateProps = Record<string, unknown>;

const Template: React.FunctionComponent<TemplateProps> = () => {
  const classNames = clsx(
    mainStyles.main,
    styles.debug,
    mobileStyles['mobile-debug'],
  );
  return (
    <main className={classNames}>
      <DialogsDebug />
      <ModalsDebug />
      <QuestionTextDebug />
      <MessageInputDebug />
      <ButtonsDebug />
      <AvatarsDebug />
    </main>
  );
};

export default React.memo(Template);
