import { AnyAction } from 'redux';

import { analyticsTrackEvent } from 'modules/services/analytics';
import { Dispatch, RootState } from 'modules/redux';
import isOnfidoAction from './isOnfidoAction';
import { OnfidoAction } from '../constants';

type AnalyticsTrackOnfido = (props: {
  action: AnyAction;
  getState: () => RootState;
  next: Dispatch<AnyAction>;
  nextAction: AnyAction | undefined;
}) => AnyAction | undefined;

const analyticsTrackOnfido: AnalyticsTrackOnfido = ({ action, nextAction }) => {
  if (isOnfidoAction(action))
    switch (action.type) {
      case OnfidoAction.PLUGIN_CANCEL:
      case OnfidoAction.CANCEL: {
        analyticsTrackEvent({
          category: 'Onfido',
          action: 'pressedCloseButton',
        });
        break;
      }

      case OnfidoAction.SHOW_STARTED: {
        analyticsTrackEvent({
          category: 'Onfido',
          action: 'pressedCTAOnfidoButton',
        });
        break;
      }

      case OnfidoAction.PLUGIN_SUCCESS: {
        analyticsTrackEvent({
          category: 'Onfido',
          action: 'pluginSuccess',
          label: `${action.variant} - ${action.document}`,
        });
        break;
      }

      case OnfidoAction.POST_SUCCESS: {
        analyticsTrackEvent({
          category: 'Onfido',
          action: 'postSuccess',
        });
        break;
      }
    }

  return nextAction;
};

export default analyticsTrackOnfido;
