import { Dialog as CapacitorDialog } from '@capacitor/dialog';

import { platform } from 'modules/services/platform';
import {
  DialogPluginShowConfirmCapacitor,
  DialogPluginShowConfirmBrowser,
  DialogPluginShowConfirm,
} from './types';

const { isCapacitor } = platform;

const dialogPluginShowConfirmCapacitor: DialogPluginShowConfirmCapacitor =
  async ({
    cancelButtonTitle = 'Cancel',
    message,
    okButtonTitle = 'Confirm',
    onCancel,
    onConfirm,
    title,
  }) => {
    try {
      const { value } = await CapacitorDialog.confirm({
        cancelButtonTitle,
        okButtonTitle,
        message,
        title,
      });
      value ? !!onConfirm && onConfirm() : !!onCancel && onCancel();
    } catch {
      !!onCancel && onCancel();
    }
  };

const dialogPluginShowConfirmBrowser: DialogPluginShowConfirmBrowser = ({
  message,
  onCancel,
  onConfirm,
}) => {
  try {
    window.confirm(message)
      ? !!onConfirm && onConfirm()
      : !!onCancel && onCancel();
  } catch {
    !!onCancel && onCancel();
  }
};

const dialogPluginShowConfirm: DialogPluginShowConfirm = ({
  cancelLabel: cancelButtonTitle,
  confirmLabel: okButtonTitle,
  message,
  onCancel,
  onConfirm,
  title,
}) =>
  isCapacitor
    ? dialogPluginShowConfirmCapacitor({
        cancelButtonTitle,
        okButtonTitle,
        message,
        onCancel,
        onConfirm,
        title,
      })
    : dialogPluginShowConfirmBrowser({ message, onCancel, onConfirm });

export default dialogPluginShowConfirm;
