import { TeamStatus, TeamType } from '../constants';
import isTeamStatusType from './isTeamStatusType';
import { TeamMember } from '../types';

type NewTeamMember = (props: {
  image: string | null;
  name: string | null;
  status: string | null;
  telephone: string | null;
  type: TeamType;
}) => TeamMember | null;

const newTeamMember: NewTeamMember = ({
  image,
  name,
  status,
  telephone,
  type,
}) =>
  name
    ? {
        image: image || undefined,
        name,
        status: isTeamStatusType(status) ? status : TeamStatus.LOGGED_OUT,
        telephone: telephone || '',
        type,
      }
    : null;

export default newTeamMember;
