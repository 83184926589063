import { createDefaultAction } from 'modules/redux/factories';
import { isActive, isPresent } from './utils';
import { TeamAction } from './constants';
import { API } from 'modules/api';
import {
  TeamGetFailureAction,
  TeamGetStartedAction,
  TeamGetSuccessAction,
  TeamLogoutAction,
  TeamGetAction,
} from './types';

export const teamGetAction: TeamGetAction = request =>
  createDefaultAction({
    actionStarted: teamGetStartedAction,
    actionSuccess: teamGetSuccessAction,
    actionFailure: teamGetFailureAction,
    endpoint: API.GET_TEAM,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const teamGetStartedAction: TeamGetStartedAction = payload => ({
  type: TeamAction.GET_STARTED,
  ...payload,
});

const teamGetSuccessAction: TeamGetSuccessAction = payload => ({
  type: TeamAction.GET_SUCCESS,
  ...payload,
});

const teamGetFailureAction: TeamGetFailureAction = payload => ({
  type: TeamAction.GET_FAILURE,
  ...payload,
});

export const teamLogoutAction: TeamLogoutAction = () => ({
  type: TeamAction.LOGOUT,
});
