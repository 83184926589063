import {
  AnalyticsPluginTrackPageViewGA4,
  AnalyticsPluginTrackEventGA4,
} from './types';

export const analyticsPluginTrackEventGA4: AnalyticsPluginTrackEventGA4 = ({
  category,
  action,
  label,
}) =>
  typeof window.gtag === 'function' &&
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
  });

export const analyticsPluginTrackPageViewGA4: AnalyticsPluginTrackPageViewGA4 =
  ({ path, title }) =>
    typeof window.gtag === 'function' &&
    window.gtag('event', 'page_view', {
      page_path: path,
      page_title: title,
    });

export const analyticsPluginInitGA4 = () => {
  window.gtag('js', new Date());
  window.gtag('config', 'G-4WXMQYCJWS', { send_page_view: false });
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4WXMQYCJWS';
  document.head.appendChild(script);
};
