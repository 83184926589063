import { StatusStep, StatusType } from 'modules/states/status';

type IsStatusStepActive = (step: StatusStep, status: StatusType) => boolean;

const isStatusStepActive: IsStatusStepActive = (step, status) => {
  switch (step) {
    case StatusStep.STEP1:
      return status === StatusType.ISS;
    case StatusStep.STEP2:
      return status === StatusType.RES;
    case StatusStep.STEP3:
      return status === StatusType.REF;
    case StatusStep.STEP4:
      return status === StatusType.LEN;
    case StatusStep.STEP5:
      return status === StatusType.COM;
    default:
      return false;
  }
};

export default isStatusStepActive;
