import { Network as CapacitorNetwork } from '@capacitor/network';

import { NetworkStatus } from '../../constants';
import {
  NetworkPluginStatusCapacitor,
  NetworkPluginInitCapacitor,
} from './types';

export const networkPluginInitCapacitor: NetworkPluginInitCapacitor =
  async handler => {
    await CapacitorNetwork.removeAllListeners();

    CapacitorNetwork.addListener('networkStatusChange', status =>
      handler({
        status: status.connected ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE,
      }),
    );
  };

export const networkPluginStatusCapacitor: NetworkPluginStatusCapacitor =
  async () => {
    try {
      const status = await CapacitorNetwork.getStatus();
      return status.connected ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
    } catch {
      return NetworkStatus.ONLINE;
    }
  };
