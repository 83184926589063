import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { modalDestroyAction, modalHideAction } from 'modules/modals/actions';
import { docuploadCloseAction, docuploadLogoutAction } from './actions';
import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { analyticsTrackDocupload, shouldClose } from './utils';
import { AuthAction, AuthActionType } from 'modules/auth';
import { docuploadPluginDestroy } from './plugin';
import { RouterActionType } from 'modules/router';
import { DocuploadActionType } from './types';
import { DocuploadAction } from './constants';

const docuploadMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | DocuploadActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE: {
        nextAction = next(action);
        shouldClose(getState) && dispatch(docuploadCloseAction());
        break;
      }

      case AuthAction.LOGOUT: {
        nextAction = next(action);
        dispatchAbort(getState().docupload);
        dispatch(docuploadLogoutAction());
        break;
      }

      case DocuploadAction.CANCEL:
      case DocuploadAction.UPLOAD_SUCCESS: {
        nextAction = next(action);
        dispatch(docuploadCloseAction());
        break;
      }

      case DocuploadAction.CLOSE: {
        const modalId = getState().docupload.modalId;
        nextAction = next(action);
        !!modalId && dispatch(modalHideAction(modalId));
        break;
      }

      case DocuploadAction.DESTROY: {
        docuploadPluginDestroy();

        const modalId = getState().docupload.modalId;
        nextAction = next(action);
        !!modalId && dispatch(modalDestroyAction(modalId));
        break;
      }

      case DocuploadAction.LOGOUT: {
        !!getState().docupload.modalId && docuploadPluginDestroy();
        break;
      }
    }

    nextAction = analyticsTrackDocupload({
      action,
      getState,
      next,
      nextAction,
    });

    return nextAction ? nextAction : next(action);
  };

export default docuploadMiddleware;
