import isMessageActionDocupload from './isMessageActionDocupload';
import isMessageActionOnfido from './isMessageActionOnfido';
import { MessageType } from '../constants';

type GetMessageType = (action: unknown) => MessageType;

const getMessageType: GetMessageType = action => {
  return !!action && isMessageActionDocupload(action)
    ? MessageType.DOCUMENT_UPLOAD
    : !!action && isMessageActionOnfido(action)
      ? MessageType.ONFIDO
      : MessageType.DEFAULT;
};

export default getMessageType;
