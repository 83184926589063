import { useEffect } from 'react';

import { useDialogAlert, useDialogConfirm } from 'modules/dialogs';
import {
  useHandleDefaultError,
  useHandleErrorSwitch,
  useHandlePostError,
  useHandleGetError,
} from './hooks';
import {
  shallowEqual,
  useSelector,
  useDispatch,
  Dispatch,
} from 'modules/redux';

const useHandleError = () => {
  const [showDialogConfirm] = useDialogConfirm();
  const [showDialogAlert] = useDialogAlert();
  const dispatch: Dispatch = useDispatch();

  const { isError, isSendingError } = useSelector(
    state => state.onfido,
    shallowEqual,
  );

  const payload = { showDialogConfirm, showDialogAlert, dispatch };

  const handleDefaultError = useHandleDefaultError(payload);
  const handlePostError = useHandlePostError(payload);
  const handleGetError = useHandleGetError(payload);

  const handleError = useHandleErrorSwitch({
    handleDefaultError,
    handlePostError,
    handleGetError,
  });

  useEffect(() => {
    if (isError || isSendingError)
      handleError(isSendingError ? isSendingError : isError);
  }, [handleError, isError, isSendingError]);
};

export default useHandleError;
