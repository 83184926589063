export enum StatusAction {
  GET_STARTED = 'STATUS_GET_STARTED',
  GET_SUCCESS = 'STATUS_GET_SUCCESS',
  GET_FAILURE = 'STATUS_GET_FAILURE',

  LOGOUT = 'STATUS_LOGOUT',
}

export enum StatusProgress {
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  PERCENT_20 = 'PERCENT_20',
  PERCENT_40 = 'PERCENT_40',
  PERCENT_60 = 'PERCENT_60',
  PERCENT_80 = 'PERCENT_80',
  PERCENT_100 = 'PERCENT_100',
  UNKNOWN = 'UNKNOWN',
}

export enum StatusStep {
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
  STEP4 = 'STEP4',
  STEP5 = 'STEP5',
  UNKNOWN = 'UNKNOWN',
}

export enum StatusType {
  CAN = 'CAN',
  CAN_LEN = 'CAN(LEN)',
  CAN_REF = 'CAN(REF)',
  CAN_RES = 'CAN(RES)',
  COM = 'COM',
  DEC = 'DEC',
  DEC_LEN = 'DEC(LEN)',
  DEC_REF = 'DEC(REF)',
  DEC_RES = 'DEC(RES)',
  ISS = 'ISS',
  LEN = 'LEN',
  REF = 'REF',
  RES = 'RES',
  SEA = 'SEA',
  SOL = 'SOL',
}
