import { StatusStep, StatusType } from 'modules/states/status';

type IsStatusStepComplete = (step: StatusStep, status: StatusType) => boolean;

const isStatusStepComplete: IsStatusStepComplete = (step, status) => {
  switch (step) {
    case StatusStep.STEP1:
      return (
        !!status &&
        !![
          StatusType.CAN,
          StatusType.CAN_LEN,
          StatusType.CAN_REF,
          StatusType.CAN_RES,
          StatusType.COM,
          StatusType.DEC,
          StatusType.DEC_LEN,
          StatusType.DEC_REF,
          StatusType.DEC_RES,
          StatusType.LEN,
          StatusType.REF,
          StatusType.RES,
        ].includes(status)
      );
    case StatusStep.STEP2:
      return (
        !!status &&
        !![
          StatusType.CAN,
          StatusType.CAN_LEN,
          StatusType.CAN_REF,
          StatusType.COM,
          StatusType.DEC,
          StatusType.DEC_LEN,
          StatusType.DEC_REF,
          StatusType.LEN,
          StatusType.REF,
        ].includes(status)
      );
    case StatusStep.STEP3:
      return (
        !!status &&
        !![
          StatusType.CAN,
          StatusType.CAN_LEN,
          StatusType.COM,
          StatusType.DEC,
          StatusType.DEC_LEN,
          StatusType.LEN,
        ].includes(status)
      );
    case StatusStep.STEP4:
      return (
        !!status &&
        !![StatusType.CAN, StatusType.COM, StatusType.DEC].includes(status)
      );
    case StatusStep.STEP5:
      return !!status && StatusType.COM === status;
    default:
      return false;
  }
};

export default isStatusStepComplete;
