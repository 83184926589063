import { MessagesGetActionApiResponseItem } from '../actions/messagesGetAction/types';
import { Message, MessagesData } from '../types';
import { MessageFrom } from '../constants';

type FindMessageFromResponse = (
  messages: MessagesData,
  data: MessagesGetActionApiResponseItem,
) => Message | undefined;

const findMessageFromResponse: FindMessageFromResponse = (array, data) =>
  array.find(
    message =>
      (!message.placeholder && message.messageId === data.id) ||
      (message.placeholder &&
        message.from === MessageFrom.USER &&
        message.text === data.message),
  );

export default findMessageFromResponse;
