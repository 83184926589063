import { videoShowFailureAction, videoShowSuccessAction } from '../actions';
import { videoPluginCloseAction } from '../../';
import { ModalVideo } from 'modules/modals';
import { Dispatch } from 'modules/redux';
import { isError } from 'modules/utils';

type GetHandlers = (props: {
  dispatch: Dispatch;
  modalId: string;
  payload: ModalVideo['payload'];
}) => {
  onCancel: () => void;
  onComplete: () => void;
  onError: (error: unknown) => void;
  onReady: () => void;
};

const getHandlers: GetHandlers = ({ dispatch, modalId, payload }) => ({
  onCancel: () => dispatch(videoPluginCloseAction()),

  onComplete: () => dispatch(videoPluginCloseAction()),

  onError: (error: unknown) =>
    dispatch(
      videoShowFailureAction({
        error: isError(error) ? error : new Error('Unexpected Error'),
        ...payload,
      }),
    ),

  onReady: () => dispatch(videoShowSuccessAction({ modalId, ...payload })),
});

export default getHandlers;
