import { getCaseIdFromPath } from 'modules/router';
import { loadCaseData } from 'modules/auth';

type GetSavedCaseId = (username: string) => number | null;

const getSavedCaseId: GetSavedCaseId = username => {
  let caseId = getCaseIdFromPath(window.location.pathname);
  !caseId && !!username && (caseId = loadCaseData(username));
  return !!caseId && !Number.isNaN(Number(caseId)) ? Number(caseId) : null;
};

export default getSavedCaseId;
