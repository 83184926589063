export enum DocuploadAction {
  PLUGIN_CAMERA_STARTED = 'DOCUPLOAD_PLUGIN_CAMERA_STARTED',
  PLUGIN_CAMERA_SUCCESS = 'DOCUPLOAD_PLUGIN_CAMERA_SUCCESS',
  PLUGIN_CAMERA_FAILURE = 'DOCUPLOAD_PLUGIN_CAMERA_FAILURE',

  PLUGIN_DELETE_STARTED = 'DOCUPLOAD_PLUGIN_DELETE_STARTED',
  PLUGIN_DELETE_SUCCESS = 'DOCUPLOAD_PLUGIN_DELETE_SUCCESS',
  PLUGIN_DELETE_FAILURE = 'DOCUPLOAD_PLUGIN_DELETE_FAILURE',

  PLUGIN_FILE_STARTED = 'DOCUPLOAD_PLUGIN_FILE_STARTED',
  PLUGIN_FILE_SUCCESS = 'DOCUPLOAD_PLUGIN_FILE_SUCCESS',
  PLUGIN_FILE_FAILURE = 'DOCUPLOAD_PLUGIN_FILE_FAILURE',

  PLUGIN_INPUT_STARTED = 'DOCUPLOAD_PLUGIN_INPUT_STARTED',
  PLUGIN_INPUT_SUCCESS = 'DOCUPLOAD_PLUGIN_INPUT_SUCCESS',
  PLUGIN_INPUT_FAILURE = 'DOCUPLOAD_PLUGIN_INPUT_FAILURE',

  PLUGIN_PREVIEW_STARTED = 'DOCUPLOAD_PLUGIN_PREVIEW_STARTED',
  PLUGIN_PREVIEW_SUCCESS = 'DOCUPLOAD_PLUGIN_PREVIEW_SUCCESS',
  PLUGIN_PREVIEW_FAILURE = 'DOCUPLOAD_PLUGIN_PREVIEW_FAILURE',

  POST_STARTED = 'DOCUPLOAD_POST_STARTED',
  POST_PROGRESS = 'DOCUPLOAD_POST_PROGRESS',
  POST_SUCCESS = 'DOCUPLOAD_POST_SUCCESS',
  POST_FAILURE = 'DOCUPLOAD_POST_FAILURE',

  SHOW_STARTED = 'DOCUPLOAD_SHOW_STARTED',
  SHOW_SUCCESS = 'DOCUPLOAD_SHOW_SUCCESS',
  SHOW_FAILURE = 'DOCUPLOAD_SHOW_FAILURE',

  UPLOAD_STARTED = 'DOCUPLOAD_UPLOAD_STARTED',
  UPLOAD_SUCCESS = 'DOCUPLOAD_UPLOAD_SUCCESS',
  UPLOAD_FAILURE = 'DOCUPLOAD_UPLOAD_FAILURE',

  CANCEL = 'DOCUPLOAD_CANCEL',

  CLOSE = 'DOCUPLOAD_CLOSE',

  DESTROY = 'DOCUPLOAD_DESTROY',

  LOGOUT = 'DOCUPLOAD_LOGOUT',

  RESET_ERROR = 'DOCUPLOAD_RESET_ERROR',
}

export enum DocuploadFileType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  WEBP = 'image/webp',
}

export enum DocuploadError {
  CANCELLED = 'CANCELLED',
  CREATE = 'CREATE',
  DENIED = 'DENIED',
  EMPTY = 'EMPTY',
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  NO_FILES = 'NO_FILES',
  OPEN = 'OPEN',
  PDF = 'PDF',
  REMOVE = 'REMOVE',
  TYPE = 'TYPE',
  UPLOAD = 'UPLOAD',
  POST = 'POST',
}
