import { DocuploadPostActionApiRequest } from 'modules/docupload';
import { MessageActionDocupload, MessageType } from '../constants';
import { MessagesState, MessageDocupload } from '../types';
import findMessage from './findMessage';

type UpdateMessageDocuploadStatus = (
  array: MessagesState['data'],
  request: DocuploadPostActionApiRequest,
) => void;

const updateMessageDocuploadStatus: UpdateMessageDocuploadStatus = (
  array,
  request,
) => {
  const message = findMessage(array, request.caseId, request.messageId) as
    | MessageDocupload
    | undefined;
  if (message?.type === MessageType.DOCUMENT_UPLOAD) {
    message.callToAction.action = MessageActionDocupload.AWAITING_REVIEW;
  }
};

export default updateMessageDocuploadStatus;
