import { produce } from 'immer';

import { findMessage, findMessages, getMessagesStats } from '../../utils';
import { MessagesState, MessagesActionType } from '../../types';
import { MessagesAction } from '../../constants';

function messagesReadReducer(state: MessagesState, action: MessagesActionType) {
  switch (action.type) {
    case MessagesAction.READ_FAILURE:
      return produce(state, (draftState: MessagesState) => {
        const message = findMessage(
          draftState.data,
          action.request.caseId,
          action.request.messageId,
        );
        if (message) {
          message.actionId = null;
          message.isSending = false;
          message.isSendingError = action.error;
        }
      });

    case MessagesAction.READ_STARTED:
      return produce(state, (draftState: MessagesState) => {
        const message = findMessage(
          draftState.data,
          action.request.caseId,
          action.request.messageId,
        );
        if (message) {
          message.actionId = action.actionId;
          message.isSending = action.controller || true;
          message.isSendingError = false;
        }
      });

    case MessagesAction.READ_SUCCESS:
      return produce(state, (draftState: MessagesState) => {
        const message = findMessage(
          draftState.data,
          action.request.caseId,
          action.request.messageId,
        );
        if (message) {
          message.actionId = null;
          message.isSending = false;
          message.isSendingError = false;
          message.read = true;
        }

        const messages = findMessages(draftState.data, action.request.caseId);
        if (messages) {
          messages.stats = getMessagesStats(messages.data);
        }
      });

    default:
      return state;
  }
}

export default messagesReadReducer;
