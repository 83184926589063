import { StatusType } from 'modules/states/status';

type IsCaseDeclined = (status: StatusType) => boolean;

const isCaseDeclined: IsCaseDeclined = status =>
  [
    StatusType.DEC,
    StatusType.DEC_LEN,
    StatusType.DEC_REF,
    StatusType.DEC_RES,
  ].includes(status);

export default isCaseDeclined;
