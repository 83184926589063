import { AnyAction } from 'redux';

import { analyticsTrackEvent } from 'modules/services/analytics';
import { Dispatch, RootState } from 'modules/redux';
import isCasesAction from './isCasesAction';
import { CasesAction } from '../constants';

type AnalyticsTrackCases = (props: {
  action: AnyAction;
  getState: () => RootState;
  next: Dispatch<AnyAction>;
  nextAction: AnyAction | undefined;
}) => AnyAction | undefined;

const analyticsTrackCases: AnalyticsTrackCases = ({ action, nextAction }) => {
  if (isCasesAction(action))
    switch (action.type) {
      case CasesAction.SELECT: {
        analyticsTrackEvent({
          category: 'Cases',
          action: 'selectedCaseToSwitchTo',
          label: `${action.caseId}`,
        });
        break;
      }
    }

  return nextAction;
};

export default analyticsTrackCases;
