import { produce } from 'immer';

import { Onfido, OnfidoActionType } from '../../types';
import { OnfidoAction } from '../../constants';

function onfidoShowReducer(state: Onfido, action: OnfidoActionType) {
  switch (action.type) {
    case OnfidoAction.SHOW_FAILURE:
      return produce(state, (draftState: Onfido) => {
        draftState.isError = action.error;
      });

    case OnfidoAction.SHOW_STARTED:
      return produce(state, (draftState: Onfido) => {
        draftState.isError = false;

        draftState.caseId = action.caseId;
        draftState.messageId = action.messageId;
        draftState.startURL = action.startURL;
      });

    case OnfidoAction.SHOW_SUCCESS:
      return produce(state, (draftState: Onfido) => {
        draftState.isError = false;

        draftState.finishURL = action.finishURL;
        draftState.modalId = action.modalId;
        draftState.token = action.token;
        draftState.variant = action.variant;
      });

    default:
      return state;
  }
}

export default onfidoShowReducer;
