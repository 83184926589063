import React from 'react';

import { UseLoanRoute } from 'modules/states/loan';
import Loan from 'components/desktop/Loan';
import Main from 'components/desktop/Main';

type TemplateProps = ReturnType<UseLoanRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  data,
  handleError,
  isError,
  isLoading,
}) => {
  return (
    <Main handleError={handleError} isError={isError} isLoading={isLoading}>
      {!!data && <Loan loan={data} />}
    </Main>
  );
};

export default React.memo(Template);
