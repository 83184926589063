import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { statusLogoutAction } from './actions';
import { StatusActionType } from './types';
import { abortStatus } from './utils';

const statusMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | StatusActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.LOGOUT:
        nextAction = next(action);
        abortStatus(getState().status);
        dispatch(statusLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default statusMiddleware;
