import React from 'react';
import clsx from 'clsx';

import { ButtonProps } from './types';
import { ButtonSize, ButtonType } from './constants';
import { ReactComponent as Chevron } from 'modules/theme/images/icons/button/chevron.svg';
import { useActiveAnimation, useHandlers, useTabIndex } from './hooks';

import styles from './styles.module.scss';

export { ButtonSize, ButtonStyle, ButtonType } from './constants';

const Button: React.FunctionComponent<ButtonProps> = ({
  ariaControls,
  ariaExpanded,
  ariaLabel,
  chevron = true,
  children,
  className,
  disabled,
  label,
  onClick,
  style,
  size = ButtonSize.SMALL,
  tabIndex,
  title,
  type = ButtonType.BUTTON,
  ...props
}) => {
  const { animation, handleActiveAnimation, ref } = useActiveAnimation();
  const { handleClick, handleKeyDown, handleMouseDown } = useHandlers(
    handleActiveAnimation,
    disabled,
    onClick,
  );
  const tabindex = useTabIndex(disabled, tabIndex);

  const classNames = clsx(
    style && styles.button,
    style && styles[`${style}`],
    style && size && styles[`${size}`],
    animation && 'active-animation',
    className,
  );

  return (
    <button
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel || label}
      className={classNames}
      disabled={disabled}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      ref={ref}
      tabIndex={tabindex}
      title={title || ariaLabel}
      type={type}
      {...props}>
      {label && <span>{label}</span>}
      {children}
      {style && chevron && <Chevron />}
    </button>
  );
};

export default React.memo(Button);
