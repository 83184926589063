import { useCallback, useMemo } from 'react';

import { casesGetAction, Case } from 'modules/states/cases';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseCasesSelector = () => {
  caseId: number | null;
  data: Case | null;
  handleError: () => void;
  isError: Error | false;
  isLoading: boolean;
};

const useCasesSelector: UseCasesSelector = () => {
  const dispatch: Dispatch = useDispatch();

  const {
    caseId,
    data: cases,
    isError,
    isLoading,
  } = useSelector(state => state.cases, shallowEqual);

  const handleError = useCallback(() => dispatch(casesGetAction()), [dispatch]);

  const data = useMemo(
    () => cases?.find(item => item.caseId === caseId) || null,
    [caseId, cases],
  );

  return {
    caseId: data ? caseId : null,
    data,
    handleError,
    isError,
    isLoading: !!isLoading,
  };
};

export default useCasesSelector;
