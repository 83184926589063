import React from 'react';

import { MessageBodyProps } from './types';

const MessageBody: React.FunctionComponent<MessageBodyProps> = ({
  children,
  text,
  title,
}) => {
  return (
    <div>
      {!!title && <h3>{title}</h3>}
      <p>{text}</p>
      {children}
    </div>
  );
};

export default React.memo(MessageBody);
