import { MessageActionDocupload } from '../constants';

const isMessageActionDocupload = (
  value: unknown,
): value is MessageActionDocupload =>
  Object.values(MessageActionDocupload).includes(
    value as MessageActionDocupload,
  );

export default isMessageActionDocupload;
