import { MessagesAction } from '../constants';
import {
  MessagesPollingStartedAction,
  MessagesPollingEndedAction,
  MessagesLogoutAction,
} from '../types';

export {
  type MessagesReadActionApiRequest,
  type MessagesReadActionType,
  messagesReadAction,
  messagesReadReducer,
} from './messagesReadAction';

export {
  type MessagesGetActionApiRequest,
  type MessagesGetActionType,
  messagesGetAction,
  messagesGetReducer,
} from './messagesGetAction';

export {
  type MessagesPostActionApiRequest,
  type MessagesPostActionType,
  messagesPostResetAction,
  messagesPostReducer,
  messagesPostAction,
} from './messagesPostAction';

export {
  messagesSendResetAction,
  messagesSendAction,
} from './messagesSendAction';

export const messagesPollingStartedAction: MessagesPollingStartedAction =
  payload => ({
    type: MessagesAction.POLLING_STARTED,
    ...payload,
  });

export const messagesPollingEndedAction: MessagesPollingEndedAction = () => ({
  type: MessagesAction.POLLING_ENDED,
});

export const messagesLogoutAction: MessagesLogoutAction = () => ({
  type: MessagesAction.LOGOUT,
});
