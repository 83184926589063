import React, {
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  fallback: React.ReactNode;
};

type State = {
  isError: boolean;
  isLoading: boolean;
  url: string | undefined;
};

const initialState: State = {
  isError: false,
  isLoading: true,
  url: undefined,
};

const Image: React.FunctionComponent<ImageProps> = ({
  alt = '',
  fallback,
  src,
  ...props
}) => {
  const [{ isError, isLoading, url }, setState] = useState<State>(initialState);

  const handleError = useCallback(
    () =>
      setState(state => {
        return { ...state, isError: true };
      }),
    [],
  );

  const handleLoad = useCallback(
    () =>
      setState(state => {
        return { ...state, isLoading: false };
      }),
    [],
  );

  useEffect(() => {
    setState({ isError: false, isLoading: true, url: src });
  }, [src]);

  const className = clsx(styles['image'], isLoading && styles['loading']);

  return (isError || !src) && React.isValidElement(fallback) ? (
    fallback
  ) : (
    <img
      {...props}
      alt={alt}
      className={className}
      onError={!isError ? handleError : undefined}
      onLoad={handleLoad}
      src={(isError || !url) && typeof fallback === 'string' ? fallback : url}
    />
  );
};

export default React.memo(Image);
