import React from 'react';

import { UseAuthRoute } from 'modules/auth';
import Login from 'components/mobile/Login';
import { Dialogs } from 'modules/dialogs';

import styles from './styles.module.scss';

type TemplateProps = ReturnType<UseAuthRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  ...props
}) => {
  return (
    <React.Fragment>
      <main className={styles.login}>
        <Login isLoading={isLoading} {...props} />
        {isLoading && <div className={styles.loading}></div>}
      </main>
      <div className={styles['modals']}>
        <Dialogs />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Template);
