import { useCallback } from 'react';

import { messagesReadAction } from '../../../actions';
import { Dispatch } from 'modules/redux';

type UseHandleInView = (props: {
  caseId: number | null;
  dispatch: Dispatch;
}) => (id: number) => void;

const useHandleInView: UseHandleInView = ({ caseId, dispatch }) => {
  const handleInView = useCallback(
    (messageId: number) =>
      !!caseId && dispatch(messagesReadAction({ caseId, messageId })),
    [caseId, dispatch],
  );

  return handleInView;
};

export default useHandleInView;
