import { push, replace } from 'redux-first-history';

import { Dispatch } from 'modules/redux';

type MiddlewareNavigate = (
  dispatch: Dispatch,
  path: string,
  type?: 'push' | 'replace',
) => void;

const middlewareNavigate: MiddlewareNavigate = (dispatch, path, type) =>
  dispatch(type === 'replace' ? replace(path) : push(path));

export default middlewareNavigate;
