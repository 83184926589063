import * as rdd from 'react-device-detect';
import { Platform } from './types';

export const isAdmin =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'uat';

export const isAndroid: Platform['isAndroid'] = rdd.isAndroid;

export const isCapacitor: Platform['isCapacitor'] =
  !!process.env.REACT_APP_CAPACITOR;

export const isChrome: Platform['isChrome'] = rdd.isChrome;

export const isDesktop: Platform['isDesktop'] = !isCapacitor && !rdd.isMobile;

export const isDevelopment = process.env.NODE_ENV === 'development';

export const isFirefox: Platform['isFirefox'] = rdd.isFirefox;

export const isIE: Platform['isIE'] = rdd.isIE;

export const isIOS: Platform['isIOS'] = rdd.isIOS;

export const isMacOs: Platform['isMacOs'] =
  !isIOS && rdd.isMacOs && !rdd.isTablet;

export const isMobile: Platform['isMobile'] = rdd.isMobile || isCapacitor;

export const isMobileOnly: Platform['isMobileOnly'] = rdd.isMobileOnly;

export const isSafari: Platform['isSafari'] = rdd.isSafari;

export const isStandalone: Platform['isStandalone'] =
  'standalone' in window.navigator && !!window.navigator.standalone;

export const isTablet: Platform['isTablet'] = rdd.isTablet;

export const isWindows: Platform['isWindows'] = rdd.isWindows;

export const osVersion: Platform['osVersion'] = rdd.osVersion;

export const platform: Platform = {
  isAdmin,
  isAndroid,
  isCapacitor,
  isChrome,
  isDesktop,
  isDevelopment,
  isFirefox,
  isIE,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isStandalone,
  isTablet,
  isWindows,
  osVersion,
};
