import { Dispatch, RootState } from 'modules/redux';
import { networkEventAction } from '../actions';
import { Network } from '../types';

export type UpdateNetwork = (
  dispatch: Dispatch,
  getState: () => RootState,
  payload: Omit<Network, 'timer'>,
) => void;

const updateNetwork: UpdateNetwork = (dispatch, getState, payload) =>
  getState().network.status !== payload.status &&
  dispatch(networkEventAction(payload));

export default updateNetwork;
