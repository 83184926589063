import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { modalDestroyAction, modalHideAction } from 'modules/modals/actions';
import { analyticsTrackVideo, getVideoModal, shouldClose } from './utils';
import { videoPluginInit, videoPluginDestroy } from './plugin';
import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { RouterActionType } from 'modules/router';
import { VideoActionType } from './types';
import { VideoAction } from './constants';
import {
  videoDestroyAction,
  videoLogoutAction,
  videoCloseAction,
} from './actions';

const videoMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | RouterActionType | VideoActionType) => {
    const html = document.getElementsByTagName('html')[0];
    const isCapacitor = getState().platform.isCapacitor;
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE:
        nextAction = next(action);
        shouldClose(getState()) &&
          dispatch(isCapacitor ? videoDestroyAction() : videoCloseAction());
        break;

      case AuthAction.LOGOUT:
        nextAction = next(action);
        dispatch(videoLogoutAction());
        break;

      case VideoAction.PLUGIN_INIT: {
        if (isCapacitor) {
          html.classList.add('platform-video');
          videoPluginInit(action);
        }
        break;
      }

      case VideoAction.PLUGIN_CLOSE:
      case VideoAction.SHOW_FAILURE: {
        const modalId = getVideoModal(getState())?.modalId;
        nextAction = next(action);
        if (isCapacitor) {
          html.classList.remove('platform-video');
        }
        !!modalId && dispatch(modalDestroyAction(modalId));
        break;
      }

      case VideoAction.CLOSE: {
        const modalId = getVideoModal(getState())?.modalId;
        nextAction = next(action);
        !!modalId && dispatch(modalHideAction(modalId));
        break;
      }

      case VideoAction.DESTROY: {
        const modalId = getVideoModal(getState())?.modalId;
        nextAction = next(action);
        if (isCapacitor) {
          !!modalId && videoPluginDestroy(modalId);
          html.classList.remove('platform-video');
        }
        !!modalId && dispatch(modalDestroyAction(modalId));
        break;
      }

      case VideoAction.LOGOUT: {
        const modalId = getVideoModal(getState())?.modalId;
        if (isCapacitor) {
          !!modalId && videoPluginDestroy(modalId);
          html.classList.remove('platform-video');
        }
        break;
      }
    }

    nextAction = analyticsTrackVideo({
      action,
      getState,
      next,
      nextAction,
    });

    return nextAction ? nextAction : next(action);
  };

export default videoMiddleware;
