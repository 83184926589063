import React from 'react';
import clsx from 'clsx';

import { ReactComponent as DocumentsSVG } from 'modules/theme/images/icons/desktop-menu/documents.svg';
import { ReactComponent as LoanSVG } from 'modules/theme/images/icons/desktop-menu/loan.svg';
import { ReactComponent as MessagesSVG } from 'modules/theme/images/icons/desktop-menu/messages.svg';
import { ReactComponent as StatusSVG } from 'modules/theme/images/icons/desktop-menu/status.svg';
import { ReactComponent as TeamSVG } from 'modules/theme/images/icons/desktop-menu/team.svg';
import { useCasesSwitch } from 'modules/states/cases';
import { useAuthSelector } from 'modules/auth';
import { useNavigation } from 'modules/router';
import { NavItemType } from './constants';
import MenuButton from './MenuButton';
import MenuLink from './NavLink';

import styles from './styles.module.scss';

type NavigationProps = {
  handleBlur: (id: string) => void;
  handleFocus: (id: string) => void;
  handleMouseOver: (id: string) => void;
  handleMouseOut: (id: string) => void;
  gradientValue: string;
  navRef: React.MutableRefObject<HTMLElement | null>;
};

const Navigation: React.FunctionComponent<NavigationProps> = ({
  navRef,
  ...props
}) => {
  const { disableSwitch, handleSwitch } = useCasesSwitch();
  const { handleLogout } = useAuthSelector();

  const {
    linkContact,
    linkDocuments,
    linkLoan,
    linkMessages,
    linkStatus,
    linkTeam,
    messageCount,
    messageCountActive,
  } = useNavigation();

  const className = clsx(
    styles.navigation,
    messageCount && styles['message-count'],
    messageCountActive && styles['message-count-active'],
  );
  return (
    <nav aria-label="Main" className={className} ref={navRef}>
      <ul>
        <MenuLink
          label="My Progress"
          route={linkStatus}
          type={NavItemType.PRIMARY}
          {...props}>
          <StatusSVG />
        </MenuLink>

        <MenuLink
          label="My Messages"
          route={linkMessages}
          type={NavItemType.PRIMARY}
          {...props}>
          <MessagesSVG />
          {messageCount > 0 && <span>{messageCount}</span>}
        </MenuLink>

        <MenuLink
          label="My Loan"
          route={linkLoan}
          type={NavItemType.PRIMARY}
          {...props}>
          <LoanSVG />
        </MenuLink>

        <MenuLink
          label="My Team"
          route={linkTeam}
          type={NavItemType.PRIMARY}
          {...props}>
          <TeamSVG />
        </MenuLink>

        <MenuLink
          label="My Documents"
          route={linkDocuments}
          type={NavItemType.PRIMARY}
          {...props}>
          <DocumentsSVG />
        </MenuLink>

        <MenuButton
          disabled={disableSwitch}
          label="Switch Cases"
          handleClick={handleSwitch}
          type={NavItemType.SECONDARY}
          {...props}
        />

        <MenuLink
          label="Contact"
          route={linkContact}
          type={NavItemType.SECONDARY}
          {...props}
        />

        <MenuButton
          label="Logout"
          handleClick={handleLogout}
          type={NavItemType.SECONDARY}
          {...props}
        />
      </ul>
    </nav>
  );
};

export default React.memo(Navigation);
