import { DocuploadError } from '../constants';
import { isError } from 'modules/utils';

const getError = (error: unknown, fallback: DocuploadError) =>
  new Error(
    isError(error) &&
    Object.values(DocuploadError).includes(error.message as DocuploadError)
      ? error.message
      : fallback,
  );

export default getError;
