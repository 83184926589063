import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { menuLogoutAction } from './actions';
import { MenuActionType } from './types';

const menuMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | MenuActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.LOGOUT:
        nextAction = next(action);
        dispatch(menuLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default menuMiddleware;
