import { CasesGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';

type IsActive = (props: {
  request: CasesGetActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ state }) => !!state.cases.isLoading;

export default isActive;
