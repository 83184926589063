import getMessageName from './getMessageName';

type GetMessageRecipient = (
  firstName: string,
  surname: string,
) => string | undefined;

const getMessageRecipient: GetMessageRecipient = (firstName, surname) => {
  const recipient = getMessageName(firstName, surname);
  return recipient ? `Hi, ${recipient}` : undefined;
};

export default getMessageRecipient;
