import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { teamLogoutAction } from './actions';
import { TeamActionType } from './types';
import { abortTeam } from './utils';

const teamMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | TeamActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.LOGOUT:
        nextAction = next(action);
        abortTeam(getState().team);
        dispatch(teamLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default teamMiddleware;
