import {
  BiometryType,
  NativeBiometric,
} from '@capgo/capacitor-native-biometric';

import { AuthBiometricStatus } from '../../constants';

type GetStatus = () => Promise<AuthBiometricStatus>;

const getStatus: GetStatus = async () => {
  try {
    const { biometryType, isAvailable } = await NativeBiometric.isAvailable();
    if (isAvailable)
      switch (true) {
        case biometryType === BiometryType.FACE_ID:
          return AuthBiometricStatus.FACE;
        case biometryType === BiometryType.TOUCH_ID:
          return AuthBiometricStatus.TOUCH;
        default:
          return AuthBiometricStatus.UNKNOWN;
      }
    return AuthBiometricStatus.UNAVAILABLE;
  } catch {
    return AuthBiometricStatus.UNAVAILABLE;
  }
};

export default getStatus;
