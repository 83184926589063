import React from 'react';
import clsx from 'clsx';

import { ReactComponent as Info } from 'modules/theme/images/icons/loan/info.svg';
import { LoanData } from 'modules/states/loan';
import Image from 'components/shared/Image';
import { useTextLength } from './hooks';

import styles from './styles.module.scss';

type LoanProps = {
  loan: LoanData;
};

const Loan: React.FunctionComponent<LoanProps> = ({
  loan: {
    lender: { lenderLogo, lenderName },
    loan: {
      loanAdvance,
      loanAnnualRate,
      loanApr,
      loanInitialTerm,
      loanInitialRate,
      loanInterestRateType,
      loanInitialRepayment,
      loanRepayment,
      loanStandardTerm,
      loanTerm,
    },
  },
}) => {
  const length = useTextLength(
    loanAnnualRate,
    loanInitialRate,
    loanInitialRepayment,
    loanRepayment,
  );

  const classNames = clsx(styles.loan, styles[`char-length-${length}`]);

  return (
    <div className={classNames}>
      <section>
        <div>
          <h2>Lender</h2>
          {lenderLogo ? (
            <Image
              alt={lenderName}
              fallback={<p>{lenderName}</p>}
              src={lenderLogo}
            />
          ) : (
            <p>{lenderName}</p>
          )}
        </div>
        <div>
          <h2>Amount</h2>
          <p>{loanAdvance}</p>
        </div>
        <div>
          {loanStandardTerm ? (
            <React.Fragment>
              <h3>Initial Rate</h3>
              <strong>{loanInitialRate}*</strong>
              <p>
                <span>{loanInterestRateType}</span>
                <span> for {loanInitialTerm} months</span>
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3>Rate</h3>
              <strong>{loanAnnualRate}*</strong>
              <p>
                <span>{loanInterestRateType}</span>
                <span> for {loanTerm} months</span>
              </p>
            </React.Fragment>
          )}
        </div>
        <div>
          {loanStandardTerm ? (
            <React.Fragment>
              <h3>Initial Repayment</h3>
              <strong>{loanInitialRepayment}*</strong>
              <p>For {loanInitialTerm} months</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3>Repayment</h3>
              <strong>{loanRepayment}*</strong>
              <p>For {loanTerm} months</p>
            </React.Fragment>
          )}
        </div>
      </section>
      <section>
        <h2>Loan Details</h2>
        {!!loanStandardTerm && (
          <React.Fragment>
            <div>
              <h3>Standard Repayment</h3>
              <p>{loanRepayment}</p>
            </div>
            <div>
              <h3>Standard Rate</h3>
              <p>{loanAnnualRate}</p>
            </div>
            <div>
              <h3>Standard Term</h3>
              <p>{loanStandardTerm} months</p>
            </div>
          </React.Fragment>
        )}
        <div>
          <h3>APRC</h3>
          <p>{loanApr}</p>
        </div>
        <div>
          <h3>Term</h3>
          <p>{loanTerm} months</p>
        </div>
        <p>
          <Info />
          This is a basic summary only. Please refer to your ESIS or KFI
          document for full details of the product we have recommended.
        </p>
      </section>
    </div>
  );
};

export default React.memo(Loan);
