import { ThunkMiddleware } from 'redux-thunk';
import { isIE } from 'react-device-detect';
import { logger } from 'redux-logger';
import { Middleware } from 'redux';

import { platform } from 'modules/services/platform';

import notificationsMiddleware from 'modules/services/notifications/middleware';
import splashScreenMiddleware from 'modules/services/splashscreen/middleware';
import certificateMiddleware from 'modules/services/certificate/middleware';
import orientationMiddleware from 'modules/services/orientation/middleware';
import analyticsMiddleware from 'modules/services/analytics/middleware';
import appStatusMiddleware from 'modules/services/appstatus/middleware';
import jailbreakMiddleware from 'modules/services/jailbreak/middleware';
import statusBarMiddleware from 'modules/services/statusbar/middleware';
import keyboardMiddleware from 'modules/services/keyboard/middleware';
import platformMiddleware from 'modules/services/platform/middleware';
import securityMiddleware from 'modules/services/security/middleware';
import networkMiddleware from 'modules/services/network/middleware';
import versionMiddleware from 'modules/services/version/middleware';
import configMiddleware from 'modules/services/config/middleware';
import badgeMiddleware from 'modules/services/badge/middleware';
import themeMiddleware from 'modules/services/theme/middleware';
import videoMiddleware from 'modules/services/video/middleware';

import documentsMiddleware from 'modules/states/documents/middleware';
import messagesMiddleware from 'modules/states/messages/middleware';
import statusMiddleware from 'modules/states/status/middleware';
import casesMiddleware from 'modules/states/cases/middleware';
import loanMiddleware from 'modules/states/loan/middleware';
import teamMiddleware from 'modules/states/team/middleware';
import userMiddleware from 'modules/states/user/middleware';
import menuMiddleware from 'modules/states/menu/middleware';

import docuploadMiddleware from 'modules/docupload/middleware';
import sessionMiddleware from 'modules/session/middleware';
import dialogsMiddleware from 'modules/dialogs/middleware';
import modalsMiddleware from 'modules/modals/middleware';
import onfidoMiddleware from 'modules/onfido/middleware';
import routerMiddleware from 'modules/router/middleware';
import authMiddleware from 'modules/auth/middleware';
import appMiddleware from 'modules/app/middleware';

const { isAdmin } = platform;
const isLogger = true;

const middleware = (): Array<ThunkMiddleware | Middleware> => {
  const middleware = [
    dialogsMiddleware(),
    modalsMiddleware(),

    documentsMiddleware(),
    messagesMiddleware(),
    statusMiddleware(),
    casesMiddleware(),
    loanMiddleware(),
    teamMiddleware(),
    userMiddleware(),
    menuMiddleware(),

    notificationsMiddleware(),
    splashScreenMiddleware(),
    certificateMiddleware(),
    orientationMiddleware(),
    analyticsMiddleware(),
    appStatusMiddleware(),
    jailbreakMiddleware(),
    statusBarMiddleware(),
    keyboardMiddleware(),
    platformMiddleware(),
    badgeMiddleware(),
    themeMiddleware(),
    videoMiddleware(),

    docuploadMiddleware(),
    securityMiddleware(),
    onfidoMiddleware(),

    networkMiddleware(),
    sessionMiddleware(),
    versionMiddleware(),
    configMiddleware(),
    routerMiddleware(),
    authMiddleware(),
    appMiddleware(),
  ];
  isAdmin && isLogger && !isIE && middleware.push(logger);
  return middleware;
};

export default middleware;
