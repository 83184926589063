import {
  BiometricPluginDelete,
  BiometricPluginVerifyCredentials,
  BiometricPluginGetCredentials,
  BiometricPluginStatus,
  BiometricPluginSave,
} from './types';
import {
  deleteCredentials,
  verifyCredentials,
  saveCredentials,
  getCredentials,
  getStatus,
} from './utils';

export const biometricPluginGetCredentials: BiometricPluginGetCredentials =
  getCredentials;

export const biometricPluginDelete: BiometricPluginDelete = deleteCredentials;

export const biometricPluginSave: BiometricPluginSave = saveCredentials;

export const biometricPluginStatus: BiometricPluginStatus = getStatus;

export const biometricPluginVerifyCredentials: BiometricPluginVerifyCredentials =
  verifyCredentials;
