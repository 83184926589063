import { useCallback } from 'react';

import { MessageActionOnfido } from 'modules/states/messages';
import { shallowEqual, useSelector } from 'modules/redux';
import { UseOnfidoMessage } from './types';

const useOnfidoMessage: UseOnfidoMessage = ({
  message: {
    callToAction: { action, startURL },
    messageId,
  },
  onClick,
}) => {
  const { messageId: docuploadId } = useSelector(
    state => state.docupload,
    shallowEqual,
  );
  const { messageId: onfidoId } = useSelector(
    state => state.onfido,
    shallowEqual,
  );

  const active = messageId === onfidoId;

  const disabled =
    action !== MessageActionOnfido.REQUESTED || !!docuploadId || !!onfidoId;

  const handleClick = useCallback(
    () => !active && !disabled && onClick(messageId as number, startURL),
    [active, disabled, messageId, onClick, startURL],
  );

  return { active, disabled, handleClick };
};

export default useOnfidoMessage;
