import { produce } from 'immer';

import { MessagesState, MessagesActionType } from '../../types';
import { newMessages, updateMessages } from './utils';
import { MessagesAction } from '../../constants';
import { findMessages } from '../../utils';

function messagesGetReducer(state: MessagesState, action: MessagesActionType) {
  switch (action.type) {
    case MessagesAction.GET_FAILURE:
      return produce(state, (draftState: MessagesState) => {
        const messages = findMessages(draftState.data, action.request.caseId);
        if (messages) {
          messages.actionId = null;
          messages.isError = action.error;
          messages.isLoading = false;
        }
      });

    case MessagesAction.GET_STARTED:
      return produce(state, (draftState: MessagesState) => {
        const messages =
          findMessages(draftState.data, action.request.caseId) ||
          newMessages(draftState.data, action.request.caseId);
        messages.actionId = action.actionId;
        messages.isError = false;
        messages.isLoading = action.controller || true;
      });

    case MessagesAction.GET_SUCCESS:
      return produce(state, (draftState: MessagesState) => {
        const messages = findMessages(draftState.data, action.request.caseId);
        if (messages) {
          messages.actionId = null;
          messages.isDirty = true;
          messages.isError = false;
          messages.isLoading = false;
          !messages.data && (messages.data = []);
          updateMessages(messages, action.data);
        }
      });

    default:
      return state;
  }
}

export default messagesGetReducer;
