import { platform } from 'modules/services/platform';
import {
  DialogPluginShowNotificationsCapacitor,
  DialogPluginShowNotifications,
} from './types';
import {
  notificationsPluginRequestPermissions,
  NotificationsStatus,
} from 'modules/services/notifications';

const { isCapacitor } = platform;

const dialogPluginShowNotificationsCapacitor: DialogPluginShowNotificationsCapacitor =
  async ({ onCancel, onConfirm }) => {
    try {
      const status = await notificationsPluginRequestPermissions();
      status === NotificationsStatus.GRANTED
        ? !!onConfirm && onConfirm()
        : !!onCancel && onCancel();
    } catch {
      !!onCancel && onCancel();
    }
  };

const dialogPluginShowNotifications: DialogPluginShowNotifications = ({
  onCancel,
  onConfirm,
}) =>
  isCapacitor &&
  dialogPluginShowNotificationsCapacitor({ onCancel, onConfirm });

export default dialogPluginShowNotifications;
