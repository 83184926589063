import { VideoPluginCloseAction, VideoPluginInitAction } from './types';
import { VideoAction } from '../../constants';

export const videoPluginInitAction: VideoPluginInitAction = payload => ({
  type: VideoAction.PLUGIN_INIT,
  ...payload,
});

export const videoPluginCloseAction: VideoPluginCloseAction = () => ({
  type: VideoAction.PLUGIN_CLOSE,
});
