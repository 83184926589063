import { MessagesSendAction, MessagesSendResetAction } from './types';
import { timeout } from 'modules/redux/factories/utils';
import { cleanString } from 'modules/utils';
import {
  messagesPostResetAction,
  messagesPostAction,
} from '../messagesPostAction';

export const messagesSendAction: MessagesSendAction = ({
  caseId,
  reset,
  values,
}) => {
  return async (dispatch, getState) => {
    const startTime = new Date().getTime();
    try {
      const message = cleanString(values.message);
      reset();
      await dispatch(messagesPostAction({ caseId, message }));
      await timeout(startTime, 1000);
    } catch (error) {
      await timeout(startTime, 1000);
      reset(values);
    }
  };
};

export const messagesSendResetAction: MessagesSendResetAction = caseId => {
  return async (dispatch, getState) => {
    dispatch(messagesPostResetAction(caseId));
  };
};
