import { StatusType } from 'modules/states/status';

type IsCaseCancelled = (status: StatusType) => boolean;

const isCaseCancelled: IsCaseCancelled = status =>
  [
    StatusType.CAN,
    StatusType.CAN_LEN,
    StatusType.CAN_REF,
    StatusType.CAN_RES,
  ].includes(status);

export default isCaseCancelled;
