export enum AuthAction {
  INIT = 'AUTH_INIT',

  LOGOUT = 'AUTH_LOGOUT',

  BIOMETRIC_LOGIN_STARTED = 'AUTH_BIOMETRIC_LOGIN_STARTED',
  BIOMETRIC_LOGIN_SUCCESS = 'AUTH_BIOMETRIC_LOGIN_SUCCESS',
  BIOMETRIC_LOGIN_FAILURE = 'AUTH_BIOMETRIC_LOGIN_FAILURE',
  BIOMETRIC_LOGIN_STATUS = 'AUTH_BIOMETRIC_LOGIN_STATUS',
  BIOMETRIC_LOGIN_RESET = 'AUTH_BIOMETRIC_LOGIN_RESET',

  BIOMETRIC_QUESTION_STARTED = 'AUTH_BIOMETRIC_QUESTION_STARTED',
  BIOMETRIC_QUESTION_SUCCESS = 'AUTH_BIOMETRIC_QUESTION_SUCCESS',
  BIOMETRIC_QUESTION_FAILURE = 'AUTH_BIOMETRIC_QUESTION_FAILURE',

  LOGIN_CHANGE_PASSWORD = 'AUTH_LOGIN_CHANGE_PASSWORD',
  LOGIN_STARTED = 'AUTH_LOGIN_STARTED',
  LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE',
  LOGIN_RESET = 'AUTH_LOGIN_RESET',

  CHANGE_PASSWORD_STARTED = 'AUTH_CHANGE_PASSWORD_STARTED',
  CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'AUTH_CHANGE_PASSWORD_FAILURE',
}

export enum AuthBiometricStatus {
  FACE = 'FACE',
  TOUCH = 'TOUCH',
  UNAVAILABLE = 'UNAVAILABLE',
  UNKNOWN = 'UNKNOWN',
}

export enum AuthBiometricError {
  ANSWERED = 'ANSWERED',
  CANCELLED = 'CANCELLED',
  KEYCHAIN = 'KEYCHAIN',
  REJECTED = 'REJECTED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum AuthLoginError {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  LOCKED = 'LOCKED',
}
