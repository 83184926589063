import { registerPlugin } from '@capacitor/core';

import { SecurityStatus } from './constants';
import logger from 'modules/logger';
import {
  SecurityPluginUnauthenticated,
  SecurityPluginAuthenticated,
  SecurityPlugin,
} from './types';

const securityPlugin = registerPlugin<SecurityPlugin>('FluentSecurity');

export const securityPluginAuthenticated: SecurityPluginAuthenticated = () =>
  securityPlugin.authenticated({
    isAuthenticated: SecurityStatus.AUTHENTICATED,
  });

export const securityPluginUnauthenticated: SecurityPluginUnauthenticated =
  () =>
    securityPlugin.authenticated({
      isAuthenticated: SecurityStatus.UNAUTHENTICATED,
    });

export const securityPluginInit = async () => {
  await securityPlugin.reset();
  securityPlugin.addListener('error', data => logger.error(data.error));
};
