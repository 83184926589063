import React from 'react';

import { TextAreaProps } from './types';
import { useHandlers, useTabIndex } from './hooks';

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  autoFocus,
  children,
  disabled,
  id,
  inputRef,
  invalid,
  name,
  onBlur,
  onFocus,
  readOnly,
  rows,
  tabIndex,
  value,
  ...props
}) => {
  const { handleBlur, handleFocus } = useHandlers(
    disabled,
    onBlur,
    onFocus,
    readOnly,
  );
  const tabindex = useTabIndex(disabled, readOnly, tabIndex);

  return (
    <div>
      <textarea
        autoFocus={!disabled && autoFocus}
        disabled={disabled}
        id={id ? id : name}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        readOnly={readOnly}
        ref={inputRef}
        rows={rows ? rows : 6}
        tabIndex={tabindex}
        value={value || ''}
        {...props}
      />
      {children}
    </div>
  );
};

export default React.memo(TextArea);
