import { Middleware } from 'redux';

import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { versionInitAction } from './actions';
import { versionPluginInit } from './plugin';
import { VersionAction } from './constants';
import { VersionActionType } from './types';

const appStatusMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | VersionActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(versionInitAction());
        break;

      case VersionAction.INIT:
        action.payload = await versionPluginInit();
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default appStatusMiddleware;
