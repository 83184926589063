import { TeamGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';
import findTeam from './findTeam';

type IsPresent = (props: {
  actionId: string;
  request: TeamGetActionApiRequest;
  state: RootState;
}) => boolean;

const isPresent: IsPresent = ({ actionId, request, state }) =>
  actionId === findTeam(state.team, request.caseId)?.actionId;

export default isPresent;
