import findMessages from '../../../utils/findMessages';
import { MessagesPostActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';

type IsActive = (props: {
  request: MessagesPostActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ request, state }) =>
  !!findMessages(state.messages.data, request.caseId)?.isSending;

export default isActive;
