import { produce } from 'immer';

import { findDocuments, newDocuments, updateDocuments } from './utils';
import { DocumentsState, DocumentsActionType } from './types';
import { DocumentsAction } from './constants';

const initialState = (): DocumentsState => {
  return [];
};

function documentsReducer(state = initialState(), action: DocumentsActionType) {
  switch (action.type) {
    case DocumentsAction.GET_FAILURE:
      return produce(state, (draftState: DocumentsState) => {
        const documents = findDocuments(draftState, action.request.caseId);
        if (documents) {
          documents.actionId = null;
          documents.isError = action.error;
          documents.isLoading = false;
        }
      });

    case DocumentsAction.GET_STARTED:
      return produce(state, (draftState: DocumentsState) => {
        const documents =
          findDocuments(draftState, action.request.caseId) ||
          newDocuments(draftState, action.request.caseId);
        documents.actionId = action.actionId;
        documents.isError = false;
        documents.isLoading = action.controller || true;
      });

    case DocumentsAction.GET_SUCCESS:
      return produce(state, (draftState: DocumentsState) => {
        const documents = findDocuments(draftState, action.request.caseId);
        if (documents) {
          documents.actionId = null;
          documents.isDirty = true;
          documents.isError = false;
          documents.isLoading = false;
          documents.data = updateDocuments(action.data);
        }
      });

    case DocumentsAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default documentsReducer;
