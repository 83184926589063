import { DocumentsData, DocumentsGetActionApiResponseItem } from '../types';
import newDocumentUpload from './newDocumentUpload';
import newDocumentCase from './newDocumentCase';
import { DocumentType } from '../constants';

type NewDocument = (
  array: DocumentsData,
  data: DocumentsGetActionApiResponseItem,
) => void;

const newDocument: NewDocument = (array, data) => {
  const document =
    data.type === DocumentType.CASE
      ? newDocumentCase(data)
      : newDocumentUpload(data);
  !!document && array.push(document);
};

export default newDocument;
