import { useCallback } from 'react';

import { Dispatch } from 'modules/redux';
import {
  authChangePasswordAction,
  AuthChangePasswordModel,
} from '../../../actions';

type UseHandleSubmit = (
  dispatch: Dispatch,
) => (result: AuthChangePasswordModel) => void;

const useHandleSubmit: UseHandleSubmit = dispatch => {
  const handleSubmit: ReturnType<UseHandleSubmit> = useCallback(
    credentials => dispatch(authChangePasswordAction(credentials)),
    [dispatch],
  );

  return handleSubmit;
};

export default useHandleSubmit;
