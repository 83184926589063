import { Documents, DocumentsState } from '../types';

type NewDocuments = (array: DocumentsState, caseId: number) => Documents;

const newDocuments: NewDocuments = (array, caseId) => {
  const documents: Documents = {
    actionId: null,
    caseId,
    data: null,
    isDirty: false,
    isError: false,
    isLoading: false,
  };
  array.push(documents);
  return documents;
};

export default newDocuments;
