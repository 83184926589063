import React from 'react';

import ModalDocupload, { ModalDocuploadProps } from '../ModalDocupload';
import ModalOnfido, { ModalOnfidoProps } from '../ModalOnfido';
import ModalCases, { ModalCasesProps } from '../ModalCases';
import ModalVideo, { ModalVideoProps } from '../ModalVideo';
import { shallowEqual, useSelector } from 'modules/redux';
import { ModalType } from 'modules/modals/constants';
import { Modal } from '../../types';

const RenderModal = React.memo<Modal>(({ type, ...modal }) => {
  switch (type) {
    case ModalType.CASES:
      return <ModalCases {...(modal as ModalCasesProps)} />;
    case ModalType.DOCUMENT_UPLOAD:
      return <ModalDocupload {...(modal as ModalDocuploadProps)} />;
    case ModalType.ONFIDO:
      return <ModalOnfido {...(modal as ModalOnfidoProps)} />;
    case ModalType.VIDEO:
      return <ModalVideo {...(modal as ModalVideoProps)} />;
    default:
      return null;
  }
});

const Modals: React.FunctionComponent = () => {
  const modals = useSelector(state => state.modals, shallowEqual);
  return modals.length > 0 ? (
    <React.Fragment>
      {modals.map(
        modal =>
          !modal.placeholder && (
            <RenderModal key={modal.modalId as string} {...modal} />
          ),
      )}
    </React.Fragment>
  ) : null;
};

export default Modals;
