import { useCallback, useMemo } from 'react';

import { StatusStep } from 'modules/states/status';

const getLabel = (step: StatusStep) => {
  switch (step) {
    case StatusStep.CANCELLED:
      return 'Cancelled';
    case StatusStep.DECLINED:
      return 'Declined';
    case StatusStep.STEP1:
      return 'Quote Sent';
    case StatusStep.STEP2:
      return 'Pack Received';
    case StatusStep.STEP3:
      return 'Approval Checks';
    case StatusStep.STEP4:
      return 'Lender Sign Off';
    case StatusStep.STEP5:
    default:
      return 'Loan Decision';
  }
};

type UseCaseItem = (props: {
  caseId: number;
  disabled?: boolean;
  onClick: (caseId: number) => void;
  step: StatusStep;
}) => {
  handleClick: () => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLLIElement>) => void;
  handleMouseDown: (event: React.MouseEvent<HTMLLIElement>) => void;
  label: string;
};

const useCaseItem: UseCaseItem = ({ caseId, disabled, onClick, step }) => {
  const handleClick: ReturnType<UseCaseItem>['handleClick'] = useCallback(
    () => !!onClick && !disabled && onClick(caseId),
    [caseId, disabled, onClick],
  );

  const handleKeyDown: ReturnType<UseCaseItem>['handleKeyDown'] = useCallback(
    event => !!onClick && !disabled && event.key === 'Enter' && onClick(caseId),
    [caseId, disabled, onClick],
  );

  const handleMouseDown: ReturnType<UseCaseItem>['handleMouseDown'] =
    useCallback(event => event.preventDefault(), []);

  const label = useMemo(() => getLabel(step), [step]);

  return { handleClick, handleKeyDown, handleMouseDown, label };
};

export default useCaseItem;
