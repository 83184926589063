import { createDefaultAction } from 'modules/redux/factories';
import { isActive, isPresent } from './utils';
import { DocumentsAction } from './constants';
import { API } from 'modules/api';
import {
  DocumentsGetFailureAction,
  DocumentsGetStartedAction,
  DocumentsGetSuccessAction,
  DocumentsLogoutAction,
  DocumentsGetAction,
} from './types';

export const documentsGetAction: DocumentsGetAction = request =>
  createDefaultAction({
    actionStarted: documentsGetStartedAction,
    actionSuccess: documentsGetSuccessAction,
    actionFailure: documentsGetFailureAction,
    endpoint: API.GET_DOCUMENTS,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const documentsGetStartedAction: DocumentsGetStartedAction = payload => ({
  type: DocumentsAction.GET_STARTED,
  ...payload,
});

const documentsGetSuccessAction: DocumentsGetSuccessAction = payload => ({
  type: DocumentsAction.GET_SUCCESS,
  ...payload,
});

const documentsGetFailureAction: DocumentsGetFailureAction = payload => ({
  type: DocumentsAction.GET_FAILURE,
  ...payload,
});

export const documentsLogoutAction: DocumentsLogoutAction = () => ({
  type: DocumentsAction.LOGOUT,
});
