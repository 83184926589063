import React from 'react';
import useFitText from 'use-fit-text';

import styles from './styles.module.scss';

type FluidTextProps = {
  maxSize: number;
  minSize: number;
  text: string;
};

const FluidText: React.FunctionComponent<FluidTextProps> = ({
  maxSize,
  minSize,
  text,
}) => {
  const { fontSize, ref } = useFitText({
    maxFontSize: maxSize,
    minFontSize: minSize,
  });

  return (
    <div className={styles['fluid-text']}>
      <p ref={ref} style={{ fontSize: `${parseInt(fontSize) / 10}rem` }}>
        {text}
      </p>
    </div>
  );
};

export default React.memo(FluidText);
