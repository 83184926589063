import findMessages from '../../../utils/findMessages';
import { MessagesGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';

type IsActive = (props: {
  request: MessagesGetActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ request, state }) =>
  !!findMessages(state.messages.data, request.caseId)?.isLoading;

export default isActive;
