import { useCallback, useLayoutEffect, useRef } from 'react';

import { getScrollOffset, shouldScroll } from './utils';
import { usePlatformContext } from 'modules/services/platform';

type UseHandleScroll = (count?: number) => React.RefObject<HTMLElement>;

const useHandleScroll: UseHandleScroll = count => {
  const scrollRef = useRef<HTMLElement>(null);
  const { isDesktop } = usePlatformContext();
  const firstUpdate = useRef(true);

  const handleScroll = useCallback(() => {
    const target = isDesktop ? window : scrollRef.current;
    target &&
      shouldScroll(target) &&
      target.scroll({
        behavior: firstUpdate.current ? 'auto' : 'smooth',
        top: getScrollOffset(target),
      });
    firstUpdate.current = false;
  }, [isDesktop]);

  useLayoutEffect(() => {
    !!count && handleScroll();
  }, [count, handleScroll]);

  return scrollRef;
};

export default useHandleScroll;
