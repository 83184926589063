import { AuthChangePasswordActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';

type IsPresent = (props: {
  actionId: string;
  request: AuthChangePasswordActionApiRequest;
  state: RootState;
}) => boolean;

const isPresent: IsPresent = ({ actionId, state }) =>
  actionId === state.auth.actionId;

export default isPresent;
