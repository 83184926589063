import { produce } from 'immer';

import { Onfido, OnfidoActionType } from '../../types';
import { OnfidoAction } from '../../constants';

function onfidoGetReducer(state: Onfido, action: OnfidoActionType) {
  switch (action.type) {
    case OnfidoAction.GET_FAILURE:
      return produce(state, (draftState: Onfido) => {
        draftState.actionId = null;
        draftState.isLoading = false;
      });

    case OnfidoAction.GET_STARTED:
      return produce(state, (draftState: Onfido) => {
        draftState.actionId = action.actionId;
        draftState.isLoading = action.controller || true;
      });

    case OnfidoAction.GET_SUCCESS:
      return produce(state, (draftState: Onfido) => {
        draftState.actionId = null;
        draftState.isLoading = false;
      });

    default:
      return state;
  }
}

export default onfidoGetReducer;
