import React from 'react';

import Overlay, { OverlayAnimation } from 'components/shared/Overlay';
import Button, { ButtonStyle } from 'components/shared/Button';
import { DialogProps, DialogTemplateProps } from './types';
import { DialogType } from '../../constants';

import styles from '../Dialogs/styles.module.scss';

const DialogTemplate = React.memo<DialogTemplateProps>(
  ({
    animating,
    ariaLabel,
    cancelLabel,
    confirmLabel,
    message,
    onCancel,
    onConfirm,
    open,
    title,
    type,
  }) => {
    return (
      <div aria-label={ariaLabel} className={styles['dialog']} role="dialog">
        <header>
          <h2>{title}</h2>
        </header>
        <div>
          <p>{message}</p>
        </div>
        <footer>
          {type === DialogType.CONFIRM && !!onCancel && (
            <Button
              chevron={false}
              disabled={animating || !open}
              label={cancelLabel || 'Cancel'}
              onClick={onCancel}
              style={ButtonStyle.PRIMARY}
            />
          )}
          <Button
            chevron={false}
            disabled={animating || !open}
            label={confirmLabel || 'Confirm'}
            onClick={onConfirm}
            style={ButtonStyle.PRIMARY}
          />
        </footer>
      </div>
    );
  },
);

const Dialog: React.FunctionComponent<DialogProps> = ({
  animation,
  dialogId,
  disableEscapeKey,
  onCancel,
  onConfirm,
  onDestroy,
  open,
  type,
  ...dialog
}) => {
  return (
    <Overlay
      animation={animation || OverlayAnimation.ZOOM_IN}
      disableEscapeKey={disableEscapeKey}
      id={dialogId}
      onDestroy={onDestroy}
      onEscapeKey={
        type === DialogType.CONFIRM && onCancel ? onCancel : onConfirm
      }
      open={open}>
      <DialogTemplate
        onCancel={onCancel}
        onConfirm={onConfirm}
        open={open}
        type={type}
        {...dialog}
      />
    </Overlay>
  );
};

export default React.memo(Dialog);
