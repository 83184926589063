import { shallowEqual, useSelector } from 'modules/redux';
import { CasesData } from 'modules/states/cases';

export type UseCases = () => {
  caseId: number | null;
  cases: CasesData | null;
};

const useCases: UseCases = () => {
  const { caseId, data: cases } = useSelector(
    state => state.cases,
    shallowEqual,
  );
  return { caseId, cases };
};

export default useCases;
