import React, { useMemo } from 'react';
import clsx from 'clsx';

import { StatusProgress } from 'modules/states/status/constants';

import styles from './styles.module.scss';

type StatusProgressBarProps = {
  progress: StatusProgress;
};

const getProgressStyle = (progress: StatusProgress) => {
  switch (progress) {
    case StatusProgress.CANCELLED:
      return styles['status-progress-bar-cancelled'];
    case StatusProgress.DECLINED:
      return styles['status-progress-bar-declined'];
    case StatusProgress.PERCENT_100:
      return styles['status-progress-bar-100'];
    case StatusProgress.PERCENT_80:
      return styles['status-progress-bar-80'];
    case StatusProgress.PERCENT_60:
      return styles['status-progress-bar-60'];
    case StatusProgress.PERCENT_40:
      return styles['status-progress-bar-40'];
    case StatusProgress.PERCENT_20:
      return styles['status-progress-bar-20'];
    case StatusProgress.UNKNOWN:
      return styles['status-progress-bar-unknown'];
  }
};

const getProgressText = (progress: StatusProgress) => {
  switch (progress) {
    case StatusProgress.CANCELLED:
      return 'Cancelled';
    case StatusProgress.DECLINED:
      return 'Declined';
    case StatusProgress.PERCENT_100:
      return '100%';
    case StatusProgress.PERCENT_80:
      return '80%';
    case StatusProgress.PERCENT_60:
      return '60%';
    case StatusProgress.PERCENT_40:
      return '40%';
    case StatusProgress.PERCENT_20:
      return '20%';
    case StatusProgress.UNKNOWN:
      return '';
  }
};

const StatusProgressBar: React.FunctionComponent<StatusProgressBarProps> = ({
  progress,
}) => {
  const progressStyle = useMemo(() => getProgressStyle(progress), [progress]);
  const progressText = useMemo(() => getProgressText(progress), [progress]);
  const className = clsx(styles['status-progress-bar'], progressStyle);
  return (
    <span className={className}>
      <span>{progressText}</span>
    </span>
  );
};

export default React.memo(StatusProgressBar);
