import React from 'react';

import { useAppRoute, UseAppRoute } from 'modules/router';
import Session from 'modules/session';

import DesktopTemplate from './Desktop';
import MobileTemplate from './Mobile';

type TemplateProps = {
  children?: (
    props: Omit<ReturnType<UseAppRoute>, 'isMobile'>,
  ) => React.ReactNode;
};

const Route: React.FunctionComponent<TemplateProps> = ({ children }) => {
  const { isAdmin, isCaseActive, isMobile } = useAppRoute();
  return (
    <Session>
      {isMobile ? (
        <MobileTemplate>
          {children && children({ isAdmin, isCaseActive })}
        </MobileTemplate>
      ) : (
        <DesktopTemplate>
          {children && children({ isAdmin, isCaseActive })}
        </DesktopTemplate>
      )}
    </Session>
  );
};

export default React.memo(Route);
