import { useCallback } from 'react';

import { docuploadCancelAction } from '../../../actions';
import { HandleClose, UseHandleClose } from './types';

const useHandleClose: UseHandleClose = ({ disabled, dispatch }) => {
  const handleClose: HandleClose = useCallback(
    () => !disabled && dispatch(docuploadCancelAction()),
    [disabled, dispatch],
  );

  return handleClose;
};

export default useHandleClose;
