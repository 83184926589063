import React from 'react';

import ErrorMessage from '../ErrorMessage';
import { Router } from 'modules/router';
import Version from '../Version';
import useApp from './hooks';

const App: React.FunctionComponent = () => {
  const { isDirty, isSecure, ...props } = useApp();
  return isSecure ? (
    <React.Fragment>
      <Router />
      <Version />
    </React.Fragment>
  ) : isDirty ? (
    <ErrorMessage {...props} />
  ) : null;
};

export default React.memo(App);
