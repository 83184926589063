import debounce from 'debounce';
import { Middleware } from 'redux';

import { networkInitAction, networkPollingStartedAction } from './actions';
import { networkPluginInit, networkPluginStatus } from './plugin';
import { AppAction, AppActionType } from 'modules/app';
import { networkTimer, updateNetwork } from './utils';
import { Dispatch, RootState } from 'modules/redux';
import { NetworkActionType } from './types';
import { NetworkAction } from './constants';

const debounceAction = debounce(updateNetwork, 2000, { immediate: true });

const networkMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | NetworkActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(networkInitAction({ status: await networkPluginStatus() }));
        break;

      case NetworkAction.INIT:
        nextAction = next(action);
        networkPluginInit(payload =>
          debounceAction(dispatch, getState, payload),
        );
        dispatch(networkPollingStartedAction());
        break;

      case NetworkAction.POLLING_STARTED: {
        action.timer = networkTimer({ dispatch, getState });
        break;
      }

      case NetworkAction.POLLING_ENDED: {
        const { network } = getState();
        network.timer !== null && window.clearInterval(network.timer);
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default networkMiddleware;
