import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import ErrorMessage from 'components/desktop/ErrorMessage';
import Loading from 'components/desktop/Loading';
import { ROUTE } from 'modules/router';

import styles from './styles.module.scss';

type MainProps = {
  children: React.ReactNode;
  className?: string;
  handleError?: () => void;
  isError?: Error | false;
  isLoading?: boolean;
};

const Main: React.FunctionComponent<MainProps> = ({
  children,
  className,
  handleError,
  isError,
  isLoading,
}) => {
  const location = useLocation();
  const classNames = clsx(
    styles.main,
    location.pathname === (ROUTE.MESSAGES || ROUTE.MESSAGES) && styles.messages,
    className,
  );
  return isError ? (
    <ErrorMessage isError={isError} onError={handleError} />
  ) : isLoading ? (
    <Loading />
  ) : (
    <main className={classNames}>{children}</main>
  );
};

export default React.memo(Main);
