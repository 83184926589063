import { shallowEqual, useSelector } from 'modules/redux';

import { Version } from '../types';

export type UseVersion = () => Version;

const useVersion: UseVersion = () =>
  useSelector(state => state.version, shallowEqual);

export default useVersion;
