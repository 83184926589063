import { Team, TeamState } from '../types';

type NewTeam = (array: TeamState, caseId: number) => Team;

const newTeam: NewTeam = (array, caseId) => {
  const team: Team = {
    actionId: null,
    caseId,
    data: null,
    isDirty: false,
    isError: false,
    isLoading: false,
  };
  array.push(team);
  return team;
};

export default newTeam;
