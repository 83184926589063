import { AuthChangePasswordActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';

type IsActive = (props: {
  request: AuthChangePasswordActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ state }) => !!state.auth.isLoading;

export default isActive;
