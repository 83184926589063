export enum OrientationAction {
  INIT = 'ORIENTATION_INIT',
  CHANGE = 'ORIENTATION_CHANGE',
}

export enum OrientationStatus {
  LANDSCAPE = 'landscape',
  LANDSCAPE_PRIMARY = 'landscape-primary',
  LANDSCAPE_SECONDARY = 'landscape-secondary',
  PORTRAIT = 'portrait',
  PORTRAIT_PRIMARY = 'portrait-primary',
  PORTRAIT_SECONDARY = 'portrait-secondary',
}
