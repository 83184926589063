import React from 'react';

import Overlay, { OverlayAnimation } from 'components/shared/Overlay';
import { ModalOnfidoProps, ModalOnfidoTemplateProps } from './types';
import { useOnfidoModal } from 'modules/onfido';
import Button from 'components/shared/Button';

import styles from './styles.module.scss';

export type { ModalOnfidoProps } from './types';

const ModalOnfidoTemplate = React.memo<ModalOnfidoTemplateProps>(
  ({ animating, disabled, onClose }) => {
    return (
      <div aria-label="Onfido" className={styles['modal-onfido']} role="dialog">
        <header>
          <div>
            <Button
              ariaLabel="Close Onfido"
              disabled={!!animating || disabled}
              onClick={onClose}
              title="Close Onfido"
            />
            <h2>Onfido</h2>
          </div>
        </header>
        <div id="onfido-mount"></div>
      </div>
    );
  },
);

const ModalOnfido: React.FunctionComponent<ModalOnfidoProps> = ({
  disableEscapeKey,
  modalId,
  onDestroy,
  open,
  payload,
  ...modal
}) => {
  const { disabled, handleClose, isMobile } = useOnfidoModal();
  return (
    <Overlay
      animation={
        isMobile ? OverlayAnimation.SLIDE_UP : OverlayAnimation.ZOOM_IN
      }
      disableEscapeKey={disableEscapeKey}
      id={modalId}
      onDestroy={onDestroy}
      onEscapeKey={handleClose}
      open={open}>
      <ModalOnfidoTemplate
        {...modal}
        disabled={disabled}
        onClose={handleClose}
      />
    </Overlay>
  );
};

export default React.memo(ModalOnfido);
