import { Status, StatusState } from '../types';

type NewStatus = (array: StatusState, caseId: number) => Status;

const newStatus: NewStatus = (array, caseId) => {
  const status: Status = {
    actionId: null,
    caseId,
    isError: false,
    isLoading: false,
  };
  array.push(status);
  return status;
};

export default newStatus;
