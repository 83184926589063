import { Case, CasesGetActionApiResponseItem } from '../types';
import { convertStringToDate } from 'modules/redux/utils';
import normalizeCaseType from './normalizeCaseType';
import getStatusProgress from './getStatusProgress';
import { formatCurrency } from 'modules/utils';
import getStatusVideo from './getStatusVideo';
import getStatusStep from './getStatusStep';
import isStatusType from './isStatusType';
import { CaseType } from '../constants';
import isCaseType from './isCaseType';

type NewCase = (
  array: Case[] | null,
  data: CasesGetActionApiResponseItem,
) => void;

const newCase: NewCase = (array, data) => {
  if (isStatusType(data.status) && isCaseType(data.fpt) && data.caseId) {
    const step = getStatusStep(data.status);
    const type = normalizeCaseType(data.fpt as CaseType);
    const progress = getStatusProgress(step);
    const video = getStatusVideo(step, type);
    array?.push({
      advance: formatCurrency(data.advance),
      caseId: data.caseId,
      date: convertStringToDate(data.entryDate),
      isCaseActive: !!data.isCaseActive,
      lender: data.lender || '',
      lenderLogo: data.lenderLogo || '',
      progress,
      status: data.status,
      step,
      type,
      video,
    });
  }
};

export default newCase;
