import { Middleware } from 'redux';

import { themeInitAction, themeChangeAction } from './actions';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { ThemeAction } from './constants';
import { ThemeActionType } from './types';
import {
  themePluginSetClass,
  themePluginColor,
  themePluginInit,
} from './plugin';

const themeMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | ThemeActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(themeInitAction());
        break;

      case ThemeAction.INIT:
        themePluginInit(() => dispatch(themeChangeAction(themePluginColor())));
        break;

      case ThemeAction.CHANGE:
        themePluginSetClass(action.theme);
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default themeMiddleware;
