import React from 'react';

import { Document } from 'modules/states/documents';
import DocumentsTabPanel from './DocumentsTabPanel';
import DocumentsTabList from './DocumentsTabList';
import { useDocuments } from './hooks';

import styles from './styles.module.scss';

type DocumentsProps = {
  documents: Document[];
};

const Documents: React.FunctionComponent<DocumentsProps> = ({ documents }) => {
  const {
    handleClick,
    handleKeyDown,
    handleKeyUp,
    selectedTab,
    tabList,
    tabListRef,
    tabPanelRef,
    tabPanels,
  } = useDocuments(documents);

  return (
    <div id="documents-tab" className={styles.documents}>
      <DocumentsTabList
        handleClick={handleClick}
        handleKeyDown={handleKeyDown}
        handleKeyUp={handleKeyUp}
        tabListRef={tabListRef}
        selectedTab={selectedTab}
        tabList={tabList}
      />
      <div>
        {tabPanels?.map((tabPanel, key) => {
          return (
            <DocumentsTabPanel
              key={`tab-panel-${tabPanel[0].type}-${key}`}
              selectedTab={selectedTab}
              tabPanel={tabPanel}
              tabPanelRef={tabPanelRef}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Documents);
