import { AuthBiometricStatus } from '../constants';

type GetBiometricLabel = (type: AuthBiometricStatus) => string;

const getBiometricLabel: GetBiometricLabel = type => {
  switch (type) {
    case AuthBiometricStatus.FACE:
      return 'Face ID';
    case AuthBiometricStatus.TOUCH:
      return 'Touch ID';
    default:
      return 'Biometric ID';
  }
};

export default getBiometricLabel;
