import React, { useCallback, useEffect } from 'react';

import { useAccountSelector } from 'modules/states/account';
import useMessagesSelector from '../useMessagesSelector';
import { Dispatch, useDispatch } from 'modules/redux';
import { useDocuploadRoute } from 'modules/docupload';
import { FormProps } from 'components/shared/Form';
import { useOnfidoRoute } from 'modules/onfido';
import { Messages } from '../../types';
import { Model } from '../../model';
import {
  useHandleInView,
  useHandleScroll,
  useHandleSubmit,
  useHandleError,
} from './hooks';

export type UseMessagesRoute = () => {
  data: Messages['data'];
  handleDocupload: (id: number, uploadURL: string) => void;
  handleError: () => void;
  handleInView: (id: number) => void;
  handleOnfido: (id: number, startURL: string) => void;
  handleSubmit: FormProps<Model>['onSubmit'];
  isError: Error | false;
  isLoading: boolean;
  isSending: boolean;
  scrollRef: React.RefObject<HTMLElement>;
};

const useMessagesRoute: UseMessagesRoute = () => {
  const dispatch: Dispatch = useDispatch();

  const {
    caseId,
    handleError: handleAccountError,
    isError: isAccountError,
    isLoading: isAccountLoading,
  } = useAccountSelector();

  const { data, handleRequest, isDirty, isError, isSending, isSendingError } =
    useMessagesSelector(caseId);

  const handleSubmit = useHandleSubmit({ caseId, dispatch });
  const handleInView = useHandleInView({ caseId, dispatch });
  const handleDocupload = useDocuploadRoute(caseId);
  const scrollRef = useHandleScroll(data?.length);
  const handleOnfido = useOnfidoRoute(caseId);

  useHandleError({ caseId, dispatch, isSendingError });

  const handleError = useCallback(
    () => (isAccountError ? handleAccountError() : handleRequest()),
    [handleAccountError, handleRequest, isAccountError],
  );

  useEffect(() => {
    !isAccountLoading && handleRequest();
  }, [caseId, handleRequest, isAccountLoading]);

  return {
    data,
    handleDocupload,
    handleError,
    handleInView,
    handleOnfido,
    handleSubmit,
    isError: isAccountError
      ? isAccountError
      : !isDirty && isError
        ? isError
        : false,
    isLoading: isAccountLoading || !isDirty,
    isSending,
    scrollRef,
  };
};

export default useMessagesRoute;
