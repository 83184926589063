import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

type FieldsetProps = {
  className?: string;
  children: React.ReactNode;
  title?: string;
};

const Fieldset: React.FunctionComponent<FieldsetProps> = ({
  className,
  children,
  title,
}) => {
  const classNames = clsx(styles.fieldset, className);

  return (
    <fieldset className={classNames}>
      {title && (
        <legend>
          <h2>{title}</h2>
        </legend>
      )}
      {children}
    </fieldset>
  );
};

export default React.memo(Fieldset);
