import { produce } from 'immer';

import { NetworkAction, NetworkStatus } from './constants';
import { Network, NetworkActionType } from './types';

const initialState = (): Network => {
  return { status: NetworkStatus.ONLINE, timer: null };
};

function networkReducer(state = initialState(), action: NetworkActionType) {
  switch (action.type) {
    case NetworkAction.INIT:
      return produce(state, (draftState: Network) => {
        draftState.status = action.status;
      });

    case NetworkAction.EVENT:
      return produce(state, (draftState: Network) => {
        draftState.status = action.status;
      });

    case NetworkAction.POLLING_STARTED:
      return produce(state, (draftState: Network) => {
        draftState.timer = action?.timer || null;
      });

    case NetworkAction.POLLING_ENDED:
      return produce(state, (draftState: Network) => {
        draftState.timer = null;
      });

    default:
      return state;
  }
}

export default networkReducer;
