import { produce } from 'immer';

import { Platform, PlatformActionType } from './types';
import { PlatformAction } from './constants';
import { initialState } from './utils';

function platformReducer(state = initialState(), action: PlatformActionType) {
  switch (action.type) {
    case PlatformAction.UPDATE:
      return produce(state, (draftState: Platform) => {
        draftState = { ...draftState, ...action.platform };
      });

    default:
      return state;
  }
}

export default platformReducer;
