/* eslint-disable no-template-curly-in-string */

export enum ValdationMessages {
  DATE = 'Please enter a valid date',

  EMAIL = 'Must be a valid email',

  NOT_TYPE = 'Please enter a valid ${type}',

  ONE_OF = 'Please complete this question',

  NEW_PASSWORD = 'Password must contain a letter & number',

  REQUIRED = "This field can't be blank",
}

/* eslint-enable no-template-curly-in-string */

export default ValdationMessages;
