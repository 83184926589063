import { OrientationChangeAction, OrientationInitAction } from './types';
import { orientationPluginGetOrientation } from './plugin';
import { OrientationAction } from './constants';

export const orientationInitAction: OrientationInitAction = () => ({
  type: OrientationAction.INIT,
  orientation: orientationPluginGetOrientation(),
});

export const orientationChangeAction: OrientationChangeAction =
  orientation => ({
    type: OrientationAction.CHANGE,
    orientation,
  });
