import { OrientationAction, OrientationStatus } from './constants';
import { Orientation, OrientationActionType } from './types';

const initialState = (): Orientation => {
  return OrientationStatus.PORTRAIT;
};

function orientationReducer(
  state = initialState(),
  action: OrientationActionType,
) {
  switch (action.type) {
    case OrientationAction.INIT:
      return action.orientation;

    case OrientationAction.CHANGE:
      return action.orientation;

    default:
      return state;
  }
}

export default orientationReducer;
