import React, { useMemo } from 'react';

import Button, { ButtonSize, ButtonStyle } from 'components/shared/Button';
import {
  getStatusTitle,
  getStatusText,
  StatusStep,
} from 'modules/states/status';

import styles from './styles.module.scss';

type StatusMessageProps = {
  handleVideo?: () => void;
  isPlaying: boolean;
  step: StatusStep;
};

const StatusMessage: React.FunctionComponent<StatusMessageProps> = ({
  handleVideo,
  isPlaying,
  step,
}) => {
  const text = useMemo(() => getStatusText(step), [step]);
  const title = useMemo(() => getStatusTitle(step), [step]);
  return (
    <div className={styles['status-message']}>
      {!!title && <h2>{title}</h2>}
      {!!text && <p>{text}</p>}
      {!!handleVideo && (
        <Button
          disabled={isPlaying}
          label="Play video"
          onClick={handleVideo}
          style={ButtonStyle.PRIMARY}
          size={ButtonSize.SMALL}
        />
      )}
    </div>
  );
};

export default React.memo(StatusMessage);
