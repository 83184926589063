import React from 'react';

import Navigation from 'components/desktop/Menu';
import Header from 'components/desktop/Header';
import { Dialogs } from 'modules/dialogs';
import { Modals } from 'modules/modals';

import styles from './styles.module.scss';

type TemplateProps = {
  children: React.ReactNode;
};

const Template: React.FunctionComponent<TemplateProps> = ({ children }) => {
  return (
    <React.Fragment>
      <div className={styles['app']}>
        <div>
          <Navigation />
          <Header />
          {children}
        </div>
      </div>
      <div className={styles['modals']}>
        <Modals />
        <Dialogs />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Template);
