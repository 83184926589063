import { useCallback } from 'react';

import { UseHandlePostError } from './types';
import {
  docuploadResetErrorAction,
  docuploadUploadAction,
} from '../../../../../actions';

const useHandlePostError: UseHandlePostError = ({
  showDialogConfirm,
  showDialogAlert,
  dispatch,
}) => {
  const handleError = useCallback(() => {
    if (showDialogAlert && showDialogConfirm)
      showDialogConfirm({
        message: 'An error has occurred. Try again?',
        onConfirm: () => dispatch(docuploadUploadAction()),
        onCancel: () => dispatch(docuploadResetErrorAction()),
        title: 'MyFluent',
      });
  }, [dispatch, showDialogAlert, showDialogConfirm]);

  return handleError;
};

export default useHandlePostError;
