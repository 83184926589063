import { produce } from 'immer';

import { Menu, MenuActionType } from './types';
import { MenuAction } from './constants';

const initialState = (): Menu => {
  return {
    open: false,
  };
};

function menuReducer(state = initialState(), action: MenuActionType) {
  switch (action.type) {
    case MenuAction.CLOSE:
      return produce(state, (draftState: Menu) => {
        draftState.open = false;
      });

    case MenuAction.OPEN:
      return produce(state, (draftState: Menu) => {
        draftState.open = true;
      });

    case MenuAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default menuReducer;
