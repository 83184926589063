import { isBiometricActive, isBiometricValid } from '../utils';
import { AuthBiometricStatus } from '../constants';
import {
  biometricPluginGetCredentials,
  biometricPluginDelete,
  biometricPluginStatus,
} from '../plugin';

type GetBiometricUserStatus = (
  username?: string,
) => Promise<AuthBiometricStatus | false>;

const getBiometricUserStatus: GetBiometricUserStatus = async username => {
  try {
    if (!username) return false;

    const credentials = await biometricPluginGetCredentials(username);
    if (!isBiometricValid(credentials, username)) return false;

    const status = await biometricPluginStatus();
    if (!isBiometricActive(status)) {
      if (credentials) await biometricPluginDelete(username);
      return false;
    }

    return status;
  } catch (error) {
    return false;
  }
};

export default getBiometricUserStatus;
