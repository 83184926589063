import { Case, CasesGetActionApiResponseItem } from '../types';
import { convertStringToDate } from 'modules/redux/utils';
import getStatusProgress from './getStatusProgress';
import normalizeCaseType from './normalizeCaseType';
import { formatCurrency } from 'modules/utils';
import getStatusVideo from './getStatusVideo';
import getStatusStep from './getStatusStep';
import isStatusType from './isStatusType';
import { CaseType } from '../constants';
import isCaseType from './isCaseType';

type UpdateCase = (item: Case, data: CasesGetActionApiResponseItem) => void;

const updateCase: UpdateCase = (item, data) => {
  item.advance = formatCurrency(data.advance);

  item.isCaseActive = !!data.isCaseActive;

  !!data.lender && (item.lender = data.lender);

  !!data.lenderLogo && (item.lenderLogo = data.lenderLogo);

  !!data.entryDate && (item.date = convertStringToDate(data.entryDate));

  isCaseType(data.fpt) && (item.type = normalizeCaseType(data.fpt as CaseType));

  if (isStatusType(data.status)) {
    const step = getStatusStep(data.status);
    item.progress = getStatusProgress(step);
    item.status = data.status;
    item.step = step;
    item.video = getStatusVideo(step, item.type);
  }
};

export default updateCase;
