import { Directory, Filesystem } from '@capacitor/filesystem';
import { saveAs } from 'file-saver';
import sleep from 'sleep-promise';

import { ReadFile, SaveFileBrowser, SaveFileCapacitor } from './types';
import { DocuploadFileType } from '../../constants';

export const checkFile = (file: File): file is File =>
  Object.values(DocuploadFileType).includes(file.type as DocuploadFileType);

export const readFile: ReadFile = blob =>
  new Promise(function (resolve, reject) {
    const fileReader = new FileReader();
    fileReader.onload = event => {
      event.target
        ? resolve(event.target.result as string)
        : reject('Cannot Read File');
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(blob);
  });

export const saveFileBrowser: SaveFileBrowser = (blob, name) =>
  saveAs(blob, name);

export const saveFileCapacitor: SaveFileCapacitor = async (blob, name) => {
  const result = await Filesystem.writeFile({
    data: await readFile(blob),
    directory: Directory.Data,
    path: name,
  });
  await sleep(500);
  return result.uri;
};
