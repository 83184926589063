import ImageBlobReduce from 'image-blob-reduce';
import { PageSizes } from 'pdf-lib';
import Pica from 'pica';

import { EmbedImage, PositionImage, ResizeImage } from './types';
import { DocuploadFileType } from '../../constants';
import { readFile } from '../file';

export const embedImage: EmbedImage = async (config, file, pdf) => {
  const resized = await resizeImage(config, file);
  const image = await readFile(resized);
  const position = await positionImage(image);
  const embed =
    resized.type === DocuploadFileType.PNG
      ? await pdf.embedPng(image)
      : await pdf.embedJpg(image);
  const page = pdf.addPage(
    position.width > position.height
      ? [PageSizes.A4[1], PageSizes.A4[0]]
      : PageSizes.A4,
  );
  page.drawImage(embed, position);
};

const positionImage: PositionImage = image =>
  new Promise(function (resolve, reject) {
    const point = 2.83465;
    const padding = 10;
    let pageWidth = 210;
    let pageHeight = 297;
    const img = document.createElement('img');
    img.onload = () => {
      if (img.width > img.height) {
        pageWidth = 297;
        pageHeight = 210;
      }
      const maxImageWidth = pageWidth - padding * 2;
      const maxImageHeight = pageHeight - padding * 2;
      let imageWidth = maxImageWidth;
      let imageHeight = img.height * (maxImageWidth / img.width);
      if (imageHeight > maxImageHeight) {
        imageHeight = maxImageHeight;
        imageWidth = img.width * (maxImageHeight / img.height);
      }
      resolve({
        x: (pageWidth / 2 - imageWidth / 2) * point,
        y: (pageHeight / 2 - imageHeight / 2) * point,
        width: imageWidth * point,
        height: imageHeight * point,
      });
    };
    img.onerror = reject;
    img.src = image;
  });

const resizeImage: ResizeImage = (config, file) =>
  new Promise(function (resolve, reject) {
    const compression = config.data.imageQuality / 100;
    const dpi = config.data.imageMultiplier * 786;
    const pica = Pica({ features: ['js', 'wasm', 'cib'] });
    const reducer = new ImageBlobReduce({ pica });
    reducer._create_blob = async function (env) {
      return this.pica
        .toBlob(env.out_canvas, 'image/jpeg', compression)
        .then(blob => {
          env.out_blob = blob;
          return env;
        });
    };
    reducer
      .toBlob(file, { max: dpi })
      .then(blob => resolve(blob))
      .catch(error => reject(error));
  });
