import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { loanLogoutAction } from './actions';
import { LoanActionType } from './types';
import { abortLoan } from './utils';

const loanMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | LoanActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.LOGOUT:
        nextAction = next(action);
        abortLoan(getState().loan);
        dispatch(loanLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default loanMiddleware;
