import { useCallback, useEffect, useRef, useState } from 'react';

import { usePlatformContext } from 'modules/services/platform';
import useDialogAction from '../useDialogAction';
import { ShowDialog, UseDialog } from './types';

const useDialog: UseDialog = () => {
  const { dialogDestroy, dialogHide, dialogShow } = useDialogAction();
  const [dialogId, setDialog] = useState<string | null>();
  const destroyDialogRef = useRef<() => void>();
  const hideDialogRef = useRef<() => void>();
  const { isDesktop } = usePlatformContext();
  const mounted = useRef(true);

  useEffect(() => {
    destroyDialogRef.current = () => {
      !!dialogId && dialogDestroy(dialogId);
      mounted.current && isDesktop && setDialog(null);
    };
    hideDialogRef.current = () => {
      !!dialogId && dialogHide(dialogId);
      mounted.current && !isDesktop && setDialog(null);
    };
  }, [dialogDestroy, dialogHide, dialogId, isDesktop]);

  const destroyDialog = useCallback(() => {
    destroyDialogRef.current && destroyDialogRef.current();
  }, []);

  const hideDialog = useCallback(() => {
    hideDialogRef.current && hideDialogRef.current();
  }, []);

  const showDialog: ShowDialog = useCallback(
    ({ onCancel, onConfirm, ...dialog }) => {
      if (!dialogId) {
        const id = dialogShow({
          onCancel: () => {
            !!onCancel && onCancel();
            hideDialog();
          },
          onConfirm: () => {
            !!onConfirm && onConfirm();
            hideDialog();
          },
          onDestroy: destroyDialog,
          ...dialog,
        });
        setDialog(id);
        return id;
      }
    },
    [destroyDialog, dialogId, dialogShow, hideDialog],
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      hideDialog();
    };
  }, [hideDialog]);

  const result: ReturnType<UseDialog> = [
    !dialogId ? showDialog : undefined,
    dialogId ? hideDialog : undefined,
  ];
  result.hideDialog = dialogId ? hideDialog : undefined;
  result.showDialog = !dialogId ? showDialog : undefined;
  return result;
};

export default useDialog;
