import { AppInitAction, AppInitializedAction, AppReadyAction } from './types';
import { AppAction } from './constants';

export const appInitAction: AppInitAction = () => ({
  type: AppAction.INIT,
});

export const appInitializedAction: AppInitializedAction = () => ({
  type: AppAction.INITIALIZED,
});

export const appReadyAction: AppReadyAction = () => ({
  type: AppAction.READY,
});
