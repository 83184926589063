import { useCallback, useEffect } from 'react';

import { messagesSendResetAction } from '../../../actions';
import { useDialogAlert } from 'modules/dialogs';
import { Dispatch } from 'modules/redux';

type HandleError = (error: Error) => void;

type UseHandleError = (props: {
  caseId: number | null;
  dispatch: Dispatch;
  isSendingError: Error | false;
}) => void;

const useHandleError: UseHandleError = ({
  caseId,
  dispatch,
  isSendingError,
}) => {
  const [showDialogAlert] = useDialogAlert();

  const handleError: HandleError = useCallback(
    error => {
      if (showDialogAlert)
        showDialogAlert({
          message: 'An error has occurred. Please try again later.',
          onConfirm: () =>
            !!caseId && dispatch(messagesSendResetAction(caseId)),
          title: 'MyFluent',
        });
    },
    [caseId, dispatch, showDialogAlert],
  );

  useEffect(() => {
    !!isSendingError && handleError(isSendingError);
  }, [handleError, isSendingError]);
};

export default useHandleError;
