import { produce } from 'immer';

import { Docupload, DocuploadActionType } from '../../types';
import { DocuploadAction } from '../../constants';

function docuploadUploadReducer(state: Docupload, action: DocuploadActionType) {
  switch (action.type) {
    case DocuploadAction.UPLOAD_FAILURE:
      return produce(state, (draftState: Docupload) => {
        draftState.isSendingError = action.error;
        draftState.isLoading = false;
      });

    case DocuploadAction.UPLOAD_STARTED:
      return produce(state, (draftState: Docupload) => {
        draftState.isSendingError = false;
        draftState.isLoading = true;
      });

    case DocuploadAction.UPLOAD_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.isSendingError = false;
        draftState.isLoading = false;
      });

    default:
      return state;
  }
}

export default docuploadUploadReducer;
