import { produce } from 'immer';

import { Docupload, DocuploadActionType } from '../../../../types';
import { DocuploadAction } from '../../../../constants';

function docuploadPluginDeleteReducer(
  state: Docupload,
  action: DocuploadActionType,
) {
  switch (action.type) {
    case DocuploadAction.PLUGIN_DELETE_FAILURE:
      return produce(state, (draftState: Docupload) => {
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case DocuploadAction.PLUGIN_DELETE_STARTED:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = true;
      });

    case DocuploadAction.PLUGIN_DELETE_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = false;

        draftState.data = draftState.data.filter(file => file.id !== action.id);
      });

    default:
      return state;
  }
}

export default docuploadPluginDeleteReducer;
