import { DocuploadError, DocuploadAction } from '../../../../constants';
import { docuploadPluginPreview } from '../../../../plugin';
import { getError, isPresent } from '../../../../utils';
import {
  DocuploadPluginPreviewFailureAction,
  DocuploadPluginPreviewStartedAction,
  DocuploadPluginPreviewSuccessAction,
  DocuploadPluginPreviewAction,
} from './types';

export const docuploadPluginPreviewAction: DocuploadPluginPreviewAction =
  () => {
    return async (dispatch, getState) => {
      const { messageId } = getState().docupload;
      try {
        if (!getState().auth.data?.token) return;

        dispatch(docuploadPluginPreviewStartedAction());

        await docuploadPluginPreview();

        isPresent({ messageId, state: getState() }) &&
          dispatch(docuploadPluginPreviewSuccessAction());
      } catch (error) {
        isPresent({ messageId, state: getState() }) &&
          dispatch(
            docuploadPluginPreviewFailureAction(
              getError(error, DocuploadError.CREATE),
            ),
          );
      }
    };
  };

const docuploadPluginPreviewStartedAction: DocuploadPluginPreviewStartedAction =
  () => ({
    type: DocuploadAction.PLUGIN_PREVIEW_STARTED,
  });

const docuploadPluginPreviewSuccessAction: DocuploadPluginPreviewSuccessAction =
  () => ({
    type: DocuploadAction.PLUGIN_PREVIEW_SUCCESS,
  });

const docuploadPluginPreviewFailureAction: DocuploadPluginPreviewFailureAction =
  error => ({
    type: DocuploadAction.PLUGIN_PREVIEW_FAILURE,
    error,
  });
