import React from 'react';

import { ModalDocuploadHeaderProps } from './types';
import Button from 'components/shared/Button';

import styles from './styles.module.scss';

const ModalDocuploadHeader: React.FunctionComponent<
  ModalDocuploadHeaderProps
> = ({ disabled, onClose }) => {
  return (
    <header className={styles['modal-document-upload-header']}>
      <div>
        <Button
          ariaLabel="Close Document Upload"
          disabled={!!disabled}
          onClick={onClose}
          title="Close Document Upload"
        />
        <h2>
          Document Upload<span> Request</span>
        </h2>
      </div>
    </header>
  );
};

export default React.memo(ModalDocuploadHeader);
