import { useEffect } from 'react';

import { platform, usePlatformContext } from 'modules/services/platform';
import { Dispatch, useDispatch } from 'modules/redux';
import { appReadyAction } from 'modules/app/actions';
import { useCase } from 'modules/states/cases';
import useAppRedirect from './useAppRedirect';

export type UseAppRoute = () => {
  isAdmin: boolean;
  isCaseActive: boolean;
  isMobile: boolean;
};

const { isAdmin } = platform;

const useAppRoute: UseAppRoute = () => {
  const { isMobile } = usePlatformContext();
  const { caseId, isCaseActive } = useCase();
  const dispatch: Dispatch = useDispatch();
  useAppRedirect(caseId);

  useEffect(() => {
    dispatch(appReadyAction());
  }, [dispatch]);

  return {
    isAdmin,
    isCaseActive,
    isMobile,
  };
};

export default useAppRoute;
