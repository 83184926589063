import React from 'react';

import { MessageFooterProps } from './types';

const MessageFooter: React.FunctionComponent<MessageFooterProps> = ({
  dateFooter,
  dateTime,
}) => {
  return (
    <footer>
      <time dateTime={dateTime}>{dateFooter}</time>
    </footer>
  );
};

export default React.memo(MessageFooter);
