import { useMemo } from 'react';

import { platform } from 'modules/services/platform';
import { useConfig } from 'modules/services/config';
import { isVersionExpired } from '../utils';
import useVersion from './useVersion';

const { isCapacitor } = platform;

export type UseVersionExpired = () => {
  isVersionDirty: boolean;
  isVersionExpired: boolean | string;
};

const useVersionExpired: UseVersionExpired = () => {
  const { isDirty, message, minVersion } = useConfig();
  const version = useVersion()?.version; //TODO

  const isExpired = useMemo(() => {
    return isCapacitor ? isVersionExpired(version, minVersion) : false;
  }, [minVersion, version]);

  return {
    isVersionDirty: isDirty, //needs error
    isVersionExpired: isExpired && !!message ? message : isExpired,
  };
};

export default useVersionExpired;
