import { DocumentUpload, DocumentsGetActionApiResponseItem } from '../types';
import { convertStringToDate } from 'modules/redux/utils';
import { DocumentType } from '../constants';
import getDocumentId from './getDocumentId';

const newDocumentUpload = (
  data: DocumentsGetActionApiResponseItem,
): DocumentUpload | null => {
  return data.description
    ? {
        date: convertStringToDate(data.dateRequested),
        description: data.description,
        documentId: getDocumentId(data),
        extension: data.extension?.toLocaleLowerCase() || 'pdf',
        type: DocumentType.UPLOAD,
      }
    : null;
};

export default newDocumentUpload;
