import { DocuploadError, DocuploadAction } from '../../../../constants';
import { docuploadPluginDelete } from '../../../../plugin';
import { getError, isPresent } from '../../../../utils';
import { timeout } from 'modules/redux/factories/utils';
import {
  DocuploadPluginDeleteFailureAction,
  DocuploadPluginDeleteStartedAction,
  DocuploadPluginDeleteSuccessAction,
  DocuploadPluginDeleteAction,
} from './types';

export const docuploadPluginDeleteAction: DocuploadPluginDeleteAction = id => {
  return async (dispatch, getState) => {
    const { messageId } = getState().docupload;
    const startTime = new Date().getTime();
    try {
      if (!getState().auth.data?.token) return;

      dispatch(docuploadPluginDeleteStartedAction());

      docuploadPluginDelete(getState().docupload.data, id);

      await timeout(startTime, 1000);

      isPresent({ messageId, state: getState() }) &&
        dispatch(docuploadPluginDeleteSuccessAction(id));
    } catch (error) {
      await timeout(startTime, 1000);

      isPresent({ messageId, state: getState() }) &&
        dispatch(
          docuploadPluginDeleteFailureAction(
            getError(error, DocuploadError.REMOVE),
          ),
        );
    }
  };
};

const docuploadPluginDeleteStartedAction: DocuploadPluginDeleteStartedAction =
  () => ({
    type: DocuploadAction.PLUGIN_DELETE_STARTED,
  });

const docuploadPluginDeleteSuccessAction: DocuploadPluginDeleteSuccessAction =
  id => ({
    type: DocuploadAction.PLUGIN_DELETE_SUCCESS,
    id,
  });

const docuploadPluginDeleteFailureAction: DocuploadPluginDeleteFailureAction =
  error => ({
    type: DocuploadAction.PLUGIN_DELETE_FAILURE,
    error,
  });
