import { OnfidoShowActionPayload } from '../types';
import { RootState } from 'modules/redux/store';

type IsAllowed = (
  request: OnfidoShowActionPayload,
  state: RootState,
) => boolean;

const isAllowed: IsAllowed = (request, state) =>
  state.auth.data?.token
    ? state.onfido.isError
      ? request.messageId === state.onfido.messageId
      : !state.onfido.messageId
    : false;

export default isAllowed;
