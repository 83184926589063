import { useCallback, useEffect } from 'react';

import { authLoginResetAction } from '../../../actions';
import { useDialogAlert } from 'modules/dialogs';
import { Dispatch } from 'modules/redux';

type HandleError = (error: Error) => void;

type UseHandleError = (props: {
  dispatch: Dispatch;
  isError: Error | false;
}) => void;

const useHandleError: UseHandleError = ({ dispatch, isError }) => {
  const [showDialogAlert] = useDialogAlert();

  const handleError: HandleError = useCallback(
    error => {
      if (showDialogAlert)
        showDialogAlert({
          message: error.message,
          onConfirm: () => dispatch(authLoginResetAction()),
          title: 'MyFluent',
        });
    },
    [dispatch, showDialogAlert],
  );

  useEffect(() => {
    !!isError && handleError(isError);
  }, [handleError, isError]);
};

export default useHandleError;
