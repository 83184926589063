import { produce } from 'immer';

import { OnfidoAction, OnfidoError } from '../../constants';
import { Onfido, OnfidoActionType } from '../../types';
import { initialState } from '../../utils';

function onfidoPostReducer(state: Onfido, action: OnfidoActionType) {
  switch (action.type) {
    case OnfidoAction.POST_FAILURE:
      return produce(state, (draftState: Onfido) => {
        draftState.actionId = null;
        draftState.isSendingError = new Error(OnfidoError.POST);
        draftState.isSending = false;
      });

    case OnfidoAction.POST_STARTED:
      return produce(state, (draftState: Onfido) => {
        draftState.actionId = action.actionId;
        draftState.isSendingError = false;
        draftState.isSending = action.controller || true;
      });

    case OnfidoAction.POST_SUCCESS:
      return initialState();

    default:
      return state;
  }
}

export default onfidoPostReducer;
