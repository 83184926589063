import { useCallback } from 'react';

import { loanGetAction } from '../actions';
import { Loan } from '../types';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseLoanSelector = (caseId: number | null) => {
  data: Loan['data'];
  handleRequest: () => void;
  isDirty: boolean;
  isError: Error | false;
  isLoading: boolean;
};

const useLoanSelector: UseLoanSelector = caseId => {
  const dispatch: Dispatch = useDispatch();

  const data = useSelector(
    state => state.loan.find(items => items.caseId === caseId),
    shallowEqual,
  );

  const handleRequest = useCallback(
    () => !!caseId && dispatch(loanGetAction({ caseId })),
    [caseId, dispatch],
  );

  return {
    data: data?.data || null,
    handleRequest,
    isDirty: data?.isDirty || false,
    isError: data?.isError || false,
    isLoading: !!data?.isLoading,
  };
};

export default useLoanSelector;
