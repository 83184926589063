type FormatCurrency = (value: number | null) => string;

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

const formatCurrency: FormatCurrency = value =>
  formatter.format(Number(value || 0)).replace('.00', '');

export default formatCurrency;
