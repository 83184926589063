import React from 'react';

import { ReactComponent as Info } from 'modules/theme/images/icons/loan/info.svg';
import { LoanData } from 'modules/states/loan';
import Image from 'components/shared/Image';

import styles from './styles.module.scss';
import FluidText from './FluidText';

type LoanProps = {
  loan: LoanData;
};

const Loan: React.FunctionComponent<LoanProps> = ({
  loan: {
    lender: { lenderLogo, lenderName },
    loan: {
      loanAdvance,
      loanAnnualRate,
      loanApr,
      loanInitialTerm,
      loanInitialRate,
      loanInterestRateType,
      loanInitialRepayment,
      loanRepayment,
      loanStandardTerm,
      loanTerm,
    },
  },
}) => {
  return (
    <div className={styles.loan}>
      <section>
        <div>
          <h1>Lender</h1>
          {lenderLogo ? (
            <Image
              alt={lenderName}
              fallback={<p>{lenderName}</p>}
              src={lenderLogo}
            />
          ) : (
            <p>{lenderName}</p>
          )}
        </div>
        <div>
          <h2>Amount</h2>
          <FluidText maxSize={80} minSize={20} text={loanAdvance} />
        </div>
      </section>
      <section>
        <div>
          {loanStandardTerm ? (
            <React.Fragment>
              <h2>Initial Rate</h2>
              <FluidText
                maxSize={34}
                minSize={10}
                text={`${loanInitialRate}*`}
              />
              <p>
                <span>{loanInterestRateType}</span>
                <span> for {loanInitialTerm} months</span>
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2>Rate</h2>
              <FluidText
                maxSize={34}
                minSize={10}
                text={`${loanAnnualRate}*`}
              />
              <p>
                <span>{loanInterestRateType}</span>
                <span> for {loanTerm} months</span>
              </p>
            </React.Fragment>
          )}
        </div>
        <div>
          {loanStandardTerm ? (
            <React.Fragment>
              <h2>Initial Repayment</h2>
              <FluidText
                maxSize={34}
                minSize={10}
                text={`${loanInitialRepayment}*`}
              />
              <p>For {loanInitialTerm} months</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2>Repayment</h2>
              <FluidText maxSize={34} minSize={10} text={`${loanRepayment}*`} />
              <p>For {loanTerm} months</p>
            </React.Fragment>
          )}
        </div>
      </section>
      <section>
        <div>
          <h2>Loan Details</h2>
          {!!loanStandardTerm && (
            <React.Fragment>
              <div>
                <h3>Standard Repayment</h3>
                <p>{loanRepayment}</p>
              </div>
              <div>
                <h3>Standard Rate</h3>
                <p>{loanAnnualRate}</p>
              </div>
              <div>
                <h3>Standard Term</h3>
                <p>{loanStandardTerm} months</p>
              </div>
            </React.Fragment>
          )}
          <div>
            <h3>APRC</h3>
            <p>{loanApr}</p>
          </div>
          <div>
            <h3>Term</h3>
            <p>{loanTerm} months</p>
          </div>
        </div>
        <div>
          <Info />
          <p>
            This is a basic summary only. Please refer to your ESIS or KFI
            document for full details of the product we have recommended.
          </p>
        </div>
      </section>
    </div>
  );
};

export default React.memo(Loan);
