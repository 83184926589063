import { Middleware } from 'redux';

import { platform } from 'modules/services/platform';
import { Dispatch, RootState } from 'modules/redux';
import { AppActionType } from 'modules/app/types';
import { AppAction } from 'modules/app/constants';
import { ConfigAction } from './constants';
import { ConfigActionType } from './types';
import { configTimer } from './utils';
import {
  configPollingStartedAction,
  configInitAction,
  configGetAction,
} from './actions';

const { isAdmin } = platform;

const configMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | ConfigActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(configInitAction());
        break;

      case ConfigAction.INIT:
        nextAction = next(action);
        dispatch(configGetAction());
        !isAdmin && dispatch(configPollingStartedAction());
        break;

      case ConfigAction.POLLING_STARTED: {
        action.timer = configTimer({ dispatch, getState });
        break;
      }

      case ConfigAction.POLLING_ENDED: {
        const { config } = getState();
        config.timer !== null && window.clearInterval(config.timer);
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default configMiddleware;
