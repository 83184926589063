export enum ExternalLinkTarget {
  BLANK = '_blank',
  SELF = '_self',
}

export enum HyperlinkSize {
  LARGE = 'large',
  SMALL = 'small',
}

export enum HyperlinkStyle {
  PRIMARY_INVERTED = 'primary-inverted',
  PRIMARY = 'primary',
}
