import { produce } from 'immer';

import { findTeam, newTeam, updateTeam } from './utils';
import { TeamState, TeamActionType } from './types';
import { TeamAction } from './constants';

const initialState = (): TeamState => {
  return [];
};

function teamReducer(state = initialState(), action: TeamActionType) {
  switch (action.type) {
    case TeamAction.GET_FAILURE:
      return produce(state, (draftState: TeamState) => {
        const team = findTeam(draftState, action.request.caseId);
        if (team) {
          team.actionId = null;
          team.isError = action.error;
          team.isLoading = false;
        }
      });

    case TeamAction.GET_STARTED:
      return produce(state, (draftState: TeamState) => {
        const team =
          findTeam(draftState, action.request.caseId) ||
          newTeam(draftState, action.request.caseId);
        team.actionId = action.actionId;
        team.isError = false;
        team.isLoading = action.controller || true;
      });

    case TeamAction.GET_SUCCESS:
      return produce(state, (draftState: TeamState) => {
        const team = findTeam(draftState, action.request.caseId);
        if (team) {
          team.actionId = null;
          team.isDirty = true;
          team.isError = false;
          team.isLoading = false;
          team.data = updateTeam(action.data);
        }
      });

    case TeamAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default teamReducer;
