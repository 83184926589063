export enum VideoAction {
  PLUGIN_INIT = 'VIDEO_PLUGIN_INIT',
  PLUGIN_CLOSE = 'VIDEO_PLUGIN_CLOSE',

  SHOW_STARTED = 'VIDEO_SHOW_STARTED',
  SHOW_SUCCESS = 'VIDEO_SHOW_SUCCESS',
  SHOW_FAILURE = 'VIDEO_SHOW_FAILURE',

  CLOSE = 'VIDEO_CLOSE',

  DESTROY = 'VIDEO_DESTROY',

  LOGOUT = 'VIDEO_LOGOUT',
}
