import { produce } from 'immer';

import { CertificateAction, CertificateStatus } from './constants';
import { Certificate, CertificateActionType } from './types';
import { platform } from 'modules/services/platform';

const { isCapacitor } = platform;

const initialState = (isSecure: boolean): Certificate => {
  return {
    isDirty: isSecure,
    isSecure,
  };
};

function certificateReducer(
  state = initialState(!isCapacitor),
  action: CertificateActionType,
) {
  switch (action.type) {
    case CertificateAction.INIT:
      return state;

    case CertificateAction.RESULT:
      return produce(state, (draftState: Certificate) => {
        //draftState.isDirty = action.status !== CertificateStatus.FAILED; //TODO FAILURE causes a refresh, probably needs to be true so connection error shows
        draftState.isDirty = true;
        draftState.isSecure = action.status === CertificateStatus.SECURE;
      });

    default:
      return state;
  }
}

export default certificateReducer;
