import { produce } from 'immer';

import { Docupload, DocuploadActionType } from '../../../../types';
import { DocuploadAction } from '../../../../constants';

function docuploadPluginInputReducer(
  state: Docupload,
  action: DocuploadActionType,
) {
  switch (action.type) {
    case DocuploadAction.PLUGIN_INPUT_FAILURE:
      return produce(state, (draftState: Docupload) => {
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case DocuploadAction.PLUGIN_INPUT_STARTED:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = true;
      });

    case DocuploadAction.PLUGIN_INPUT_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = false;
      });

    default:
      return state;
  }
}

export default docuploadPluginInputReducer;
