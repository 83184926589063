import { StatusStep, StatusType } from 'modules/states/status';

type IsStatusStepCancelled = (step: StatusStep, status: StatusType) => boolean;

const isStatusStepCancelled: IsStatusStepCancelled = (step, status) => {
  switch (step) {
    case StatusStep.STEP1:
      return false;
    case StatusStep.STEP2:
      return status === StatusType.CAN_RES;
    case StatusStep.STEP3:
      return status === StatusType.CAN_REF;
    case StatusStep.STEP4:
      return status === StatusType.CAN_LEN;
    case StatusStep.STEP5:
      return status === StatusType.CAN;
    default:
      return false;
  }
};

export default isStatusStepCancelled;
