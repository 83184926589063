import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useMessagesStats } from 'modules/states/messages';
import { useCase } from 'modules/states/cases';
import { ROUTE } from '../constants';

export type UseNavigation = () => {
  linkContact: string | null;
  linkDocuments: string | null;
  linkLoan: string;
  linkMessages: string | null;
  linkStatus: string;
  linkTeam: string | null;
  messageCount: number;
  messageCountActive: boolean;
};

const useNavigation: UseNavigation = () => {
  const { caseId, isCaseActive } = useCase();
  const { pathname } = useLocation();

  const { unread: messageCount } = useMessagesStats(caseId);
  const messageCountActive = useMemo(
    () =>
      messageCount > 0 &&
      (!!matchPath(ROUTE.MESSAGES, pathname) ||
        !!matchPath(ROUTE.MESSAGES_CASE, pathname)),
    [messageCount, pathname],
  );

  return {
    linkStatus: caseId
      ? ROUTE.STATUS_CASE.replace(':caseId', caseId.toString())
      : ROUTE.STATUS,

    linkContact: !isCaseActive
      ? caseId
        ? ROUTE.CONTACT_CASE.replace(':caseId', caseId.toString())
        : ROUTE.CONTACT
      : null,

    linkMessages: isCaseActive
      ? caseId
        ? ROUTE.MESSAGES_CASE.replace(':caseId', caseId.toString())
        : ROUTE.MESSAGES
      : null,

    linkLoan: caseId
      ? ROUTE.LOAN_CASE.replace(':caseId', caseId.toString())
      : ROUTE.LOAN,

    linkTeam: isCaseActive
      ? caseId
        ? ROUTE.TEAM_CASE.replace(':caseId', caseId.toString())
        : ROUTE.TEAM
      : null,

    linkDocuments: isCaseActive
      ? caseId
        ? ROUTE.DOCUMENTS_CASE.replace(':caseId', caseId.toString())
        : ROUTE.DOCUMENTS
      : null,

    messageCount,
    messageCountActive,
  };
};

export default useNavigation;
