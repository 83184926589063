import { AnyAction } from 'redux';

import { analyticsTrackEvent } from 'modules/services/analytics';
import { Dispatch, RootState } from 'modules/redux';
import isVideoAction from './isVideoAction';
import { VideoAction } from '../constants';

type AnalyticsTrackVideo = (props: {
  action: AnyAction;
  getState: () => RootState;
  next: Dispatch<AnyAction>;
  nextAction: AnyAction | undefined;
}) => AnyAction | undefined;

const analyticsTrackVideo: AnalyticsTrackVideo = ({
  action,
  getState,
  next,
  nextAction,
}) => {
  if (isVideoAction(action))
    switch (action.type) {
      case VideoAction.SHOW_SUCCESS: {
        !nextAction && (nextAction = next(action));
        const state = getState();
        analyticsTrackEvent({
          category: 'Videos',
          action: 'Play',
          label:
            state.cases.data?.find(item => item.caseId === state.cases.caseId)
              ?.status || '',
        });
        break;
      }
    }

  return nextAction;
};

export default analyticsTrackVideo;
