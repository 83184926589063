import { Case } from '../types';

type FindCase = (
  array: Case[] | null,
  caseId: number | null,
) => Case | undefined;

const findCase: FindCase = (array, caseId) =>
  array?.find(item => item.caseId === caseId);

export default findCase;
