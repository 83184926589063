import React from 'react';

import { ButtonStyle } from 'components/shared/Button/constants';
import Button from 'components/shared/Button';
import Fieldset from 'components/shared/Fieldset';

import styles from 'routes/debug/styles.module.scss';

const ButtonsDebug: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className={styles['buttons-container']}>
        <Fieldset title="Button Text">
          <Button label="Test" style={ButtonStyle.PRIMARY} />
        </Fieldset>
        <Fieldset title="Button - Disabled">
          <Button disabled={true} label="Test" style={ButtonStyle.PRIMARY} />
        </Fieldset>
      </div>
      <div className={styles['buttons-container']}>
        <Fieldset title="Button Text">
          <Button label="Test" style={ButtonStyle.PRIMARY_INVERTED} />
        </Fieldset>
        <Fieldset title="Button - Disabled">
          <Button
            disabled={true}
            label="Test"
            style={ButtonStyle.PRIMARY_INVERTED}
          />
        </Fieldset>
      </div>
    </React.Fragment>
  );
};

export default React.memo(ButtonsDebug);
