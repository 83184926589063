import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getRedirectFromPath } from '../utils';

export type UseAppRedirect = (caseId: number | null) => void;

const useAppRedirect: UseAppRedirect = caseId => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = getRedirectFromPath(caseId, pathname);
    !!redirect && navigate(redirect, { replace: true });
  }, [caseId, navigate, pathname]);
};

export default useAppRedirect;
