import { OnfidoError } from '../../constants';
import { isError } from 'modules/utils';

type GetError = (error: unknown) => Error;

const checkError = (error: unknown, search: string): boolean =>
  (isError(error) ? error.message : typeof error === 'string' ? error : '')
    .toLowerCase()
    .includes(search);

const getError: GetError = error => {
  switch (true) {
    case isError(error) &&
      Object.values(OnfidoError).includes(error.message as OnfidoError):
      return error as Error;
    case checkError(error, 'active'):
      return new Error(OnfidoError.ACTIVE);
    case checkError(error, 'cancelled'):
      return new Error(OnfidoError.CANCELLED);
    case checkError(error, 'container'):
    case checkError(error, 'element'):
      return new Error(OnfidoError.MOUNT);
    case checkError(error, 'referrer'):
      return new Error(OnfidoError.REFERRER);
    case checkError(error, 'sdk'):
      return new Error(OnfidoError.SDK);
    case checkError(error, 'token'):
    case checkError(error, 'credentials'):
      return new Error(OnfidoError.TOKEN);
    case checkError(error, 'variant'):
      return new Error(OnfidoError.VARIANT);
    default:
      return new Error(OnfidoError.UNKNOWN);
  }
};

export default getError;
