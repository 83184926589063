import { useContext } from 'react';

import { Context } from './context';
import { Platform } from './types';

type UsePlatformContext = () => Platform;

const useAppContext: UsePlatformContext = () => useContext(Context);

export default useAppContext;
