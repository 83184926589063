export const isTarget = (
  event: TransitionEvent,
  ref: React.RefObject<HTMLTextAreaElement>,
) => event.currentTarget === ref.current;

export const isTransition = ({
  transition,
  event,
}: {
  transition: string;
  event: TransitionEvent;
}) => event.propertyName === transition;
