import { useCallback } from 'react';

import { UseHandlePostError } from './types';
import {
  onfidoResetErrorAction,
  onfidoPostRetryAction,
} from 'modules/onfido/actions';

const useHandlePostError: UseHandlePostError = ({
  showDialogConfirm,
  showDialogAlert,
  dispatch,
}) => {
  const handleError = useCallback(() => {
    if (showDialogAlert && showDialogConfirm)
      showDialogConfirm({
        message: 'An error has occurred. Try again?',
        onConfirm: () => dispatch(onfidoPostRetryAction()),
        onCancel: () => dispatch(onfidoResetErrorAction()),
        title: 'MyFluent',
      });
  }, [dispatch, showDialogAlert, showDialogConfirm]);

  return handleError;
};

export default useHandlePostError;
