import { platform } from 'modules/services/platform';
import { useCallback } from 'react';

const { isMobile } = platform;

export const useDesktopScreenLock = () => {
  const lockScreen = useCallback(() => {
    if (!isMobile) {
      document.body.style.paddingRight = `${
        window.innerWidth - document.documentElement.clientWidth
      }px`;
      document.body.style.overflow = 'hidden';
    }
  }, []);

  const unlockScreen = useCallback(() => {
    if (!isMobile) {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    }
  }, []);

  return {
    lockScreen,
    unlockScreen,
  };
};
