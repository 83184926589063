import { Middleware } from 'redux';

import { userGetAction, userLogoutAction } from './actions';
import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { AppActionType } from 'modules/app/types';
import { AppAction } from 'modules/app/constants';
import { UserActionType } from './types';
import { abortUser } from './utils';

const userMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | AuthActionType | UserActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.READY: {
        nextAction = next(action);
        const { auth } = getState();
        !!auth.data?.token && dispatch(userGetAction());
        break;
      }

      case AuthAction.LOGOUT:
        nextAction = next(action);
        abortUser(getState().user);
        dispatch(userLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default userMiddleware;
