import { shallowEqual, useSelector } from 'modules/redux';

export type UseJailbreak = () => {
  isJailbreakDirty: boolean;
  isJailbreakSecure: boolean;
};

const useJailbreak: UseJailbreak = () => {
  const { isDirty, isSecure } = useSelector(
    state => state.jailbreak,
    shallowEqual,
  );
  return { isJailbreakDirty: isDirty, isJailbreakSecure: isSecure };
};

export default useJailbreak;
