import { compare, validate } from 'compare-versions';

import { VersionENV } from './constants';

type GetVersionAndroidBuild = (build: string) => string | undefined;

export const getVersionAndroidBuild: GetVersionAndroidBuild = build =>
  build.length === 6 ? Number(build.substring(4, 6)).toString() : undefined;

type GetVersionEnvironment = () => VersionENV;

export const getVersionEnvironment: GetVersionEnvironment = () => {
  switch (true) {
    case process.env.REACT_APP_ENV === 'development':
      return VersionENV.DEV;
    case process.env.REACT_APP_ENV === 'uat':
      return VersionENV.UAT;
    default:
      return VersionENV.PROD;
  }
};

const isVersion = (version: unknown): version is string =>
  !!version && typeof version === 'string' && validate(version);

type IsVersionExpired = (version: unknown, minVersion: unknown) => boolean;

export const isVersionExpired: IsVersionExpired = (version, minVersion) =>
  isVersion(version) &&
  isVersion(minVersion) &&
  compare(version, minVersion, '<');
