export enum TeamAction {
  GET_STARTED = 'TEAM_GET_STARTED',
  GET_SUCCESS = 'TEAM_GET_SUCCESS',
  GET_FAILURE = 'TEAM_GET_FAILURE',

  LOGOUT = 'TEAM_LOGOUT',
}

export enum TeamStatus {
  LOGGED_IN = 'Logged In',
  LOGGED_OUT = 'Logged Out',
}

export enum TeamType {
  ADVISER = 'adviser',
  CASE_MANAGER = 'case-manager',
}
