import { Middleware } from 'redux';

import { authLogoutAction } from 'modules/auth';
import { SessionActionType } from './types';
import { SessionAction } from './constants';
import { RootState } from 'modules/redux';

const sessionMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch }) =>
  next =>
  async (action: SessionActionType) => {
    let nextAction;

    switch (action.type) {
      case SessionAction.TIMEOUT:
        nextAction = next(action);
        dispatch(authLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default sessionMiddleware;
