import React from 'react';

import { usePlatformContext } from 'modules/services/platform';
import { shallowEqual, useSelector } from 'modules/redux';
import Dialog, { DialogProps } from '../Dialog';

import { DialogType } from '../../constants';

const RenderDialog = React.memo<DialogProps>(dialog => {
  switch (dialog.type) {
    case DialogType.ALERT:
      return <Dialog {...dialog} />;
    case DialogType.CONFIRM:
      return <Dialog {...dialog} />;
    default:
      return null;
  }
});

const Dialogs: React.FunctionComponent = () => {
  const dialogs = useSelector(state => state.dialogs, shallowEqual);
  const { isDesktop } = usePlatformContext();
  return isDesktop && dialogs.length > 0 ? (
    <RenderDialog {...dialogs[0]} />
  ) : null;
};

export default React.memo(Dialogs);
