import { useCallback, useEffect, useRef, useState } from 'react';

import useModalAction from '../useModalAction';
import { ShowModal, UseModal } from './types';

const useModal: UseModal = () => {
  const { modalDestroy, modalHide, modalShow } = useModalAction();
  const [modalId, setModal] = useState<string | null>();
  const destroyModalRef = useRef<() => void>();
  const hideModalRef = useRef<() => void>();
  const mounted = useRef(true);

  useEffect(() => {
    destroyModalRef.current = () => {
      !!modalId && modalDestroy(modalId);
      mounted.current && setModal(null);
    };
    hideModalRef.current = () => {
      !!modalId && modalHide(modalId);
    };
  }, [modalDestroy, modalHide, modalId]);

  const destroyModal = useCallback(() => {
    destroyModalRef.current && destroyModalRef.current();
  }, []);

  const hideModal = useCallback(() => {
    hideModalRef.current && hideModalRef.current();
  }, []);

  const showModal: ShowModal = useCallback(
    ({ onClose, onDestroy, ...modal }) => {
      if (!modalId) {
        const id = modalShow({
          onClose: () => {
            !!onClose && onClose();
            hideModal();
          },
          onDestroy: () => {
            !!onDestroy && onDestroy();
            destroyModal();
          },
          ...modal,
        });
        setModal(id);
        return id;
      }
    },
    [destroyModal, hideModal, modalId, modalShow],
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      hideModal();
    };
  }, [hideModal]);

  const result: ReturnType<UseModal> = [
    !modalId ? showModal : undefined,
    modalId ? hideModal : undefined,
  ];
  result.hideModal = modalId ? hideModal : undefined;
  result.showModal = !modalId ? showModal : undefined;
  return result;
};

export default useModal;
