import React from 'react';

import { ExternalLink } from 'components/shared/Hyperlinks';

export const LinkAndroid: React.FunctionComponent = () => {
  return process.env.REACT_APP_ANDROID_STORE ? (
    <React.Fragment>
      <br />
      <br />
      <ExternalLink
        href={process.env.REACT_APP_ANDROID_STORE}
        label="Go to Google Play"
      />
    </React.Fragment>
  ) : null;
};

export const LinkIOS: React.FunctionComponent = () => {
  return process.env.REACT_APP_IOS_STORE ? (
    <React.Fragment>
      <br />
      <br />
      <ExternalLink
        href={process.env.REACT_APP_IOS_STORE}
        label="Go to App Store"
      />
    </React.Fragment>
  ) : null;
};

export const LinkWebsite: React.FunctionComponent = () => {
  return (
    <ExternalLink
      href="https://www.myfluent.co.uk"
      label="https://www.myfluent.co.uk"
    />
  );
};
