import React from 'react';
import clsx from 'clsx';

import {
  ButtonsDebug,
  DialogsDebug,
  MessageInputDebug,
  ModalsDebug,
  QuestionTextDebug,
} from 'routes/debug/components/';

import debugStyles from './styles.module.scss';
import mainStyles from 'components/desktop/Main/styles.module.scss';
import styles from '../styles.module.scss';

type TemplateProps = Record<string, unknown>;

const Template: React.FunctionComponent<TemplateProps> = () => {
  const classNames = clsx(
    mainStyles.main,
    styles.debug,
    debugStyles['desktop-debug'],
  );
  return (
    <main className={classNames}>
      <DialogsDebug />
      <ModalsDebug />
      <QuestionTextDebug />
      <MessageInputDebug />
      <ButtonsDebug />
    </main>
  );
};

export default React.memo(Template);
