import { AuthInitAction, AuthLogoutAction } from '../types';
import { AuthAction } from '../constants';

export {
  type AuthBiometricLoginActionType,
  authBiometricLoginStatusAction,
  authBiometricLoginResetAction,
  authBiometricLoginReducer,
  authBiometricLoginAction,
} from './authBiometricLoginAction';

export {
  type AuthBiometricQuestionActionType,
  authBiometricQuestionReducer,
  authBiometricQuestionAction,
} from './authBiometricQuestionAction';

export {
  defaultValues as authChangePasswordDefaultValues,
  type AuthChangePasswordActionApiRequest,
  type Model as AuthChangePasswordModel,
  schema as authChangePasswordSchema,
  type AuthChangePasswordActionType,
  authChangePasswordReducer,
  authChangePasswordAction,
} from './authChangePasswordAction';

export {
  type Model as AuthLoginModel,
  type AuthLoginActionType,
  authLoginResetAction,
  authLoginReducer,
  authLoginAction,
} from './authLoginAction';

export const authInitAction: AuthInitAction = () => ({
  type: AuthAction.INIT,
  isBiometric: false,
});

export const authLogoutAction: AuthLogoutAction = () => ({
  type: AuthAction.LOGOUT,
});
