import { v4 as uuidv4 } from 'uuid';

import { api, API, isAxiosAbortError, newAbortController } from 'modules/api';
import { ConfigAction } from './constants';
import { isError } from 'modules/utils';
import { isActive } from './utils';
import {
  ConfigPollingStartedAction,
  ConfigPollingEndedAction,
  ConfigGetFailureAction,
  ConfigGetStartedAction,
  ConfigGetSuccessAction,
  ConfigApiResponse,
  ConfigInitAction,
  ConfigGetAction,
} from './types';

export const configInitAction: ConfigInitAction = () => ({
  type: ConfigAction.INIT,
});

export const configGetAction: ConfigGetAction = () => {
  return async (dispatch, getState) => {
    const actionId = uuidv4();
    try {
      if (getState().config.isLoading) return;

      const controller = newAbortController();

      dispatch(configGetStartedAction({ actionId, controller }));

      const response = (await api(
        API.GET_CONFIG({ dispatch, getState }),
      )) as ConfigApiResponse;

      isActive(actionId, getState().config) &&
        dispatch(configGetSuccessAction({ actionId, data: response.data }));
    } catch (error) {
      isActive(actionId, getState().config) &&
        !isAxiosAbortError(error) &&
        dispatch(
          configGetFailureAction({
            actionId,
            error: isError(error) ? error : new Error('Unexpected Error'),
          }),
        );
    }
  };
};

const configGetStartedAction: ConfigGetStartedAction = payload => ({
  type: ConfigAction.GET_STARTED,
  ...payload,
});

const configGetSuccessAction: ConfigGetSuccessAction = payload => ({
  type: ConfigAction.GET_SUCCESS,
  ...payload,
});

const configGetFailureAction: ConfigGetFailureAction = payload => ({
  type: ConfigAction.GET_FAILURE,
  ...payload,
});

export const configPollingStartedAction: ConfigPollingStartedAction = () => ({
  type: ConfigAction.POLLING_STARTED,
});

export const configPollingEndedAction: ConfigPollingEndedAction = () => ({
  type: ConfigAction.POLLING_ENDED,
});
