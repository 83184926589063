import React from 'react';

import MessageTemplateDocupload from './templates/MessageTemplateDocupload';
import MessageTemplateDefault from './templates/MessageTemplateDefault';
import MessageTemplateOnfido from './templates/MessageTemplateOnfido';
import { MessageProps } from './types';
import useMessage from './hooks';
import {
  MessageDocupload,
  MessageDefault,
  MessageOnfido,
  MessageType,
} from 'modules/states/messages';

const Message: React.FunctionComponent<MessageProps> = ({
  handleDocupload,
  handleInView,
  handleOnfido,
  message,
  showHeader,
}) => {
  const { ref, ...props } = useMessage({
    handleInView,
    message,
  });
  switch (message.type) {
    case MessageType.DOCUMENT_UPLOAD:
      return (
        <MessageTemplateDocupload
          forwardedRef={ref}
          message={message as MessageDocupload}
          onClick={handleDocupload}
          showHeader={showHeader}
          {...props}
        />
      );
    case MessageType.ONFIDO:
      return (
        <MessageTemplateOnfido
          forwardedRef={ref}
          message={message as MessageOnfido}
          onClick={handleOnfido}
          showHeader={showHeader}
          {...props}
        />
      );
    default:
      return (
        <MessageTemplateDefault
          forwardedRef={ref}
          message={message as MessageDefault}
          showHeader={showHeader}
          {...props}
        />
      );
  }
};

export default React.memo(Message);
