import { useEffect } from 'react';

import { useAccountSelector } from 'modules/states/account';
import useStatusSelector from './useStatusSelector';
import { useVideo } from 'modules/services/video';
import { Case } from 'modules/states/cases';

export type UseStatusRoute = () => {
  data: Case | null;
  handleError: () => void;
  handleVideo?: () => void;
  isError: Error | false;
  isLoading: boolean;
  isPlaying: boolean;
};

const useStatusRoute: UseStatusRoute = () => {
  const { caseId, handleError, isError, isLoading } = useAccountSelector();

  const { data, handleRequest } = useStatusSelector(caseId);

  useEffect(() => {
    !isLoading && handleRequest();
  }, [handleRequest, isLoading]);

  const { handleVideo, isPlaying } = useVideo(data?.video);

  return {
    data,
    handleError,
    handleVideo,
    isError,
    isLoading,
    isPlaying,
  };
};

export default useStatusRoute;
