import { Location } from 'history';

import getRoute from './getRoute';

const pageTitle = (location: Location) => {
  const route = getRoute(location.pathname);
  document.title = `MyFluent${route && ` | ${route.title}`}`;
};

export default pageTitle;
