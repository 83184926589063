import { getPreviousRoute, matchRoute, ROUTE } from 'modules/router';
import { RootState } from 'modules/redux';

type ShouldClose = (getState: () => RootState) => boolean;

const shouldClose: ShouldClose = getState => {
  const { auth, onfido, router } = getState();
  if (auth.data?.token && onfido.messageId)
    switch (true) {
      case matchRoute(ROUTE.MESSAGES_CASE, getPreviousRoute(router)):
        return true;
      case matchRoute(ROUTE.MESSAGES, getPreviousRoute(router)):
        return true;
    }
  return false;
};

export default shouldClose;
