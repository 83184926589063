import { TeamData, TeamGetActionApiResponse } from '../types';
import newTeamMember from './newTeamMember';
import { TeamType } from '../constants';

type UpdateTeam = (data: TeamGetActionApiResponse['data']) => TeamData;

const updateTeam: UpdateTeam = data => ({
  adviser: newTeamMember({
    image: data.adviserImage,
    name: data.adviserName,
    status: data.adviserStatus,
    telephone: data.adviserTelephone,
    type: TeamType.ADVISER,
  }),
  caseManager: newTeamMember({
    image: data.caseManagerImage,
    name: data.caseManagerName,
    status: data.caseManagerStatus,
    telephone: data.caseManagerTelephone,
    type: TeamType.CASE_MANAGER,
  }),
});

export default updateTeam;
