export enum CertificateAction {
  INIT = 'CERTIFICATE_INIT',
  RESULT = 'CERTIFICATE_RESULT',
}

export enum CertificateStatus {
  NOT_SECURE = 'CONNECTION_NOT_SECURE',
  FAILED = 'CONNECTION_FAILED',
  SECURE = 'CONNECTION_SECURE',
}
