import { useEffect } from 'react';

type UseTransitionEnd = (props: {
  listener: (event: TransitionEvent) => void;
  ref: React.RefObject<HTMLTextAreaElement>;
}) => void;

const useTransitionEnd: UseTransitionEnd = ({ listener, ref }) => {
  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('transitionend', listener);
    return () => {
      element?.removeEventListener('transitionend', listener);
    };
  }, [listener, ref]);
};

export default useTransitionEnd;
