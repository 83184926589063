import React from 'react';
import clsx from 'clsx';

import { NavItemType } from './constants';
import { NavigationLink } from 'components/shared/Hyperlinks';

import styles from './styles.module.scss';

type NavLinkProps = {
  children?: React.ReactNode;
  gradientValue: string;
  handleBlur: (id: string) => void;
  handleFocus: (id: string) => void;
  handleMouseOver: (id: string) => void;
  handleMouseOut: (id: string) => void;
  label: string;
  route: string | null;
  type: NavItemType;
};

const NavLink: React.FunctionComponent<NavLinkProps> = ({
  children,
  gradientValue,
  handleBlur,
  handleFocus,
  handleMouseOver,
  handleMouseOut,
  label,
  route,
  type,
}) => {
  const classNames = clsx(styles[`${type}`]);
  const formattedLabel = label.toLowerCase().replace(/ +/g, '-');

  return route ? (
    <li className={classNames}>
      <NavigationLink
        className={styles[`${formattedLabel}`]}
        label={label}
        onBlur={() => handleBlur(formattedLabel)}
        onFocus={() => handleFocus(formattedLabel)}
        onMouseOut={() => handleMouseOut(formattedLabel)}
        onMouseOver={() => handleMouseOver(formattedLabel)}
        route={route}
        title={label}>
        {children}
        <span style={{ backgroundSize: `100% ${gradientValue}` }}></span>
      </NavigationLink>
    </li>
  ) : null;
};

export default React.memo(NavLink);
