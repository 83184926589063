import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { platform } from 'modules/services/platform';
import { Dispatch, RootState } from 'modules/redux';
import { dialogLogoutAction } from './actions';
import { dialogPluginShow } from './plugin';
import { DialogActionType } from './types';
import { DialogAction } from './constants';

const { isCapacitor, isMobile } = platform;

const dialogsMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | DialogActionType) => {
    let nextAction;

    switch (action.type) {
      case DialogAction.DESTROY:
        nextAction = next(action);
        isMobile &&
          getState().dialogs.length &&
          dialogPluginShow(getState().dialogs[0]);
        break;

      case DialogAction.INSERT:
        nextAction = next(action);
        isMobile &&
          getState().dialogs.length === 1 &&
          setTimeout(
            () => {
              dialogPluginShow(getState().dialogs[0]);
            },
            isCapacitor ? 0 : 350,
          );

        break;

      case AuthAction.LOGOUT:
        nextAction = next(action);
        dispatch(dialogLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default dialogsMiddleware;
