import React from 'react';

import { MessageActionDocupload } from 'modules/states/messages';

type GetMessageDocumentUploadLabel = (
  action: MessageActionDocupload,
) => JSX.Element;

const getMessageDocumentUploadLabel: GetMessageDocumentUploadLabel = action => {
  switch (action) {
    case MessageActionDocupload.APPROVED:
      return (
        <React.Fragment>
          Your document check has
          <br />
          been completed
        </React.Fragment>
      );
    case MessageActionDocupload.AWAITING_REVIEW:
      return (
        <React.Fragment>
          Your document is
          <br />
          being reviewed
        </React.Fragment>
      );
    case MessageActionDocupload.CANCELLED:
      return (
        <React.Fragment>
          Your document check has
          <br />
          been cancelled
        </React.Fragment>
      );
    case MessageActionDocupload.DECLINED:
      return (
        <React.Fragment>
          We need to contact you
          <br />
          about your upload
        </React.Fragment>
      );
    case MessageActionDocupload.REQUESTED:
    default:
      return <React.Fragment>Upload Document</React.Fragment>;
  }
};

export default getMessageDocumentUploadLabel;
