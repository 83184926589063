import { LoanGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';
import findLoan from './findLoan';

type IsActive = (props: {
  request: LoanGetActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ request, state }) =>
  !!findLoan(state.loan, request.caseId)?.isLoading;

export default isActive;
