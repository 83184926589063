import React, { useState } from 'react';
import clsx from 'clsx';

import { defaultValues, schema, Model } from 'modules/states/messages/model';
import Button, { ButtonType } from 'components/shared/Button';
import Form, { FormProps } from 'components/shared/Form';
import { useKeyboard } from 'modules/services/keyboard';
import MessagesInput from '../MessagesInput';

import styles from './styles.module.scss';

type MessagesControlsProps = {
  handleSubmit: FormProps<Model>['onSubmit'];
  isError: false | Error;
  isLoading: boolean;
  isSending: boolean;
};

const MessagesControls: React.FunctionComponent<MessagesControlsProps> = ({
  handleSubmit,
  isError,
  isLoading,
  isSending,
}) => {
  const [textAreaSpace, setTextAreaSpace] = useState<null | string>(null);
  const { active } = useKeyboard();
  const className = clsx(
    styles['messages-controls'],
    active && styles['focused'],
    isSending && styles['sending'],
    textAreaSpace && `${textAreaSpace}`,
  );

  return (
    <div className={className}>
      <Form<Model>
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        schema={schema}>
        {({ control, isFormSubmitting, values }) => {
          return (
            <React.Fragment>
              <MessagesInput<Model>
                control={control}
                disabled={!!isError || isLoading}
                isFocused={active || isSending}
                isSending={isSending}
                name="message"
                setTextAreaSpace={setTextAreaSpace}
              />
              <Button
                disabled={
                  !!isError ||
                  isLoading ||
                  isFormSubmitting ||
                  isSending ||
                  !values.message
                }
                label="Send"
                type={ButtonType.SUBMIT}
              />
            </React.Fragment>
          );
        }}
      </Form>
    </div>
  );
};

export default React.memo(MessagesControls);
