import { CapacitorVideoPlayer } from 'capacitor-video-player';

import { CloseVideoPlugin } from './types';
import { isActive } from './listeners';

const closeVideoPlugin: CloseVideoPlugin = async playerId => {
  if (isActive) {
    const { value: seektime } = await CapacitorVideoPlayer.getDuration({
      playerId,
    });

    await CapacitorVideoPlayer.setCurrentTime({
      playerId,
      seektime,
    });

    const { value: isPlaying } = await CapacitorVideoPlayer.isPlaying({
      playerId,
    });

    !isPlaying && (await CapacitorVideoPlayer.play({ playerId }));
  }
};

export default closeVideoPlugin;
