import React from 'react';
import clsx from 'clsx';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import { TextArea } from 'components/shared/Inputs';
import { useAutoGrow } from './hooks/useAutoGrow';

import styles from './styles.module.scss';

type MessagesInputProps<T extends FieldValues> = {
  control: Control<T>;
  disabled?: boolean;
  isFocused: boolean;
  isSending: boolean;
  name: string;
  setTextAreaSpace: React.Dispatch<React.SetStateAction<string | null>>;
};

function MessagesInput<T extends FieldValues>({
  control,
  disabled,
  isFocused,
  isSending,
  name,
  setTextAreaSpace,
}: MessagesInputProps<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { invalid },
  } = useController({
    control,
    name: name as Path<T>,
  });

  const { animating, autoGrowRef, fullyExpanded } = useAutoGrow(
    isFocused,
    setTextAreaSpace,
    value as string,
  );

  const classNames = clsx(
    styles['messages-input'],
    animating && styles.animating,
    fullyExpanded && styles['fully-expanded'],
  );

  return (
    <div className={classNames}>
      <TextArea
        disabled={disabled}
        inputRef={e => {
          ref(e);
          autoGrowRef.current = e;
        }}
        invalid={invalid}
        name={name}
        onChange={onChange}
        placeholder={
          disabled ? 'Loading...' : isSending ? 'Sending...' : 'Enter Message'
        }
        readOnly={isSending}
        rows={1}
        spellCheck={!animating}
        value={value as string}>
        <span>
          {(value as string).split('\n').map((i, key) => (
            <span key={`${key}-${i}`}>{i}</span>
          ))}
        </span>
      </TextArea>
    </div>
  );
}

export default React.memo(MessagesInput) as typeof MessagesInput;
