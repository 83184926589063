import React from 'react';
import clsx from 'clsx';

import { ReactComponent as Active } from 'modules/theme/images/icons/team/active.svg';
import { ExternalLink, HyperlinkStyle } from 'components/shared/Hyperlinks';
import { TeamStatus, TeamType } from 'modules/states/team/constants';
import Button, { ButtonStyle } from 'components/shared/Button';
import Avatar from 'components/shared/Avatar';
import { TeamMemberProps } from './types';

import styles from './styles.module.scss';

const TeamMember: React.FunctionComponent<TeamMemberProps> = ({
  handleMessage,
  image,
  name,
  status,
  telephone,
  type,
}) => {
  const className = clsx(
    styles['team-member'],
    status === TeamStatus.LOGGED_IN && styles.active,
    type === TeamType.ADVISER
      ? styles['team-member-adviser']
      : styles['team-member-case-manager'],
  );
  return (
    <section className={className}>
      <div>
        <div>
          <div>
            {type === TeamType.ADVISER ? (
              <h2>Adviser</h2>
            ) : (
              <h2>Case Manager</h2>
            )}
            <h3>{name}</h3>
          </div>
          {type === TeamType.ADVISER ? (
            <h4>I am your qualified Adviser</h4>
          ) : (
            <h4>
              I'll make sure your application is completed as soon as possible
            </h4>
          )}
          <p>
            <Active />
            {status === TeamStatus.LOGGED_IN ? (
              <React.Fragment>I'm at my desk</React.Fragment>
            ) : (
              <React.Fragment>I'm away from my desk</React.Fragment>
            )}
          </p>
          <footer>
            <Button
              label="Message"
              onClick={handleMessage}
              style={ButtonStyle.PRIMARY_INVERTED}
            />
            {!!telephone && (
              <ExternalLink
                href={`tel:${telephone.replace(/\s+/g, '')}`}
                label="Call"
                style={HyperlinkStyle.PRIMARY_INVERTED}
              />
            )}
          </footer>
          <Avatar name={name} src={image} />
        </div>
      </div>
    </section>
  );
};

export default React.memo(TeamMember);
