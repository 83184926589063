import { VideoPluginDestroy, VideoPluginInit } from './types';
import {
  closeVideoPlugin,
  initVideoPlugin,
  removeListeners,
  addListeners,
} from './utils';

export const videoPluginDestroy: VideoPluginDestroy = async modalId => {
  try {
    await removeListeners();
    closeVideoPlugin(modalId);
  } catch {
    return;
  }
};

export const videoPluginInit: VideoPluginInit = async ({
  modalId,
  onError,
  video,
  ...props
}) => {
  try {
    await addListeners(props);
    await initVideoPlugin({ playerId: modalId, url: video.mp4 });
  } catch (error) {
    removeListeners();
    onError(error);
  }
};
