import { SdkResponse } from 'onfido-sdk-ui';

import getDocumentName from './getDocumentName';
import { OnfidoPluginResponse } from '../types';
import { OnfidoVariant } from '../../constants';
import { isOnfidoVariant } from '../../utils';

type HandleComplete = (props: {
  data: SdkResponse;
  onComplete: (data: OnfidoPluginResponse) => void;
  variant: OnfidoVariant;
}) => void;

const handleComplete: HandleComplete = ({ data, onComplete, variant }) =>
  onComplete({
    document: getDocumentName(
      data.document_front?.type || data.document_back?.type || 'unknown',
    ),
    variant:
      !!data.face?.variant && isOnfidoVariant(data.face.variant)
        ? data.face.variant
        : variant,
  });

export default handleComplete;
