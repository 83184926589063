import { AnyAction } from 'redux';

import { analyticsTrackEvent } from 'modules/services/analytics';
import { ModalAction, ModalType } from '../constants';
import { Dispatch, RootState } from 'modules/redux';
import isModalAction from './isModalAction';

type AnalyticsTrackModals = (props: {
  action: AnyAction;
  getState: () => RootState;
  next: Dispatch<AnyAction>;
  nextAction: AnyAction | undefined;
}) => AnyAction | undefined;

const analyticsTrackModals: AnalyticsTrackModals = ({
  action,
  getState,
  next,
  nextAction,
}) => {
  if (isModalAction(action))
    switch (action.type) {
      case ModalAction.INSERT: {
        switch (action.modal.type) {
          case ModalType.CASES:
            analyticsTrackEvent({
              category: 'Cases',
              action: 'pressedSwitchCase',
            });
            break;
        }
        break;
      }
    }

  return nextAction;
};

export default analyticsTrackModals;
