import { CasesGetActionApiResponse, CasesState } from '../types';
import findInactiveCaseId from './findInactiveCaseId';
import findActiveCaseId from './findActiveCaseId';
import updateCase from './updateCase';
import findCase from './findCase';
import newCase from './newCase';

type UpdateCases = (
  state: CasesState,
  data: CasesGetActionApiResponse['data'],
) => void;

const updateCases: UpdateCases = (state, data) => {
  Array.isArray(data) &&
    data.forEach(item => {
      const result = findCase(state.data, item.caseId);
      result ? updateCase(result, item) : newCase(state.data, item);
    });

  if (!state.caseId || !findCase(state.data, state.caseId))
    state.caseId =
      findActiveCaseId(state.data) || findInactiveCaseId(state.data) || null;
};

export default updateCases;
