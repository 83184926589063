import { useCallback, useState } from 'react';

import { useChromeEmulator } from 'modules/hooks/useChromeEmulator';
import { useResizeObserver } from 'modules/hooks/useResizeObserver';

export const useScrollbar = (
  ref: React.MutableRefObject<HTMLElement | null>,
) => {
  const [scrollbarActive, setScrollbarActive] = useState<boolean>(
    window.innerWidth - document.documentElement.clientWidth > 0,
  );

  const scrollbarWidth = useCallback(() => {
    if (ref && ref.current) {
      setScrollbarActive(
        window.innerWidth - document.documentElement.clientWidth > 0,
      );
    }
  }, [ref]);

  useChromeEmulator({
    on: () => setScrollbarActive(false),
    off: () =>
      setScrollbarActive(
        window.innerWidth - document.documentElement.clientWidth > 0,
      ),
  });

  useResizeObserver(ref, scrollbarWidth);

  return {
    scrollbarActive,
  };
};
