import React from 'react';

import { ReactComponent as DOC } from 'modules/theme/images/icons/documents/file-doc.svg';
import { ReactComponent as PDF } from 'modules/theme/images/icons/documents/file-pdf.svg';
import { ReactComponent as WEB } from 'modules/theme/images/icons/documents/file-web.svg';
import { ReactComponent as FILE } from 'modules/theme/images/icons/documents/file.svg';
import { DocumentExtension } from 'modules/states/documents';

type ExtensionIconProps = {
  extension: DocumentExtension | string;
};

const ExtensionIcon: React.FunctionComponent<ExtensionIconProps> = ({
  extension,
}) => {
  switch (extension) {
    case DocumentExtension.WEB:
      return <WEB />;
    case DocumentExtension.PDF:
      return <PDF />;
    case DocumentExtension.DOC:
    case DocumentExtension.DOCX:
      return <DOC />;
    default:
      return <FILE />;
  }
};

export default React.memo(ExtensionIcon);
