import { useCallback } from 'react';

import { usePlatformContext } from 'modules/services/platform';
import { onfidoCancelAction } from '../../actions';
import { UseOnfidoModal } from './types';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

const useOnfidoModal: UseOnfidoModal = () => {
  const isMobileBreakpoint = window.matchMedia('(max-width: 767px)').matches;
  const { isMobile } = usePlatformContext();
  const dispatch: Dispatch = useDispatch();

  const { isError, isLoading, isSending, isSendingError } = useSelector(
    state => state.onfido,
    shallowEqual,
  );

  const disabled = !!isError || !!isLoading || !!isSending || !!isSendingError;

  const handleClose = useCallback(
    () => !disabled && dispatch(onfidoCancelAction()),
    [disabled, dispatch],
  );

  return {
    disabled,
    handleClose,
    isMobile: isMobile || isMobileBreakpoint,
  };
};

export default useOnfidoModal;
