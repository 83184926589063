import { ThemeChangeAction, ThemeInitAction } from './types';
import { themePluginColor } from './plugin';
import { ThemeAction } from './constants';

export const themeInitAction: ThemeInitAction = () => ({
  type: ThemeAction.INIT,
  theme: themePluginColor(),
});

export const themeChangeAction: ThemeChangeAction = theme => ({
  type: ThemeAction.CHANGE,
  theme,
});
