import { useCallback, useEffect } from 'react';

import { useAccountSelector } from 'modules/states/account';
import useLoanSelector from './useLoanSelector';
import { Loan } from '../types';

export type UseLoanRoute = () => {
  data: Loan['data'];
  handleError: () => void;
  isError: Error | false;
  isLoading: boolean;
};

const useLoanRoute: UseLoanRoute = () => {
  const {
    caseId,
    handleError: handleAccountError,
    isError: isAccountError,
    isLoading: isAccountLoading,
  } = useAccountSelector();

  const { data, handleRequest, isDirty, isError } = useLoanSelector(caseId);

  const handleError = useCallback(
    () => (isAccountError ? handleAccountError() : !!caseId && handleRequest()),
    [caseId, handleAccountError, handleRequest, isAccountError],
  );

  useEffect(() => {
    !isAccountLoading && handleRequest();
  }, [handleRequest, isAccountLoading]);

  return {
    data,
    handleError,
    isError: isAccountError
      ? isAccountError
      : !isDirty && isError
        ? isError
        : false,
    isLoading: isAccountLoading || !isDirty,
  };
};

export default useLoanRoute;

//TODO LOAN AND CASES FIRE AT THE SAME TIME?
