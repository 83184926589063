import { RootState } from 'modules/redux';
import {
  OnfidoPostActionApiRequest,
  OnfidoPluginSuccessAction,
} from '../actions';

type GetSuccessPayload = (
  action: ReturnType<OnfidoPluginSuccessAction>,
  getState: () => RootState,
) => OnfidoPostActionApiRequest | null;

const getSuccessPayload: GetSuccessPayload = (action, getState) => {
  const { caseId, finishURL, messageId } = getState().onfido;
  return caseId && finishURL && messageId
    ? {
        caseId,
        finishURL,
        messageId,
        variant: action.variant,
      }
    : null;
};

export default getSuccessPayload;
