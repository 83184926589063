import { platform } from 'modules/services/platform';
import {
  analyticsPluginTrackPageViewFirebase,
  analyticsPluginTrackEventFirebase,
  analyticsPluginInitFirebase,
} from './firebase';
import {
  analyticsPluginTrackPageViewGA4,
  analyticsPluginTrackEventGA4,
  analyticsPluginInitGA4,
} from './web';
import {
  AnalyticsPluginTrackPageView,
  AnalyticsPluginTrackEvent,
} from './types';

const { isCapacitor } = platform;

export const analyticsPluginTrackEvent: AnalyticsPluginTrackEvent = payload =>
  isCapacitor
    ? analyticsPluginTrackEventFirebase(payload)
    : analyticsPluginTrackEventGA4(payload);

export const analyticsPluginTrackPageView: AnalyticsPluginTrackPageView =
  payload =>
    isCapacitor
      ? analyticsPluginTrackPageViewFirebase(payload)
      : analyticsPluginTrackPageViewGA4(payload);

export const analyticsPluginInit = () =>
  isCapacitor ? analyticsPluginInitFirebase() : analyticsPluginInitGA4();
