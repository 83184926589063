import { produce } from 'immer';

import { Notifications, NotificationsActionType } from '../../types';
import { NotificationsAction } from '../../constants';

function notificationsPostReducer(
  state: Notifications,
  action: NotificationsActionType,
) {
  switch (action.type) {
    case NotificationsAction.POST_FAILURE:
      return produce(state, (draftState: Notifications) => {
        draftState.actionId = null;
        draftState.isError = action.error;
        draftState.isSending = false;
      });

    case NotificationsAction.POST_STARTED:
      return produce(state, (draftState: Notifications) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isSending = action.controller || true;
      });

    case NotificationsAction.POST_SUCCESS:
      return produce(state, (draftState: Notifications) => {
        draftState.actionId = null;
        draftState.isError = false;
        draftState.isSending = false;
      });

    default:
      return state;
  }
}

export default notificationsPostReducer;
