import { useCallback } from 'react';

import { docuploadPluginDeleteAction } from '../../../actions';
import { HandleDelete, UseHandleDelete } from './types';

const useHandleDelete: UseHandleDelete = ({ dispatch, showDialogConfirm }) => {
  const handleDelete: HandleDelete = useCallback(
    id =>
      !!showDialogConfirm &&
      showDialogConfirm({
        message: 'Delete file?',
        onConfirm: () => dispatch(docuploadPluginDeleteAction(id)),
        title: 'MyFluent',
      }),
    [dispatch, showDialogConfirm],
  );

  return handleDelete;
};

export default useHandleDelete;
