import { useMemo } from 'react';

export const countChar = (str: string) => str.trim().replace('.', '').length;

export const useTextLength = (
  loanAnnualRate: string,
  loanInitialRate: string,
  loanInitialRepayment: string,
  loanRepayment: string,
) => {
  const array = useMemo(
    () => [
      countChar(loanAnnualRate),
      countChar(loanInitialRate),
      countChar(loanInitialRepayment),
      countChar(loanRepayment),
    ],
    [loanAnnualRate, loanInitialRate, loanInitialRepayment, loanRepayment],
  );

  return Math.max(...array);
};
