import { formatDate, isDateToday, isDateYesterday } from 'modules/date';

type GetMessageDateFooter = (date: Date) => string;

const getMessageDateFooter: GetMessageDateFooter = date =>
  isDateToday(date)
    ? `${formatDate(date, 'HH:mm')} | Today`
    : isDateYesterday(date)
      ? `${formatDate(date, 'HH:mm')} | Yesterday`
      : formatDate(date, 'HH:mm | ddd D MMM YYYY');

export default getMessageDateFooter;
