import { produce } from 'immer';

import { Auth, AuthActionType } from '../../types';
import { AuthAction } from '../../constants';

function authChangePasswordReducer(state: Auth, action: AuthActionType) {
  switch (action.type) {
    case AuthAction.CHANGE_PASSWORD_FAILURE:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case AuthAction.CHANGE_PASSWORD_STARTED:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isLoading = action.controller || true;
      });

    case AuthAction.CHANGE_PASSWORD_SUCCESS:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isError = false;
        draftState.isLoading = false;

        draftState.changePassword = false;
      });

    default:
      return state;
  }
}

export default authChangePasswordReducer;
