import { docuploadPluginFileAction } from '../docuploadPluginFileAction';
import { DocuploadError, DocuploadAction } from '../../../../constants';
import { getError, isPresent } from '../../../../utils';
import { timeout } from 'modules/redux/factories/utils';
import {
  DocuploadPluginInputFailureAction,
  DocuploadPluginInputStartedAction,
  DocuploadPluginInputSuccessAction,
  DocuploadPluginInputAction,
} from './types';

export const docuploadPluginInputAction: DocuploadPluginInputAction = event => {
  return async (dispatch, getState) => {
    const { messageId } = getState().docupload;
    const startTime = new Date().getTime();
    try {
      if (!getState().auth.data?.token) return;

      dispatch(docuploadPluginInputStartedAction());

      if (event.target.files) {
        await dispatch(docuploadPluginFileAction(event.target.files));
      } else {
        throw new Error(DocuploadError.EMPTY);
      }

      if (isPresent({ messageId, state: getState() })) {
        dispatch(docuploadPluginInputSuccessAction());
        event.target.value = '';
      }
    } catch (error) {
      await timeout(startTime, 1000);

      if (isPresent({ messageId, state: getState() })) {
        dispatch(
          docuploadPluginInputFailureAction(
            getError(error, DocuploadError.FILE),
          ),
        );
        event.target.value = '';
      }
    }
  };
};

const docuploadPluginInputStartedAction: DocuploadPluginInputStartedAction =
  () => ({
    type: DocuploadAction.PLUGIN_INPUT_STARTED,
  });

const docuploadPluginInputSuccessAction: DocuploadPluginInputSuccessAction =
  () => ({
    type: DocuploadAction.PLUGIN_INPUT_SUCCESS,
  });

const docuploadPluginInputFailureAction: DocuploadPluginInputFailureAction =
  error => ({
    type: DocuploadAction.PLUGIN_INPUT_FAILURE,
    error,
  });
