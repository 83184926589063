import { NetworkPluginInit, NetworkPluginStatus } from './types';

import { networkPluginInitWeb, networkPluginStatusWeb } from './web';
import { platform } from 'modules/services/platform';
import {
  networkPluginStatusCapacitor,
  networkPluginInitCapacitor,
} from './capacitor';

const { isCapacitor } = platform;

export const networkPluginInit: NetworkPluginInit = handler => {
  switch (true) {
    case isCapacitor:
      networkPluginInitCapacitor(handler);
      break;
    default:
      networkPluginInitWeb(handler);
      break;
  }
};

export const networkPluginStatus: NetworkPluginStatus = async () => {
  switch (true) {
    case isCapacitor:
      return await networkPluginStatusCapacitor();
    default:
      return networkPluginStatusWeb();
  }
};
