import { RouteType } from './types';

export enum ROUTE {
  CONTACT = '/contact',
  CONTACT_CASE = '/:caseId/contact',

  DEBUG = '/debug',
  DEBUG_CASE = '/:caseId/debug',

  DOCUMENTS = '/documents',
  DOCUMENTS_CASE = '/:caseId/documents',

  LOAN = '/loan',
  LOAN_CASE = '/:caseId/loan',

  LOGIN = '/',

  MESSAGES = '/messages',
  MESSAGES_CASE = '/:caseId/messages',

  PASSWORD = '/',

  STATUS = '/status',
  STATUS_CASE = '/:caseId/status',

  TEAM = '/team',
  TEAM_CASE = '/:caseId/team',
}

export const routes: RouteType[] = [
  {
    path: ROUTE.CONTACT,
    title: 'Contact',
  },
  {
    path: ROUTE.CONTACT_CASE,
    title: 'Contact',
  },
  {
    path: ROUTE.DEBUG,
    title: 'Debug',
  },
  {
    path: ROUTE.DEBUG_CASE,
    title: 'Debug',
  },
  {
    path: ROUTE.DOCUMENTS,
    title: 'My Documents',
  },
  {
    path: ROUTE.DOCUMENTS_CASE,
    title: 'My Documents',
  },

  {
    path: ROUTE.LOAN,
    title: 'My Loan',
  },
  {
    path: ROUTE.LOAN_CASE,
    title: 'My Loan',
  },

  {
    path: ROUTE.LOGIN,
    title: 'Login',
  },

  {
    path: ROUTE.MESSAGES,
    title: 'Messages',
  },
  {
    path: ROUTE.MESSAGES_CASE,
    title: 'Messages',
  },

  {
    path: ROUTE.PASSWORD,
    title: 'Password',
  },

  {
    path: ROUTE.STATUS,
    title: 'My Progress',
  },
  {
    path: ROUTE.STATUS_CASE,
    title: 'My Progress',
  },

  {
    path: ROUTE.TEAM,
    title: 'My Team',
  },
  {
    path: ROUTE.TEAM_CASE,
    title: 'My Team',
  },
];
