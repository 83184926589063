import { Message, MessagesState } from '../types';
import findMessages from './findMessages';

type FindMessage = (
  array: MessagesState['data'],
  caseId: number,
  messageId: number | string,
) => Message | undefined;

const findMessage: FindMessage = (array, caseId, messageId) =>
  findMessages(array, caseId)?.data?.find(
    message => message.messageId === messageId,
  );

export default findMessage;
