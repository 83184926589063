import { RootState } from 'modules/redux';

type GetPreviousRoute = (router: RootState['router']) => string | undefined;

const getPreviousRoute: GetPreviousRoute = router =>
  router.previousLocations && router.previousLocations.length > 1
    ? router.previousLocations[1].location?.pathname
    : undefined;

export default getPreviousRoute;
