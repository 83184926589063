import { createDefaultAction } from 'modules/redux/factories';
import { onfidoResetErrorAction } from '../../actions';
import { onfidoShowAction } from '../onfidoShowAction';
import { OnfidoAction } from '../../constants';
import { isPresent } from './utils';
import { API } from 'modules/api';
import {
  OnfidoGetFailureAction,
  OnfidoGetStartedAction,
  OnfidoGetSuccessAction,
  OnfidoGetRetryAction,
  OnfidoGetAction,
} from './types';

export const onfidoGetAction: OnfidoGetAction = request =>
  createDefaultAction({
    actionStarted: onfidoGetStartedAction,
    actionSuccess: onfidoGetSuccessAction,
    actionFailure: onfidoGetFailureAction,
    endpoint: API.GET_ONFIDO,
    isActive: ({ state }) => !!state.onfido.isLoading,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
    throwable: true,
  });

const onfidoGetStartedAction: OnfidoGetStartedAction = payload => ({
  type: OnfidoAction.GET_STARTED,
  ...payload,
});

const onfidoGetSuccessAction: OnfidoGetSuccessAction = payload => ({
  type: OnfidoAction.GET_SUCCESS,
  ...payload,
});

const onfidoGetFailureAction: OnfidoGetFailureAction = payload => ({
  type: OnfidoAction.GET_FAILURE,
  ...payload,
});

export const onfidoGetRetryAction: OnfidoGetRetryAction = () => {
  return async (dispatch, getState) => {
    const { caseId, messageId, startURL } = getState().onfido;
    caseId && messageId && startURL
      ? dispatch(onfidoShowAction({ caseId, messageId, startURL }))
      : dispatch(onfidoResetErrorAction());
  };
};
