import { produce } from 'immer';

import { findLoan, newLoan, updateLoan } from './utils';
import { LoanActionType, LoanState } from './types';
import { LoanAction } from './constants';

const initialState = (): LoanState => {
  return [];
};

function loanReducer(state = initialState(), action: LoanActionType) {
  switch (action.type) {
    case LoanAction.GET_FAILURE:
      return produce(state, (draftState: LoanState) => {
        const loan = findLoan(draftState, action.request.caseId);
        if (loan) {
          loan.actionId = null;
          loan.isError = action.error;
          loan.isLoading = false;
        }
      });

    case LoanAction.GET_STARTED:
      return produce(state, (draftState: LoanState) => {
        const loan =
          findLoan(draftState, action.request.caseId) ||
          newLoan(draftState, action.request.caseId);
        loan.actionId = action.actionId;
        loan.isError = false;
        loan.isLoading = action.controller || true;
      });

    case LoanAction.GET_SUCCESS:
      return produce(state, (draftState: LoanState) => {
        const loan = findLoan(draftState, action.request.caseId);
        if (loan) {
          loan.actionId = null;
          loan.isDirty = true;
          loan.isError = false;
          loan.isLoading = false;
          loan.data = updateLoan(action.data);
        }
      });

    case LoanAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default loanReducer;
