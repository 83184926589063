import { FileOpener } from '@capacitor-community/file-opener';
import { PDFDocument } from 'pdf-lib';

import { readFile, saveFileBrowser, saveFileCapacitor } from '../file';
import { platform } from 'modules/services/platform';
import { DocuploadFileType } from '../../constants';
import {
  BlobPDF,
  DestroyPDF,
  EmbedPDF,
  NewPDF,
  SavePDF,
  SavePDFBrowser,
  SavePDFCapacitor,
  TrimPDF,
  ViewPDF,
  ViewPDFBrowser,
  ViewPDFCapacitor,
} from './types';

const { isCapacitor } = platform;

export const blobPDF: BlobPDF = async pdf =>
  new Blob([await pdf.save()], { type: DocuploadFileType.PDF });

export const destroyPDF: DestroyPDF = pdf => {
  const pages = pdf && typeof pdf === 'object' ? pdf.getPageCount() : 0;
  if (pages > 0) {
    for (let page = 0; page < pages; page++) {
      if (pdf.getPageCount() > 0) {
        pdf.removePage(0);
      }
    }
  }
};

export const embedPDF: EmbedPDF = async (file, pdf) => {
  const newPDF = await PDFDocument.load(await readFile(file));
  const newPages = await pdf.copyPages(newPDF, newPDF.getPageIndices());
  newPages.forEach(function (page) {
    pdf.addPage(page);
  });
};

export const newPDF: NewPDF = async () => await PDFDocument.create();

export const savePDF: SavePDF = async (name, pdf) =>
  isCapacitor
    ? await savePDFCapacitor(name, pdf)
    : await savePDFBrowser(name, pdf);

export const savePDFBrowser: SavePDFBrowser = async (name, pdf) =>
  saveFileBrowser(await blobPDF(pdf), name);

const savePDFCapacitor: SavePDFCapacitor = async (name, pdf) =>
  await saveFileCapacitor(await blobPDF(pdf), name);

export const trimPDF: TrimPDF = (files, index, pdf) => {
  let start = 0;
  if (typeof files[index] === 'object') {
    for (let file = 0; file < index; file++) {
      start += files[file].pages || 0;
    }
    for (let page = start; page < start + files[index].pages; page++) {
      if (pdf.getPageCount() > start) {
        pdf.removePage(start);
      }
    }
  }
};

export const viewPDF: ViewPDF = async (name, pdf) =>
  isCapacitor ? await viewPDFCapacitor(name, pdf) : await viewPDFBrowser(pdf);

const viewPDFBrowser: ViewPDFBrowser = async pdf => {
  const link = window.URL.createObjectURL(await blobPDF(pdf));
  window.open(link, '_blank');
  setTimeout(() => window.URL.revokeObjectURL(link), 5000);
};

const viewPDFCapacitor: ViewPDFCapacitor = async (name, pdf) =>
  FileOpener.open({
    filePath: await savePDFCapacitor(name, pdf),
    contentType: DocuploadFileType.PDF,
  });
