import { UserGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';

type IsPresent = (props: {
  actionId: string;
  request: UserGetActionApiRequest;
  state: RootState;
}) => boolean;

const isPresent: IsPresent = ({ actionId, state }) =>
  actionId === state.user.actionId;

export default isPresent;
