import { dialogDestroyAction, dialogHideAction } from './actions';
import { Dispatch, RootState } from 'modules/redux';
import { Dialog, DialogPayload } from './types';
import { DialogType } from './constants';

type FindDialog = (
  dialogs: RootState['dialogs'],
  id: string,
) => Dialog | undefined;

export const getDialog: FindDialog = (dialogs, dialogId) =>
  dialogs.find(dialog => dialog.dialogId === dialogId);

type NewDialog = (
  dialog: DialogPayload,
  dispatch: Dispatch,
  getState: () => RootState,
  dialogId: string,
) => Dialog;

export const newDialog: NewDialog = (
  { onCancel, onConfirm, onDestroy, type, ...dialog },
  dispatch,
  getState,
  dialogId,
) => {
  return {
    onCancel:
      type === DialogType.ALERT
        ? undefined
        : () =>
            !!getDialog(getState().dialogs, dialogId)?.open &&
            (onCancel ? onCancel() : dispatch(dialogHideAction(dialogId))),

    onConfirm: () =>
      !!getDialog(getState().dialogs, dialogId)?.open &&
      (onConfirm ? onConfirm() : dispatch(dialogHideAction(dialogId))),

    onDestroy: () =>
      !!getDialog(getState().dialogs, dialogId) &&
      (onDestroy ? onDestroy() : dispatch(dialogDestroyAction(dialogId))),

    dialogId,
    open: true,
    type,
    ...dialog,
  };
};
