import React from 'react';

import StatusProgressBar from 'components/shared/StatusProgressBar';
import { StatusProgress } from 'modules/states/status';
import { Case } from 'modules/states/cases';
import StatusMessage from './StatusMessage';
import StatusPeople from './StatusPeople';

import styles from './styles.module.scss';

type StatusProps = {
  data: Case;
  handleVideo?: () => void;
  isPlaying: boolean;
};

const Status: React.FunctionComponent<StatusProps> = ({
  data,
  handleVideo,
  isPlaying,
}) => {
  return (
    <div className={styles.status}>
      <h1>My Progress</h1>
      {data.progress !== StatusProgress.UNKNOWN && (
        <StatusProgressBar progress={data.progress} />
      )}
      <StatusPeople step={data.step} />
      <StatusMessage
        handleVideo={handleVideo}
        isPlaying={isPlaying}
        step={data.step}
      />
    </div>
  );
};

export default React.memo(Status);
