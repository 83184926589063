import { useEffect } from 'react';

import { UseHandleError } from '../types';
import {
  useHandleDefaultError,
  useHandleErrorSwitch,
  useHandlePostError,
} from './hooks';

const useHandleError: UseHandleError = ({
  dispatch,
  isError,
  isSendingError,
  showDialogAlert,
  showDialogConfirm,
}) => {
  const payload = { showDialogConfirm, showDialogAlert, dispatch };
  const handleDefaultError = useHandleDefaultError(payload);
  const handlePostError = useHandlePostError(payload);

  const handleError = useHandleErrorSwitch({
    handleDefaultError,
    handlePostError,
  });

  useEffect(() => {
    if (isError || isSendingError)
      handleError(isSendingError ? isSendingError : isError);
  }, [handleError, isError, isSendingError]);
};

export default useHandleError;

//https://github.com/react-hook-form/react-hook-form/issues/566
