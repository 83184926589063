import retry from 'async-retry';
import axios from 'axios';

import { isAbortError, isUnauthorizedError } from './errors';
import { ApiConfig, DefaultApiResponse } from './types';
import { platform } from 'modules/services/platform';
import { authLogoutAction } from 'modules/auth';
import logger from 'modules/logger';

export { isAxiosAbortError, isUnauthorizedError } from './errors';
export { apiAbort, newAbortController } from './utils';
export { API } from './constants';

const { isAdmin } = platform;
const RETRIES = isAdmin ? 2 : 5;

export const api = async ({
  dispatch,
  getState,
  ...config
}: ApiConfig): Promise<DefaultApiResponse> => {
  const response = await retry(
    async bail => {
      try {
        return await axios(config);
      } catch (error) {
        if (isUnauthorizedError(error) && !!getState().auth.data?.token)
          dispatch(authLogoutAction());
        if (isAbortError(error)) return bail(error as Error);
        logger.warn('API RETRY');
        throw error;
      }
    },
    {
      retries: RETRIES,
    },
  );
  return response as DefaultApiResponse;
};
