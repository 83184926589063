import React from 'react';

import NoDocuments from 'components/shared/Documents/NoDocuments';
import { UseDocumentsRoute } from 'modules/states/documents';
import Documents from 'components/shared/Documents';
import Main from 'components/mobile/Main';

import styles from './styles.module.scss';

type TemplateProps = ReturnType<UseDocumentsRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  data,
  handleError,
  isError,
  isLoading,
}) => {
  return (
    <Main
      className={styles.documents}
      handleError={handleError}
      isError={isError}
      isLoading={isLoading}>
      {data && data.length > 0 ? (
        <Documents documents={data} />
      ) : (
        <NoDocuments />
      )}
    </Main>
  );
};

export default React.memo(Template);
