import isExpired from './isExpired';
import { AuthData } from '../types';
import {
  localStorageRemoveItem,
  localStorageGetObject,
  localStorageSetObject,
} from 'modules/storage';

type CaseData = number | null;

type Data = AuthData & { caseId?: CaseData };

type LoadAuthData = () => AuthData | null;

export const loadAuthData: LoadAuthData = () => {
  const data = localStorageGetObject<Data>('AUTH');
  if (data?.username) {
    if (typeof data.expires === 'number' && isExpired(data.expires)) {
      saveAuthData(
        { expires: null, token: null, username: data.username },
        data.caseId || null,
      );
      return { expires: null, token: null, username: data.username };
    }
    return {
      expires: data.expires,
      token: data.token,
      username: data.username,
    };
  }
  return null;
};

type LoadCaseData = (username: string) => CaseData;

export const loadCaseData: LoadCaseData = username => {
  const data = localStorageGetObject<Data>('AUTH');
  return !!data?.caseId && data.username === username ? data.caseId : null;
};

type SaveAuthData = (auth: AuthData | null, caseId: number | null) => void;

export const saveAuthData: SaveAuthData = (auth, caseId) => {
  const data: Data | null = auth?.username
    ? {
        caseId: caseId ? caseId : undefined,
        expires: auth.expires,
        token: auth.token,
        username: auth.username,
      }
    : null;
  data ? localStorageSetObject('AUTH', data) : localStorageRemoveItem('AUTH');
};
