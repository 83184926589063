import { useCallback, useMemo, useState } from 'react';

import { isCaseCancelled, isCaseDeclined } from '../utils';
import { shallowEqual, useSelector } from 'modules/redux';
import { casesSelectAction } from 'modules/states/cases';
import { ROUTE, useNavigateCase } from 'modules/router';
import { Dispatch, useDispatch } from 'modules/redux';
import { Case } from '../types';

export type UseCasesModal = (props: {
  animating?: boolean;
  onClose: () => void;
}) => {
  activeCases: Case[] | null;
  caseId: number | null;
  handleContact: () => void;
  handleSelectCase: (caseId: number) => void;
  handleShowInactive: () => void;
  inactiveCases: Case[] | null;
  showInactive: boolean;
};

const useCasesModal: UseCasesModal = ({ animating, onClose }) => {
  const { caseId, data } = useSelector(state => state.cases, shallowEqual);
  const [showInactive, setShowInactive] = useState(false);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigateCase(caseId);

  const activeCases = useMemo(
    () =>
      data?.filter(
        item =>
          item.isCaseActive &&
          !isCaseCancelled(item.status) &&
          !isCaseDeclined(item.status),
      ) || null,
    [data],
  );

  const inactiveCases = useMemo(
    () =>
      data?.filter(
        item =>
          !item.isCaseActive ||
          isCaseCancelled(item.status) ||
          isCaseDeclined(item.status),
      ) || null,
    [data],
  );

  const handleContact = useCallback(() => {
    !animating && onClose();
    !animating && navigate(ROUTE.CONTACT, { replace: false });
  }, [animating, navigate, onClose]);

  const handleShowInactive = useCallback(
    () => !animating && setShowInactive(true),
    [animating],
  );

  const handleSelectCase = useCallback(
    (caseId: number) => {
      !animating && onClose();
      !animating && dispatch(casesSelectAction({ caseId }));
    },
    [animating, dispatch, onClose],
  );

  return {
    activeCases,
    caseId,
    handleContact,
    handleSelectCase,
    handleShowInactive,
    inactiveCases,
    showInactive,
  };
};

export default useCasesModal;
