import * as yup from 'yup';

import { ValdationMessages } from './messages';
import * as validators from './validators';

export { yupResolver as resolver } from '@hookform/resolvers/yup';
export { type AnyObjectSchema, type ObjectSchema } from 'yup';

yup.setLocale({
  mixed: {
    notType: ValdationMessages.NOT_TYPE,
    oneOf: ValdationMessages.ONE_OF,
    required: ValdationMessages.REQUIRED,
  },
  string: {
    email: ValdationMessages.EMAIL,
  },
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  'newPassword',
  validators.newPassword,
);

export default yup;
