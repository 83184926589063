import React from 'react';

import { Case } from 'modules/states/cases';
import CaseItem from './CaseItem';

import styles from './styles.module.scss';

type CaseListProps = {
  activeCases: Case[] | null;
  caseId: number | null;
  disabled: boolean;
  handleSelectCase: (caseId: number) => void;
  inactiveCases: Case[] | null;
};

const CaseList: React.FunctionComponent<CaseListProps> = ({
  activeCases,
  caseId,
  disabled,
  handleSelectCase,
  inactiveCases,
}) => {
  return (
    <div className={styles['case-list']}>
      <ul>
        {!!activeCases?.length && (
          <li className={styles.title}>
            <div>
              <span>Active Cases</span>
            </div>
          </li>
        )}
        {activeCases?.map((item, index) => (
          <CaseItem
            key={index}
            disabled={disabled}
            onClick={handleSelectCase}
            selected={caseId === item.caseId}
            {...item}
          />
        ))}
        {!!inactiveCases?.length && (
          <li className={styles.title}>
            <div>
              <span>Inactive Cases</span>
            </div>
          </li>
        )}
        {inactiveCases?.map((item, index) => (
          <CaseItem
            key={index}
            disabled={disabled}
            onClick={handleSelectCase}
            selected={caseId === item.caseId}
            {...item}
          />
        ))}
      </ul>
    </div>
  );
};

export default React.memo(CaseList);
