import { VideoAction } from '../constants';
import {
  VideoDestroyAction,
  VideoLogoutAction,
  VideoCloseAction,
} from '../types';

export {
  type VideoPluginActionType,
  videoPluginCloseAction,
  videoPluginInitAction,
} from './videoPluginAction';

export { type VideoShowActionType, videoShowAction } from './videoShowAction';

export const videoCloseAction: VideoCloseAction = () => ({
  type: VideoAction.CLOSE,
});

export const videoDestroyAction: VideoDestroyAction = () => ({
  type: VideoAction.DESTROY,
});

export const videoLogoutAction: VideoLogoutAction = () => ({
  type: VideoAction.LOGOUT,
});
