import { NetworkPluginInitWeb, NetworkPluginStatusWeb } from './types';
import { platform } from 'modules/services/platform';
import { NetworkStatus } from '../../constants';

const { isDevelopment } = platform;

export const networkPluginInitWeb: NetworkPluginInitWeb = handler => {
  const offlineListener = () => handler({ status: NetworkStatus.OFFLINE });
  const onlineListener = () => handler({ status: NetworkStatus.ONLINE });

  if (isDevelopment) {
    if (typeof window.myfluent.listeners.network === 'function') {
      window.myfluent.listeners.network();
      delete window.myfluent.listeners.network;
    }

    window.myfluent.listeners.network = () => {
      window.removeEventListener('offline', offlineListener);
      window.removeEventListener('online', onlineListener);
    };
  }

  window.addEventListener('offline', offlineListener);
  window.addEventListener('online', onlineListener);
};

export const networkPluginStatusWeb: NetworkPluginStatusWeb = () =>
  window.navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
