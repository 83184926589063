import yup from 'modules/validation';

export type Model = {
  password: string;
  confirmPassword: string;
};

export const defaultValues: Model = {
  password: '',
  confirmPassword: '',
};

export const schema: yup.ObjectSchema<Model> = yup.object().shape({
  password: yup
    .string()
    .required()
    .newPassword()
    .min(8, 'Password should be at least 8 characters'),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Passwords must match'),
});
