import { useCallback } from 'react';

export const useHandlers = (
  handleActiveAnimation: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
  ) => void,
  disabled?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      !disabled && onClick && onClick(event);
      disabled && event.preventDefault();
    },
    [disabled, onClick],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        handleActiveAnimation(event);
      }
    },
    [handleActiveAnimation],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      handleActiveAnimation(event);
    },
    [handleActiveAnimation],
  );

  return {
    handleClick,
    handleKeyDown,
    handleMouseDown,
  };
};
