import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { CasesAction, CasesActionType } from '../cases';
import { abortMessages, messagesTimer } from './utils';
import { platform } from 'modules/services/platform';
import { Dispatch, RootState } from 'modules/redux';
import { getBaseRoute } from 'modules/router';
import { findCase } from '../cases/utils';
import {
  messagesPollingStartedAction,
  messagesPollingEndedAction,
  messagesLogoutAction,
  messagesGetAction,
} from './actions';
import { ROUTE } from 'modules/router';

const { isAdmin } = platform;

const messagesMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | CasesActionType) => {
    let nextAction;

    switch (action.type) {
      case CasesAction.GET_SUCCESS:
      case CasesAction.SELECT: {
        nextAction = next(action);
        const { auth, cases, router } = getState();
        const caseId = cases.caseId;
        if (auth.data?.token) {
          !isAdmin &&
            dispatch(
              messagesPollingStartedAction({
                timer: messagesTimer({ dispatch, getState }),
              }),
            );
          !!caseId &&
            getBaseRoute(router.location?.pathname) !== ROUTE.MESSAGES &&
            !!findCase(cases.data, cases.caseId)?.isCaseActive &&
            dispatch(messagesGetAction({ caseId }));
        }
        break;
      }

      case AuthAction.LOGOUT: {
        nextAction = next(action);
        const { messages } = getState();
        messages.timer !== null && window.clearInterval(messages.timer);
        dispatch(messagesPollingEndedAction());
        abortMessages(getState().messages);
        dispatch(messagesLogoutAction());
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default messagesMiddleware;
