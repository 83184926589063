import React from 'react';

import { ReactComponent as Chevron } from 'modules/theme/images/icons/button/chevron.svg';
import NoActiveCasesIcon from 'modules/theme/images/cases/no-active-cases.svg';
import Button, { ButtonSize, ButtonStyle } from 'components/shared/Button';

import styles from './styles.module.scss';

type NoActiveCasesProps = {
  disabled?: boolean;
  handleContact: () => void;
  handleShowInactive?: () => void;
  inactiveCases?: boolean;
};

const NoActiveCases: React.FunctionComponent<NoActiveCasesProps> = ({
  disabled,
  handleContact,
  handleShowInactive,
  inactiveCases,
}) => {
  return (
    <div className={styles['no-active-cases']}>
      <div>
        <div style={{ backgroundImage: `url(${NoActiveCasesIcon})` }}></div>
        <h3>You have no active cases</h3>
        <p>Please contact support</p>
        <div>
          <Button
            chevron={false}
            disabled={disabled}
            label="Contact"
            onClick={handleContact}
            style={ButtonStyle.PRIMARY}
            size={ButtonSize.LARGE}
          />
          {!!handleShowInactive && !!inactiveCases && (
            <Button
              disabled={disabled}
              label="View inactive cases"
              onClick={handleShowInactive}>
              <Chevron />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoActiveCases);
