import { v4 as uuidv4 } from 'uuid';

import { timeout } from 'modules/redux/factories/utils';
import { AuthApiResponse } from 'modules/auth/types';
import { getError, isActive } from './utils';
import { AuthAction } from '../../constants';
import { api, API } from 'modules/api';
import {
  AuthLoginChangePasswordAction,
  AuthLoginFailureAction,
  AuthLoginStartedAction,
  AuthLoginSuccessAction,
  AuthLoginResetAction,
  AuthLoginAction,
} from './types';

export const authLoginAction: AuthLoginAction = request => {
  return async (dispatch, getState) => {
    const startTime = new Date().getTime();
    const auth = getState().auth;
    const actionId = uuidv4();
    try {
      if (auth.data?.token || auth.isLoading) return;

      dispatch(authLoginStartedAction({ actionId, request }));

      const response = (await api(
        API.POST_AUTH({ dispatch, getState, request }),
      )) as AuthApiResponse;

      await timeout(startTime, 1000);

      isActive(actionId, getState().auth) &&
        dispatch(
          response.data.changePassword
            ? authLoginChangePasswordAction({
                actionId,
                request,
                data: response.data,
              })
            : authLoginSuccessAction({
                actionId,
                request,
                data: response.data,
              }),
        );
    } catch (error) {
      await timeout(startTime, 1000);

      isActive(actionId, getState().auth) &&
        dispatch(
          authLoginFailureAction({
            actionId,
            request,
            error: getError(error),
          }),
        );
    }
  };
};

const authLoginStartedAction: AuthLoginStartedAction = payload => ({
  type: AuthAction.LOGIN_STARTED,
  ...payload,
});

const authLoginSuccessAction: AuthLoginSuccessAction = payload => ({
  type: AuthAction.LOGIN_SUCCESS,
  ...payload,
});

const authLoginFailureAction: AuthLoginFailureAction = payload => ({
  type: AuthAction.LOGIN_FAILURE,
  ...payload,
});

const authLoginChangePasswordAction: AuthLoginChangePasswordAction =
  payload => ({
    type: AuthAction.LOGIN_CHANGE_PASSWORD,
    ...payload,
  });

export const authLoginResetAction: AuthLoginResetAction = () => ({
  type: AuthAction.LOGIN_RESET,
});
