import { modalDestroyAction, modalHideAction } from '../actions';
import { Dispatch, RootState } from 'modules/redux';
import { Modal, ModalPayload } from '../types';
import getModal from './getModal';

type NewModal = (
  dispatch: Dispatch,
  getState: () => RootState,
  modalId: string,
  modal: ModalPayload,
) => Modal;

const newModal: NewModal = (
  dispatch,
  getState,
  modalId,
  { onClose, onDestroy, ...modal },
) => {
  return {
    onClose: () =>
      !!getModal(getState().modals, modalId)?.open &&
      (onClose ? onClose() : dispatch(modalHideAction(modalId))),
    onDestroy: () =>
      !!getModal(getState().modals, modalId) &&
      (onDestroy ? onDestroy() : dispatch(modalDestroyAction(modalId))),
    modalId,
    open: true,
    ...modal,
  };
};

export default newModal;
