import React from 'react';

import Messages, { MessagesControls } from 'components/desktop/Messages';
import { UseMessagesRoute } from 'modules/states/messages';
import Main from 'components/desktop/Main';

type TemplateProps = ReturnType<UseMessagesRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  handleError,
  handleSubmit,
  isError,
  isLoading,
  isSending,
  ...props
}) => {
  return (
    <React.Fragment>
      <MessagesControls
        handleSubmit={handleSubmit}
        isError={isError}
        isLoading={isLoading}
        isSending={isSending}
      />
      <Main handleError={handleError} isError={isError} isLoading={isLoading}>
        <Messages {...props} />
      </Main>
    </React.Fragment>
  );
};

export default React.memo(Template);
