import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { analyticsTrackModals } from './utils';
import { modalLogoutAction } from './actions';
import { ModalActionType } from './types';

const modalsMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | ModalActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.LOGOUT:
        nextAction = next(action);
        dispatch(modalLogoutAction());
        break;
    }

    nextAction = analyticsTrackModals({
      action,
      getState,
      next,
      nextAction,
    });

    return nextAction ? nextAction : next(action);
  };

export default modalsMiddleware;
