import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

import {
  AnalyticsPluginTrackPageViewFirebase,
  AnalyticsPluginTrackEventFirebase,
} from './types';

export const analyticsPluginTrackEventFirebase: AnalyticsPluginTrackEventFirebase =
  ({ category, action, label }) =>
    FirebaseAnalytics.logEvent({
      name: action,
      params: { event_category: category, event_label: label },
    });

// export const analyticsPluginTrackPageViewFirebase: AnalyticsPluginTrackPageViewFirebase =
//   ({ title }) =>
//     FirebaseAnalytics.logEvent({
//       name: 'screen_view',
//       params: { screen_name: title, screen_class: 'MainActivity' },
//     });

export const analyticsPluginTrackPageViewFirebase: AnalyticsPluginTrackPageViewFirebase =
  ({ title }) =>
    FirebaseAnalytics.setCurrentScreen({
      screenName: title,
      screenClassOverride: 'MainActivity',
    });

export const analyticsPluginInitFirebase = () =>
  FirebaseAnalytics.setEnabled({ enabled: true });
