import clsx from 'clsx';

import { MessageActionDocupload as MessageAction } from 'modules/states/messages';

import styles from '../../../styles.module.scss';

type GetMessageDocumentUploadClassName = (props: {
  action: MessageAction;
  active: boolean;
  defaultClassName: string;
  disabled: boolean;
}) => string;

const getMessageDocumentUploadClassName: GetMessageDocumentUploadClassName = ({
  action,
  active,
  defaultClassName,
  disabled,
}) =>
  clsx(
    defaultClassName,
    styles['document-upload'],
    active && styles['active'],
    disabled && styles['disabled'],
    action === MessageAction.APPROVED && styles['approved'],
    action === MessageAction.AWAITING_REVIEW && styles['awaiting-review'],
    action === MessageAction.CANCELLED && styles['cancelled'],
    action === MessageAction.DECLINED && styles['declined'],
    action === MessageAction.REQUESTED && styles['requested'],
  );

export default getMessageDocumentUploadClassName;
