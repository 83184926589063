import { MessagesGetActionApiResponseItem } from '../../types';
import { MessageType } from '../../../../constants';
import {
  MessagesData,
  MessageDocupload,
  MessageOnfido,
} from '../../../../types';

import newMessageDocumentUpload from './newMessageDocumentUpload';
import newMessageDefault from './newMessageDefault';
import newMessageOnfido from './newMessageOnfido';

const newMessage = (
  messages: MessagesData,
  data: MessagesGetActionApiResponseItem,
) => {
  const message = newMessageDefault(data);

  message?.type === MessageType.DOCUMENT_UPLOAD &&
    !!data.callToAction &&
    newMessageDocumentUpload(data.callToAction, message as MessageDocupload);

  message?.type === MessageType.ONFIDO &&
    !!data.callToAction &&
    newMessageOnfido(data.callToAction, message as MessageOnfido);

  !!message && messages.push(message);
};

export default newMessage;
