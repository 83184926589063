//import { init } from 'onfido-sdk-ui';
//https://documentation.onfido.com/sdk/web/

import { onfidoConfig, onfidoImport } from './constants';
import { OnfidoError } from '../../constants';
import {
  OnfidoPluginDestroyWeb,
  OnfidoPluginInitWeb,
  OnfidoInit,
  OnfidoApp,
} from './types';

declare global {
  const init: OnfidoInit | undefined;
  interface Window {
    Onfido?: {
      init: OnfidoInit | undefined;
    };
  }
}

let app: OnfidoApp | null = null;

export const onfidoPluginDestroyWeb: OnfidoPluginDestroyWeb = () => {
  if (typeof app?.safeTearDown === 'function') app.safeTearDown();
  app = null;
};

export const onfidoPluginInitWeb: OnfidoPluginInitWeb = ({
  token,
  onComplete,
  onError,
  onUserExit,
  ...props
}) => {
  if (!app)
    try {
      switch (true) {
        case !onfidoImport && typeof window.Onfido?.init !== 'function':
          throw new Error(OnfidoError.SDK);

        case !onfidoImport && typeof window.Onfido?.init === 'function':
          app = window.Onfido?.init
            ? window.Onfido.init({
                ...onfidoConfig(props),
                onComplete,
                onError,
                onUserExit,
                token,
              })
            : null;
          break;

        case onfidoImport && typeof init !== 'function':
          throw new Error(OnfidoError.SDK);

        case onfidoImport && typeof init === 'function':
          app = init
            ? init({
                ...onfidoConfig(props),
                onComplete,
                onError,
                onUserExit,
                token,
              })
            : null;
          break;
      }
    } catch (error) {
      onError(error);
    }
};
