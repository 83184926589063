import { Auth } from '../types';

type DeleteAuthToken = (state: Auth) => Auth;

const deleteAuthToken: DeleteAuthToken = state => {
  !!state.data?.expires && (state.data.expires = null);
  !!state.data?.token && (state.data.token = null);
  return state;
};

export default deleteAuthToken;
