import { useCallback, useEffect } from 'react';

import { useAccountSelector } from 'modules/states/account';
import { ROUTE, useNavigateCase } from 'modules/router';
import useTeamSelector from './useTeamSelector';
import { Team } from '../types';

export type UseTeamHeader = () => {
  data: Team['data'];
  handleMessage: () => void;
};

const useTeamHeader: UseTeamHeader = () => {
  const { caseId, isLoading } = useAccountSelector();
  const { data, handleRequest } = useTeamSelector(caseId);
  const navigate = useNavigateCase(caseId);

  const handleMessage = useCallback(
    () => navigate(ROUTE.MESSAGES, { replace: false }),
    [navigate],
  );

  useEffect(() => {
    !isLoading && handleRequest();
  }, [handleRequest, isLoading]);

  return {
    data,
    handleMessage,
  };
};

export default useTeamHeader;
