import { StatusBarInitAction, StatusBarUpdateAction } from './types';
import { StatusBarAction } from './constants';

export const statusBarInitAction: StatusBarInitAction = location => ({
  type: StatusBarAction.INIT,
  location,
});

export const statusBarUpdateAction: StatusBarUpdateAction = location => ({
  type: StatusBarAction.UPDATE,
  location,
});
