import { MessageActionDocupload } from '../../../../constants';
import { MessagesGetActionApiResponseItemCTA } from '../../types';
import { MessageDocupload } from '../../../../types';

type UpdateMessageDocupload = (
  data: MessagesGetActionApiResponseItemCTA,
  message: MessageDocupload,
) => void;

const updateMessageDocupload: UpdateMessageDocupload = (data, message) =>
  !!message.callToAction &&
  Object.values(MessageActionDocupload).includes(
    data.action as MessageActionDocupload,
  ) &&
  (message.callToAction.action = data.action as MessageActionDocupload);

export default updateMessageDocupload;
