import React from 'react';

import { UseTeamRoute } from 'modules/states/team';
import TeamMember from './TeamMember';

import styles from './styles.module.scss';

type TeamProps = ReturnType<UseTeamRoute>['data'] & {
  handleMessage: () => void;
};

const Team: React.FunctionComponent<TeamProps> = ({
  adviser,
  caseManager,
  handleMessage,
}) => {
  return (
    <div className={styles.team}>
      {!!caseManager && (
        <TeamMember handleMessage={handleMessage} {...caseManager} />
      )}
      {!!adviser && <TeamMember handleMessage={handleMessage} {...adviser} />}
    </div>
  );
};

export default React.memo(Team);
