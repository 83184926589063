import { shallowEqual, useSelector } from 'modules/redux';
import { NetworkStatus } from './constants';

export type UseNetwork = () => NetworkStatus;

const useNetwork: UseNetwork = () => {
  const { status } = useSelector(state => state.network, shallowEqual);
  return status;
};

export default useNetwork;
