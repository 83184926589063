import { TeamGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';
import findTeam from './findTeam';

type IsActive = (props: {
  request: TeamGetActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ request, state }) =>
  !!findTeam(state.team, request.caseId)?.isLoading;

export default isActive;
