import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { getCaseRoute } from '../utils';
import { ROUTE } from '../constants';

export type UseNavigateCase = (
  caseId: number | null,
) => (route: ROUTE, options: NavigateOptions) => void;

const useNavigateCase: UseNavigateCase = caseId => {
  const navigate = useNavigate();

  const handleNavigate: ReturnType<UseNavigateCase> = useCallback(
    (route, options) => navigate(getCaseRoute(caseId, route), options),
    [caseId, navigate],
  );

  return handleNavigate;
};

export default useNavigateCase;
