import { useCallback } from 'react';

import { docuploadResetErrorAction } from '../../../../../actions';
import { getErrorMessage } from '../../../../../utils';
import { UseHandleDefaultError } from './types';

const useHandleDefaultError: UseHandleDefaultError = ({
  showDialogConfirm,
  showDialogAlert,
  dispatch,
}) => {
  const handleError = useCallback(
    (error: unknown) => {
      if (showDialogAlert && showDialogConfirm)
        showDialogAlert({
          message: getErrorMessage(error),
          onConfirm: () => dispatch(docuploadResetErrorAction()),
          title: 'MyFluent',
        });
    },
    [dispatch, showDialogAlert, showDialogConfirm],
  );

  return handleError;
};

export default useHandleDefaultError;
