import { useHandleError, useHandleOnfido } from './hooks';
import { UseOnfidoRoute } from './types';

const useOnfidoRoute: UseOnfidoRoute = caseId => {
  const handleOnfido = useHandleOnfido(caseId);
  useHandleError();
  return handleOnfido;
};

export default useOnfidoRoute;
