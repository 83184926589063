import { dispatchAbort } from 'modules/redux';
import { MessagesState } from '../types';

type AbortMessages = (state: MessagesState) => void;

const abortMessages: AbortMessages = state =>
  state.data.forEach(messages => {
    dispatchAbort(messages);
    messages.data?.forEach(message => dispatchAbort(message));
  });

export default abortMessages;
