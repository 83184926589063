import React from 'react';
import { FieldValues } from 'react-hook-form';

import { Control, DefaultValues, useForm, UseFormReset } from 'react-hook-form';
import { AnyObjectSchema, resolver } from 'modules/validation';
import logger from 'modules/logger';

const onError = (errors: unknown) =>
  logger.debug('Form Validation Errors', errors);

export type FormProps<T extends FieldValues> = {
  children?: (props: {
    control: Control<T>;
    isFormSubmitting: boolean;
    isValid: boolean;
    reset: UseFormReset<T>;
    values: T;
  }) => React.ReactNode;
  className?: string;
  defaultValues: DefaultValues<T>;
  onSubmit: (
    data: T,
    reset: UseFormReset<T>,
    event?: React.BaseSyntheticEvent,
  ) => void;
  schema?: AnyObjectSchema;
};

function Form<T extends FieldValues>({
  children,
  className,
  defaultValues,
  onSubmit,
  schema,
}: FormProps<T>) {
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
    watch,
  } = useForm<T>({
    criteriaMode: 'firstError',
    defaultValues,
    mode: 'onTouched',
    resolver: schema ? resolver(schema) : undefined,
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const values = watch();
  return (
    <form
      className={className}
      onSubmit={handleSubmit(
        (data, event) => onSubmit(data, reset, event),
        onError,
      )}
      noValidate={true}>
      {children &&
        children({
          control,
          isFormSubmitting: isSubmitting,
          isValid,
          reset,
          values,
        })}
    </form>
  );
}

export default React.memo(Form) as typeof Form;
