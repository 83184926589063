import React from 'react';
import clsx from 'clsx';

import { ReactComponent as Active } from 'modules/theme/images/icons/team/active.svg';
import { TeamStatus, TeamType } from 'modules/states/team/constants';
import Button, { ButtonStyle } from 'components/shared/Button';
import { TeamMember } from 'modules/states/team/types';
import { ExternalLink } from 'components/shared/Hyperlinks';
import Avatar from 'components/shared/Avatar';

import styles from './styles.module.scss';

export type HeaderItemProps = TeamMember & { handleMessage: () => void };

const HeaderItem: React.FunctionComponent<HeaderItemProps> = ({
  handleMessage,
  image,
  name,
  status,
  telephone,
  type,
}) => {
  const className = clsx(
    styles['header-item'],
    status === TeamStatus.LOGGED_IN && styles.active,
  );
  return (
    <section className={className}>
      {type === TeamType.ADVISER ? <h2>Adviser</h2> : <h2>Case Manager</h2>}
      <h3>{name}</h3>
      {!!telephone && (
        <ExternalLink
          href={`tel:${telephone.replace(/\s+/g, '')}`}
          label={telephone}
        />
      )}
      <p>
        <Active />
        {status === TeamStatus.LOGGED_IN ? (
          <React.Fragment>I'm at my desk</React.Fragment>
        ) : (
          <React.Fragment>I'm away from my desk</React.Fragment>
        )}
      </p>
      <Button
        label="Message"
        onClick={handleMessage}
        style={ButtonStyle.PRIMARY}
      />
      <Avatar name={name} src={image} />
    </section>
  );
};

export default React.memo(HeaderItem);
