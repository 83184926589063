import { createDefaultAction } from 'modules/redux/factories';
import { DocuploadAction } from '../../constants';
import { API } from 'modules/api';
import {
  DocuploadPostProgressAction,
  DocuploadPostFailureAction,
  DocuploadPostStartedAction,
  DocuploadPostSuccessAction,
  DocuploadPostAction,
} from './types';

export const docuploadPostAction: DocuploadPostAction = request =>
  createDefaultAction({
    actionStarted: docuploadPostStartedAction,
    actionSuccess: docuploadPostSuccessAction,
    actionFailure: docuploadPostFailureAction,
    actionProgressUpload: docuploadPostProgressAction,
    endpoint: API.POST_DOCUMENT_UPLOAD,
    isActive: ({ state }) => !!state.docupload.isSending,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent: ({ actionId, state }) => actionId === state.docupload.actionId,
    request: request,
    throwable: true,
  });

const docuploadPostStartedAction: DocuploadPostStartedAction = payload => ({
  type: DocuploadAction.POST_STARTED,
  ...payload,
});

const docuploadPostProgressAction: DocuploadPostProgressAction = payload => ({
  type: DocuploadAction.POST_PROGRESS,
  ...payload,
});

const docuploadPostSuccessAction: DocuploadPostSuccessAction = payload => ({
  type: DocuploadAction.POST_SUCCESS,
  ...payload,
});

const docuploadPostFailureAction: DocuploadPostFailureAction = payload => ({
  type: DocuploadAction.POST_FAILURE,
  ...payload,
});
