import { produce } from 'immer';

import { JailbreakAction, JailbreakStatus } from './constants';
import { Jailbreak, JailbreakActionType } from './types';
import { platform } from 'modules/services/platform';

const { isCapacitor } = platform;

const initialState = (isSecure: boolean): Jailbreak => {
  return {
    isDirty: isSecure,
    isSecure,
  };
};

function jailbreakReducer(
  state = initialState(!isCapacitor),
  action: JailbreakActionType,
) {
  switch (action.type) {
    case JailbreakAction.INIT:
      return state;

    case JailbreakAction.RESULT:
      return produce(state, (draftState: Jailbreak) => {
        draftState.isDirty = true;
        draftState.isSecure = action.status === JailbreakStatus.SECURE;
      });

    default:
      return state;
  }
}

export default jailbreakReducer;
