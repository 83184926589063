import { statusBarPluginInitIOS, statusBarPluginUpdateIOS } from './ios';
import { StatusBarPluginInit, StatusBarPluginUpdate } from './types';
import { platform } from 'modules/services/platform';
import {
  statusBarPluginUpdateAndroid,
  statusBarPluginInitAndroid,
} from './android';

const { isAndroid, isCapacitor } = platform;

export const statusBarPluginInit: StatusBarPluginInit = async path => {
  switch (true) {
    case isCapacitor && isAndroid:
      statusBarPluginInitAndroid(path);
      break;
    case isCapacitor && !isAndroid:
      statusBarPluginInitIOS(path);
      break;
  }
};

export const statusBarPluginUpdate: StatusBarPluginUpdate = async path => {
  switch (true) {
    case isCapacitor && isAndroid:
      statusBarPluginUpdateAndroid(path);
      break;
    case isCapacitor && !isAndroid:
      statusBarPluginUpdateIOS(path);
      break;
  }
};
