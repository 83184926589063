import { OverlayAnimation, OverlayAnimationOut } from '../../constants';

const getAnimationOut = (animation: OverlayAnimation) => {
  switch (animation) {
    case OverlayAnimation.SLIDE_UP:
      return OverlayAnimationOut.SLIDE_DOWN;
    case OverlayAnimation.ZOOM_IN:
      return OverlayAnimationOut.ZOOM_OUT;
  }
};

export const isAnimationIn = (
  animation: OverlayAnimation,
  event: AnimationEvent,
) => event.animationName === animation;

export const isAnimationOut = (
  animation: OverlayAnimation,
  event: AnimationEvent,
) => event.animationName === getAnimationOut(animation);

export const isAnimation = (
  animation: OverlayAnimation,
  event: AnimationEvent,
) => isAnimationIn(animation, event) || isAnimationOut(animation, event);

export const isTarget = (
  event: AnimationEvent,
  ref: React.RefObject<HTMLDivElement>,
) => event.currentTarget === ref.current;
