import { produce } from 'immer';

import { Docupload, DocuploadActionType } from '../../types';
import { DocuploadAction } from '../../constants';
import { getUploadProgress } from './utils';

function docuploadPostReducer(state: Docupload, action: DocuploadActionType) {
  switch (action.type) {
    case DocuploadAction.POST_FAILURE:
      return produce(state, (draftState: Docupload) => {
        draftState.actionId = null;

        draftState.isSendingProgress = 100;
        draftState.isSending = false;
      });

    case DocuploadAction.POST_PROGRESS:
      return produce(state, (draftState: Docupload) => {
        draftState.isSendingProgress = getUploadProgress(action.event);
      });

    case DocuploadAction.POST_STARTED:
      return produce(state, (draftState: Docupload) => {
        draftState.actionId = action.actionId;

        draftState.isSendingProgress = 0;
        draftState.isSending = action.controller || true;
      });

    case DocuploadAction.POST_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.actionId = null;

        draftState.isSendingProgress = 100;
        draftState.isSending = false;
      });

    default:
      return state;
  }
}

export default docuploadPostReducer;
