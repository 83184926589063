import { ModalActionType, Modals } from './types';
import { ModalAction } from './constants';

const initialState: () => Modals = () => {
  return [];
};

function modalsReducer(state = initialState(), action: ModalActionType) {
  switch (action.type) {
    case ModalAction.DESTROY:
      return state.filter(modal => modal.modalId !== action.modalId);

    case ModalAction.HIDE:
      return state.map(modal => {
        if (modal.modalId !== action.modalId) {
          return modal;
        }
        return {
          ...modal,
          open: false,
        };
      });

    case ModalAction.INSERT:
      return [...state, action.modal];

    case ModalAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default modalsReducer;
