import { createDefaultAction } from 'modules/redux/factories';
import { isActive, isPresent } from './utils';
import { StatusAction } from './constants';
import { API } from 'modules/api';
import {
  StatusGetFailureAction,
  StatusGetStartedAction,
  StatusGetSuccessAction,
  StatusLogoutAction,
  StatusGetAction,
} from './types';

export const statusGetAction: StatusGetAction = request =>
  createDefaultAction({
    actionStarted: statusGetStartedAction,
    actionSuccess: statusGetSuccessAction,
    actionFailure: statusGetFailureAction,
    endpoint: API.GET_STATUS,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const statusGetStartedAction: StatusGetStartedAction = payload => ({
  type: StatusAction.GET_STARTED,
  ...payload,
});

const statusGetSuccessAction: StatusGetSuccessAction = payload => ({
  type: StatusAction.GET_SUCCESS,
  ...payload,
});

const statusGetFailureAction: StatusGetFailureAction = payload => ({
  type: StatusAction.GET_FAILURE,
  ...payload,
});

export const statusLogoutAction: StatusLogoutAction = () => ({
  type: StatusAction.LOGOUT,
});
