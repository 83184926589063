import { shallowEqual, useSelector } from 'modules/redux';

export type UseCertificate = () => {
  isCertificateDirty: boolean;
  isCertificateSecure: boolean;
};

const useCertificate: UseCertificate = () => {
  const { isDirty, isSecure } = useSelector(
    state => state.certificate,
    shallowEqual,
  );
  return {
    isCertificateDirty: isDirty,
    isCertificateSecure: isSecure,
  };
};

export default useCertificate;
