import React from 'react';

import { useMenu } from 'modules/states/menu';
import Header from 'components/mobile/Header';
import Footer from 'components/mobile/Footer';
import Menu from 'components/mobile/Menu';
import { Dialogs } from 'modules/dialogs';
import { Modals } from 'modules/modals';

import menuStyles from 'components/mobile/Menu/styles.module.scss';
import styles from './styles.module.scss';

type TemplateProps = {
  children: React.ReactNode;
};

const Template: React.FunctionComponent<TemplateProps> = ({ children }) => {
  const { animating, handleOpenMenu, handleCloseMenu, menuRef, open } = useMenu(
    {
      in: menuStyles['menu-slide-in'],
      out: menuStyles['menu-slide-out'],
    },
  );
  return (
    <React.Fragment>
      <div className={styles['app']}>
        <Menu
          animating={animating}
          handleCloseMenu={handleCloseMenu}
          menuRef={menuRef}
          open={open}
        />
        <Header handleOpenMenu={handleOpenMenu} />
        {children}
        <Footer />
      </div>
      <div className={styles['modals']}>
        <Modals />
        <Dialogs />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Template);
