import {
  AppState as CapacitorAppState,
  App as CapacitorApp,
} from '@capacitor/app';

export const appStatusPluginGetState = async () => {
  const { isActive } = await CapacitorApp.getState();
  return isActive;
};

export const appStatusPluginInit = async (
  handler: (state: CapacitorAppState) => void,
) => {
  await CapacitorApp.removeAllListeners();
  CapacitorApp.addListener('appStateChange', handler);
};
