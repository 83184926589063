import { SplashScreen } from '@capacitor/splash-screen';
import sleep from 'sleep-promise';

const options = {
  autoHide: false,
};

export const splashScreenPluginHide = async () => {
  SplashScreen.hide();
  await sleep(200);
};

export const splashScreenPluginShow = async () => {
  SplashScreen.show(options);
  await sleep(200);
};
