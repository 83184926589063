import { CapacitorVideoPlayer } from 'capacitor-video-player';

import { InitVideoPlugin } from './types';

const initVideoPlugin: InitVideoPlugin = async props => {
  const response = await CapacitorVideoPlayer.initPlayer({
    bkmodeEnabled: false,
    chromecast: false,
    exitOnEnd: true,
    mode: 'fullscreen',
    pipEnabled: false,
    ...props,
  });

  if (!response.result) throw new Error('INIT');
};

export default initVideoPlugin;
