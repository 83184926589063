import { AnyAction } from 'redux';

import { analyticsTrackEvent } from 'modules/services/analytics';
import { Dispatch, RootState } from 'modules/redux';
import isDocuploadAction from './isDocuploadAction';
import { DocuploadAction } from '../constants';
import getDocType from './getDocType';

type AnalyticsTrackDocupload = (props: {
  action: AnyAction;
  getState: () => RootState;
  next: Dispatch<AnyAction>;
  nextAction: AnyAction | undefined;
}) => AnyAction | undefined;

const analyticsTrackDocupload: AnalyticsTrackDocupload = ({
  action,
  getState,
  next,
  nextAction,
}) => {
  if (isDocuploadAction(action))
    switch (action.type) {
      case DocuploadAction.CANCEL: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'pressedCloseButton',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.PLUGIN_CAMERA_STARTED: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'pressedTakePhotoButton',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.PLUGIN_CAMERA_SUCCESS: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'addedPhotoFromCamera',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.PLUGIN_DELETE_SUCCESS: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'removedFile',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.PLUGIN_FILE_SUCCESS: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action:
            action.file.type === 'application/pdf'
              ? 'addedPDFFromBrowse'
              : 'addedPhotoFromBrowse',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.PLUGIN_INPUT_STARTED: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'pressedAddFileButton',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.PLUGIN_PREVIEW_STARTED: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'pressedPreviewButton',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.SHOW_STARTED: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'pressedCTAUploadDocumentButton',
          label: getDocType(getState()),
        });
        break;
      }

      case DocuploadAction.UPLOAD_STARTED: {
        !nextAction && (nextAction = next(action));
        analyticsTrackEvent({
          category: 'DocumentUpload',
          action: 'pressedUploadDocumentButton',
          label: getDocType(getState()),
        });
        break;
      }
    }

  return nextAction;
};

export default analyticsTrackDocupload;
