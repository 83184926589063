import { UserData } from 'modules/states/user';
import { Case } from 'modules/states/cases';
import { AccountData } from '../types';
import getName from './getName';

type GetAccountData = (
  dataCase: Case | null,
  dataUser: UserData | null,
) => AccountData | null;

const getAccountData: GetAccountData = (dataCase, dataUser) =>
  dataUser
    ? {
        firstName: dataUser.firstName,
        surname: dataUser.surname,
        name: getName(dataUser.firstName, dataUser.surname),

        caseId: dataCase?.caseId || null,
        status: dataCase?.status || null,
        type: dataCase?.type || null,
      }
    : null;

export default getAccountData;
