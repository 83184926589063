import { produce } from 'immer';

import { MessagesState, MessagesActionType } from '../../types';
import { findMessages, getMessagesStats } from '../../utils';
import { MessagesAction } from '../../constants';
import { newMessagePlaceholder } from './utils';

function messagesPostReducer(state: MessagesState, action: MessagesActionType) {
  switch (action.type) {
    case MessagesAction.POST_FAILURE:
      return produce(state, (draftState: MessagesState) => {
        const messages = findMessages(draftState.data, action.request.caseId);
        if (messages) {
          messages.actionId = null;
          messages.isSending = false;
          messages.isSendingError = action.error;
        }
      });

    case MessagesAction.POST_STARTED:
      return produce(state, (draftState: MessagesState) => {
        const messages = findMessages(draftState.data, action.request.caseId);
        if (messages) {
          messages.actionId = action.actionId;
          messages.isSending = action.controller || true;
          messages.isSendingError = false;
        }
      });

    case MessagesAction.POST_SUCCESS:
      return produce(state, (draftState: MessagesState) => {
        const messages = findMessages(draftState.data, action.request.caseId);
        if (messages) {
          messages.actionId = null;
          messages.isSending = false;
          messages.isSendingError = false;

          !messages.data && (messages.data = []);
          messages.data.push(newMessagePlaceholder(action.request.message));

          messages.stats = getMessagesStats(messages.data);
        }
      });

    case MessagesAction.POST_RESET:
      return produce(state, (draftState: MessagesState) => {
        const messages = findMessages(draftState.data, action.caseId);
        if (messages) {
          messages.isSendingError = false;
        }
      });

    default:
      return state;
  }
}

export default messagesPostReducer;
