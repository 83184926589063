import yup from 'modules/validation';

export type Model = {
  message: string;
};

export const defaultValues: Model = {
  message: '',
};

export const schema: yup.ObjectSchema<Model> = yup.object().shape({
  message: yup.string().required(),
});
