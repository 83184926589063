import logger from 'modules/logger';

enum StorageLocation {
  LOCAL = 'LOCAL',
  SESSION = 'SESSION',
}

const storageGetItem = (type: StorageLocation, name: string): string | null => {
  try {
    return type === StorageLocation.LOCAL
      ? localStorage.getItem(name)
      : sessionStorage.getItem(name);
  } catch {
    logger.error(
      `Error Getting ${
        StorageLocation.LOCAL ? 'Local' : 'Session'
      } Storage Item: ${name}`,
    );
    return null;
  }
};

const storageRemoveItem = (type: StorageLocation, name: string) => {
  try {
    type === StorageLocation.LOCAL
      ? localStorage.removeItem(name)
      : sessionStorage.removeItem(name);
  } catch {
    logger.error(
      `Error Removing ${
        StorageLocation.LOCAL ? 'Local' : 'Session'
      } Storage Item: ${name}`,
    );
  }
};

const storageSetItem = (type: StorageLocation, name: string, value: string) => {
  try {
    type === StorageLocation.LOCAL
      ? localStorage.setItem(name, value)
      : sessionStorage.setItem(name, value);
  } catch {
    logger.error(
      `Error Setting ${
        StorageLocation.LOCAL ? 'Local' : 'Session'
      } Storage Item: ${name}`,
    );
  }
};

const storageGetObject = <T>(type: StorageLocation, name: string): T | null => {
  try {
    const value =
      type === StorageLocation.LOCAL
        ? localStorage.getItem(name)
        : sessionStorage.getItem(name);
    return value ? JSON.parse(value) : null;
  } catch {
    logger.error(
      `Error Getting ${
        StorageLocation.LOCAL ? 'Local' : 'Session'
      } Storage Object: ${name}`,
    );
    return null;
  }
};

const storageSetObject = (
  type: StorageLocation,
  name: string,
  value: Record<string, unknown> | unknown[],
) => {
  try {
    type === StorageLocation.LOCAL
      ? localStorage.setItem(name, JSON.stringify(value))
      : sessionStorage.setItem(name, JSON.stringify(value));
  } catch {
    logger.error(
      `Error Setting ${
        StorageLocation.LOCAL ? 'Local' : 'Session'
      } Storage Object: ${name}`,
    );
  }
};

export const localStorageGetItem = (name: string): string | null =>
  storageGetItem(StorageLocation.LOCAL, name);

export const localStorageRemoveItem = (name: string) =>
  storageRemoveItem(StorageLocation.LOCAL, name);

export const localStorageSetItem = (name: string, value: string) =>
  storageSetItem(StorageLocation.LOCAL, name, value);

export const localStorageGetObject = <T>(name: string): T | null =>
  storageGetObject<T>(StorageLocation.LOCAL, name);

export const localStorageSetObject = (
  name: string,
  value: Record<string, unknown> | unknown[],
) => storageSetObject(StorageLocation.LOCAL, name, value);

export const sessionStorageGetItem = (name: string): string | null =>
  storageGetItem(StorageLocation.SESSION, name);

export const sessionStorageRemoveItem = (name: string) =>
  storageRemoveItem(StorageLocation.SESSION, name);

export const sessionStorageSetItem = (name: string, value: string) =>
  storageSetItem(StorageLocation.SESSION, name, value);

export const sessionStorageGetObject = (
  name: string,
): Record<string, unknown> | unknown[] | null =>
  storageGetObject(StorageLocation.SESSION, name);

export const sessionStorageSetObject = (
  name: string,
  value: Record<string, unknown> | unknown[],
) => storageSetObject(StorageLocation.SESSION, name, value);
