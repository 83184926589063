import { useCallback } from 'react';

import { docuploadUploadAction } from '../../../actions';
import { HandleUpload, UseHandleUpload } from './types';

const useHandleUpload: UseHandleUpload = dispatch => {
  const handleUpload: HandleUpload = useCallback(
    () => dispatch(docuploadUploadAction()),
    [dispatch],
  );

  return handleUpload;
};

export default useHandleUpload;
