import { platform } from 'modules/services/platform';
import { OnfidoError } from '../../constants';
import getError from './getError';

const { isCapacitor } = platform;

type HandleError = (props: {
  error: unknown;
  onCancel: () => void;
  onError: (error: Error) => void;
}) => void;

const handleError: HandleError = ({ error, onCancel, onError }) =>
  getError(error).message === OnfidoError.CANCELLED
    ? onCancel()
    : setTimeout(() => onError(getError(error)), isCapacitor ? 1000 : 350);

export default handleError;
