import { produce } from 'immer';

import { KeyboardAction, KeyboardStatus } from './constants';
import { Keyboard, KeyboardActionType } from './types';

const initialState = (): Keyboard => {
  return { active: false, offset: 0, status: KeyboardStatus.CLOSED };
};

function keyboardReducer(state = initialState(), action: KeyboardActionType) {
  switch (action.type) {
    case KeyboardAction.EVENT:
      return produce(state, (draftState: Keyboard) => {
        draftState.active = action.active;
        draftState.offset = action.offset;
        draftState.status = action.status;
      });

    default:
      return state;
  }
}

export default keyboardReducer;
