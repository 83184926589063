import { produce } from 'immer';

import { DocuploadAction, DocuploadError } from '../../../../constants';
import { Docupload, DocuploadActionType } from '../../../../types';

function docuploadPluginCameraReducer(
  state: Docupload,
  action: DocuploadActionType,
) {
  switch (action.type) {
    case DocuploadAction.PLUGIN_CAMERA_FAILURE:
      return produce(state, (draftState: Docupload) => {
        draftState.isError =
          action.error.message === DocuploadError.CANCELLED
            ? false
            : action.error;
        draftState.isLoading = false;
      });

    case DocuploadAction.PLUGIN_CAMERA_STARTED:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = true;
      });

    case DocuploadAction.PLUGIN_CAMERA_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = false;

        draftState.data.push(action.file);
      });

    default:
      return state;
  }
}

export default docuploadPluginCameraReducer;
