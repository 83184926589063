import React from 'react';

import { UseStatusRoute } from 'modules/states/status';
import Status from 'components/desktop/Status';
import Main from 'components/desktop/Main';

type TemplateProps = ReturnType<UseStatusRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  data,
  handleError,
  handleVideo,
  isError,
  isLoading,
  isPlaying,
}) => {
  return (
    <Main handleError={handleError} isError={isError} isLoading={isLoading}>
      {!!data && (
        <Status data={data} handleVideo={handleVideo} isPlaying={isPlaying} />
      )}
    </Main>
  );
};

export default React.memo(Template);
