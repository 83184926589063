import React from 'react';

import { getLabel } from '../helpers';

import styles from './styles.module.scss';

type DocumentsTabListProps = {
  handleClick: (item: string) => void;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLLIElement>,
    item: string,
  ) => void;
  handleKeyUp: (event: React.KeyboardEvent<HTMLLIElement>) => void;
  selectedTab: string | null;
  tabList: string[] | null;
  tabListRef: React.MutableRefObject<HTMLUListElement | null>;
};

const DocumentsTabList: React.FunctionComponent<DocumentsTabListProps> = ({
  tabListRef,
  handleClick,
  handleKeyDown,
  handleKeyUp,
  selectedTab,
  tabList,
}) => {
  return tabList && tabList.length > 0 ? (
    <ul
      aria-controls="documents-tab"
      className={styles['documents-tab-list']}
      ref={tabListRef}
      role="tablist">
      {tabList?.map((item, key) => {
        return (
          <li
            aria-controls={item}
            aria-selected={selectedTab === item}
            id={item}
            key={`tab-${item}-${key}`}
            onClick={() => handleClick(item)}
            onKeyDown={event => handleKeyDown(event, item)}
            onKeyUp={handleKeyUp}
            onMouseDown={event => event.preventDefault()}
            role="tab"
            tabIndex={selectedTab === item ? 0 : -1}>
            {getLabel(item)}
          </li>
        );
      })}
    </ul>
  ) : null;
};

export default React.memo(DocumentsTabList);
