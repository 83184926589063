import { Middleware } from 'redux';

import { abortCases, analyticsTrackCases, getSavedCaseId } from './utils';
import { getCaseRoute, middlewareNavigate, ROUTE } from 'modules/router';
import { AuthAction, AuthActionType, saveAuthData } from 'modules/auth';
import { UserAction, UserActionType } from 'modules/states/user';
import { Dispatch, RootState } from 'modules/redux';
import { AppActionType } from 'modules/app/types';
import { AppAction } from 'modules/app/constants';
import { CasesActionType } from './types';
import { CasesAction } from './constants';
import {
  casesRestoreAction,
  casesLogoutAction,
  casesGetAction,
} from './actions';

const casesMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (
    action: AppActionType | AuthActionType | CasesActionType | UserActionType,
  ) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT: {
        nextAction = next(action);
        const { auth } = getState();
        !!auth.data?.token &&
          dispatch(casesRestoreAction(getSavedCaseId(auth.data.username)));
        break;
      }

      case AppAction.READY: {
        nextAction = next(action);
        const { auth } = getState();
        !!auth.data?.token && dispatch(casesGetAction());
        break;
      }

      case AuthAction.LOGIN_SUCCESS: {
        dispatch(casesRestoreAction(getSavedCaseId(action.request.username)));
        break;
      }

      case CasesAction.SELECT: {
        const previousCaseId = getState().cases.caseId;
        nextAction = next(action);
        const { auth, cases } = getState();
        saveAuthData(auth.data, cases.caseId);
        switch (true) {
          case !!action.route:
            !!action.route && middlewareNavigate(dispatch, action.route);
            break;
          default:
            previousCaseId !== cases.caseId &&
              middlewareNavigate(
                dispatch,
                getCaseRoute(cases.caseId, ROUTE.STATUS),
              );
        }
        break;
      }

      case CasesAction.GET_SUCCESS: {
        nextAction = next(action);
        const { auth, cases } = getState();
        saveAuthData(auth.data, cases.caseId);
        break;
      }

      case UserAction.GET_SUCCESS: {
        nextAction = next(action);
        const { auth, cases } = getState();
        saveAuthData(auth.data, cases.caseId);
        break;
      }

      case AuthAction.LOGOUT: {
        nextAction = next(action);
        abortCases(getState().cases);
        dispatch(casesLogoutAction());
        break;
      }
    }

    nextAction = analyticsTrackCases({
      action,
      getState,
      next,
      nextAction,
    });

    return nextAction ? nextAction : next(action);
  };

export default casesMiddleware;
