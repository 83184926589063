import matchRoute from './matchRoute';
import { routes } from '../constants';
import { RouteType } from '../types';

type GetRoute = (path: string) => RouteType | undefined;

const getRoute: GetRoute = path =>
  routes.find(route => matchRoute(route.path, path));

export default getRoute;
