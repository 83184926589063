import { useCallback } from 'react';

import { MessageActionDocupload } from 'modules/states/messages';
import { shallowEqual, useSelector } from 'modules/redux';
import { UseDocuploadMessage } from './types';

const useDocuploadMessage: UseDocuploadMessage = ({
  message: {
    callToAction: { action, uploadURL },
    messageId,
  },
  onClick,
}) => {
  const { messageId: docuploadId } = useSelector(
    state => state.docupload,
    shallowEqual,
  );
  const { messageId: onfidoId } = useSelector(
    state => state.onfido,
    shallowEqual,
  );

  const active = messageId === docuploadId;

  const disabled =
    action !== MessageActionDocupload.REQUESTED || !!docuploadId || !!onfidoId;

  const handleClick = useCallback(
    () => !active && !disabled && onClick(messageId as number, uploadURL),
    [active, disabled, messageId, onClick, uploadURL],
  );

  return { active, disabled, handleClick };
};

export default useDocuploadMessage;
