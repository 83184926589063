export enum ButtonSize {
  LARGE = 'large',
  SMALL = 'small',
}

export enum ButtonStyle {
  PRIMARY_INVERTED = 'primary-inverted',
  PRIMARY = 'primary',
}

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}
