import { useCallback } from 'react';

import { docuploadPluginCameraAction } from '../../../actions';
import { HandleCamera, UseHandleCamera } from './types';

const useHandleCamera: UseHandleCamera = dispatch => {
  const handleCamera: HandleCamera = useCallback(
    () => dispatch(docuploadPluginCameraAction()),
    [dispatch],
  );

  return handleCamera;
};

export default useHandleCamera;
