import React from 'react';

import { usePlatformContext } from 'modules/services/platform';
import { useTeamRoute } from 'modules/states/team';
import DesktopTemplate from './Desktop';
import MobileTemplate from './Mobile';

const Route: React.FunctionComponent = () => {
  const { isMobile } = usePlatformContext();
  const route = useTeamRoute();
  return isMobile ? (
    <MobileTemplate {...route} />
  ) : (
    <DesktopTemplate {...route} />
  );
};

export default React.memo(Route);
