import React from 'react';

import styles from './styles.module.scss';

const NoDocuments: React.FunctionComponent = () => {
  return (
    <div className={styles['no-documents']}>
      <p>You don't currently have any documents.</p>
    </div>
  );
};

export default React.memo(NoDocuments);
