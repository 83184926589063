import getDocumentName from './getDocumentName';
import { OnfidoPluginResponse } from '../types';
import { OnfidoVariant } from '../../constants';
import { isOnfidoVariant } from '../../utils';
import { SdkResponse } from '../ios/types';

type HandleComplete = (props: {
  data: SdkResponse;
  onComplete: (data: OnfidoPluginResponse) => void;
  variant: OnfidoVariant;
}) => void;

const handleComplete: HandleComplete = ({ data, onComplete, variant }) =>
  onComplete({
    document: getDocumentName(data.document || 'unknown'),
    variant: isOnfidoVariant(data.variant) ? data.variant : variant,
  });

export default handleComplete;
