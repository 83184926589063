import { StatusStep, StatusType } from 'modules/states/status';

type IsStatusStepDeclined = (step: StatusStep, status: StatusType) => boolean;

const isStatusStepDeclined: IsStatusStepDeclined = (step, status) => {
  switch (step) {
    case StatusStep.STEP5:
      return status === StatusType.DEC;
    case StatusStep.STEP4:
      return status === StatusType.DEC_LEN;
    case StatusStep.STEP3:
      return status === StatusType.DEC_REF;
    case StatusStep.STEP2:
      return status === StatusType.DEC_RES;
    case StatusStep.STEP1:
    default:
      return false;
  }
};

export default isStatusStepDeclined;
