import dayjs from 'dayjs';

import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

export const formatDate = (date: Date, format: string): string =>
  dayjs(date).format(format);

export const isDateToday = (date: Date): boolean => dayjs(date).isToday();

export const isDateYesterday = (date: Date): boolean =>
  dayjs(date).isYesterday();
