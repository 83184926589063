export enum CasesAction {
  GET_STARTED = 'CASES_GET_STARTED',
  GET_SUCCESS = 'CASES_GET_SUCCESS',
  GET_FAILURE = 'CASES_GET_FAILURE',

  RESTORE = 'CASES_RESTORE',

  SELECT = 'CASES_SELECT',

  LOGOUT = 'CASES_LOGOUT',
}

export enum CaseType {
  FLUENT_BRIDGING = 'Bridging',
  FLUENT_EQUITY_RELEASE = 'EquityRelease',
  FLUENT_LIFETIME = 'Lifetime',
  FLUENT_MONEY = 'Secured',
  FLUENT_MORTGAGES = 'Mortgage',
}
