import { produce } from 'immer';

import { StatusActionType, StatusState } from './types';
import { findStatus, newStatus } from './utils';
import { StatusAction } from './constants';

const initialState = (): StatusState => {
  return [];
};

function statusReducer(state = initialState(), action: StatusActionType) {
  switch (action.type) {
    case StatusAction.GET_FAILURE:
      return produce(state, (draftState: StatusState) => {
        const status = findStatus(draftState, action.request.caseId);
        if (status) {
          status.actionId = null;
          status.isError = action.error;
          status.isLoading = false;
        }
      });

    case StatusAction.GET_STARTED:
      return produce(state, (draftState: StatusState) => {
        const status =
          findStatus(draftState, action.request.caseId) ||
          newStatus(draftState, action.request.caseId);
        status.actionId = action.actionId;
        status.isError = false;
        status.isLoading = action.controller || true;
      });

    case StatusAction.GET_SUCCESS:
      return produce(state, (draftState: StatusState) => {
        const status = findStatus(draftState, action.request.caseId);
        if (status) {
          status.actionId = null;
          status.isError = false;
          status.isLoading = false;
        }
      });

    case StatusAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default statusReducer;
