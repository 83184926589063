import React from 'react';

import Contact from 'components/mobile/Contact';
import Main from 'components/mobile/Main';

const Template: React.FunctionComponent = () => {
  return (
    <Main>
      <Contact />
    </Main>
  );
};

export default React.memo(Template);
