import React from 'react';
import clsx from 'clsx';

import { ReactComponent as DocumentsSVG } from 'modules/theme/images/icons/footer/documents.svg';
import { ReactComponent as MessagesSVG } from 'modules/theme/images/icons/footer/messages.svg';
import { ReactComponent as ContactSVG } from 'modules/theme/images/icons/footer/contact.svg';
import { ReactComponent as StatusSVG } from 'modules/theme/images/icons/footer/status.svg';
import { ReactComponent as LoanSVG } from 'modules/theme/images/icons/footer/loan.svg';
import { ReactComponent as TeamSVG } from 'modules/theme/images/icons/footer/team.svg';
import { NavigationLink } from 'components/shared/Hyperlinks';
import { useNavigation } from 'modules/router';

import styles from './styles.module.scss';

const Footer: React.FunctionComponent = () => {
  const {
    linkContact,
    linkDocuments,
    linkLoan,
    linkMessages,
    linkStatus,
    linkTeam,
    messageCount,
    messageCountActive,
  } = useNavigation();

  const className = clsx(
    styles.footer,
    messageCount && styles['message-count'],
    messageCountActive && styles['message-count-active'],
  );

  return (
    <footer className={className}>
      <nav aria-label="Main">
        <ul>
          <li>
            <NavigationLink label="My Progress" route={linkStatus}>
              <StatusSVG />
            </NavigationLink>
          </li>
          {!!linkMessages && (
            <li>
              <NavigationLink label="Messages" route={linkMessages}>
                <MessagesSVG />
                {messageCount > 0 && <span>{messageCount}</span>}
              </NavigationLink>
            </li>
          )}
          <li>
            <NavigationLink label="My Loan" route={linkLoan}>
              <LoanSVG />
            </NavigationLink>
          </li>
          {!!linkTeam && (
            <li>
              <NavigationLink label="My Team" route={linkTeam}>
                <TeamSVG />
              </NavigationLink>
            </li>
          )}
          {!!linkDocuments && (
            <li>
              <NavigationLink label="My Docs" route={linkDocuments}>
                <DocumentsSVG />
              </NavigationLink>
            </li>
          )}
          {!!linkContact && (
            <li>
              <NavigationLink label="Contact" route={linkContact}>
                <ContactSVG />
              </NavigationLink>
            </li>
          )}
        </ul>
      </nav>
    </footer>
  );
};

export default React.memo(Footer);
