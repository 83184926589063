import getImageQuality from './getImageQuality';
import { ConfigApiResponse } from '../types';

const updateImageQuality = (
  value: ConfigApiResponse['data']['documentUploadImageQuality'],
): number => {
  const newValue = Number(value);
  return !isNaN(newValue) && newValue >= 0 && newValue <= 100
    ? newValue
    : getImageQuality();
};

export default updateImageQuality;
