import { produce } from 'immer';

import { AuthAction, AuthBiometricError } from '../../constants';
import { Auth, AuthActionType } from '../../types';

function authBiometricLoginReducer(state: Auth, action: AuthActionType) {
  switch (action.type) {
    case AuthAction.BIOMETRIC_LOGIN_FAILURE:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isLoading = false;

        action.error.message !== AuthBiometricError.CANCELLED &&
          (draftState.isBiometric = false);
      });

    case AuthAction.BIOMETRIC_LOGIN_STARTED:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = action.actionId;
        draftState.isLoading = true;
      });

    case AuthAction.BIOMETRIC_LOGIN_SUCCESS:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isLoading = false;
      });

    case AuthAction.BIOMETRIC_LOGIN_RESET:
      return produce(state, (draftState: Auth) => {
        draftState.isBiometric = false;
      });

    case AuthAction.BIOMETRIC_LOGIN_STATUS:
      return produce(state, (draftState: Auth) => {
        draftState.isBiometric = action.isBiometric;
      });

    default:
      return state;
  }
}

export default authBiometricLoginReducer;
