import { v4 as uuidv4 } from 'uuid';

import { DialogAction } from './constants';
import { newDialog } from './utils';
import {
  DialogDestroyAction,
  DialogHideAction,
  DialogInsertAction,
  DialogLogoutAction,
  DialogShowAction,
} from './types';

export const dialogShowAction: DialogShowAction = dialog => {
  return (dispatch, getState) => {
    const dialogId = uuidv4();
    dispatch(
      dialogInsertAction(newDialog(dialog, dispatch, getState, dialogId)),
    );
    return dialogId;
  };
};

export const dialogDestroyAction: DialogDestroyAction = dialogId => ({
  type: DialogAction.DESTROY,
  dialogId,
});

export const dialogHideAction: DialogHideAction = dialogId => ({
  type: DialogAction.HIDE,
  dialogId,
});

const dialogInsertAction: DialogInsertAction = dialog => ({
  type: DialogAction.INSERT,
  dialog,
});

export const dialogLogoutAction: DialogLogoutAction = () => ({
  type: DialogAction.LOGOUT,
});
