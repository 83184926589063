import { v4 as uuidv4 } from 'uuid';

import { AuthAction, AuthBiometricError } from '../../constants';
import { isAllowed, isCurrentAction, getError } from './utils';
import {
  AuthBiometricLoginFailureAction,
  AuthBiometricLoginStartedAction,
  AuthBiometricLoginSuccessAction,
  AuthBiometricLoginStatusAction,
  AuthBiometricLoginResetAction,
  AuthBiometricLoginAction,
} from './types';
import {
  getBiometricCredentials,
  isBiometricAnswered,
  getBiometricStatus,
  isBiometricActive,
  isBiometricValid,
} from '../../utils';

export const authBiometricLoginAction: AuthBiometricLoginAction = username => {
  return async (dispatch, getState) => {
    const actionId = uuidv4();
    const state = getState();
    try {
      if (!isAllowed(state)) return;

      dispatch(authBiometricLoginStartedAction({ actionId, username }));

      const status = await getBiometricStatus();
      if (!isBiometricActive(status))
        throw new Error(AuthBiometricError.UNAVAILABLE);

      const answered = await isBiometricAnswered(username);
      if (!answered) throw new Error(AuthBiometricError.ANSWERED);

      const credentials = await getBiometricCredentials(username);
      if (!isBiometricValid(credentials, username))
        throw new Error(AuthBiometricError.KEYCHAIN);

      isCurrentAction(actionId, getState()) &&
        dispatch(authBiometricLoginSuccessAction({ actionId, credentials }));
    } catch (error) {
      isCurrentAction(actionId, getState()) &&
        dispatch(
          authBiometricLoginFailureAction({
            actionId,
            error: getError(error),
            username,
          }),
        );
    }
  };
};

const authBiometricLoginStartedAction: AuthBiometricLoginStartedAction =
  payload => ({
    type: AuthAction.BIOMETRIC_LOGIN_STARTED,
    ...payload,
  });

const authBiometricLoginSuccessAction: AuthBiometricLoginSuccessAction =
  payload => ({
    type: AuthAction.BIOMETRIC_LOGIN_SUCCESS,
    ...payload,
  });

const authBiometricLoginFailureAction: AuthBiometricLoginFailureAction =
  payload => ({
    type: AuthAction.BIOMETRIC_LOGIN_FAILURE,
    ...payload,
  });

export const authBiometricLoginResetAction: AuthBiometricLoginResetAction = (
  username: string,
) => ({
  type: AuthAction.BIOMETRIC_LOGIN_RESET,
  username,
});

export const authBiometricLoginStatusAction: AuthBiometricLoginStatusAction =
  () => ({
    type: AuthAction.BIOMETRIC_LOGIN_STATUS,
    isBiometric: false,
  });
