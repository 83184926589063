import { useCallback } from 'react';

import { Dispatch, useDispatch } from 'modules/redux';
import { onfidoShowAction } from '../../../actions';

type HandleOnfido = (messageId: number, startURL: string) => void;

type UseHandleOnfido = (caseId: number | null) => HandleOnfido;

const useHandleOnfido: UseHandleOnfido = caseId => {
  const dispatch: Dispatch = useDispatch();

  const handleOnfido: HandleOnfido = useCallback(
    (messageId, startURL) => {
      if (caseId) dispatch(onfidoShowAction({ caseId, messageId, startURL }));
    },
    [caseId, dispatch],
  );

  return handleOnfido;
};

export default useHandleOnfido;
