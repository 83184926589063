export enum DocumentsAction {
  GET_STARTED = 'DOCUMENTS_GET_STARTED',
  GET_SUCCESS = 'DOCUMENTS_GET_SUCCESS',
  GET_FAILURE = 'DOCUMENTS_GET_FAILURE',

  LOGOUT = 'DOCUMENTS_LOGOUT',
}

export enum DocumentExtension {
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  WEB = 'web',
}

export enum DocumentType {
  CASE = 'casedoc',
  UPLOAD = 'docupload',
}
