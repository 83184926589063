import { createDefaultAction } from 'modules/redux/factories';
import { MessagesAction } from '../../constants';
import { isActive, isPresent } from './utils';
import { API } from 'modules/api';
import {
  MessagesGetFailureAction,
  MessagesGetStartedAction,
  MessagesGetSuccessAction,
  MessagesGetAction,
} from './types';

export { default as messagesGetReducer } from './reducer';
export type {
  MessagesGetActionApiRequest,
  MessagesGetActionType,
} from './types';

export const messagesGetAction: MessagesGetAction = request =>
  createDefaultAction({
    actionStarted: messagesGetStartedAction,
    actionSuccess: messagesGetSuccessAction,
    actionFailure: messagesGetFailureAction,
    endpoint: API.GET_MESSAGES,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const messagesGetStartedAction: MessagesGetStartedAction = payload => ({
  type: MessagesAction.GET_STARTED,
  ...payload,
});

const messagesGetSuccessAction: MessagesGetSuccessAction = payload => ({
  type: MessagesAction.GET_SUCCESS,
  ...payload,
});

const messagesGetFailureAction: MessagesGetFailureAction = payload => ({
  type: MessagesAction.GET_FAILURE,
  ...payload,
});
