import { AppStatusAction } from './constants';
import {
  AppStatusInactiveAction,
  AppStatusActiveAction,
  AppStatusInitAction,
} from './types';

export const appStatusInitAction: AppStatusInitAction = () => ({
  type: AppStatusAction.INIT,
  state: true,
});

export const appStatusActiveAction: AppStatusActiveAction = () => ({
  type: AppStatusAction.ACTIVE,
});

export const appStatusInactiveAction: AppStatusInactiveAction = () => ({
  type: AppStatusAction.INACTIVE,
});
