import { RootState } from 'modules/redux/store';
import isAuthorized from './isAuthorized';
import isPresent from './isPresent';
import isActive from './isActive';

type IsCurrentAction = (actionId: string, state: RootState) => boolean;

const isCurrentAction: IsCurrentAction = (actionId, state) =>
  !isAuthorized(state) && isActive(state) && isPresent(actionId, state);

export default isCurrentAction;
