import { AuthBiometricError, AuthBiometricStatus } from '../../../constants';
import { Dispatch, RootState } from 'modules/redux';
import { getBiometricLabel } from '../../../utils';
import { AuthApiRequest } from '../../../types';
import isCurrentAction from './isCurrentAction';
import {
  authBiometricQuestionFailureAction,
  authBiometricQuestionSuccessAction,
} from '../actions';
import {
  dialogDestroyAction,
  dialogShowAction,
  DialogType,
} from 'modules/dialogs';

export type ShowDialog = (props: {
  actionId: string;
  credentials: AuthApiRequest;
  dispatch: Dispatch;
  getState: () => RootState;
  status: AuthBiometricStatus;
}) => void;

const showDialog: ShowDialog = ({
  actionId,
  credentials,
  dispatch,
  getState,
  status,
}) => {
  const dialogId = dispatch(
    dialogShowAction({
      message: `Do you want to use your ${getBiometricLabel(
        status,
      )} to log in next time?`,
      title: 'MyFluent',
      type: DialogType.CONFIRM,

      onCancel: () => {
        isCurrentAction(actionId, getState()) &&
          dispatch(
            authBiometricQuestionFailureAction({
              actionId,
              credentials,
              error: new Error(AuthBiometricError.REJECTED),
            }),
          );
        dispatch(dialogDestroyAction(dialogId));
      },

      onConfirm: () => {
        isCurrentAction(actionId, getState()) &&
          dispatch(
            authBiometricQuestionSuccessAction({
              actionId,
              credentials,
              status,
            }),
          );
        dispatch(dialogDestroyAction(dialogId));
      },
    }),
  );
};

export default showDialog;
