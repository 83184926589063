import { Middleware } from 'redux';

import { Dispatch, RootState } from 'modules/redux';
import { AppActionType } from './types';
import { AppAction } from './constants';

const appMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT: {
        const html = document.getElementsByTagName('html')[0];
        html.classList.remove('preload');
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default appMiddleware;
