import { JailbreakInitAction, JailbreakResultAction } from './types';
import { JailbreakAction } from './constants';

export const jailbreakInitAction: JailbreakInitAction = () => ({
  type: JailbreakAction.INIT,
});

export const jailbreakResultAction: JailbreakResultAction = status => ({
  type: JailbreakAction.RESULT,
  status,
});
