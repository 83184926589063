import { CapacitorVideoPlayer } from 'capacitor-video-player';

import {
  RemoveListeners,
  AddListeners,
  AddListener,
  VideoPlugin,
  Listener,
} from './types';

let endedListener: Listener | null = null;
let readyListener: Listener | null = null;
let exitListener: Listener | null = null;

export let isActive = false;

const addListener: AddListener = async (type, listener) =>
  (CapacitorVideoPlayer as VideoPlugin).addListener(type, listener);

export const addListeners: AddListeners = async ({
  onCancel,
  onComplete,
  onReady,
}) => {
  await removeListeners();

  readyListener = await addListener('jeepCapVideoPlayerReady', () => {
    isActive = true;
    onReady();
  });

  endedListener = await addListener('jeepCapVideoPlayerEnded', () => {
    isActive = false;
    removeListeners();
    onComplete();
  });

  exitListener = await addListener('jeepCapVideoPlayerExit', () => {
    isActive = false;
    removeListeners();
    onCancel();
  });
};

export const removeListeners: RemoveListeners = async () => {
  await readyListener?.remove();
  await endedListener?.remove();
  await exitListener?.remove();
};
