import { CertificatePluginCheck, CertificatePluginInit } from './types';
import { platform } from 'modules/services/platform';
import { CertificateStatus } from './constants';
import { isCertificateStatus } from './utils';

const fingerprints: string[] = [];
const { isCapacitor } = platform;

!!process.env.REACT_APP_SSL_FINGERPRINT &&
  fingerprints.push(process.env.REACT_APP_SSL_FINGERPRINT);

!!process.env.REACT_APP_SSL_FINGERPRINT_NEW &&
  fingerprints.push(process.env.REACT_APP_SSL_FINGERPRINT_NEW);

const certificatePluginCheck: CertificatePluginCheck = () =>
  new Promise(function (resolve, reject) {
    try {
      fingerprints.length
        ? window.plugins.sslCertificateChecker.check(
            result =>
              result === CertificateStatus.SECURE
                ? resolve(CertificateStatus.SECURE)
                : reject(CertificateStatus.NOT_SECURE),
            error =>
              reject(
                error.includes(CertificateStatus.FAILED)
                  ? CertificateStatus.FAILED
                  : CertificateStatus.NOT_SECURE,
              ),
            process.env.REACT_APP_API_ROOT,
            fingerprints,
          )
        : resolve(CertificateStatus.SECURE);
    } catch {
      isCapacitor
        ? reject(CertificateStatus.NOT_SECURE)
        : resolve(CertificateStatus.SECURE);
    }
  });

export const certificatePluginInit: CertificatePluginInit = async handler => {
  try {
    if (!isCapacitor) return;
    handler(await certificatePluginCheck());
  } catch (error) {
    handler(isCertificateStatus(error) ? error : CertificateStatus.NOT_SECURE);
  }
};
