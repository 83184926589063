import React from 'react';
import clsx from 'clsx';

import { useFocusLock } from 'modules/hooks/useFocusLock';
import Navigation from './Navigation';
import Account from './Account';

import styles from './styles.module.scss';

type MenuProps = {
  animating: boolean;
  handleCloseMenu: () => void;
  menuRef: React.MutableRefObject<HTMLDivElement | null>;
  open: boolean;
};

const Menu: React.FunctionComponent<MenuProps> = ({
  animating,
  handleCloseMenu,
  menuRef,
  open,
}) => {
  useFocusLock({ isLocked: open || animating, rootNode: menuRef });
  const className = clsx(
    styles.menu,
    open && styles.open,
    animating && styles.animating,
  );
  return (
    <div className={className} id="menu" ref={menuRef} tabIndex={0}>
      <Account animating={animating} handleCloseMenu={handleCloseMenu} />
      <Navigation animating={animating} handleCloseMenu={handleCloseMenu} />
    </div>
  );
};

export default React.memo(Menu);
