import { getPreviousRoute, matchRoute, ROUTE } from 'modules/router';
import getVideoModal from './getVideoModal';
import { RootState } from 'modules/redux';

type ShouldClose = (state: RootState) => boolean;

const shouldClose: ShouldClose = state => {
  if (state.auth.data?.token && getVideoModal(state))
    switch (true) {
      case matchRoute(ROUTE.STATUS_CASE, getPreviousRoute(state.router)):
        return true;
      case matchRoute(ROUTE.STATUS, getPreviousRoute(state.router)):
        return true;
    }
  return false;
};

export default shouldClose;
