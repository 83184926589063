import yup from 'modules/validation';

export type Model = {
  debugEmailInput: string;
  debugEmailInputEmpty: string;
  debugPasswordInput: string;
  debugPasswordInputEmpty: string;
  debugEmailInputDisabled: string;
  debugEmailInputDisabledEmpty: string;
  debugPasswordInputDisabled: string;
  debugPasswordInputDisabledEmpty: string;
  debugEmailInputReadOnly: string;
  debugEmailInputEmptyReadOnly: string;
  debugPasswordInputReadOnly: string;
  debugPasswordInputEmptyReadOnly: string;
};

export const defaultValues: Model = {
  debugEmailInput: 'test@email.com',
  debugEmailInputEmpty: '',
  debugPasswordInput: 'testpassword',
  debugPasswordInputEmpty: '',
  debugEmailInputDisabled: 'test@email.com',
  debugEmailInputDisabledEmpty: '',
  debugPasswordInputDisabled: 'testpassword',
  debugPasswordInputDisabledEmpty: '',
  debugEmailInputReadOnly: 'test@email.com',
  debugEmailInputEmptyReadOnly: '',
  debugPasswordInputReadOnly: 'testpassword',
  debugPasswordInputEmptyReadOnly: '',
};

export const schema = yup.object().shape({
  debugEmailInput: yup.string().required().email(),
  debugEmailInputEmpty: yup.string().required().email(),
  debugPasswordInput: yup.string().required(),
  debugPasswordInputEmpty: yup.string().required(),
  debugEmailInputDisabled: yup.string(),
  debugEmailInputDisabledEmpty: yup.string(),
  debugPasswordInputDisabled: yup.string(),
  debugPasswordInputDisabledEmpty: yup.string(),
  debugEmailInputReadOnly: yup.string(),
  debugEmailInputEmptyReadOnly: yup.string(),
  debugPasswordInputReadOnly: yup.string(),
  debugPasswordInputEmptyReadOnly: yup.string(),
});
