import { formatCurrency } from 'modules/utils';
import findCase from './findCase';
import { Case } from '../types';
import {
  LoanGetActionApiResponse,
  LoanGetActionApiRequest,
} from 'modules/states/loan';

type UpdateCasesFromLoan = (
  array: Case[],
  data: LoanGetActionApiResponse['data'],
  request: LoanGetActionApiRequest,
) => void;

const updateCasesFromLoan: UpdateCasesFromLoan = (array, data, request) => {
  const result = findCase(array, request.caseId);
  if (result) {
    !!data.lenderLogo && (result.lenderLogo = data.lenderLogo);
    !!data.lenderName && (result.lender = data.lenderName);
    !!data.loanAdvance && (result.advance = formatCurrency(data.loanAdvance));
  }
};

export default updateCasesFromLoan;
