import { PDFDocument } from 'pdf-lib';

import { blobPDF, destroyPDF, embedPDF, newPDF, trimPDF, viewPDF } from './pdf';
import { DocuploadError, DocuploadFileType } from '../constants';
import { embedCamera, errorCamera } from './camera';
import { embedImage } from './image';
import { checkFile } from './file';
import {
  DocuploadPluginCamera,
  DocuploadPluginDelete,
  DocuploadPluginDestroy,
  DocuploadPluginFile,
  DocuploadPluginPreview,
  DocuploadPluginFormData,
} from './types';

let pdf: PDFDocument | null = null;

export const docuploadPluginCamera: DocuploadPluginCamera = async config => {
  try {
    !pdf && (pdf = await newPDF());
    const count = pdf.getPageCount();
    await embedCamera(config, pdf);
    return pdf.getPageCount() - count;
  } catch (error) {
    throw new Error(errorCamera(error));
  }
};

export const docuploadPluginDelete: DocuploadPluginDelete = (files, id) => {
  const index = files.findIndex(file => file.id === id);
  if (index !== -1 && pdf) {
    trimPDF(files, index, pdf);
  } else {
    throw new Error(DocuploadError.REMOVE);
  }
};

export const docuploadPluginDestroy: DocuploadPluginDestroy = () => {
  if (pdf) {
    destroyPDF(pdf);
    pdf = null;
  }
};

export const docuploadPluginFile: DocuploadPluginFile = async (
  config,
  file,
) => {
  if (checkFile(file)) {
    try {
      !pdf && (pdf = await newPDF());
      const count = pdf.getPageCount();
      file.type === DocuploadFileType.PDF
        ? await embedPDF(file, pdf)
        : await embedImage(config, file, pdf);
      return pdf.getPageCount() - count;
    } catch {
      throw new Error(
        file.type === DocuploadFileType.PDF
          ? DocuploadError.PDF
          : DocuploadError.IMAGE,
      );
    }
  } else {
    throw new Error(DocuploadError.TYPE);
  }
};

export const docuploadPluginPreview: DocuploadPluginPreview = async () => {
  if (pdf) {
    await viewPDF('document.pdf', pdf);
  } else {
    throw new Error(DocuploadError.NO_FILES);
  }
};

export const docuploadPluginFormData: DocuploadPluginFormData = async () => {
  if (pdf) {
    const blob = await blobPDF(pdf);
    const data = new FormData();
    data.append('file', blob, 'file.pdf');
    return data;
  } else {
    throw new Error(DocuploadError.NO_FILES);
  }
};
