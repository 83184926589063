import { RootState } from 'modules/redux';
import { Modal } from '../types';

type GetModal = (
  modals: RootState['modals'],
  modalId: string,
) => Modal | undefined;

const getModal: GetModal = (modals, modalId) =>
  modals.find(modal => modal.modalId === modalId);

export default getModal;
