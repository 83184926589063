import { produce } from 'immer';

import { Docupload, DocuploadActionType } from '../../../../types';
import { DocuploadAction } from '../../../../constants';

function docuploadPluginPreviewReducer(
  state: Docupload,
  action: DocuploadActionType,
) {
  switch (action.type) {
    case DocuploadAction.PLUGIN_PREVIEW_FAILURE:
      return produce(state, (draftState: Docupload) => {
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case DocuploadAction.PLUGIN_PREVIEW_STARTED:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = true;
      });

    case DocuploadAction.PLUGIN_PREVIEW_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.isLoading = false;
      });

    default:
      return state;
  }
}

export default docuploadPluginPreviewReducer;
