import { useEffect, useState } from 'react';

import { NetworkStatus, useNetwork } from 'modules/services/network';

export type UseStatus = () => NetworkStatus;

const useStatus: UseStatus = () => {
  const network = useNetwork();
  const [status, setStatus] = useState<NetworkStatus>(network);
  useEffect(() => {
    network === NetworkStatus.OFFLINE && setStatus(network);
  }, [network]);
  return status;
};

export default useStatus;
