import { OnfidoPostActionApiRequest } from './types';
import { RootState } from 'modules/redux/store';

type IsPresent = (props: {
  actionId: string;
  request: OnfidoPostActionApiRequest;
  state: RootState;
}) => boolean;

export const isPresent: IsPresent = ({ actionId, request, state }) =>
  actionId === state.onfido.actionId &&
  request.messageId === state.onfido.messageId;
