import { MessageActionOnfido, MessageType } from '../constants';
import { OnfidoPostActionApiRequest } from 'modules/onfido';
import { MessageOnfido, MessagesState } from '../types';
import findMessage from './findMessage';

type UpdateMessageOnfidoStatus = (
  array: MessagesState['data'],
  request: OnfidoPostActionApiRequest,
) => void;

const updateMessageOnfidoStatus: UpdateMessageOnfidoStatus = (
  array,
  request,
) => {
  const message = findMessage(array, request.caseId, request.messageId) as
    | MessageOnfido
    | undefined;
  if (message?.type === MessageType.ONFIDO) {
    message.callToAction.action = MessageActionOnfido.INPROGRESS;
  }
};

export default updateMessageOnfidoStatus;
