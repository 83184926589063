import { createDefaultAction } from 'modules/redux/factories';
import { isActive, isPresent } from './utils';
import { CasesAction } from './constants';
import { API } from 'modules/api';
import {
  CasesGetFailureAction,
  CasesGetStartedAction,
  CasesGetSuccessAction,
  CasesRestoreAction,
  CasesLogoutAction,
  CasesSelectAction,
  CasesGetAction,
} from './types';

export const casesGetAction: CasesGetAction = () =>
  createDefaultAction({
    actionStarted: casesGetStartedAction,
    actionSuccess: casesGetSuccessAction,
    actionFailure: casesGetFailureAction,
    endpoint: API.GET_CASES,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: undefined,
  });

const casesGetStartedAction: CasesGetStartedAction = payload => ({
  type: CasesAction.GET_STARTED,
  ...payload,
});

const casesGetSuccessAction: CasesGetSuccessAction = payload => ({
  type: CasesAction.GET_SUCCESS,
  ...payload,
});

const casesGetFailureAction: CasesGetFailureAction = payload => ({
  type: CasesAction.GET_FAILURE,
  ...payload,
});

export const casesRestoreAction: CasesRestoreAction = caseId => ({
  type: CasesAction.RESTORE,
  caseId,
});

export const casesSelectAction: CasesSelectAction = payload => ({
  type: CasesAction.SELECT,
  ...payload,
});

export const casesLogoutAction: CasesLogoutAction = () => ({
  type: CasesAction.LOGOUT,
});
