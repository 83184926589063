import { Middleware } from 'redux';

import { abortAuth, getBiometricUserStatus, saveAuthData } from './utils';
import { biometricPluginDelete, biometricPluginSave } from './plugin';
import { AuthAction, AuthBiometricError } from './constants';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { AuthActionType } from './types';
import {
  authBiometricQuestionAction,
  authLoginAction,
  authInitAction,
} from './actions';

const authMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | AuthActionType) => {
    const isCapacitor = getState().platform.isCapacitor;
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(authInitAction());
        break;

      case AuthAction.INIT:
        action.isBiometric = isCapacitor
          ? await getBiometricUserStatus(getState().auth.data?.username)
          : false;
        break;

      case AuthAction.LOGIN_SUCCESS:
      case AuthAction.CHANGE_PASSWORD_SUCCESS: {
        nextAction = next(action);

        const { auth, cases } = getState();
        saveAuthData(auth.data, cases.caseId);

        !!auth.data &&
          dispatch(
            authBiometricQuestionAction({
              username: auth.data.username,
              password: action.request.password,
            }),
          );
        break;
      }

      case AuthAction.BIOMETRIC_LOGIN_SUCCESS:
        nextAction = next(action);
        dispatch(authLoginAction(action.credentials));
        break;

      case AuthAction.BIOMETRIC_LOGIN_STATUS:
        action.isBiometric = isCapacitor
          ? await getBiometricUserStatus(getState().auth.data?.username)
          : false;
        break;

      case AuthAction.BIOMETRIC_LOGIN_FAILURE:
        if (action.error.message !== AuthBiometricError.CANCELLED)
          isCapacitor && biometricPluginDelete(action.username);
        break;

      case AuthAction.BIOMETRIC_QUESTION_SUCCESS:
        isCapacitor && biometricPluginSave(action.credentials);
        break;

      case AuthAction.BIOMETRIC_QUESTION_FAILURE:
        switch (true) {
          case action.error.message === AuthBiometricError.REJECTED:
            isCapacitor &&
              biometricPluginSave({
                username: action.credentials.username,
                password: '',
              });
            break;

          case action.error.message !== AuthBiometricError.ANSWERED:
            isCapacitor && biometricPluginDelete(action.credentials.username);
            break;
        }
        break;

      case AuthAction.BIOMETRIC_LOGIN_RESET:
        nextAction = next(action);
        isCapacitor && biometricPluginDelete(action.username);
        break;

      case AuthAction.LOGOUT: {
        abortAuth(getState().auth);
        nextAction = next(action);

        const { auth, cases } = getState();
        saveAuthData(auth.data, cases.caseId);

        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default authMiddleware;
