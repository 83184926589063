export enum KeyboardAction {
  INIT = 'KEYBOARD_INIT',

  EVENT = 'KEYBOARD_EVENT',
}

export enum KeyboardStatus {
  WILL_CLOSE = 'WILL_CLOSE',
  WILL_OPEN = 'WILL_OPEN',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}
