import { useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';

import { usePlatformContext } from 'modules/services/platform';
import { MessageFrom } from 'modules/states/messages';
import { getMessageDates } from './utils';
import { UseMessage } from './types';

import styles from './styles.module.scss';

const useMessage: UseMessage = ({
  handleInView,
  message: { date, from, isSending, messageId, read },
}) => {
  const { isAndroid } = usePlatformContext();
  const [unread] = useState(!read);

  const { dateFooter, dateHeader, dateTime } = useMemo(
    () => getMessageDates(date),
    [date],
  );

  const skip = useMemo(
    () => from !== MessageFrom.FLUENT || !!isSending || read,
    [from, isSending, read],
  );

  const { ref } = useInView({
    onChange: inView => {
      inView && handleInView(messageId as number);
    },
    skip,
    threshold: isAndroid ? 0.95 : 1,
    triggerOnce: true,
  });

  const className = clsx(
    styles['message'],
    from === MessageFrom.FLUENT ? styles['fluent'] : styles['user'],
    !!isSending && styles['sending'],
    unread && styles['unread'],
  );

  return { className, dateFooter, dateHeader, dateTime, ref };
};

export default useMessage;
