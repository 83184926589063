import { Middleware } from 'redux';

import { keyboardEventAction, keyboardInitAction } from './actions';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { keyboardPluginInit } from './plugin';
import { KeyboardAction } from './constants';
import { KeyboardActionType } from './types';

const keyboardMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | KeyboardActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(keyboardInitAction());
        break;

      case KeyboardAction.INIT:
        keyboardPluginInit(payload => dispatch(keyboardEventAction(payload)));
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default keyboardMiddleware;
