export enum DialogAction {
  DESTROY = 'DIALOG_DESTROY',
  INSERT = 'DIALOG_INSERT',
  HIDE = 'DIALOG_HIDE',
  LOGOUT = 'DIALOG_LOGOUT',
}

export enum DialogType {
  ALERT = 'alert',
  CONFIRM = 'confirm',
  NOTIFICATIONS = 'notifications',
}
