import { videoPluginInitAction } from '../../videoPluginAction';
import { videoCloseAction, videoDestroyAction } from '../../';
import { modalShowAction } from 'modules/modals/actions';
import { ModalType, ModalVideo } from 'modules/modals';
import { Dispatch } from 'modules/redux';
import getHandlers from './getHandlers';

//TODO
//eslint-disable-next-line
//import { middlewareNavigate } from 'modules/router';
//eslint-disable-next-line
//import { authLogoutAction } from 'modules/auth';

type ShowVideo = (props: {
  dispatch: Dispatch;
  isCapacitor: boolean;
  video: ModalVideo['payload']['video'];
}) => string;

const showVideo: ShowVideo = ({ dispatch, isCapacitor, ...payload }) => {
  const modalId = dispatch(
    modalShowAction({
      onClose: () => dispatch(videoCloseAction()),
      onDestroy: () => dispatch(videoDestroyAction()),
      type: ModalType.VIDEO,
      payload,
      placeholder: isCapacitor,
    }),
  ) as string;

  isCapacitor &&
    dispatch(
      videoPluginInitAction({
        ...getHandlers({ dispatch, modalId, payload }),
        ...payload,
        modalId,
      }),
    );

  // setTimeout(() => {
  //   //dispatch(authLogoutAction());
  //   middlewareNavigate(dispatch, '/2486272/team');
  // }, 7000);

  return modalId;
};

export default showVideo;
