import React from 'react';

import Messages, { MessagesControls } from 'components/mobile/Messages';
import { UseMessagesRoute } from 'modules/states/messages';
import Main from 'components/mobile/Main';

type TemplateProps = ReturnType<UseMessagesRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  handleError,
  handleSubmit,
  isError,
  isLoading,
  isSending,
  scrollRef,
  ...props
}) => {
  return (
    <React.Fragment>
      <Main
        handleError={handleError}
        isError={isError}
        isLoading={isLoading}
        scrollRef={scrollRef}>
        <Messages {...props} />
      </Main>
      <MessagesControls
        handleSubmit={handleSubmit}
        isError={isError}
        isLoading={isLoading}
        isSending={isSending}
      />
    </React.Fragment>
  );
};

export default React.memo(Template);
