import { notificationsPluginInit } from '../../plugin';
import { notificationsReceivedAction } from '../index';
import { isError } from 'modules/utils';
import {
  notificationsRegisterFailureAction,
  notificationsRegisterSuccessAction,
} from '../notificationsRegisterAction';
import {
  NotificationsInitAction,
  OnRegistrationError,
  OnRegistration,
  OnReceived,
} from './types';

export const notificationsInitAction: NotificationsInitAction = () => {
  return async (dispatch, getState) => {
    if (!getState().platform.isCapacitor) return;

    const handleError = (error: unknown) =>
      dispatch(
        notificationsRegisterFailureAction(
          isError(error) ? error : new Error('Unexpected Error'),
        ),
      );

    try {
      const onRegistration: OnRegistration = token =>
        !!getState().auth.data?.token &&
        dispatch(notificationsRegisterSuccessAction(token));

      const onRegistrationError: OnRegistrationError = error =>
        !!getState().auth.data?.token && handleError(error);

      const onReceived: OnReceived = notification =>
        !!getState().auth.data?.token &&
        dispatch(notificationsReceivedAction(notification));

      notificationsPluginInit({
        onRegistrationError,
        onRegistration,
        onReceived,
      });
    } catch (error) {
      !!getState().auth.data?.token && handleError(error);
    }
  };
};
