import { Messages, MessagesState } from '../types';

type FindMessages = (
  array: MessagesState['data'],
  caseId: number | null,
) => Messages | undefined;

const findMessages: FindMessages = (array, caseId) =>
  array.find(item => item.caseId === caseId);

export default findMessages;
