import React, { useMemo } from 'react';

import MessageFooter from '../../components/MessageFooter';
import MessageHeader from '../../components/MessageHeader';
import MessageBody from '../../components/MessageBody';
import { MessageTemplateDefaultProps } from './types';
import { MessageFrom } from 'modules/states/messages';
import { htmlSafe } from 'modules/utils';

const allowLinks = false;

const MessageTemplateDefault: React.FunctionComponent<
  MessageTemplateDefaultProps
> = ({
  className,
  dateFooter,
  dateHeader,
  dateTime,
  forwardedRef,
  message: { from, name, text },
  showHeader,
}) => {
  const parsed = useMemo(
    () => (allowLinks && from === MessageFrom.FLUENT ? htmlSafe(text) : text),
    [from, text],
  );
  return (
    <article className={className} ref={forwardedRef}>
      <MessageHeader
        dateHeader={dateHeader}
        dateTime={dateTime}
        name={name}
        showHeader={showHeader}
      />
      <MessageBody text={parsed}>
        <MessageFooter dateFooter={dateFooter} dateTime={dateTime} />
      </MessageBody>
    </article>
  );
};

export default React.memo(MessageTemplateDefault);
