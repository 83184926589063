import React from 'react';
import clsx from 'clsx';

import { ReactComponent as Chevron } from 'modules/theme/images/icons/modal/chevron.svg';
import { StatusStep } from 'modules/states/status';
import { Case } from 'modules/states/cases';
import Image from 'components/shared/Image';
import { formatDate } from 'modules/date';
import useCaseItem from './hooks';

import styles from './styles.module.scss';

type CaseItemProps = Case & {
  disabled: boolean;
  onClick: (caseId: number) => void;
  selected: boolean;
};

const CaseItem: React.FunctionComponent<CaseItemProps> = ({
  advance,
  caseId,
  date,
  disabled,
  isCaseActive,
  lender,
  lenderLogo,
  onClick,
  selected,
  step,
  type,
}) => {
  const { handleClick, handleKeyDown, handleMouseDown, label } = useCaseItem({
    caseId,
    disabled,
    onClick,
    step,
  });
  const classNames = clsx(
    styles['case-item'],
    disabled && styles.disabled,
    selected && styles.selected,
    step === StatusStep.CANCELLED && styles['cancelled'],
    step === StatusStep.DECLINED && styles['declined'],
    step === StatusStep.STEP5 && styles['completed'],
    !isCaseActive && styles['inactive'],
    !![
      StatusStep.STEP1,
      StatusStep.STEP2,
      StatusStep.STEP3,
      StatusStep.STEP4,
    ].includes(step) &&
      isCaseActive &&
      styles['active'],
  );
  return (
    <li
      className={classNames}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      role="button"
      tabIndex={disabled ? -1 : 0}>
      <h4>
        {type}
        <span></span>
      </h4>
      <div>
        {lenderLogo ? (
          <Image alt={lender} fallback={<p>{lender}</p>} src={lenderLogo} />
        ) : (
          <p>{lender}</p>
        )}
      </div>
      <div>
        <span>{label}</span>
      </div>
      <time dateTime={formatDate(date, 'YYYY-MM-DD HH:mm:ss')}>
        {step === StatusStep.STEP5 ? 'Completed on ' : 'Last updated '}
        {formatDate(date, 'DD/MM/YYYY')}
      </time>
      <p>
        <span>
          {advance}
          <Chevron />
        </span>
      </p>
      <hr />
    </li>
  );
};

export default React.memo(CaseItem);
