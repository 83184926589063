import {
  NotificationsPluginRequestPermissions,
  NotificationsPluginRegister,
  NotificationsPluginStatus,
  NotificationsPluginInit,
} from './types';
import {
  requestPermissions,
  checkPermissions,
  addListeners,
  register,
} from './utils';

export const notificationsPluginInit: NotificationsPluginInit = addListeners;

export const notificationsPluginRequestPermissions: NotificationsPluginRequestPermissions =
  requestPermissions;

export const notificationsPluginRegister: NotificationsPluginRegister =
  register;

export const notificationsPluginStatus: NotificationsPluginStatus =
  checkPermissions;
