import { MessagesGetActionApiResponseItem } from '../../types';
import { convertStringToDate } from 'modules/redux/utils';
import { MessageFrom } from '../../../../constants';
import { MessageDefault } from '../../../../types';
import { getMessageType } from '../../../../utils';

type NewMessageDefault = (
  data: MessagesGetActionApiResponseItem,
) => MessageDefault | null;

const newMessageDefault: NewMessageDefault = data =>
  data.id
    ? {
        actionId: null,

        date: convertStringToDate(data.date),
        from:
          data.employee === 'Portal' ? MessageFrom.USER : MessageFrom.FLUENT,
        isSending: false,
        isSendingError: false,
        messageId: data.id,
        name: data.employee === 'Robot' ? 'Fluent Bot' : data.employee || '',
        placeholder: false,
        read: !!data.clientRead,
        text: data.message || '',
        type: getMessageType(data.callToAction?.action),
      }
    : null;

export default newMessageDefault;
