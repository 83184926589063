import { NotificationsPayload } from 'modules/services/notifications';
import { badgePluginClear, badgePluginSet } from './plugin';
import { RootState } from 'modules/redux';

type UpdateBadge = (
  getState: () => RootState,
  payload?: NotificationsPayload,
) => Promise<void>;

export const updateBadge: UpdateBadge = async (getState, payload) => {
  const caseId = getState().cases.caseId;

  const count = caseId
    ? !!payload &&
      caseId === payload.data.caseId &&
      !isNaN(Number(payload.data.count))
      ? Number(payload.data.count)
      : getState().messages.data.find(messages => messages.caseId === caseId)
          ?.stats.unread || 0
    : 0;

  count > 0 ? badgePluginSet(count) : badgePluginClear();
};
