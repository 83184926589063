import React from 'react';

import { UseDocuploadModal } from 'modules/docupload';
import Item from './Item';

import styles from './styles.module.scss';

type ListProps = {
  disabled: boolean;
  files: ReturnType<UseDocuploadModal>['data'];
  handleDelete: ReturnType<UseDocuploadModal>['handleDelete'];
};

const List: React.FunctionComponent<ListProps> = ({
  disabled,
  files,
  handleDelete,
}) => {
  return (
    <div className={styles['modal-document-upload-list']}>
      <div>
        <ul>
          {files.map(file => {
            return (
              <Item
                key={file.id}
                disabled={disabled}
                handleDelete={handleDelete}
                file={file}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(List);
