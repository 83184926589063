import React, { createContext } from 'react';

import { shallowEqual, useSelector } from 'modules/redux';
import { initialState } from './utils';
import { Platform } from './types';

type ContextWrapperProps = {
  children: React.ReactNode;
};

export const Context = createContext<Platform>(initialState());

const ContextWrapper: React.FunctionComponent<ContextWrapperProps> = ({
  children,
}) => {
  const platform = useSelector(state => state.platform, shallowEqual);
  return <Context.Provider value={platform}>{children}</Context.Provider>;
};

export default React.memo(ContextWrapper);
