import { CertificateInitAction, CertificateResultAction } from './types';
import { CertificateAction } from './constants';

export const certificateInitAction: CertificateInitAction = () => ({
  type: CertificateAction.INIT,
});

export const certificateResultAction: CertificateResultAction = status => ({
  type: CertificateAction.RESULT,
  status,
});
