import { produce } from 'immer';

import { getImageMultiplier, getImageQuality, updateConfig } from './utils';
import { Config, ConfigActionType } from './types';
import { ConfigAction } from './constants';

const initialState = (): Config => {
  return {
    actionId: null,
    data: {
      appMaintenanceMessage: null,
      appMinimumVersion: null,
      imageMultiplier: getImageMultiplier(),
      imageQuality: getImageQuality(),
      onfidoUI: false,
    },
    isDirty: false,
    isError: false,
    isLoading: false,
    timer: null,
  };
};

function configReducer(state = initialState(), action: ConfigActionType) {
  switch (action.type) {
    case ConfigAction.GET_FAILURE:
      return produce(state, (draftState: Config) => {
        draftState.actionId = null;
        draftState.isDirty = true;
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case ConfigAction.GET_STARTED:
      return produce(state, (draftState: Config) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isLoading = action.controller || true;
      });

    case ConfigAction.GET_SUCCESS:
      return produce(state, (draftState: Config) => {
        draftState.actionId = null;
        draftState.isDirty = true;
        draftState.isError = false;
        draftState.isLoading = false;
        draftState.data = updateConfig(action.data);
      });

    case ConfigAction.POLLING_STARTED:
      return produce(state, (draftState: Config) => {
        draftState.timer = action?.timer || null;
      });

    case ConfigAction.POLLING_ENDED:
      return produce(state, (draftState: Config) => {
        draftState.timer = null;
      });

    default:
      return state;
  }
}

export default configReducer;
