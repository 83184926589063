import { useCallback } from 'react';

import { UseHandleGetError } from './types';
import {
  onfidoResetErrorAction,
  onfidoGetRetryAction,
} from 'modules/onfido/actions';

const useHandleGetError: UseHandleGetError = ({
  showDialogConfirm,
  showDialogAlert,
  dispatch,
}) => {
  const handleError = useCallback(() => {
    if (showDialogAlert && showDialogConfirm)
      showDialogConfirm({
        message: 'An error has occurred. Try again?',
        onConfirm: () => dispatch(onfidoGetRetryAction()),
        onCancel: () => dispatch(onfidoResetErrorAction()),
        title: 'MyFluent',
      });
  }, [dispatch, showDialogAlert, showDialogConfirm]);

  return handleError;
};

export default useHandleGetError;
