import { StatusVideos, StatusVideo, StatusStep } from 'modules/states/status';
import { CaseType } from 'modules/states/cases';

type GetStatusVideo = (step: StatusStep, type: CaseType) => StatusVideo | null;

const getStatusVideo: GetStatusVideo = (step, type) =>
  StatusVideos.find(item => item.step === step)?.type.find(
    item => item.type === type,
  ) || null;

export default getStatusVideo;
