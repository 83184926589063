import { AuthBiometricError } from '../../../constants';
import { isError } from 'modules/utils';

type GetError = (error: unknown) => Error;

const getError: GetError = error => {
  switch (true) {
    case isError(error) &&
      Object.values(AuthBiometricError).includes(
        error.message as AuthBiometricError,
      ):
      return error as Error;
    default:
      return new Error(AuthBiometricError.UNAVAILABLE);
  }
};

export default getError;
