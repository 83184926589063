import { formatCurrency, formatPercentage } from 'modules/utils';
import { LoanData, LoanGetActionApiResponse } from '../types';

type UpdateLoan = (data: LoanGetActionApiResponse['data']) => LoanData;

const updateLoan: UpdateLoan = data => ({
  lender: {
    lenderDescription: data.lenderDescription || undefined,
    lenderLogo: data.lenderLogo || undefined,
    lenderName: data.lenderName || '',
  },
  loan: {
    loanAdvance: formatCurrency(data.loanAdvance),
    loanAnnualRate: formatPercentage(data.loanAnnualRate),
    loanApr: formatPercentage(data.loanApr),
    loanInitialRate: formatPercentage(data.loanInitialRate),
    loanInitialRepayment: formatCurrency(data.loanInitialRepayment),
    loanInitialTerm: data.loanInitialTerm || 0,
    loanInterestRateType: data.interestRateType || '',
    loanRepayment: formatCurrency(data.loanRepayment),
    loanStandardTerm:
      data.loanTerm && data.loanInitialTerm
        ? data.loanTerm - data.loanInitialTerm
        : undefined,
    loanTerm: data.loanTerm || 0,
  },
});

export default updateLoan;
