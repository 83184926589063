import { matchPath } from 'react-router-dom';

import { ROUTE } from 'modules/router';

type GetCaseIdFromPath = (path: string) => number | null;

const getCaseIdFromPath: GetCaseIdFromPath = path => {
  let caseId: string | undefined;

  switch (true) {
    case !!matchPath(ROUTE.CONTACT_CASE, path):
      caseId = matchPath(ROUTE.CONTACT_CASE, path)?.params.caseId;
      break;

    case !!matchPath(ROUTE.DEBUG_CASE, path):
      caseId = matchPath(ROUTE.DEBUG_CASE, path)?.params.caseId;
      break;

    case !!matchPath(ROUTE.DOCUMENTS_CASE, path):
      caseId = matchPath(ROUTE.DOCUMENTS_CASE, path)?.params.caseId;
      break;

    case !!matchPath(ROUTE.LOAN_CASE, path):
      caseId = matchPath(ROUTE.LOAN_CASE, path)?.params.caseId;
      break;

    case !!matchPath(ROUTE.MESSAGES_CASE, path):
      caseId = matchPath(ROUTE.MESSAGES_CASE, path)?.params.caseId;
      break;

    case !!matchPath(ROUTE.STATUS_CASE, path):
      caseId = matchPath(ROUTE.STATUS_CASE, path)?.params.caseId;
      break;

    case !!matchPath(ROUTE.TEAM_CASE, path):
      caseId = matchPath(ROUTE.TEAM_CASE, path)?.params.caseId;
      break;
  }

  return !!caseId && !Number.isNaN(Number(caseId)) ? Number(caseId) : null;
};

export default getCaseIdFromPath;
