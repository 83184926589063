import debounce from 'debounce';
import { Middleware } from 'redux';

import { MessagesActionType, MessagesAction } from 'modules/states/messages';
import { AuthAction, AuthActionType } from 'modules/auth';
import { Dispatch, RootState } from 'modules/redux';
import { updateBadge } from './utils';
import {
  NotificationsActionType,
  NotificationsAction,
} from 'modules/services/notifications';

const debounceAction = debounce(updateBadge, 2000, { immediate: false });

const badgeMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (
    action: AuthActionType | MessagesActionType | NotificationsActionType,
  ) => {
    let nextAction;

    switch (action.type) {
      case NotificationsAction.RECEIVED:
        nextAction = next(action);
        getState().platform.isCapacitor &&
          updateBadge(getState, action.notification);
        break;

      case MessagesAction.GET_SUCCESS:
        nextAction = next(action);
        getState().platform.isCapacitor && updateBadge(getState);
        break;

      case MessagesAction.READ_SUCCESS:
        nextAction = next(action);
        getState().platform.isCapacitor && debounceAction(getState);
        break;

      case AuthAction.LOGOUT:
        nextAction = next(action);
        debounceAction.clear();
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default badgeMiddleware;
