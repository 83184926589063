import { produce } from 'immer';

import { Onfido, OnfidoActionType } from './types';
import { OnfidoAction } from './constants';
import { initialState } from './utils';
import {
  onfidoPluginReducer,
  onfidoPostReducer,
  onfidoShowReducer,
  onfidoGetReducer,
} from './actions';

function onfidoReducer(state = initialState(), action: OnfidoActionType) {
  switch (action.type) {
    case OnfidoAction.GET_FAILURE:
    case OnfidoAction.GET_STARTED:
    case OnfidoAction.GET_SUCCESS:
      return onfidoGetReducer(state, action);

    case OnfidoAction.PLUGIN_FAILURE:
    case OnfidoAction.PLUGIN_SUCCESS:
    case OnfidoAction.PLUGIN_CANCEL:
      return onfidoPluginReducer(state, action);

    case OnfidoAction.POST_FAILURE:
    case OnfidoAction.POST_STARTED:
    case OnfidoAction.POST_SUCCESS:
      return onfidoPostReducer(state, action);

    case OnfidoAction.SHOW_FAILURE:
    case OnfidoAction.SHOW_STARTED:
    case OnfidoAction.SHOW_SUCCESS:
      return onfidoShowReducer(state, action);

    case OnfidoAction.CANCEL:
      return state;

    case OnfidoAction.CLOSE:
      return state;

    case OnfidoAction.DESTROY:
      return state.isError || state.isSending || state.isSendingError
        ? produce(state, (draftState: Onfido) => {
            draftState.modalId = null;
          })
        : initialState();

    case OnfidoAction.LOGOUT:
      return initialState();

    case OnfidoAction.RESET_ERROR:
      return initialState();

    default:
      return state;
  }
}

export default onfidoReducer;
