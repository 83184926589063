import findMessages from '../../../utils/findMessages';
import { MessagesPostActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';

type IsPresent = (props: {
  actionId: string;
  request: MessagesPostActionApiRequest;
  state: RootState;
}) => boolean;

const isPresent: IsPresent = ({ actionId, request, state }) =>
  actionId === findMessages(state.messages.data, request.caseId)?.actionId;

export default isPresent;
