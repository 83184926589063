import { produce } from 'immer';

import { ThemeAction, ThemeColor } from './constants';
import { Theme, ThemeActionType } from './types';

const initialState = (): Theme => {
  return {
    theme: ThemeColor.LIGHT,
  };
};

function notificationsReducer(state = initialState(), action: ThemeActionType) {
  switch (action.type) {
    case ThemeAction.INIT:
      return produce(state, (draftState: Theme) => {
        draftState.theme = action.theme;
      });

    case ThemeAction.CHANGE:
      return produce(state, (draftState: Theme) => {
        draftState.theme = action.theme;
      });

    default:
      return state;
  }
}

export default notificationsReducer;
