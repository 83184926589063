import { shallowEqual, useSelector } from 'modules/redux';
import { MessageStats } from '../types';

export type UseMessagesStats = (caseId: number | null) => MessageStats;

const useMessagesStats: UseMessagesStats = caseId => {
  const messages = useSelector(
    state => state.messages.data.find(items => items.caseId === caseId),
    shallowEqual,
  );
  return {
    read: messages?.stats.read || 0,
    total: messages?.stats.total || 0,
    unread: messages?.stats.unread || 0,
  };
};

export default useMessagesStats;
