import { useCallback } from 'react';

import { docuploadPluginPreviewAction } from '../../../actions';
import { HandlePreview, UseHandlePreview } from './types';

const useHandlePreview: UseHandlePreview = dispatch => {
  const handlePreview: HandlePreview = useCallback(
    () => dispatch(docuploadPluginPreviewAction()),
    [dispatch],
  );

  return handlePreview;
};

export default useHandlePreview;
