import { NotificationsType } from '../constants';
import { NotificationsPayload } from '../types';
import { Dispatch } from 'modules/redux';
import {
  dialogDestroyAction,
  dialogShowAction,
  DialogType,
} from 'modules/dialogs';

type ShowDialog = (
  dispatch: Dispatch,
  notification: NotificationsPayload,
) => void;

const showDialog: ShowDialog = (dispatch, notification) => {
  if (!notification.data.tap) {
    const dialogId = dispatch(
      dialogShowAction({
        type: DialogType.ALERT,
        title: 'MyFluent',
        message:
          notification.data.messageCategory === NotificationsType.NOTE
            ? 'You have received a new message'
            : 'You have received a status update',
        onConfirm: () => {
          dispatch(dialogDestroyAction(dialogId));
        },
      }),
    );
  }
};

export default showDialog;
