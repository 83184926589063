import { useCallback } from 'react';

import { videoShowAction } from './actions';
import { VideoPayload } from './types';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';
import { ModalType } from 'modules/modals';

export type UseVideo = (video?: VideoPayload | null) => {
  handleVideo: (() => void) | undefined;
  isPlaying: boolean;
};

const useVideo: UseVideo = video => {
  const modal = !!useSelector(
    state => state.modals.find(modal => modal.type === ModalType.VIDEO),
    shallowEqual,
  );

  const dispatch: Dispatch = useDispatch();

  const handleVideo = useCallback(
    () => !modal && video && dispatch(videoShowAction({ video })),
    [dispatch, modal, video],
  );

  return { handleVideo: video ? handleVideo : undefined, isPlaying: modal };
};

export default useVideo;
