import { useCallback } from 'react';

import { usePlatformContext } from 'modules/services/platform';
import { ShowModal, UseModalVideo } from './types';
import { ModalType } from '../../constants';
import useModal from '../useModal';

const useModalVideo: UseModalVideo = () => {
  const [showModalDefault, hideModal] = useModal();
  const { isCapacitor } = usePlatformContext();

  const showModal: ShowModal = useCallback(
    modal =>
      showModalDefault
        ? showModalDefault({
            ...modal,
            placeholder: isCapacitor,
            type: ModalType.VIDEO,
          })
        : undefined,
    [isCapacitor, showModalDefault],
  );

  const result: ReturnType<UseModalVideo> = [
    showModalDefault ? showModal : undefined,
    hideModal,
  ];
  result.hideModal = hideModal;
  result.showModal = showModalDefault ? showModal : undefined;
  return result;
};

export default useModalVideo;
