import React from 'react';

import { ModalDocuploadBodyProps } from './types';
import Info from './Info';
import List from './List';
import Sending from './Sending';

const ModalDocuploadBody: React.FunctionComponent<ModalDocuploadBodyProps> = ({
  disabled,
  files,
  handleDelete,
  isSending,
  isSendingProgress,
}) => {
  return isSending ? (
    <Sending isSendingProgress={isSendingProgress} />
  ) : files.length ? (
    <List disabled={disabled} files={files} handleDelete={handleDelete} />
  ) : (
    <Info disabled={disabled} />
  );
};

export default React.memo(ModalDocuploadBody);
