import { registerPlugin } from '@capacitor/core';

type Plugin = {
  addListener: (
    type: string,
    listener: (data: Record<string, unknown>) => void,
  ) => void;
  getSafeArea: () => Promise<{ bottom: number; top: number }>;
  reset: () => Promise<void>;
};

const plugin = registerPlugin<Plugin>('FluentStatusBar');

export default plugin;
