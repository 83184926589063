import { produce } from 'immer';

import { AuthAction, AuthBiometricError } from '../../constants';
import { Auth, AuthActionType } from '../../types';

function authBiometricQuestionReducer(state: Auth, action: AuthActionType) {
  switch (action.type) {
    case AuthAction.BIOMETRIC_QUESTION_FAILURE:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isLoading = false;

        action.error.message !== AuthBiometricError.ANSWERED &&
          (draftState.isBiometric = false);
      });

    case AuthAction.BIOMETRIC_QUESTION_STARTED:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = action.actionId;
        draftState.isLoading = true;
      });

    case AuthAction.BIOMETRIC_QUESTION_SUCCESS:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isLoading = false;

        draftState.isBiometric = action.status;
      });

    default:
      return state;
  }
}

export default authBiometricQuestionReducer;
