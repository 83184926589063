import { Docupload, DocuploadActionType } from '../../types';
import { DocuploadAction } from '../../constants';
import {
  docuploadPluginPreviewReducer,
  docuploadPluginCameraReducer,
  docuploadPluginDeleteReducer,
  docuploadPluginInputReducer,
  docuploadPluginFileReducer,
} from './actions';

function docuploadPluginReducer(state: Docupload, action: DocuploadActionType) {
  switch (action.type) {
    case DocuploadAction.PLUGIN_CAMERA_FAILURE:
    case DocuploadAction.PLUGIN_CAMERA_STARTED:
    case DocuploadAction.PLUGIN_CAMERA_SUCCESS:
      return docuploadPluginCameraReducer(state, action);

    case DocuploadAction.PLUGIN_DELETE_FAILURE:
    case DocuploadAction.PLUGIN_DELETE_STARTED:
    case DocuploadAction.PLUGIN_DELETE_SUCCESS:
      return docuploadPluginDeleteReducer(state, action);

    case DocuploadAction.PLUGIN_FILE_FAILURE:
    case DocuploadAction.PLUGIN_FILE_STARTED:
    case DocuploadAction.PLUGIN_FILE_SUCCESS:
      return docuploadPluginFileReducer(state, action);

    case DocuploadAction.PLUGIN_INPUT_FAILURE:
    case DocuploadAction.PLUGIN_INPUT_STARTED:
    case DocuploadAction.PLUGIN_INPUT_SUCCESS:
      return docuploadPluginInputReducer(state, action);

    case DocuploadAction.PLUGIN_PREVIEW_FAILURE:
    case DocuploadAction.PLUGIN_PREVIEW_STARTED:
    case DocuploadAction.PLUGIN_PREVIEW_SUCCESS:
      return docuploadPluginPreviewReducer(state, action);

    default:
      return state;
  }
}

export default docuploadPluginReducer;
