import { useCallback, useMemo } from 'react';

import { statusGetAction } from '../actions';
import { Case } from '../../cases';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseStatusSelector = (caseId: number | null) => {
  data: Case | null;
  handleRequest: () => void;
};

const useStatusSelector: UseStatusSelector = caseId => {
  const { data: cases } = useSelector(state => state.cases, shallowEqual);
  const dispatch: Dispatch = useDispatch();

  const data = useMemo(
    () => cases?.find(item => item.caseId === caseId) || null,
    [caseId, cases],
  );

  const handleRequest = useCallback(
    () => !!caseId && dispatch(statusGetAction({ caseId })),
    [caseId, dispatch],
  );

  return {
    data,
    handleRequest,
  };
};

export default useStatusSelector;
