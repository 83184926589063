import React from 'react';

import NoTeamMembers from 'components/mobile/Team/NoTeamMembers';
import { UseTeamRoute } from 'modules/states/team';
import Main from 'components/mobile/Main';
import Team from 'components/mobile/Team';

type TemplateProps = ReturnType<UseTeamRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  data,
  handleError,
  handleMessage,
  isError,
  isLoading,
}) => {
  return (
    <Main
      handleError={handleError}
      isError={isError}
      isLoading={isLoading}
      scroll={false}>
      {data?.adviser || data?.caseManager ? (
        <Team
          adviser={data?.adviser}
          caseManager={data?.caseManager}
          handleMessage={handleMessage}
        />
      ) : (
        <NoTeamMembers />
      )}
    </Main>
  );
};

export default React.memo(Template);
