import { KeyboardEventAction, KeyboardInitAction } from './types';
import { KeyboardAction } from './constants';

export const keyboardEventAction: KeyboardEventAction = payload => ({
  type: KeyboardAction.EVENT,
  ...payload,
});

export const keyboardInitAction: KeyboardInitAction = () => ({
  type: KeyboardAction.INIT,
});
