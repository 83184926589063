import { useEffect, useMemo } from 'react';

import { useAuthForm, useHandleError, useHandleLogin } from './hooks';
import { authBiometricLoginStatusAction } from '../../actions';
import { Dispatch, useDispatch } from 'modules/redux';
import useAuthSelector from '../useAuthSelector';
import { getBiometricLabel } from '../../utils';
import { UseAuthRoute } from './types';

const useAuthRoute: UseAuthRoute = () => {
  const { isBiometric, isError, isLoading, username } = useAuthSelector();
  const dispatch: Dispatch = useDispatch();
  useHandleError({ dispatch, isError });

  const label = useMemo(
    () => (isBiometric ? getBiometricLabel(isBiometric) : 'Password'),
    [isBiometric],
  );

  const { handleLogin, handleReset } = useHandleLogin({
    dispatch,
    isBiometric: !!isBiometric,
    username,
  });

  const { defaultValues, schema } = useAuthForm({
    isBiometric: !!isBiometric,
    username,
  });

  useEffect(() => {
    dispatch(authBiometricLoginStatusAction());
  }, [dispatch]);

  return {
    defaultValues,
    handleLogin,
    handleReset,
    isBiometric: !!isBiometric,
    isError,
    isLoading,
    label,
    schema,
  };
};

export default useAuthRoute;
