import { onfidoCloseAction, onfidoDestroyAction } from '../../';
import { modalShowAction } from 'modules/modals/actions';
import { platform } from 'modules/services/platform';
import { OnfidoVariant } from '../../../constants';
import { ModalType } from 'modules/modals';
import { Dispatch } from 'modules/redux';

const { isCapacitor } = platform;

type ShowOnfido = (props: {
  dashboard: boolean;
  caseId: number;
  dispatch: Dispatch;
  finishURL: string;
  messageId: number;
  startURL: string;
  token: string;
  variant: OnfidoVariant;
}) => string;

const showOnfido: ShowOnfido = ({ dispatch, ...payload }) =>
  dispatch(
    modalShowAction({
      onClose: () => dispatch(onfidoCloseAction()),
      onDestroy: () => dispatch(onfidoDestroyAction()),
      payload,
      placeholder: isCapacitor,
      type: ModalType.ONFIDO,
    }),
  );

export default showOnfido;
