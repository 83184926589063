import { Dialog as CapacitorDialog } from '@capacitor/dialog';

import { platform } from 'modules/services/platform';
import {
  DialogPluginShowAlertCapacitor,
  DialogPluginShowAlertBrowser,
  DialogPluginShowAlert,
} from './types';

const { isCapacitor } = platform;

const dialogPluginShowAlertCapacitor: DialogPluginShowAlertCapacitor = async ({
  onConfirm,
  buttonTitle = 'Close',
  message,
  title,
}) => {
  try {
    await CapacitorDialog.alert({ buttonTitle, message, title });
    !!onConfirm && onConfirm();
  } catch {
    !!onConfirm && onConfirm();
  }
};

const dialogPluginShowAlertBrowser: DialogPluginShowAlertBrowser = ({
  message,
  onConfirm,
}) => {
  window.alert(message);
  !!onConfirm && onConfirm();
};

const dialogPluginShowAlert: DialogPluginShowAlert = ({
  confirmLabel: buttonTitle,
  message,
  onConfirm,
  title,
}) =>
  isCapacitor
    ? dialogPluginShowAlertCapacitor({ buttonTitle, message, onConfirm, title })
    : dialogPluginShowAlertBrowser({ message, onConfirm });

export default dialogPluginShowAlert;
