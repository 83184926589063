import { DocumentsData, DocumentsGetActionApiResponse } from '../types';
import newDocument from './newDocument';

type UpdateDocuments = (
  data: DocumentsGetActionApiResponse['data'],
) => DocumentsData;

const updateDocuments: UpdateDocuments = data => {
  const documents: DocumentsData = [];
  Array.isArray(data) &&
    data.forEach(document => newDocument(documents, document));
  return documents;
};

export default updateDocuments;
