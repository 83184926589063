import React, { useMemo } from 'react';

import { ExternalLink, ExternalLinkTarget } from 'components/shared/Hyperlinks';
import { ReactComponent as Logo } from 'modules/theme/images/logos/fluent.svg';
import { QuestionText } from 'components/shared/Questions';
import Form from 'components/shared/Form';
import { LoginProps } from './types';
import {
  InputAutocomplete,
  InputMode,
  InputType,
} from 'components/shared/Inputs';
import Button, {
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from 'components/shared/Button';

import styles from './styles.module.scss';

const Login: React.FunctionComponent<LoginProps> = ({
  defaultValues,
  handleLogin,
  handleReset,
  isBiometric,
  isError,
  isLoading,
  label,
  schema,
}) => {
  const disabled = !!isError || isLoading;
  const text = useMemo(
    () =>
      isBiometric
        ? `Login using your ${label}`
        : 'Please enter your account details',
    [isBiometric, label],
  );
  return (
    <Form
      className={styles['login-form']}
      defaultValues={defaultValues}
      onSubmit={handleLogin}
      schema={schema}>
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <Logo />
          <div>
            <h1>Welcome to MyFluent</h1>
            <p>{text}</p>
          </div>
          <div>
            <QuestionText
              autoComplete={InputAutocomplete.EMAIL}
              control={control}
              disabled={isBiometric}
              inputMode={InputMode.EMAIL}
              label="Email"
              name="username"
              readOnly={disabled}
              type={InputType.EMAIL}
            />
            <QuestionText
              autoComplete={InputAutocomplete.CURRENT_PASSWORD}
              control={control}
              disabled={isBiometric}
              label={label}
              name={isBiometric ? '' : 'password'}
              readOnly={disabled}
              type={isBiometric ? InputType.TEXT : InputType.PASSWORD}
            />
          </div>
          <div>
            <Button
              label="Login"
              disabled={disabled || isFormSubmitting}
              size={ButtonSize.LARGE}
              style={ButtonStyle.PRIMARY_INVERTED}
              type={ButtonType.SUBMIT}
            />
          </div>
          <div>
            {isBiometric ? (
              <Button
                disabled={disabled || isFormSubmitting}
                label="Login by Password"
                onClick={handleReset}
              />
            ) : (
              <ExternalLink
                disabled={disabled || isFormSubmitting}
                href="https://www.myfluent.co.uk/Account/ForgotPassword/"
                label="Forgot Password?"
                target={ExternalLinkTarget.BLANK}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(Login);
