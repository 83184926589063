import { JailbreakPluginInit, JailbreakPluginCheck } from './types';
import { platform } from 'modules/services/platform';
import { JailbreakStatus } from './constants';
import { isJailbreakStatus } from './utils';

const { isAndroid, isCapacitor } = platform;

const jailbreakPluginCheck: JailbreakPluginCheck = () =>
  new Promise(function (resolve, reject) {
    try {
      window.IRoot.isRooted(
        result =>
          result === false && isAndroid
            ? window.IRoot.isRootedWithBusyBox(
                result =>
                  result
                    ? reject(JailbreakStatus.NOT_SECURE)
                    : resolve(JailbreakStatus.SECURE),
                error => reject(JailbreakStatus.NOT_SECURE),
              )
            : result
              ? reject(JailbreakStatus.NOT_SECURE)
              : resolve(JailbreakStatus.SECURE),
        error => reject(JailbreakStatus.NOT_SECURE),
      );
    } catch {
      process.env.REACT_APP_CAPACITOR
        ? reject(JailbreakStatus.NOT_SECURE)
        : resolve(JailbreakStatus.SECURE);
    }
  });

export const jailbreakPluginInit: JailbreakPluginInit = async handler => {
  try {
    if (!isCapacitor) return;
    handler(await jailbreakPluginCheck());
  } catch (error) {
    handler(isJailbreakStatus(error) ? error : JailbreakStatus.NOT_SECURE);
  }
};
