import React from 'react';

import { usePlatformContext } from 'modules/services/platform';
import { LinkAndroid, LinkIOS, LinkWebsite } from './links';
import { NetworkStatus } from 'modules/services/network';
import { cleanString } from 'modules/utils';
import { ErrorMessageProps } from './types';
import useStatus from './hooks';

import styles from './styles.module.scss';

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  isCertificateSecure,
  isExpired,
  isJailbreakSecure,
}) => {
  const { isAndroid } = usePlatformContext();
  const status = useStatus();
  return (
    <div className={styles['error-message']}>
      <div>
        {(() => {
          switch (true) {
            case !!isExpired && typeof isExpired === 'boolean' && isAndroid:
              return (
                <React.Fragment>
                  This version of MyFluent is out of date, please update to the
                  latest version. <LinkAndroid />
                </React.Fragment>
              );

            case !!isExpired && typeof isExpired === 'boolean' && !isAndroid:
              return (
                <React.Fragment>
                  This version of MyFluent is out of date, please update to the
                  latest version. <LinkIOS />
                </React.Fragment>
              );

            case !!isExpired && typeof isExpired === 'string':
              return (
                <React.Fragment>
                  {cleanString(isExpired as string)}
                </React.Fragment>
              );

            case !isCertificateSecure && status === NetworkStatus.OFFLINE:
              return (
                <React.Fragment>
                  We are unable to establish a connection to our server. Please
                  check your connection and restart the app.
                </React.Fragment>
              );

            case !isCertificateSecure:
              return (
                <React.Fragment>
                  Your connection is not supported for security reasons. Please
                  log on to <LinkWebsite /> instead.
                </React.Fragment>
              );

            case !isJailbreakSecure:
              return (
                <React.Fragment>
                  Your device is not supported for security reasons. Please log
                  on to <LinkWebsite /> instead.
                </React.Fragment>
              );

            default:
              return (
                <React.Fragment>
                  An error has occured. Please log on to <LinkWebsite />{' '}
                  instead.
                </React.Fragment>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default React.memo(ErrorMessage);
