import { StatusProgress, StatusStep } from 'modules/states/status';

type GetStatusProgress = (step: StatusStep) => StatusProgress;

const getStatusProgress: GetStatusProgress = step => {
  switch (step) {
    case StatusStep.CANCELLED:
      return StatusProgress.CANCELLED;
    case StatusStep.DECLINED:
      return StatusProgress.DECLINED;
    case StatusStep.STEP1:
      return StatusProgress.PERCENT_20;
    case StatusStep.STEP2:
      return StatusProgress.PERCENT_40;
    case StatusStep.STEP3:
      return StatusProgress.PERCENT_60;
    case StatusStep.STEP4:
      return StatusProgress.PERCENT_80;
    case StatusStep.STEP5:
      return StatusProgress.PERCENT_100;
    case StatusStep.UNKNOWN:
    default:
      return StatusProgress.UNKNOWN;
  }
};

export default getStatusProgress;
