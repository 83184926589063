import { PushNotifications } from '@capacitor/push-notifications';

import { NotificationsStatus } from '../../constants';
import { isNotificationsStatus } from '../../utils';
import { CheckPermissions } from './types';

const checkPermissions: CheckPermissions = async () => {
  const { receive } = await PushNotifications.checkPermissions();
  return isNotificationsStatus(receive) ? receive : NotificationsStatus.DENIED;
};

export default checkPermissions;
