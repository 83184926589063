import { StatusGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';
import findStatus from './findStatus';

type IsActive = (props: {
  request: StatusGetActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ request, state }) =>
  !!findStatus(state.status, request.caseId)?.isLoading;

export default isActive;
