import React from 'react';

import { InputAutocomplete, InputType } from '../../shared/Inputs';
import { QuestionText } from 'components/shared/Questions';
import Form from 'components/shared/Form';
import { PasswordProps } from './types';
import Button, {
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '../../shared/Button';

import styles from './styles.module.scss';

const Password: React.FunctionComponent<PasswordProps> = ({
  defaultValues,
  handleSubmit,
  isError,
  isLoading,

  schema,
}) => {
  const disabled = !!isError || isLoading;
  return (
    <Form
      className={styles['password-form']}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}>
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <h1>Your Password</h1>
          <p>
            Thank you for logging into MyFluent, as this is your first time
            visiting us, we require you to change your password.
          </p>
          <div>
            <QuestionText
              autoComplete={InputAutocomplete.NEW_PASSWORD}
              control={control}
              label="New Password"
              name="password"
              readOnly={disabled}
              type={InputType.PASSWORD}
            />
            <QuestionText
              autoComplete={InputAutocomplete.NEW_PASSWORD}
              control={control}
              label="Confirm New Password"
              name="confirmPassword"
              readOnly={disabled}
              type={InputType.PASSWORD}
            />
          </div>
          <div>
            <Button
              disabled={disabled || isFormSubmitting}
              label="Update"
              size={ButtonSize.LARGE}
              style={ButtonStyle.PRIMARY_INVERTED}
              type={ButtonType.SUBMIT}
            />
          </div>
          <p>
            Note that it can take 10 minutes before your request is processed.
          </p>
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(Password);
