import { platform } from 'modules/services/platform';
import shouldTrackPageView from './utils';
import Logger from 'modules/logger';
import {
  analyticsPluginTrackPageView,
  analyticsPluginTrackEvent,
  analyticsPluginInit,
} from './plugin';
import {
  AnalyticsTrackPageView,
  AnalyticsTrackEvent,
  AnalyticsInit,
} from './types';

export type { AnalyticsTrackEventPayload } from './types';

const { isAdmin } = platform;

export const analyticsInit: AnalyticsInit = () =>
  !isAdmin && analyticsPluginInit();

export const analyticsTrackEvent: AnalyticsTrackEvent = payload => {
  if (isAdmin) {
    Logger.debug(
      '%caction %c@@analytics/TRACK_EVENT %O',
      'color: gray;',
      'color: inherit; font-weight: bold;',
      payload,
    );
  } else {
    analyticsPluginTrackEvent(payload);
  }
};

export const analyticsTrackPageView: AnalyticsTrackPageView = payload => {
  if (shouldTrackPageView(payload))
    if (isAdmin) {
      Logger.debug(
        '%caction %c@@analytics/PAGE_VIEW %O',
        'color: gray;',
        'color: inherit; font-weight: bold;',
        payload,
      );
    } else {
      analyticsPluginTrackPageView(payload);
    }
};
