import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { getCaseIdFromPath, getRoute, RouterActionType } from 'modules/router';
import { analyticsInit, analyticsTrackPageView } from './';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { analyticsInitAction } from './actions';
import { AnalyticsAction } from './constants';
import { AnalyticsActionType } from './types';

const analyticsMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | AnalyticsActionType | RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(analyticsInitAction());
        break;

      case AnalyticsAction.INIT:
        analyticsInit();
        break;

      case LOCATION_CHANGE: {
        const route = getRoute(action.payload.location.pathname);
        !!route &&
          analyticsTrackPageView({
            caseId: getCaseIdFromPath(action.payload.location.pathname),
            title: route.title,
            path: route?.path.replace('/:caseId', ''),
          });
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default analyticsMiddleware;
