import getStatusProgress from './getStatusProgress';
import getStatusVideo from './getStatusVideo';
import getStatusStep from './getStatusStep';
import isStatusType from './isStatusType';
import findCase from './findCase';
import { Case } from '../types';
import {
  StatusGetActionApiResponse,
  StatusGetActionApiRequest,
} from 'modules/states/status';

type UpdateCasesFromStatus = (
  array: Case[],
  data: StatusGetActionApiResponse['data'],
  request: StatusGetActionApiRequest,
) => void;

const updateCasesFromStatus: UpdateCasesFromStatus = (array, data, request) => {
  const result = findCase(array, request.caseId);
  if (result && isStatusType(data.status)) {
    const step = getStatusStep(data.status);
    result.progress = getStatusProgress(step);
    result.status = data.status;
    result.step = step;
    result.video = getStatusVideo(step, result.type);
  }
};

export default updateCasesFromStatus;
