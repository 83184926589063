import { DocuploadError } from '../constants';
import { isError } from 'modules/utils';

type GetError = (error: unknown) => string;

const getError: GetError = error => {
  if (isError(error))
    switch (error.message) {
      case DocuploadError.CREATE:
        return 'There has been a problem creating your document. Please try again.';

      case DocuploadError.DENIED:
        return 'There has been a problem opening the camera. Please check camera access is enabled.';

      case DocuploadError.EMPTY:
        return 'Please select a file to upload.';

      case DocuploadError.FILE:
        return 'There has been a problem adding the file. Please try again.';

      case DocuploadError.IMAGE:
        return 'There has been a problem adding the image. Please try again.';

      case DocuploadError.NO_FILES:
        return 'Please add some files to continue.';

      case DocuploadError.PDF:
        return 'There has been a problem adding the PDF. Please try again.';

      case DocuploadError.REMOVE:
        return 'There has been a problem removing the file. Please try again.';

      case DocuploadError.TYPE:
        return 'The file you have uploaded is not supported. Please only upload JPG, PNG and PDF files.';

      case DocuploadError.POST:
      case DocuploadError.UPLOAD:
        return 'There has been a problem uploading your document. Please try again.';
    }
  return 'An error has occurred. Please try again later.';
};

export default getError;
