import { Middleware } from 'redux';

import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { AuthAction, AuthActionType } from 'modules/auth';
import { AppAction, AppActionType } from 'modules/app';
import { notificationsPostAction } from './actions';
import { NotificationsActionType } from './types';
import { handleNotification } from './utils';
import {
  notificationsRegisterAction,
  notificationsLogoutAction,
  notificationsInitAction,
} from './actions';
import {
  NotificationsDeviceType,
  NotificationsEndpoint,
  NotificationsAction,
} from './constants';

const notificationsMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | AuthActionType | NotificationsActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(notificationsInitAction());
        break;

      case AppAction.READY: {
        nextAction = next(action);
        const { auth } = getState();
        !!auth.data?.token && dispatch(notificationsRegisterAction());
        break;
      }

      case NotificationsAction.REGISTER_SUCCESS: {
        nextAction = next(action);
        const isAndroid = getState().platform.isAndroid;
        dispatch(
          notificationsPostAction({
            deviceType: isAndroid
              ? NotificationsDeviceType.ANDROID
              : NotificationsDeviceType.IOS,
            PlatformApplicationArn: isAndroid
              ? NotificationsEndpoint.ANDROID
              : NotificationsEndpoint.IOS,
            Token: action.result['value'],
          }),
        );
        break;
      }

      case NotificationsAction.RECEIVED: {
        nextAction = next(action);
        const { auth } = getState();
        !!auth.data?.token &&
          handleNotification({
            dispatch,
            getState,
            notification: action.notification,
          });
        break;
      }

      case AuthAction.LOGOUT:
        nextAction = next(action);
        dispatchAbort(getState().notifications);
        dispatch(notificationsLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default notificationsMiddleware;
