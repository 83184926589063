import React, { useCallback, useEffect, useRef } from 'react';

export const useFocusLock = ({
  isLocked = true,
  rootNode,
}: {
  isLocked?: boolean;
  rootNode: React.RefObject<HTMLElement | HTMLDivElement>;
}) => {
  const focusableItems = useRef<HTMLElement[]>([]);
  const prevFocusedElement = useRef<HTMLElement | null>(null);

  const focusRootNode = useCallback(() => {
    if (rootNode.current) {
      if (document.activeElement instanceof HTMLElement) {
        prevFocusedElement.current = document.activeElement;
        document.activeElement.blur();
      }
      rootNode.current.focus();
    }
  }, [rootNode]);

  const updateFocusableItems = useCallback(() => {
    if (rootNode.current) {
      (focusableItems.current as unknown) = rootNode?.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video, [role="button"]',
      );
    }
  }, [rootNode]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (!focusableItems.current) return;
      const { key, shiftKey } = event;
      const {
        length,
        0: firstItem,
        [length - 1]: lastItem,
      } = focusableItems.current;
      if (isLocked && key === 'Tab') {
        // If focused on last item then focus on first item when tab is pressed
        if (!shiftKey && document.activeElement === lastItem) {
          event.preventDefault();
          firstItem.focus();
          return;
        }
        // If focused on first item then focus on last item when shift + tab is pressed
        if (shiftKey && document.activeElement === firstItem) {
          event.preventDefault();
          lastItem.focus();
          return;
        }
      }
    },
    [isLocked],
  );

  useEffect(() => {
    isLocked && focusRootNode();
  }, [focusRootNode, isLocked]);

  useEffect(() => {
    const observer = new MutationObserver(() => updateFocusableItems());
    updateFocusableItems();
    rootNode.current && observer.observe(rootNode.current, { childList: true });
    return () => {
      observer.disconnect();
    };
  }, [rootNode, updateFocusableItems]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [focusableItems, handleKeyPress]);

  useEffect(() => {
    if (!isLocked && prevFocusedElement && prevFocusedElement.current) {
      prevFocusedElement.current.focus();
    }
  }, [isLocked]);
};
