import { v4 as uuidv4 } from 'uuid';

import { getError, isAllowed, isCurrentAction, showDialog } from './utils';
import { AuthAction, AuthBiometricError } from '../../constants';
import {
  AuthBiometricQuestionFailureAction,
  AuthBiometricQuestionStartedAction,
  AuthBiometricQuestionSuccessAction,
  AuthBiometricQuestionAction,
} from './types';
import {
  isBiometricAnswered,
  getBiometricStatus,
  isBiometricActive,
} from '../../utils';

export const authBiometricQuestionAction: AuthBiometricQuestionAction =
  credentials => {
    return async (dispatch, getState) => {
      const actionId = uuidv4();
      const state = getState();
      try {
        if (!isAllowed(state)) return;

        dispatch(authBiometricQuestionStartedAction({ actionId, credentials }));

        const status = await getBiometricStatus();
        if (!isBiometricActive(status))
          throw new Error(AuthBiometricError.UNAVAILABLE);

        const answered = await isBiometricAnswered(credentials.username);
        if (answered) throw new Error(AuthBiometricError.ANSWERED);

        isCurrentAction(actionId, getState()) &&
          showDialog({
            actionId,
            credentials,
            dispatch,
            getState,
            status,
          });
      } catch (error) {
        isCurrentAction(actionId, getState()) &&
          dispatch(
            authBiometricQuestionFailureAction({
              actionId,
              credentials,
              error: getError(error),
            }),
          );
      }
    };
  };

const authBiometricQuestionStartedAction: AuthBiometricQuestionStartedAction =
  payload => ({
    type: AuthAction.BIOMETRIC_QUESTION_STARTED,
    ...payload,
  });

export const authBiometricQuestionSuccessAction: AuthBiometricQuestionSuccessAction =
  payload => ({
    type: AuthAction.BIOMETRIC_QUESTION_SUCCESS,
    ...payload,
  });

export const authBiometricQuestionFailureAction: AuthBiometricQuestionFailureAction =
  payload => ({
    type: AuthAction.BIOMETRIC_QUESTION_FAILURE,
    ...payload,
  });
