import React, { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { events, eventsWithScroll, timeout } from './constants';
import { sessionTimeoutAction } from 'modules/session/actions';
import { usePlatformContext } from 'modules/services/platform';
import { Dispatch, useDispatch } from 'modules/redux';
import { useAuthSelector } from 'modules/auth';

type SessionProps = {
  children: React.ReactNode;
};

const Session: React.FunctionComponent<SessionProps> = ({ children }) => {
  const { isMacOs, isSafari } = usePlatformContext();
  const { isAuthenticated } = useAuthSelector();
  const dispatch: Dispatch = useDispatch();

  const handleSessionTimeout = useCallback(() => {
    isAuthenticated && dispatch(sessionTimeoutAction());
  }, [dispatch, isAuthenticated]);

  useIdleTimer({
    crossTab: true,
    events: isMacOs && isSafari ? events : eventsWithScroll,
    eventsThrottle: 500,
    onIdle: handleSessionTimeout,
    startOnMount: true,
    stopOnIdle: true,
    timeout,
  });

  return <React.Fragment>{children}</React.Fragment>;
};

export default React.memo(Session);
