export enum InputAutocomplete {
  CURRENT_PASSWORD = 'current-password',
  EMAIL = 'username',
  NEW_PASSWORD = 'new-password',
  OFF = 'off',
}

export enum InputMode {
  DECIMAL = 'decimal',
  EMAIL = 'email',
  NONE = 'none',
  NUMERIC = 'numeric',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  URL = 'url',
}

export enum InputType {
  EMAIL = 'email',
  TEXT = 'text',
  PASSWORD = 'password',
}
