import { notificationsRegisterFailureAction } from '../actions';
import { notificationsPluginRegister } from '../../../plugin';
import { NotificationsStatus } from '../../../constants';
import { Dispatch } from 'modules/redux';
import {
  dialogDestroyAction,
  dialogShowAction,
  DialogPayload,
  DialogType,
} from 'modules/dialogs';

type ShowDialog = (dispatch: Dispatch) => void;

const showDialog: ShowDialog = dispatch => {
  const dialogId = dispatch(
    dialogShowAction({
      type: DialogType.NOTIFICATIONS,

      onCancel: () => {
        dispatch(
          notificationsRegisterFailureAction(
            new Error(NotificationsStatus.DENIED),
          ),
        );
        dispatch(dialogDestroyAction(dialogId));
      },

      onConfirm: () => {
        notificationsPluginRegister();
        dispatch(dialogDestroyAction(dialogId));
      },
    } as DialogPayload),
  );
};

export default showDialog;
