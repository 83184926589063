import { UserGetActionApiResponse } from 'modules/states/user';
import { CasesState } from '../types';
import findCase from './findCase';

type UpdateCasesFromUser = (
  state: CasesState,
  data: UserGetActionApiResponse['data'],
) => void;

const updateCasesFromUser: UpdateCasesFromUser = (state, data) =>
  !state.caseId &&
  (!state.data || !!findCase(state.data, data.caseId)) &&
  (state.caseId = data.caseId);

export default updateCasesFromUser;
