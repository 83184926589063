import { OnfidoAction } from '../../constants';

import {
  OnfidoPluginFailureAction,
  OnfidoPluginSuccessAction,
  OnfidoPluginCancelAction,
} from './types';

export const onfidoPluginSuccessAction: OnfidoPluginSuccessAction =
  response => ({
    type: OnfidoAction.PLUGIN_SUCCESS,
    ...response,
  });

export const onfidoPluginFailureAction: OnfidoPluginFailureAction = (
  error: Error,
) => ({
  type: OnfidoAction.PLUGIN_FAILURE,
  error,
});

export const onfidoPluginCancelAction: OnfidoPluginCancelAction = () => ({
  type: OnfidoAction.PLUGIN_CANCEL,
});
