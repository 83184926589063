import { registerPlugin } from '@capacitor/core';

import { onfidoPluginDestroyAndroid, onfidoPluginInitAndroid } from './android';
import { OnfidoPluginDestroy, OnfidoPluginInit, OnfidoPlugin } from './types';
import { onfidoPluginDestroyIOS, onfidoPluginInitIOS } from './ios';
import { onfidoPluginDestroyWeb, onfidoPluginInitWeb } from './web';
import { platform } from 'modules/services/platform';
import {
  handleCompleteAndroid,
  handleCompleteIOS,
  handleCompleteWeb,
  handleError,
  getHandlers,
} from './utils';

const { isAndroid, isCapacitor, isIOS } = platform;

export const onfidoPlugin = registerPlugin<OnfidoPlugin>('OnfidoPlugin');

export const onfidoPluginDestroy: OnfidoPluginDestroy = animated => {
  switch (true) {
    case isCapacitor && isAndroid:
      onfidoPluginDestroyAndroid();
      break;
    case isCapacitor && isIOS:
      onfidoPluginDestroyIOS(animated);
      break;
    default:
      onfidoPluginDestroyWeb();
      break;
  }
};

export const onfidoPluginInit: OnfidoPluginInit = (
  { dashboard, token, variant },
  dispatch,
) => {
  const { onCancel, onComplete, onError, onUserExit } = getHandlers(dispatch);
  switch (true) {
    case isCapacitor && isAndroid:
      onfidoPluginInitAndroid({
        onComplete: data =>
          handleCompleteAndroid({ data, onComplete, variant }),
        onError: error => handleError({ error, onCancel, onError }),
        dashboard,
        token,
        variant,
      });
      break;
    case isCapacitor && isIOS:
      onfidoPluginInitIOS({
        onComplete: data => handleCompleteIOS({ data, onComplete, variant }),
        onError: error => handleError({ error, onCancel, onError }),
        dashboard,
        token,
        variant,
      });
      break;
    default:
      setTimeout(
        () =>
          onfidoPluginInitWeb({
            onComplete: data =>
              handleCompleteWeb({ data, onComplete, variant }),
            onError: error => handleError({ error, onCancel, onError }),
            onUserExit,
            dashboard,
            token,
            variant,
          }),
        0,
      );
      break;
  }
};
