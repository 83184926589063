import { useEffect, useMemo, useRef } from 'react';

import { useCertificate } from 'modules/services/certificate';
import { useVersionExpired } from 'modules/services/version';
import { appInitializedAction } from 'modules/app/actions';
import { useJailbreak } from 'modules/services/jailbreak';
import { Dispatch, useDispatch } from 'modules/redux';

type UseApp = () => {
  isCertificateSecure: boolean;
  isDirty: boolean;
  isExpired: boolean | string;
  isJailbreakSecure: boolean;
  isSecure: boolean;
};

const useApp: UseApp = () => {
  const { isCertificateDirty, isCertificateSecure } = useCertificate();
  const { /*isVersionDirty,*/ isVersionExpired } = useVersionExpired();
  const { isJailbreakDirty, isJailbreakSecure } = useJailbreak();
  const dispatch: Dispatch = useDispatch();
  const triggered = useRef(false);

  const isDirty = useMemo(
    () => (isCertificateDirty && isJailbreakDirty) || !!isVersionExpired,
    [isCertificateDirty, isJailbreakDirty, isVersionExpired],
  );

  const isSecure = useMemo(
    () => isCertificateSecure && isJailbreakSecure && !isVersionExpired,
    [isCertificateSecure, isJailbreakSecure, isVersionExpired],
  );

  useEffect(() => {
    if (!triggered.current && (isDirty || isSecure)) {
      triggered.current = true;
      dispatch(appInitializedAction());
    }
  }, [dispatch, isDirty, isSecure]);

  return {
    isCertificateSecure,
    isDirty,
    isExpired: isVersionExpired,
    isJailbreakSecure,
    isSecure,
  };
};

export default useApp;
