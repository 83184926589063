import { Middleware } from 'redux';

import { certificateInitAction, certificateResultAction } from './actions';
import { CertificateAction, CertificateStatus } from './constants';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { certificatePluginInit } from './plugin';
import { CertificateActionType } from './types';

const RETRY_TIME = 5 * 1000;

const certificateMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | CertificateActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT: {
        nextAction = next(action);
        getState().platform.isCapacitor && dispatch(certificateInitAction());
        break;
      }

      case CertificateAction.INIT:
        certificatePluginInit(status =>
          dispatch(certificateResultAction(status)),
        );
        break;

      case CertificateAction.RESULT:
        nextAction = next(action);
        action.status === CertificateStatus.FAILED &&
          setTimeout(() => dispatch(certificateInitAction()), RETRY_TIME);
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default certificateMiddleware;
