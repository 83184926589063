import { createDefaultAction } from 'modules/redux/factories';
import { isActive, isPresent } from './utils';
import { UserAction } from './constants';
import { API } from 'modules/api';
import {
  UserGetAction,
  UserGetFailureAction,
  UserGetStartedAction,
  UserGetSuccessAction,
  UserLogoutAction,
} from './types';

export const userGetAction: UserGetAction = () =>
  createDefaultAction({
    actionStarted: userGetStartedAction,
    actionSuccess: userGetSuccessAction,
    actionFailure: userGetFailureAction,
    endpoint: API.GET_USER,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: undefined,
  });

const userGetStartedAction: UserGetStartedAction = payload => ({
  type: UserAction.GET_STARTED,
  ...payload,
});

const userGetSuccessAction: UserGetSuccessAction = payload => ({
  type: UserAction.GET_SUCCESS,
  ...payload,
});

const userGetFailureAction: UserGetFailureAction = payload => ({
  type: UserAction.GET_FAILURE,
  ...payload,
});

export const userLogoutAction: UserLogoutAction = () => ({
  type: UserAction.LOGOUT,
});
