import React from 'react';

import { MessageActionOnfido } from 'modules/states/messages';
import { MessageTemplateOnfidoIconProps } from './types';
import {
  DefaultSVG,
  GreenSVG,
  OrangeSVG,
  RedSVG,
} from 'modules/theme/images/messages/onfido';

const MessageTemplateOnfidoIcon: React.FunctionComponent<
  MessageTemplateOnfidoIconProps
> = ({ action }) => {
  switch (action) {
    case MessageActionOnfido.APPROVED:
      return <span style={{ backgroundImage: `url(${GreenSVG})` }} />;
    case MessageActionOnfido.FAILED:
      return <span style={{ backgroundImage: `url(${RedSVG})` }} />;
    case MessageActionOnfido.INPROGRESS:
      return <span style={{ backgroundImage: `url(${OrangeSVG})` }} />;
    case MessageActionOnfido.REQUESTED:
    default:
      return <span style={{ backgroundImage: `url(${DefaultSVG})` }} />;
  }
};

export default React.memo(MessageTemplateOnfidoIcon);
