import { useMemo } from 'react';
import clsx from 'clsx';

import {
  isStatusStepCancelled,
  isStatusStepComplete,
  isStatusStepDeclined,
  isStatusStepActive,
  getStatusTitle,
  getStatusText,
  StatusStep,
  StatusType,
} from 'modules/states/status';

type UseStatusItem = (props: { step: StatusStep; status: StatusType }) => {
  active: boolean;
  cancelled: boolean;
  className: string;
  complete: boolean;
  declined: boolean;
  text: string;
  title: string;
};

const useStatusItem: UseStatusItem = ({ step, status }) => {
  const active = useMemo(
    () => isStatusStepActive(step, status),
    [status, step],
  );

  const cancelled = useMemo(
    () => isStatusStepCancelled(step, status),
    [status, step],
  );

  const complete = useMemo(
    () => isStatusStepComplete(step, status),
    [status, step],
  );

  const declined = useMemo(
    () => isStatusStepDeclined(step, status),
    [status, step],
  );

  const text = useMemo(() => getStatusText(step), [step]);

  const title = useMemo(() => getStatusTitle(step), [step]);

  const className = clsx(
    active && 'status-item-active',
    complete && 'status-item-complete',
    cancelled && 'status-item-cancelled',
    declined && 'status-item-declined',
    !complete && 'status-item-incomplete',
  );

  return {
    active,
    cancelled,
    className,
    complete,
    declined,
    text,
    title,
  };
};

export default useStatusItem;
