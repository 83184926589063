import React from 'react';

import { ExternalLink } from 'components/shared/Hyperlinks';

import styles from './styles.module.scss';

const Contact: React.FunctionComponent = () => {
  return (
    <div className={styles.contact}>
      <h1>Contact</h1>
      <h2>Get in touch</h2>
      <p>
        You can contact us on{' '}
        <ExternalLink href="tel:08008600025" label="0800 860 0025" /> or write
        to us at the address below.
      </p>
      <p>
        <strong>Fluent Money Limited</strong>
        <br />
        102 Rivington House
        <br />
        Chorley New Road
        <br />
        Horwich
        <br />
        Bolton
        <br />
        Lancashire
        <br />
        BL6 5UE
        <br />
        United Kingdom
      </p>
      <hr />
      <small>
        <span>
          Fluent Money® is a trading style of Fluent Money® Ltd (Firm
          Reference No. 654425) and Fluent® Mortgages Ltd (Firm Reference No.
          458914), who are authorised and regulated by the Financial Conduct
          Authority. Fluent® Lifetime Ltd and Fluent® Bridging Ltd are
          appointed representatives of Fluent® Mortgages Ltd. See{' '}
          <ExternalLink href="www.fca.org.uk" label="www.fca.org.uk" />.
        </span>
        <span>
          The companies stated act as a broker and will search a panel of
          lenders to find the right loan for you.
        </span>
        <span>
          Fluent Money Ltd (Company No. 06200496), Fluent Mortgages Ltd (Company
          No. 5962939), Fluent Lifetime Ltd (Company No. 11226852) and Fluent
          Bridging Ltd (Company No. 13198365) are registered in England and
          Wales.
        </span>
        <span>
          Registered Office: 102 Rivington House, Chorley New Road, Horwich,
          Bolton, Lancashire BL6 5UE.
        </span>
      </small>
    </div>
  );
};

export default React.memo(Contact);
