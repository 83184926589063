import React from 'react';
import { useLocation } from 'react-router-dom';

import { useCases } from 'modules/states/cases';
import Button from 'components/shared/Button';
import { useTitle } from './hooks';

import styles from './styles.module.scss';

type HeaderProps = {
  handleOpenMenu: () => void;
};

const Header: React.FunctionComponent<HeaderProps> = ({ handleOpenMenu }) => {
  const { caseId, cases } = useCases();
  const { pathname } = useLocation();
  const title = useTitle(pathname);
  return (
    <header className={styles.header}>
      <div>
        <Button
          ariaControls="menu"
          ariaLabel="Open Menu"
          onClick={handleOpenMenu}
          title="Open Menu">
          <span></span>
          <span></span>
          <span></span>
        </Button>
        {Array.isArray(cases) && cases.length > 1 ? (
          <h1>Case: {caseId}</h1>
        ) : (
          <h1>{title}</h1>
        )}
      </div>
    </header>
  );
};

export default React.memo(Header);
