import { ROUTE } from '../constants';

type GetCaseRoute = (caseId: string | number | null, route: ROUTE) => string;

const getCaseRoute: GetCaseRoute = (caseId, route) => {
  switch (true) {
    case route === ROUTE.CONTACT || route === ROUTE.CONTACT_CASE:
      return caseId
        ? ROUTE.CONTACT_CASE.replace(':caseId', caseId.toString())
        : ROUTE.CONTACT;

    case route === ROUTE.DEBUG || route === ROUTE.DEBUG_CASE:
      return caseId
        ? ROUTE.DEBUG_CASE.replace(':caseId', caseId.toString())
        : ROUTE.DEBUG;

    case route === ROUTE.DOCUMENTS || route === ROUTE.DOCUMENTS_CASE:
      return caseId
        ? ROUTE.DOCUMENTS_CASE.replace(':caseId', caseId.toString())
        : ROUTE.DOCUMENTS;

    case route === ROUTE.LOAN || route === ROUTE.LOAN_CASE:
      return caseId
        ? ROUTE.LOAN_CASE.replace(':caseId', caseId.toString())
        : ROUTE.LOAN;

    case route === ROUTE.MESSAGES || route === ROUTE.MESSAGES_CASE:
      return caseId
        ? ROUTE.MESSAGES_CASE.replace(':caseId', caseId.toString())
        : ROUTE.MESSAGES;

    case route === ROUTE.STATUS || route === ROUTE.STATUS_CASE:
      return caseId
        ? ROUTE.STATUS_CASE.replace(':caseId', caseId.toString())
        : ROUTE.STATUS;

    case route === ROUTE.TEAM || route === ROUTE.TEAM_CASE:
      return caseId
        ? ROUTE.TEAM_CASE.replace(':caseId', caseId.toString())
        : ROUTE.TEAM;

    default:
      return route;
  }
};

export default getCaseRoute;
