import { produce } from 'immer';

import { Auth, AuthActionType } from '../../types';
import { AuthAction } from '../../constants';
import { getExpires } from './utils';

function authLoginReducer(state: Auth, action: AuthActionType) {
  switch (action.type) {
    case AuthAction.LOGIN_FAILURE:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case AuthAction.LOGIN_STARTED:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isLoading = true;
      });

    case AuthAction.LOGIN_SUCCESS:
    case AuthAction.LOGIN_CHANGE_PASSWORD:
      return produce(state, (draftState: Auth) => {
        draftState.actionId = null;
        draftState.isError = false;
        draftState.isLoading = false;

        draftState.changePassword =
          action.type === AuthAction.LOGIN_CHANGE_PASSWORD;

        draftState.data = {
          expires: getExpires(action.data.expires_in),
          token: action.data.access_token,
          username: action.request.username,
        };
      });

    case AuthAction.LOGIN_RESET:
      return produce(state, (draftState: Auth) => {
        draftState.isError = false;
      });

    default:
      return state;
  }
}

export default authLoginReducer;
