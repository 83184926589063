export enum ConfigAction {
  INIT = 'CONFIG_INIT',

  GET_STARTED = 'CONFIG_GET_STARTED',
  GET_SUCCESS = 'CONFIG_GET_SUCCESS',
  GET_FAILURE = 'CONFIG_GET_FAILURE',

  POLLING_STARTED = 'CONFIG_POLLING_STARTED',
  POLLING_ENDED = 'CONFIG_POLLING_ENDED',
}
