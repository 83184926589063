import { MessagesGetActionApiResponseItem } from '../../types';
import { convertStringToDate } from 'modules/redux/utils';
import { MessageDefault } from '../../../../types';

type UpdateMessagePlaceholder = (
  data: MessagesGetActionApiResponseItem,
  message: MessageDefault,
) => void;

const updateMessagePlaceholder: UpdateMessagePlaceholder = (data, message) => {
  if (data.date && data.id) {
    message.date = convertStringToDate(data.date);
    message.messageId = data.id;
    message.placeholder = false;
  }
};

export default updateMessagePlaceholder;
