import axios from 'axios';

import { AuthLoginError } from '../../../constants';

type GetError = (error: unknown) => Error;

const getError: GetError = error => {
  switch (true) {
    case axios.isAxiosError(error) &&
      error.response?.data === AuthLoginError.INVALID_CREDENTIALS:
      return new Error(
        "Please check your username and password. If you still can't log in please contact your Case Manager.",
      );
    case axios.isAxiosError(error) &&
      error.response?.data === AuthLoginError.LOCKED:
      return new Error(
        'You have temporarily locked your account for 15 minutes due to unsuccessful logins.',
      );
    default:
      return new Error('An error has occurred. Please try again later.');
  }
};

export default getError;
