import { Filesystem } from '@capacitor/filesystem';
import { decode } from 'base64-arraybuffer';
import {
  CameraDirection,
  CameraResultType,
  CameraSource,
  ImageOptions,
  Camera,
} from '@capacitor/camera';

import { EmbedCamera, ErrorCamera, OpenCamera, ReadCamera } from './types';
import { DocuploadFileType } from '../../constants';
import { DocuploadError } from '../../constants';
import { isError } from 'modules/utils';
import { embedImage } from '../image';

const imageOptions: ImageOptions = {
  allowEditing: false,
  correctOrientation: true,
  direction: CameraDirection.Rear,
  presentationStyle: 'fullscreen',
  quality: 50,
  resultType: CameraResultType.Uri,
  saveToGallery: false,
  source: CameraSource.Camera,
};

export const embedCamera: EmbedCamera = async (config, pdf) => {
  const path = await openCamera();
  if (path) {
    await embedImage(config, await readCamera(path), pdf);
  }
};

export const errorCamera: ErrorCamera = error => {
  switch (true) {
    case isError(error) && error.message.toLowerCase().includes('cancelled'):
    case isError(error) && error.message.toLowerCase().includes('simulator'):
      return DocuploadError.CANCELLED;
    case isError(error) && error.message.toLowerCase().includes('denied'):
      return DocuploadError.DENIED;
    default:
      return isError(error) ? error.message : 'Unexpected Error';
  }
};

const openCamera: OpenCamera = async () => {
  const image = await Camera.getPhoto(imageOptions);
  return image.path;
};

const readCamera: ReadCamera = async path => {
  const file = await Filesystem.readFile({ path });
  return new Blob([new Uint8Array(decode(file.data as string))], {
    type: DocuploadFileType.JPEG,
  }) as File;
};
