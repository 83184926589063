import React from 'react';

import styles from './styles.module.scss';

type InfoProps = {
  disabled: boolean;
};

const Info: React.FunctionComponent<InfoProps> = ({ disabled }) => {
  return (
    <div className={styles['modal-document-upload-info']}>
      <div>
        <div>
          <span></span>
          <ul>
            <li>
              <span>
                Press '<strong>Take Photo</strong>' or '
                <strong>Add file</strong>
                '.
              </span>
            </li>
            <li>
              <span>
                Take photos or select one or more files to include in your
                upload.
              </span>
            </li>
            <li>
              <span>
                Press '<strong>Preview</strong>' to check what the document
                looks like.
              </span>
            </li>
            <li>
              <span>
                When you're happy, press '<strong>Upload Document</strong>' to
                send it to us!
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Info);
