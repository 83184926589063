import { Middleware } from 'redux';

import { AuthAction, AuthActionType } from 'modules/auth';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import {
  securityPluginUnauthenticated,
  securityPluginAuthenticated,
  securityPluginInit,
} from './plugin';

const securityMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | AuthActionType) => {
    switch (action.type) {
      case AppAction.INIT:
        getState().platform.isCapacitor && securityPluginInit();
        break;

      case AuthAction.LOGIN_SUCCESS:
        getState().platform.isCapacitor && securityPluginAuthenticated();
        break;

      case AuthAction.LOGOUT:
        getState().platform.isCapacitor && securityPluginUnauthenticated();
        break;
    }

    return next(action);
  };

export default securityMiddleware;
