import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { Input, InputType } from 'components/shared/Inputs';
import { QuestionTextProps } from '../types';
import { ReactComponent as Lock } from 'modules/theme/images/icons/input/password.svg';
import { ReactComponent as Unlock } from 'modules/theme/images/icons/input/password-visible.svg';
import Button from 'components/shared/Button';
import QuestionTemplate from '.';

function QuestionText<T extends FieldValues>({
  disabled,
  control,
  id,
  label,
  name,
  readOnly,
  type = InputType.TEXT,
  ...props
}: QuestionTextProps<T>) {
  const [revealPassword, setRevealPassword] = useState(false);

  return (
    <QuestionTemplate<T>
      control={control}
      disabled={disabled}
      id={id}
      label={label}
      name={name}>
      {({ invalid, onBlur, onFocus, onChange, ref, value }) => (
        <Input
          disabled={disabled}
          id={id}
          inputRef={ref}
          invalid={invalid}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={label}
          readOnly={readOnly}
          type={revealPassword ? InputType.TEXT : type}
          value={value as string}
          {...props}>
          {type === InputType.PASSWORD && (
            <Button
              ariaLabel="Reveal Password"
              disabled={disabled || readOnly}
              onClick={() => setRevealPassword(!revealPassword)}
              tabIndex={-1}>
              {revealPassword ? <Unlock /> : <Lock />}
            </Button>
          )}
        </Input>
      )}
    </QuestionTemplate>
  );
}

export default React.memo(QuestionText) as typeof QuestionText;
