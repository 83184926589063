import { useCallback } from 'react';

import { ShowModal, UseModalCases } from './types';
import { ModalType } from '../../constants';
import useModal from '../useModal';

const useModalCases: UseModalCases = () => {
  const [showModalDefault, hideModal] = useModal();

  const showModal: ShowModal = useCallback(
    modal =>
      showModalDefault
        ? showModalDefault({
            ...modal,
            placeholder: false,
            type: ModalType.CASES,
          })
        : undefined,
    [showModalDefault],
  );

  const result: ReturnType<UseModalCases> = [
    showModalDefault ? showModal : undefined,
    hideModal,
  ];
  result.hideModal = hideModal;
  result.showModal = showModalDefault ? showModal : undefined;
  return result;
};

export default useModalCases;
