import { DialogActionType, Dialogs } from './types';
import { DialogAction } from './constants';

const initialState: () => Dialogs = () => {
  return [];
};

function dialogsReducer(state = initialState(), action: DialogActionType) {
  switch (action.type) {
    case DialogAction.DESTROY:
      return state.filter(dialog => dialog.dialogId !== action.dialogId);

    case DialogAction.HIDE:
      return state.map(dialog => {
        if (dialog.dialogId !== action.dialogId) {
          return dialog;
        }
        return {
          ...dialog,
          open: false,
        };
      });

    case DialogAction.INSERT:
      return [...state, action.dialog];

    case DialogAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default dialogsReducer;
