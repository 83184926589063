import { useCallback } from 'react';

import { messagesGetAction } from '../actions';
import { Messages } from '../types';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseMessagesSelector = (caseId: number | null) => {
  data: Messages['data'];
  handleRequest: () => void;
  isDirty: boolean;
  isError: Error | false;
  isLoading: boolean;
  isSending: boolean;
  isSendingError: Error | false;
};

const useMessagesSelector: UseMessagesSelector = caseId => {
  const dispatch: Dispatch = useDispatch();

  const data = useSelector(
    state => state.messages.data.find(items => items.caseId === caseId),
    shallowEqual,
  );

  const handleRequest = useCallback(
    () => !!caseId && dispatch(messagesGetAction({ caseId })),
    [caseId, dispatch],
  );

  return {
    data: data?.data || null,
    handleRequest,
    isDirty: data?.isDirty || false,
    isError: data?.isError || false,
    isLoading: !!data?.isLoading,
    isSending: !!data?.isSending,
    isSendingError: data?.isSendingError || false,
  };
};

export default useMessagesSelector;
