import { StatusStep } from 'modules/states/status';

const getStatusTitle = (step: StatusStep) => {
  switch (step) {
    case StatusStep.CANCELLED:
      return 'Loan Cancelled';
    case StatusStep.DECLINED:
      return 'Loan Declined';
    case StatusStep.STEP1:
      return 'Quote Sent';
    case StatusStep.STEP2:
      return "We've Received Your Application Pack";
    case StatusStep.STEP3:
      return 'Approval Checks';
    case StatusStep.STEP4:
      return 'Lender Sign Off';
    case StatusStep.STEP5:
      return 'Loan Decision';
    case StatusStep.UNKNOWN:
    default:
      return '';
  }
};

export default getStatusTitle;
