import getImageMultiplier from './getImageMultiplier';
import { ConfigApiResponse } from '../types';

const updateImageMultiplier = (
  value: ConfigApiResponse['data']['documentUploadImageDPIMultiplier'],
): number => {
  const newValue = Number(value);
  return !isNaN(newValue) && newValue >= 1 && newValue <= 3
    ? newValue
    : getImageMultiplier();
};

export default updateImageMultiplier;
