import React from 'react';

import Button, { ButtonSize, ButtonStyle } from 'components/shared/Button';
import { StatusStep, StatusType } from 'modules/states/status/constants';
import useStatusItem from './hooks';

type StatusItemProps = {
  handleVideo?: () => void;
  isPlaying: boolean;
  status: StatusType;
  step: StatusStep;
};

const StatusItem: React.FunctionComponent<StatusItemProps> = ({
  handleVideo,
  isPlaying,
  ...props
}) => {
  const { active, cancelled, className, declined, text, title } = useStatusItem(
    {
      ...props,
    },
  );
  return (
    <section className={className}>
      <div>
        <h2>{title}</h2>
        {declined ? (
          <p>Your application has been declined.</p>
        ) : cancelled ? (
          <p>Your application has been cancelled.</p>
        ) : (
          <p>{text}</p>
        )}
        {active && !!handleVideo && (
          <Button
            disabled={isPlaying}
            label="Play video"
            onClick={handleVideo}
            style={ButtonStyle.PRIMARY_INVERTED}
            size={ButtonSize.SMALL}
          />
        )}
      </div>
    </section>
  );
};

export default React.memo(StatusItem);
