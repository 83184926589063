import { useCallback } from 'react';

import { userGetAction, UserState } from 'modules/states/user';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseUserSelector = () => {
  data: UserState['data'];
  handleError: () => void;
  isError: Error | false;
  isLoading: boolean;
};

const useUserSelector: UseUserSelector = () => {
  const dispatch: Dispatch = useDispatch();

  const { data, isError, isLoading } = useSelector(
    state => state.user,
    shallowEqual,
  );

  const handleError = useCallback(() => dispatch(userGetAction()), [dispatch]);

  return {
    data,
    handleError,
    isError,
    isLoading: !data || !!isLoading,
  };
};

export default useUserSelector;
