import { isWindow } from 'modules/utils';

type GetScrollOffset = (element: HTMLElement | Window) => number;

export const getScrollOffset: GetScrollOffset = element =>
  isWindow(element)
    ? document.body.scrollHeight - window.innerHeight
    : element.scrollHeight - element.clientHeight;

type ShouldScroll = (element: HTMLElement | Window) => boolean;

export const shouldScroll: ShouldScroll = element =>
  isWindow(element)
    ? document.body.scrollTop < getScrollOffset(element)
    : element.scrollTop < getScrollOffset(element);
