import { DocuploadAction, DocuploadError } from '../../constants';
import { isAllowed, isPresent, showDocupload } from './utils';
//import { testDocupload } from './utils';
import {
  DocuploadShowFailureAction,
  DocuploadShowStartedAction,
  DocuploadShowSuccessAction,
  DocuploadShowAction,
} from './types';

export const docuploadShowAction: DocuploadShowAction = request => {
  return async (dispatch, getState) => {
    try {
      if (!isAllowed(getState())) return;

      dispatch(docuploadShowStartedAction(request));

      const modalId = showDocupload({ dispatch, ...request });

      if (isPresent({ request, state: getState() })) {
        dispatch(
          docuploadShowSuccessAction({
            modalId,
            ...request,
          }),
        );

        //testDocupload(dispatch, getState);
      }
    } catch (error) {
      isPresent({ request, state: getState() }) &&
        dispatch(
          docuploadShowFailureAction({
            error: new Error(DocuploadError.OPEN),
          }),
        );
    }
  };
};

const docuploadShowStartedAction: DocuploadShowStartedAction = payload => ({
  type: DocuploadAction.SHOW_STARTED,
  ...payload,
});

const docuploadShowSuccessAction: DocuploadShowSuccessAction = payload => ({
  type: DocuploadAction.SHOW_SUCCESS,
  ...payload,
});

const docuploadShowFailureAction: DocuploadShowFailureAction = payload => ({
  type: DocuploadAction.SHOW_FAILURE,
  ...payload,
});
