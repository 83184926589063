import { CaseType } from 'modules/states/cases/constants'; //TODO THIS CAUSES IMPORT ERROR
import { StatusVideoStep } from './types';
import { StatusStep } from './constants';

const StatusVideoStep1: StatusVideoStep = {
  step: StatusStep.STEP1,
  type: [
    {
      type: CaseType.FLUENT_MONEY,
      code: '288128972',
      mp4: 'https://player.vimeo.com/external/288128972.sd.mp4?s=f4576b504d939a132e021f4a40d96d50eda909bc&profile_id=165',
      url: 'https://player.vimeo.com/video/288128972',
    },
    {
      type: CaseType.FLUENT_LIFETIME,
      code: '288130183',
      mp4: 'https://player.vimeo.com/external/288130183.sd.mp4?s=bebf572a7f835f0c4e33f6937f6b8d0485a269ef&profile_id=165',
      url: 'https://player.vimeo.com/video/288130183',
    },
    {
      type: CaseType.FLUENT_MORTGAGES,
      code: '288129176',
      mp4: 'https://player.vimeo.com/external/288129176.sd.mp4?s=109a88fcdd0bf3ad55e86282fbc8576d595fe59b&profile_id=165',
      url: 'https://player.vimeo.com/video/288129176',
    },
  ],
};

const StatusVideoStep2: StatusVideoStep = {
  step: StatusStep.STEP2,
  type: [
    {
      type: CaseType.FLUENT_MONEY,
      code: '288129030',
      mp4: 'https://player.vimeo.com/external/288129030.sd.mp4?s=e324095bead1d306185e32070662c6d9a28895a6&profile_id=165',
      url: 'https://player.vimeo.com/video/288129030',
    },
    {
      type: CaseType.FLUENT_LIFETIME,
      code: '288130227',
      mp4: 'https://player.vimeo.com/external/288130227.sd.mp4?s=5543fecc5a637cbf2c1c31eb84d932c32cebec6d&profile_id=165',
      url: 'https://player.vimeo.com/video/288130227',
    },
    {
      type: CaseType.FLUENT_MORTGAGES,
      code: '288130068',
      mp4: 'https://player.vimeo.com/external/288130068.sd.mp4?s=1e2d3697641dd137c495d769aa4fa5d4148b3008&profile_id=165',
      url: 'https://player.vimeo.com/video/288130068',
    },
  ],
};

const StatusVideoStep3: StatusVideoStep = {
  step: StatusStep.STEP3,
  type: [
    {
      type: CaseType.FLUENT_MONEY,
      code: '288129019',
      mp4: 'https://player.vimeo.com/external/288129019.sd.mp4?s=1d9a9ddedff7beafb225cf264366e86c521b4177&profile_id=165',
      url: 'https://player.vimeo.com/video/288129019',
    },
    {
      type: CaseType.FLUENT_LIFETIME,
      code: '288130217',
      mp4: 'https://player.vimeo.com/external/288130217.sd.mp4?s=31e7bcd6d3c6c3cd84e302555c899588018d8454&profile_id=165',
      url: 'https://player.vimeo.com/video/288130217',
    },
    {
      type: CaseType.FLUENT_MORTGAGES,
      code: '288130058',
      mp4: 'https://player.vimeo.com/external/288130058.sd.mp4?s=c95f62aee6e14cba97d41e176b9597520b9543ac&profile_id=165',
      url: 'https://player.vimeo.com/video/288130058',
    },
  ],
};

const StatusVideoStep4: StatusVideoStep = {
  step: StatusStep.STEP4,
  type: [
    {
      type: CaseType.FLUENT_MONEY,
      code: '288128977',
      mp4: 'https://player.vimeo.com/external/288128977.sd.mp4?s=f9bb1cf469235d4786292bb6a01c1212b71a4a1e&profile_id=165',
      url: 'https://player.vimeo.com/video/288128977',
    },
    {
      type: CaseType.FLUENT_LIFETIME,
      code: '288130197',
      mp4: 'https://player.vimeo.com/external/288130197.sd.mp4?s=09e8c9d1ad6656bfb8926239f2a270ea74512b69&profile_id=165',
      url: 'https://player.vimeo.com/video/288130197',
    },
    {
      type: CaseType.FLUENT_MORTGAGES,
      code: '288142584',
      mp4: 'https://player.vimeo.com/external/288142584.sd.mp4?s=59dcb0f0d90cf1acc9a6de057fcd2cb25c21b941&profile_id=165',
      url: 'https://player.vimeo.com/video/288142584',
    },
  ],
};

const StatusVideoStep5: StatusVideoStep = {
  step: StatusStep.STEP5,
  type: [
    {
      type: CaseType.FLUENT_MONEY,
      code: '288128951',
      mp4: 'https://player.vimeo.com/external/288128951.sd.mp4?s=3a63d59cbd9302c437d49ccbd4193906a71c0fd9&profile_id=165',
      url: 'https://player.vimeo.com/video/288128951',
    },
    {
      type: CaseType.FLUENT_LIFETIME,
      code: '288130158',
      mp4: 'https://player.vimeo.com/external/288130158.sd.mp4?s=92228b5eef1c1c2063d06d483fb7d842406a7da9&profile_id=165',
      url: 'https://player.vimeo.com/video/288130158',
    },
    {
      type: CaseType.FLUENT_MORTGAGES,
      code: '288129148',
      mp4: 'https://player.vimeo.com/external/288129148.sd.mp4?s=cf37a3c5f86cfea155710c114d99ba1a26a0b086&profile_id=165',
      url: 'https://player.vimeo.com/video/288129148',
    },
  ],
};

const StatusVideos: StatusVideoStep[] = [
  StatusVideoStep1,
  StatusVideoStep2,
  StatusVideoStep3,
  StatusVideoStep4,
  StatusVideoStep5,
];

export default StatusVideos;
