import { OnfidoConfig, OnfidoOptions, OnfidoSteps } from './types';
import { OnfidoVariant } from 'modules/onfido/constants';

export const onfidoImport = false as boolean;

const onfidoSteps: OnfidoSteps = ({ dashboard, variant }) => [
  {
    type: 'welcome',
    options: {
      title: 'Verify your identity',
      descriptions: [
        'To process your application, we will need to verify your identity.',
        'It will only take a couple of minutes.',
      ],
    },
  },
  dashboard
    ? 'document'
    : {
        type: 'document',
        options: {
          documentTypes: {
            driving_licence: {
              country: 'GBR',
            },
            passport: true,
          },
          hideCountrySelection: true,
        },
      },
  variant === OnfidoVariant.PHOTO
    ? 'face'
    : {
        type: 'face',
        options: {
          requestedVariant: variant,
          motionFallbackVariant: OnfidoVariant.VIDEO,
        },
      },
  'complete',
];

const onfidoStyles: OnfidoOptions['customUI'] & {
  colorBackgroundDropdownItemHover: string;
  colorBackgroundDropdownItemActive: string;
} = {
  borderRadiusButton: '24px',
  borderWidthSurfaceModal: '0px',

  colorContentButtonPrimaryText: '#FFFFFF',
  colorBackgroundButtonPrimary: '#00AFEC',
  colorBackgroundButtonPrimaryHover: '#125EA2',
  colorBackgroundButtonPrimaryActive: '#125EA2',
  colorBorderButtonPrimary: 'rgba(255 255 255 / 0%)',

  colorContentButtonSecondaryText: '#FFFFFF',
  colorBackgroundButtonSecondary: '#00AFEC',
  colorBackgroundButtonSecondaryHover: '#125EA2',
  colorBackgroundButtonSecondaryActive: '#125EA2',
  colorBorderButtonSecondary: 'rgba(255 255 255 / 0%)',

  colorBorderDocTypeButtonHover: '#125EA2',
  colorBorderDocTypeButtonActive: '#125EA2',

  colorContentLinkTextHover: '#2B2D33',
  colorBorderLinkUnderline: '#00AFEC',
  colorBackgroundLinkHover: 'none',
  colorBackgroundLinkActive: 'none',

  colorBackgroundIcon: '#00AFEC',

  colorBackgroundInfoPill: '#00AFEC',
  colorContentInfoPill: '#FFFFFF',

  colorBackgroundDropdownItemHover: '#e5e6e8',
  colorBackgroundDropdownItemActive: '#e5e6e8',
};

export const onfidoConfig: OnfidoConfig = props => ({
  containerId: 'onfido-mount',
  customUI: onfidoImport ? onfidoStyles : undefined,
  disableAnalytics: true,
  steps: onfidoSteps(props),
  useMemoryHistory: true,
});
