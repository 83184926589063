import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

type HtmlSafe = (string: string) => string | JSX.Element | JSX.Element[];

DOMPurify.addHook('afterSanitizeAttributes', node => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
  return node;
});

const htmlSafe: HtmlSafe = string =>
  parse(
    DOMPurify.sanitize(string, { ALLOWED_TAGS: ['a'], ADD_ATTR: ['target'] }),
  );

export default htmlSafe;
