import { Notifications, NotificationsActionType } from './types';
import { NotificationsAction } from './constants';
import {
  notificationsRegisterReducer,
  notificationsPostReducer,
} from './actions';

const initialState = (): Notifications => {
  return {
    actionId: null,
    isError: false,
    isSending: false,
    token: null,
  };
};

function notificationsReducer(
  state = initialState(),
  action: NotificationsActionType,
) {
  switch (action.type) {
    case NotificationsAction.POST_FAILURE:
    case NotificationsAction.POST_STARTED:
    case NotificationsAction.POST_SUCCESS:
      return notificationsPostReducer(state, action);

    case NotificationsAction.REGISTER_FAILURE:
    case NotificationsAction.REGISTER_STARTED:
    case NotificationsAction.REGISTER_SUCCESS:
      return notificationsRegisterReducer(state, action);

    case NotificationsAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default notificationsReducer;
