import { useCallback } from 'react';

export const useHandlers = (
  disabled?: boolean,
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void,
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void,
  readOnly?: boolean,
) => {
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) =>
      !disabled && !readOnly && !!onBlur && onBlur(event),
    [disabled, onBlur, readOnly],
  );
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      (disabled || readOnly) && event.currentTarget.blur();
      !disabled && !readOnly && !!onFocus && onFocus(event);
    },
    [disabled, onFocus, readOnly],
  );
  return {
    handleBlur,
    handleFocus,
  };
};
