import React from 'react';

import Button, { ButtonSize, ButtonStyle } from 'components/shared/Button';
import { usePlatformContext } from 'modules/services/platform';
import { File, FileCapture } from 'components/shared/Inputs';
import { ModalDocuploadFooterProps } from './types';

import styles from './styles.module.scss';

const ModalDocuploadFooter: React.FunctionComponent<
  ModalDocuploadFooterProps
> = ({
  disabled,
  files,
  handleCamera,
  handleInput,
  handlePreview,
  handleUpload,
}) => {
  const { isCapacitor, isMobile } = usePlatformContext();
  return (
    <footer className={styles['modal-document-upload-footer']}>
      <div>
        <div>
          {isCapacitor && (
            <Button
              disabled={disabled}
              label="TAKE PHOTO"
              onClick={handleCamera}
            />
          )}
          {!isCapacitor && isMobile && (
            <File
              accept="image/jpeg, image/png"
              capture={FileCapture.ENVIRONMENT}
              disabled={disabled}
              label="TAKE PHOTO"
              name="camera"
              onChange={handleInput}
            />
          )}
          <File
            accept="application/pdf, image/jpeg, image/png"
            disabled={disabled}
            label="ADD FILE"
            multiple={true}
            name="file"
            onChange={handleInput}
          />
          <Button
            disabled={disabled || !files}
            label="PREVIEW"
            onClick={handlePreview}
          />
        </div>
        <div>
          <Button
            disabled={disabled || !files}
            label="UPLOAD DOCUMENT"
            onClick={handleUpload}
            size={ButtonSize.LARGE}
            style={ButtonStyle.PRIMARY}
          />
        </div>
      </div>
    </footer>
  );
};

export default React.memo(ModalDocuploadFooter);
