import { OnfidoAction } from '../constants';
import {
  OnfidoResetErrorAction,
  OnfidoDestroyAction,
  OnfidoCancelAction,
  OnfidoLogoutAction,
  OnfidoCloseAction,
} from '../types';

export {
  type OnfidoGetActionApiRequest,
  type OnfidoGetActionType,
  onfidoGetRetryAction,
  onfidoGetReducer,
  onfidoGetAction,
} from './onfidoGetAction';

export {
  type OnfidoPluginSuccessAction,
  type OnfidoPluginActionType,
  onfidoPluginFailureAction,
  onfidoPluginSuccessAction,
  onfidoPluginCancelAction,
  onfidoPluginReducer,
} from './onfidoPluginAction';

export {
  type OnfidoPostActionApiRequest,
  type OnfidoPostActionType,
  onfidoPostRetryAction,
  onfidoPostReducer,
  onfidoPostAction,
} from './onfidoPostAction';

export {
  type OnfidoShowActionType,
  onfidoShowReducer,
  onfidoShowAction,
} from './onfidoShowAction';

export const onfidoCancelAction: OnfidoCancelAction = () => ({
  type: OnfidoAction.CANCEL,
});

export const onfidoCloseAction: OnfidoCloseAction = () => ({
  type: OnfidoAction.CLOSE,
});

export const onfidoDestroyAction: OnfidoDestroyAction = destroy => ({
  type: OnfidoAction.DESTROY,
  destroy: destroy === false ? false : true,
});

export const onfidoLogoutAction: OnfidoLogoutAction = () => ({
  type: OnfidoAction.LOGOUT,
});

export const onfidoResetErrorAction: OnfidoResetErrorAction = () => ({
  type: OnfidoAction.RESET_ERROR,
});
