import { Middleware } from 'redux';

import { appStatusPluginGetState, appStatusPluginInit } from './plugin';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { AppStatusAction } from './constants';
import { AppStatusActionType } from './types';
import {
  appStatusInactiveAction,
  appStatusActiveAction,
  appStatusInitAction,
} from './actions';

const appStatusMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | AppStatusActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(appStatusInitAction());
        break;

      case AppStatusAction.INIT:
        action.state = await appStatusPluginGetState();
        appStatusPluginInit(({ isActive }) =>
          dispatch(
            isActive ? appStatusActiveAction() : appStatusInactiveAction(),
          ),
        );
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default appStatusMiddleware;
