/* eslint-disable no-prototype-builtins */
import { browserVersion, isMobileSafari, isSafari } from 'react-device-detect';
import axios from 'axios';

import { ApiAbort, NewAbortController } from './types';

const detectAbortController =
  (isSafari || isMobileSafari) && Number(browserVersion) < 13
    ? false
    : Request.prototype.hasOwnProperty('signal');

export const newAbortController: NewAbortController = () => {
  try {
    if (!detectAbortController)
      throw new Error('AbortController Not Supported');
    return new AbortController();
  } catch {
    try {
      return axios.CancelToken.source();
    } catch {
      return;
    }
  }
};

export const apiAbort: ApiAbort = controller => {
  if (controller instanceof AbortController) {
    controller.abort();
  } else if (
    typeof controller === 'object' &&
    typeof controller.cancel === 'function'
  ) {
    controller.cancel();
  }
};
