import React from 'react';

import styles from './styles.module.scss';

type SendingProps = {
  isSendingProgress: number;
};

const Sending: React.FunctionComponent<SendingProps> = ({
  isSendingProgress,
}) => {
  return (
    <div className={styles['modal-document-upload-sending']}>
      <div>
        <div>
          <h2>Uploading... please wait</h2>
          <div>
            <span
              aria-label="File upload"
              aria-valuemax={100}
              aria-valuemin={0}
              aria-valuenow={isSendingProgress}
              role="progressbar"
              style={{ width: `${isSendingProgress}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sending);
