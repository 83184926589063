import { useMemo } from 'react';

import yup, { AnyObjectSchema } from 'modules/validation';
import { AuthLoginModel } from '../../../actions';

type UseAuthForm = (props: {
  isBiometric: boolean;
  username: string | null;
}) => {
  defaultValues: AuthLoginModel;
  schema: AnyObjectSchema;
};

const useAuthForm: UseAuthForm = ({ isBiometric, username }) => {
  const defaultValues = useMemo(
    () => ({ username: username || '', password: '' }),
    [username],
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        username: yup.string().email().required(),
        password: isBiometric
          ? yup.string().notRequired()
          : yup.string().required(),
      }),
    [isBiometric],
  );

  return { defaultValues, schema };
};

export default useAuthForm;
