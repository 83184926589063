import { useCallback } from 'react';

import { ModalDestroy, ModalHide, ModalShow, UseModalAction } from './types';
import { Dispatch, useDispatch, useSelector } from 'modules/redux';
import { getModal } from '../../utils';
import {
  modalDestroyAction,
  modalHideAction,
  modalShowAction,
} from '../../actions';

const useModalAction: UseModalAction = () => {
  const modals = useSelector(state => state.modals);
  const dispatch: Dispatch = useDispatch();

  const modalDestroy: ModalDestroy = useCallback(
    modalId =>
      !!getModal(modals, modalId) && dispatch(modalDestroyAction(modalId)),
    [modals, dispatch],
  );

  const modalHide: ModalHide = useCallback(
    modalId =>
      !!getModal(modals, modalId)?.open &&
      dispatch(
        getModal(modals, modalId)?.placeholder
          ? modalDestroyAction(modalId)
          : modalHideAction(modalId),
      ),
    [modals, dispatch],
  );

  const modalShow: ModalShow = useCallback(
    modal => dispatch(modalShowAction(modal)),
    [dispatch],
  );

  return { modalDestroy, modalHide, modalShow };
};

export default useModalAction;
