export enum NetworkAction {
  INIT = 'NETWORK_INIT',

  EVENT = 'NETWORK_EVENT',

  POLLING_STARTED = 'NETWORK_POLLING_STARTED',
  POLLING_ENDED = 'NETWORK_POLLING_ENDED',
}

export enum NetworkStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}
