import React from 'react';
import clsx from 'clsx';

import ErrorMessage from 'components/mobile/ErrorMessage';
import Loading from 'components/mobile/Loading';

import styles from './styles.module.scss';

type MainProps = {
  children: React.ReactNode;
  className?: string;
  handleError?: () => void;
  isError?: Error | false;
  isLoading?: boolean;
  scroll?: boolean;
  scrollRef?: React.RefObject<HTMLElement>;
};

const Main: React.FunctionComponent<MainProps> = ({
  children,
  className,
  handleError,
  isError,
  isLoading,
  scroll = true,
  scrollRef,
}) => {
  const classNames = clsx(
    styles['main'],
    !!scroll && styles['scroll'],
    className,
  );
  return isError ? (
    <ErrorMessage isError={isError} onError={handleError} />
  ) : isLoading ? (
    <Loading />
  ) : (
    <main className={classNames} ref={scrollRef}>
      {children}
    </main>
  );
};

export default React.memo(Main);
