import { MessagesGetActionApiResponseItem } from '../../types';
import { MessageType } from '../../../../constants';
import { Message, MessageDocupload, MessageOnfido } from '../../../../types';

import updateMessageDocupload from './updateMessageDocupload';
import updateMessagePlaceholder from './updateMessagePlaceholder';
import updateMessageOnfido from './updateMessageOnfido';

const updateMessage = (
  message: Message,
  data: MessagesGetActionApiResponseItem,
) => {
  message.read = !!data.clientRead || message.read;

  message.placeholder && updateMessagePlaceholder(data, message);

  message.type === MessageType.DOCUMENT_UPLOAD &&
    !!data.callToAction &&
    updateMessageDocupload(data.callToAction, message as MessageDocupload);

  message.type === MessageType.ONFIDO &&
    !!data.callToAction &&
    updateMessageOnfido(data.callToAction, message as MessageOnfido);
};

export default updateMessage;
