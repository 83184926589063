import React from 'react';

import { ReactComponent as Chevron } from 'modules/theme/images/icons/button/chevron.svg';
import { MessageActionDocupload } from 'modules/states/messages';
import MessageFooter from '../../components/MessageFooter';
import MessageHeader from '../../components/MessageHeader';
import { MessageTemplateDocuploadProps } from './types';
import MessageBody from '../../components/MessageBody';
import MessageTemplateDocuploadIcon from './icons';
import useMessageTemplateDocupload from './hooks';
import Button from 'components/shared/Button';

const MessageTemplateDocupload: React.FunctionComponent<
  MessageTemplateDocuploadProps
> = ({
  dateHeader,
  dateFooter,
  dateTime,
  forwardedRef,
  showHeader,
  ...props
}) => {
  const { action, className, disabled, handleClick, label, name, text, title } =
    useMessageTemplateDocupload(props);
  return (
    <article className={className} ref={forwardedRef}>
      <MessageHeader
        dateHeader={dateHeader}
        dateTime={dateTime}
        name={name}
        showHeader={showHeader}
      />
      <MessageBody text={text} title={title}>
        <Button disabled={disabled} onClick={handleClick}>
          <span>{label}</span>
          {action === MessageActionDocupload.REQUESTED && (
            <React.Fragment>
              <Chevron />
              <span>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </React.Fragment>
          )}
        </Button>
        <MessageTemplateDocuploadIcon action={action} />
        <span></span>
        <MessageFooter dateFooter={dateFooter} dateTime={dateTime} />
      </MessageBody>
    </article>
  );
};

export default React.memo(MessageTemplateDocupload);
