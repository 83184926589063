import { UserGetActionApiResponse, UserData } from '../types';

type UpdateUser = (data: UserGetActionApiResponse['data']) => UserData | null;

const updateUser: UpdateUser = data =>
  data.id
    ? {
        emailAddress: data.emailAddress,
        firstName: data.firstName || '',
        surname: data.surname || '',
      }
    : null;

export default updateUser;
