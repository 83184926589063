import { useMemo } from 'react';

import { useDocuploadMessage } from 'modules/docupload';
import { UseMessageTemplateDocupload } from './types';
import { getMessageRecipient } from '../../utils';
import {
  getMessageDocumentUploadClassName,
  getMessageDocumentUploadLabel,
} from './utils';

const useMessageTemplateDocupload: UseMessageTemplateDocupload = ({
  className: defaultClassName,
  message,
  onClick,
}) => {
  const { active, disabled, handleClick } = useDocuploadMessage({
    message,
    onClick,
  });

  const {
    callToAction: { action, firstName, surname },
    name,
    text,
  } = message;

  const title = useMemo(
    () => getMessageRecipient(firstName, surname),
    [firstName, surname],
  );

  const label = useMemo(() => getMessageDocumentUploadLabel(action), [action]);

  const className = useMemo(
    () =>
      getMessageDocumentUploadClassName({
        action,
        active,
        defaultClassName,
        disabled,
      }),
    [action, active, defaultClassName, disabled],
  );

  return {
    action,
    className,
    disabled,
    handleClick,
    label,
    name,
    text,
    title,
  };
};

export default useMessageTemplateDocupload;
