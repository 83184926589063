import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Document } from 'modules/states/documents';

export const useDocuments = (documents: Document[]) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const panelRef = useRef<HTMLUListElement | null>(null);
  const documentTypes = useMemo(
    () => Array.from(new Set(documents.map(item => item.type))) as string[],
    [documents],
  );
  const documentLists = useMemo(
    () =>
      documentTypes.map(type =>
        documents.filter(document => document.type === type),
      ),
    [documents, documentTypes],
  );

  const [selectedTab, setSelectedTab] = useState<string | null>(
    documentTypes[0],
  );

  const handleClick = useCallback((item: string) => setSelectedTab(item), []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLLIElement>, item: string) => {
      event.key === 'Enter' && setSelectedTab(item);
    },
    [],
  );

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLLIElement>) => {
      const activeTab =
        listRef.current &&
        listRef.current.querySelector("[aria-selected='true']");

      if (listRef.current && activeTab) {
        const tabs = listRef.current.querySelectorAll("[role='tab']");
        const firstTab = tabs[0].getAttribute('aria-controls');
        const lastTab = tabs[tabs.length - 1].getAttribute('aria-controls');
        let nextTab = Array.from(tabs).indexOf(activeTab) + 1;
        let prevTab = Array.from(tabs).indexOf(activeTab) - 1;

        switch (event.key) {
          case 'ArrowLeft':
            event.preventDefault();
            prevTab = prevTab >= 0 ? prevTab : tabs.length - 1;
            setSelectedTab(tabs[prevTab].getAttribute('aria-controls'));
            (tabs[prevTab] as HTMLElement).focus();
            break;
          case 'ArrowRight':
            event.preventDefault();
            nextTab = nextTab && nextTab < tabs.length ? nextTab : 0;
            setSelectedTab(tabs[nextTab].getAttribute('aria-controls'));
            (tabs[nextTab] as HTMLElement).focus();
            break;
          case 'Home':
            event.preventDefault();
            setSelectedTab(firstTab);
            (tabs[0] as HTMLElement).focus();
            break;
          case 'End':
            event.preventDefault();
            setSelectedTab(lastTab);
            (tabs[tabs.length - 1] as HTMLElement).focus();
            break;
        }
      }
    },
    [listRef],
  );

  const handleFocus = useCallback(
    (event: KeyboardEvent) => {
      const focusableItems = document.body.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video',
      );
      if (panelRef.current === event.target && event.key === 'Tab') {
        const panelRefIndex = Array.from(focusableItems).indexOf(
          panelRef.current as Element,
        );
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
          if (event.shiftKey)
            (focusableItems[panelRefIndex - 1] as HTMLElement).focus();
          if (!event.shiftKey)
            (focusableItems[panelRefIndex + 1] as HTMLElement).focus();
        }
      }
    },
    [panelRef],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleFocus);
    return () => {
      window.removeEventListener('keyup', handleFocus);
    };
  }, [handleFocus]);

  return {
    handleClick,
    handleKeyDown,
    handleKeyUp,
    selectedTab,
    tabList: documentTypes,
    tabListRef: listRef,
    tabPanelRef: panelRef,
    tabPanels: documentLists,
  };
};
