import { StatusBarPluginInitIOS, StatusBarPluginUpdateIOS } from './types';
import { isLightRoute, setStyle } from '../utils';
import { StatusBarStyle } from '../constants';

export const statusBarPluginInitIOS: StatusBarPluginInitIOS = async path =>
  await statusBarPluginUpdateIOS(path);

export const statusBarPluginUpdateIOS: StatusBarPluginUpdateIOS =
  async path => {
    switch (true) {
      case isLightRoute(path):
        await setStyle(StatusBarStyle.Light);
        break;
      default:
        await setStyle(StatusBarStyle.Dark);
    }
  };
