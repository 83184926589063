import { useCallback, useRef, useState } from 'react';

import { useResizeObserver } from 'modules/hooks/useResizeObserver';

export const useDesktopMenu = () => {
  const [verticalValue, setVerticalValue] = useState<string>(
    `${window.innerHeight}px`,
  );
  const [activeItems, setActiveItems] = useState<string[]>([]);
  const [focused, setFocused] = useState<string>('');
  const ref = useRef<HTMLElement | null>(null);

  const calculateHeight = useCallback(() => {
    if (ref && ref.current) {
      setVerticalValue(
        `${Math.max(ref?.current.clientHeight, window.innerHeight)}px`,
      );
    }
  }, [ref]);

  useResizeObserver(ref, calculateHeight);

  const handleMouseOver = useCallback(
    (id: string) => {
      if (!activeItems.includes(`${id}-active`)) {
        setActiveItems(prevState => [...prevState, `${id}-active`]);
      }
    },
    [activeItems],
  );

  const handleMouseOut = useCallback(
    (id: string) => {
      if (focused !== `${id}-active`) {
        setActiveItems(prevState =>
          prevState.filter(item => item !== `${id}-active`),
        );
      }
    },
    [focused],
  );

  const handleFocus = useCallback(
    (id: string) => {
      setFocused(`${id}-active`);
      if (!activeItems.includes(`${id}-active`)) {
        setActiveItems(prevState => [...prevState, `${id}-active`]);
      }
    },
    [activeItems],
  );

  const handleBlur = useCallback((id: string) => {
    setFocused('');
    setActiveItems(prevState =>
      prevState.filter(item => item !== `${id}-active`),
    );
  }, []);

  return {
    activeItems,
    handleBlur,
    handleFocus,
    handleMouseOver,
    handleMouseOut,
    gradientValue: verticalValue,
    navRef: ref,
  };
};
