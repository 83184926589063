import React from 'react';

import StatusProgressBar from 'components/shared/StatusProgressBar';
import { StatusProgress, StatusStep } from 'modules/states/status';
import { Case } from 'modules/states/cases';
import StatusItem from './StatusItem';

import styles from './styles.module.scss';

type StatusProps = {
  data: Case;
  handleVideo?: () => void;
  isPlaying: boolean;
};

const Status: React.FunctionComponent<StatusProps> = ({
  data,
  handleVideo,
  isPlaying,
}) => {
  return (
    <div className={styles.status}>
      {data.progress !== StatusProgress.UNKNOWN && (
        <div>
          <StatusProgressBar progress={data.progress} />
        </div>
      )}
      <StatusItem
        handleVideo={handleVideo}
        isPlaying={isPlaying}
        status={data.status}
        step={StatusStep.STEP1}
      />
      <StatusItem
        handleVideo={handleVideo}
        isPlaying={isPlaying}
        status={data.status}
        step={StatusStep.STEP2}
      />
      <StatusItem
        handleVideo={handleVideo}
        isPlaying={isPlaying}
        status={data.status}
        step={StatusStep.STEP3}
      />
      <StatusItem
        handleVideo={handleVideo}
        isPlaying={isPlaying}
        status={data.status}
        step={StatusStep.STEP4}
      />
      <StatusItem
        handleVideo={handleVideo}
        isPlaying={isPlaying}
        status={data.status}
        step={StatusStep.STEP5}
      />
    </div>
  );
};

export default React.memo(Status);
