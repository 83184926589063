import { useCallback } from 'react';

import { Dispatch, useDispatch, useSelector } from 'modules/redux';
import { usePlatformContext } from 'modules/services/platform';
import { getDialog } from '../../utils';
import {
  dialogDestroyAction,
  dialogHideAction,
  dialogShowAction,
} from '../../actions';
import {
  DialogDestroy,
  DialogHide,
  DialogShow,
  UseDialogAction,
} from './types';

const useDialogAction: UseDialogAction = () => {
  const dialogs = useSelector(state => state.dialogs);
  const { isDesktop } = usePlatformContext();
  const dispatch: Dispatch = useDispatch();

  const dialogDestroy: DialogDestroy = useCallback(
    dialogId =>
      !!getDialog(dialogs, dialogId) && dispatch(dialogDestroyAction(dialogId)),
    [dialogs, dispatch],
  );

  const dialogHide: DialogHide = useCallback(
    dialogId =>
      !!getDialog(dialogs, dialogId)?.open &&
      dispatch(
        isDesktop ? dialogHideAction(dialogId) : dialogDestroyAction(dialogId),
      ),
    [dialogs, dispatch, isDesktop],
  );

  const dialogShow: DialogShow = useCallback(
    dialog => dispatch(dialogShowAction(dialog)),
    [dispatch],
  );

  return { dialogDestroy, dialogHide, dialogShow };
};

export default useDialogAction;
