import { RootState } from 'modules/redux/store';

type IsPresent = (props: {
  messageId: number | null;
  state: RootState;
}) => boolean;

const isPresent: IsPresent = ({ messageId, state }) =>
  !!state.auth.data?.token &&
  messageId === state.docupload.messageId &&
  state.docupload.isLoading;

export default isPresent;
