import { NativeBiometric } from '@capgo/capacitor-native-biometric';

import { localStorageSetObject } from 'modules/storage';
import { platform } from 'modules/services/platform';

const { isDevelopment } = platform;

type SaveCredentials = (props: {
  username: string;
  password: string;
}) => Promise<void>;

const saveCredentials: SaveCredentials = async ({ username, password }) => {
  try {
    isDevelopment
      ? localStorageSetObject('CREDENTIALS', { username, password })
      : await NativeBiometric.setCredentials({
          username,
          password,
          server: `com.fluent.fluentview:${username}`,
        });
  } catch {
    return void 0;
  }
};

export default saveCredentials;
