import { useMemo } from 'react';

import { Case, useCases } from 'modules/states/cases';

export type UseCase = () => {
  caseId: number | null;
  data: Case | null;
  isCaseActive: boolean;
};

const useCase: UseCase = () => {
  const { caseId, cases } = useCases();

  const data = useMemo(
    () => cases?.find(item => item.caseId === caseId) || null,
    [caseId, cases],
  );

  const isCaseActive = !data || data?.isCaseActive;

  return { caseId, data, isCaseActive };
};

export default useCase;
