import { StatusStep, StatusType } from 'modules/states/status';

type GetStatusStep = (status: StatusType) => StatusStep;

const getStatusStep: GetStatusStep = status => {
  switch (status) {
    case StatusType.CAN:
    case StatusType.CAN_LEN:
    case StatusType.CAN_REF:
    case StatusType.CAN_RES:
      return StatusStep.CANCELLED;
    case StatusType.DEC:
    case StatusType.DEC_LEN:
    case StatusType.DEC_REF:
    case StatusType.DEC_RES:
      return StatusStep.DECLINED;
    case StatusType.ISS:
      return StatusStep.STEP1;
    case StatusType.RES:
      return StatusStep.STEP2;
    case StatusType.REF:
      return StatusStep.STEP3;
    case StatusType.LEN:
      return StatusStep.STEP4;
    case StatusType.COM:
      return StatusStep.STEP5;
    case StatusType.SEA:
    case StatusType.SOL:
    default:
      return StatusStep.UNKNOWN;
  }
};

export default getStatusStep;
