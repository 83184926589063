import { CaseType } from '../constants';

type NormalizeCaseType = (productType: CaseType) => CaseType;

const normalizeCaseType: NormalizeCaseType = productType =>
  productType === CaseType.FLUENT_EQUITY_RELEASE
    ? CaseType.FLUENT_LIFETIME
    : productType;

export default normalizeCaseType;
