import { Status, StatusState } from '../types';

type FindStatus = (
  array: StatusState,
  caseId: number | null,
) => Status | undefined;

const findStatus: FindStatus = (array, caseId) =>
  array.find(item => item.caseId === caseId);

export default findStatus;
