import { AuthBiometricStatus } from '../constants';
import { biometricPluginStatus } from '../plugin';

type GetBiometricStatus = () => Promise<AuthBiometricStatus>;

const getBiometricStatus: GetBiometricStatus = async () => {
  try {
    return await biometricPluginStatus();
  } catch {
    return AuthBiometricStatus.UNAVAILABLE;
  }
};

export default getBiometricStatus;
