import { onfidoCloseAction, onfidoDestroyAction } from '../actions';
import { Dispatch, RootState } from 'modules/redux';

type CloseModal = (
  dispatch: Dispatch,
  getState: () => RootState,
  destroy?: false,
) => void;

const closeModal: CloseModal = (dispatch, getState, destroy) =>
  dispatch(
    getState().platform.isCapacitor
      ? onfidoDestroyAction(destroy)
      : onfidoCloseAction(),
  );

export default closeModal;
