import { useHandleError, useHandleSubmit } from './hooks';
import { Dispatch, useDispatch } from 'modules/redux';
import useAuthSelector from '../useAuthSelector';
import { UsePasswordRoute } from './types';
import {
  authChangePasswordDefaultValues,
  authChangePasswordSchema,
} from '../../actions';

const usePasswordRoute: UsePasswordRoute = () => {
  const { isError, isLoading } = useAuthSelector();
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = useHandleSubmit(dispatch);
  useHandleError({ dispatch, isError });

  return {
    defaultValues: authChangePasswordDefaultValues,
    handleSubmit,
    isError,
    isLoading,
    schema: authChangePasswordSchema,
  };
};

export default usePasswordRoute;
