import { NativeBiometric } from '@capgo/capacitor-native-biometric';

type VerifyCredentials = () => Promise<void>;

const verifyCredentials: VerifyCredentials = async () =>
  NativeBiometric.verifyIdentity({
    reason: 'Access your MyFluent account',
    title: 'Login to MyFluent',
  });

export default verifyCredentials;
