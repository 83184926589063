import { Middleware } from 'redux';

import { splashScreenPluginHide, splashScreenPluginShow } from './plugin';
import { statusBarPluginUpdate } from 'modules/services/statusbar/plugin';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { splashScreenHideAction } from './actions';
import { SplashScreenAction } from './constants';
import { SplashScreenActionType } from './types';

const splashScreenMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | SplashScreenActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INITIALIZED:
        nextAction = next(action);
        getState().platform.isCapacitor && dispatch(splashScreenHideAction());
        break;

      case SplashScreenAction.SHOW:
        splashScreenPluginShow();
        break;

      case SplashScreenAction.HIDE: {
        await splashScreenPluginHide();
        const pathname = getState().router.location?.pathname;
        !!pathname && statusBarPluginUpdate(pathname);
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default splashScreenMiddleware;
