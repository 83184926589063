import { produce } from 'immer';

import { Docupload, DocuploadActionType } from './types';
import { DocuploadAction } from './constants';
import {
  docuploadPluginReducer,
  docuploadUploadReducer,
  docuploadPostReducer,
  docuploadShowReducer,
} from './actions';

const initialState = (): Docupload => {
  return {
    actionId: null,
    caseId: null,
    data: [],
    isError: false,
    isLoading: false,
    isSending: false,
    isSendingError: false,
    isSendingProgress: 0,
    messageId: null,
    modalId: null,
    uploadURL: null,
  };
};

function docuploadReducer(state = initialState(), action: DocuploadActionType) {
  switch (action.type) {
    case DocuploadAction.PLUGIN_CAMERA_FAILURE:
    case DocuploadAction.PLUGIN_CAMERA_STARTED:
    case DocuploadAction.PLUGIN_CAMERA_SUCCESS:
      return docuploadPluginReducer(state, action);

    case DocuploadAction.PLUGIN_DELETE_FAILURE:
    case DocuploadAction.PLUGIN_DELETE_STARTED:
    case DocuploadAction.PLUGIN_DELETE_SUCCESS:
      return docuploadPluginReducer(state, action);

    case DocuploadAction.PLUGIN_FILE_FAILURE:
    case DocuploadAction.PLUGIN_FILE_STARTED:
    case DocuploadAction.PLUGIN_FILE_SUCCESS:
      return docuploadPluginReducer(state, action);

    case DocuploadAction.PLUGIN_INPUT_FAILURE:
    case DocuploadAction.PLUGIN_INPUT_STARTED:
    case DocuploadAction.PLUGIN_INPUT_SUCCESS:
      return docuploadPluginReducer(state, action);

    case DocuploadAction.PLUGIN_PREVIEW_FAILURE:
    case DocuploadAction.PLUGIN_PREVIEW_STARTED:
    case DocuploadAction.PLUGIN_PREVIEW_SUCCESS:
      return docuploadPluginReducer(state, action);

    case DocuploadAction.POST_FAILURE:
    case DocuploadAction.POST_PROGRESS:
    case DocuploadAction.POST_STARTED:
    case DocuploadAction.POST_SUCCESS:
      return docuploadPostReducer(state, action);

    case DocuploadAction.SHOW_FAILURE:
    case DocuploadAction.SHOW_STARTED:
    case DocuploadAction.SHOW_SUCCESS:
      return docuploadShowReducer(state, action);

    case DocuploadAction.UPLOAD_FAILURE:
    case DocuploadAction.UPLOAD_STARTED:
    case DocuploadAction.UPLOAD_SUCCESS:
      return docuploadUploadReducer(state, action);

    case DocuploadAction.CANCEL:
      return state;

    case DocuploadAction.CLOSE:
      return state;

    case DocuploadAction.DESTROY:
      return initialState();

    case DocuploadAction.LOGOUT:
      return initialState();

    case DocuploadAction.RESET_ERROR:
      return produce(state, (draftState: Docupload) => {
        draftState.isError = false;
        draftState.isSendingError = false;
      });

    default:
      return state;
  }
}

export default docuploadReducer;
