import React from 'react';

import { MessageActionDocupload } from 'modules/states/messages';
import { MessageTemplateDocuploadIconProps } from './types';
import {
  DefaultSVG,
  OrangeSVG,
  GreenSVG,
  RedSVG,
} from 'modules/theme/images/messages/document-upload';

const MessageTemplateDocuploadIcon: React.FunctionComponent<
  MessageTemplateDocuploadIconProps
> = ({ action }) => {
  switch (action) {
    case MessageActionDocupload.APPROVED:
      return <span style={{ backgroundImage: `url(${GreenSVG})` }} />;
    case MessageActionDocupload.AWAITING_REVIEW:
      return <span style={{ backgroundImage: `url(${OrangeSVG})` }} />;
    case MessageActionDocupload.CANCELLED:
    case MessageActionDocupload.DECLINED:
      return <span style={{ backgroundImage: `url(${RedSVG})` }} />;
    case MessageActionDocupload.REQUESTED:
    default:
      return <span style={{ backgroundImage: `url(${DefaultSVG})` }} />;
  }
};

export default React.memo(MessageTemplateDocuploadIcon);
