import React from 'react';

import Password from 'components/mobile/Password';
import { UsePasswordRoute } from 'modules/auth';
import { Dialogs } from 'modules/dialogs';

import styles from './styles.module.scss';

type TemplateProps = ReturnType<UsePasswordRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  ...props
}) => {
  return (
    <React.Fragment>
      <main className={styles.password}>
        <Password isLoading={isLoading} {...props} />
        {isLoading && <div className={styles.loading}></div>}
      </main>
      <div className={styles['modals']}>
        <Dialogs />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Template);
