import { docuploadPostAction } from '../docuploadPostAction';
import { timeout } from 'modules/redux/factories/utils';
import { docuploadPluginFormData } from '../../plugin';
import { DocuploadAction } from '../../constants';
import { isPresent } from '../../utils';
import { getError } from './utils';
import {
  DocuploadUploadFailureAction,
  DocuploadUploadStartedAction,
  DocuploadUploadSuccessAction,
  DocuploadUploadAction,
} from './types';

export const docuploadUploadAction: DocuploadUploadAction = () => {
  return async (dispatch, getState) => {
    const { caseId, messageId, modalId, uploadURL } = getState().docupload;
    const request = { caseId, messageId, modalId, uploadURL };
    const startTime = new Date().getTime();
    try {
      if (!getState().auth.data?.token) return;

      dispatch(docuploadUploadStartedAction(request));

      const data = await docuploadPluginFormData();

      if (caseId && messageId && uploadURL)
        await dispatch(
          docuploadPostAction({
            caseId,
            data,
            messageId,
            uploadURL,
          }),
        );

      await timeout(startTime, 1000);

      isPresent({ messageId, state: getState() }) &&
        dispatch(docuploadUploadSuccessAction(request));
    } catch (error) {
      await timeout(startTime, 1000);

      isPresent({ messageId, state: getState() }) &&
        dispatch(
          docuploadUploadFailureAction({
            error: getError(error),
            ...request,
          }),
        );
    }
  };
};

const docuploadUploadStartedAction: DocuploadUploadStartedAction = payload => ({
  type: DocuploadAction.UPLOAD_STARTED,
  ...payload,
});

const docuploadUploadSuccessAction: DocuploadUploadSuccessAction = payload => ({
  type: DocuploadAction.UPLOAD_SUCCESS,
  ...payload,
});

const docuploadUploadFailureAction: DocuploadUploadFailureAction = payload => ({
  type: DocuploadAction.UPLOAD_FAILURE,
  ...payload,
});
