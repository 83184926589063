import yup from 'modules/validation';

import ValdationMessages from '../messages';

const regex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])');

export const validateNewPassword = (value: string): boolean =>
  regex.test(value) ? true : false;

function validator(this: yup.StringSchema) {
  return this.test(
    'newPassword',
    ValdationMessages.NEW_PASSWORD,
    function (value) {
      return value ? validateNewPassword(value) : true;
    },
  );
}

export default validator;
