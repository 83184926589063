import React from 'react';
import clsx from 'clsx';

import { OverlayProps } from './types';
import { useFocusLock } from 'modules/hooks/useFocusLock';
import { useOverlay } from './hooks';

import styles from './styles.module.scss';

export { OverlayAnimation } from './constants';

const Overlay: React.FunctionComponent<OverlayProps> = ({
  animation,
  children,
  disableEscapeKey = false,
  id,
  onDestroy,
  onEscapeKey,
  open,
}) => {
  const { animating, ref } = useOverlay({
    animation,
    disableEscapeKey,
    onDestroy,
    onEscapeKey,
  });

  useFocusLock({ isLocked: open || animating, rootNode: ref });

  const classNames = clsx(
    styles['overlay'],
    styles[`${animation}`],
    animating && styles.animating,
    open ? styles.open : styles.closing,
  );

  return (
    <div className={classNames} id={`dialog-${id}`} ref={ref} tabIndex={0}>
      <div>
        {React.Children.map(children, child =>
          React.isValidElement(child)
            ? React.cloneElement(child as React.ReactElement, { animating })
            : child,
        )}
      </div>
    </div>
  );
};

export default React.memo(Overlay);
