import { useCallback, useEffect, useRef, useState } from 'react';

import { menuCloseAction, menuOpenAction } from 'modules/states/menu';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseMenu = {
  animating: boolean;
  handleCloseMenu: () => void;
  handleOpenMenu: () => void;
  menuRef: React.MutableRefObject<HTMLDivElement | null>;
  open: boolean;
};

const useMenu = (animationName?: { in: string; out: string }): UseMenu => {
  const { open } = useSelector(state => state.menu, shallowEqual);
  const [animating, setAnimating] = useState(false);
  const dispatch: Dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleAnimationEnd = useCallback(
    (event: AnimationEvent) => {
      if (
        animationName &&
        event.currentTarget === menuRef.current &&
        (event.animationName === animationName.in ||
          event.animationName === animationName.out)
      ) {
        setAnimating(false);
        document.documentElement.classList.remove('menu-animating');
      }
    },
    [animationName],
  );

  const handleCloseMenu = useCallback(() => {
    setAnimating(true);
    dispatch(menuCloseAction());
    document.documentElement.classList.remove('menu-open');
    document.documentElement.classList.add('menu-animating');
  }, [dispatch]);

  const handleOpenMenu = useCallback(() => {
    setAnimating(true);
    dispatch(menuOpenAction());
    document.documentElement.classList.add('menu-open');
    document.documentElement.classList.add('menu-animating');
  }, [dispatch]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !animating && open) {
        handleCloseMenu();
      }
      if (event.key === 'Tab' && animating) {
        event.preventDefault();
      }
    },
    [animating, open, handleCloseMenu],
  );

  useEffect(() => {
    const element = menuRef.current;
    element?.addEventListener('animationend', handleAnimationEnd);
    return () => {
      element?.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [handleAnimationEnd, menuRef]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, open]);

  useEffect(() => {
    return () => {
      document.documentElement.classList.remove('menu-open');
    };
  }, [dispatch]);

  return {
    animating,
    handleCloseMenu,
    handleOpenMenu,
    menuRef,
    open,
  };
};

export default useMenu;
