import { ResizeObserver } from '@juggle/resize-observer';
import 'intersection-observer';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './modules/app';

import 'modules/theme/sass/index.scss';

if ('ResizeObserver' in window === false) {
  window.ResizeObserver = ResizeObserver;
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
