import { produce } from 'immer';

import { DocuploadActionType, DocuploadAction } from 'modules/docupload';
import { OnfidoAction, OnfidoActionType } from 'modules/onfido';
import { MessagesState, MessagesActionType } from './types';
import { MessagesAction } from './constants';
import {
  updateMessageDocuploadStatus,
  updateMessageOnfidoStatus,
} from './utils';
import {
  messagesReadReducer,
  messagesPostReducer,
  messagesGetReducer,
} from './actions';

const initialState = (): MessagesState => {
  return {
    data: [],
    timer: null,
  };
};

function messagesReducer(
  state = initialState(),
  action: DocuploadActionType | MessagesActionType | OnfidoActionType,
) {
  switch (action.type) {
    case MessagesAction.GET_FAILURE:
    case MessagesAction.GET_STARTED:
    case MessagesAction.GET_SUCCESS:
      return messagesGetReducer(state, action);

    case MessagesAction.POST_FAILURE:
    case MessagesAction.POST_STARTED:
    case MessagesAction.POST_SUCCESS:
    case MessagesAction.POST_RESET:
      return messagesPostReducer(state, action);

    case MessagesAction.READ_FAILURE:
    case MessagesAction.READ_STARTED:
    case MessagesAction.READ_SUCCESS:
      return messagesReadReducer(state, action);

    case DocuploadAction.POST_SUCCESS:
      return produce(state, (draftState: MessagesState) => {
        updateMessageDocuploadStatus(draftState.data, action.request);
      });

    case OnfidoAction.POST_SUCCESS:
      return produce(state, (draftState: MessagesState) => {
        updateMessageOnfidoStatus(draftState.data, action.request);
      });

    case MessagesAction.POLLING_ENDED:
      return produce(state, (draftState: MessagesState) => {
        draftState.timer = null;
      });

    case MessagesAction.POLLING_STARTED:
      return produce(state, (draftState: MessagesState) => {
        draftState.timer = action.timer;
      });

    case MessagesAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default messagesReducer;
