import { NativeBiometric } from '@capgo/capacitor-native-biometric';

import { localStorageRemoveItem } from 'modules/storage';
import { platform } from 'modules/services/platform';

const { isDevelopment } = platform;

type DeleteCredentials = (username: string) => Promise<void>;

const deleteCredentials: DeleteCredentials = async username => {
  try {
    isDevelopment
      ? localStorageRemoveItem('CREDENTIALS')
      : await NativeBiometric.deleteCredentials({
          server: `com.fluent.fluentview:${username}`,
        });
  } catch {
    return void 0;
  }
};

export default deleteCredentials;
