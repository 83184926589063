import { MessagesGetActionApiResponseItemCTA } from '../../types';
import { MessageActionDocupload } from '../../../../constants';
import { MessageDocupload } from '../../../../types';

type NewMessageDocumentUpload = (
  data: MessagesGetActionApiResponseItemCTA,
  message: MessageDocupload,
) => void;

const newMessageDocumentUpload: NewMessageDocumentUpload = (data, message) => {
  message.callToAction = {
    action: data.action as MessageActionDocupload,
    docType:
      data.attributes?.find(element => element.key === 'DocumentUploadTypeName')
        ?.value || null,
    firstName: data.firstName || '',
    surname: data.surname || '',
    uploadURL: data.uploadURL || '',
  };
};

export default newMessageDocumentUpload;
