import { Middleware } from 'redux';

import { jailbreakInitAction, jailbreakResultAction } from './actions';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { jailbreakPluginInit } from './plugin';
import { JailbreakActionType } from './types';
import { JailbreakAction } from './constants';

const jailbreakMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | JailbreakActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT: {
        nextAction = next(action);
        getState().platform.isCapacitor && dispatch(jailbreakInitAction());
        break;
      }

      case JailbreakAction.INIT:
        jailbreakPluginInit(status => dispatch(jailbreakResultAction(status)));
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default jailbreakMiddleware;
