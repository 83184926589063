import { createDefaultAction } from 'modules/redux/factories';
import { isActive, isPresent } from './utils';
import { LoanAction } from './constants';
import { API } from 'modules/api';
import {
  LoanGetFailureAction,
  LoanGetStartedAction,
  LoanGetSuccessAction,
  LoanLogoutAction,
  LoanGetAction,
} from './types';

export const loanGetAction: LoanGetAction = request =>
  createDefaultAction({
    actionStarted: loanGetStartedAction,
    actionSuccess: loanGetSuccessAction,
    actionFailure: loanGetFailureAction,
    endpoint: API.GET_LOAN,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const loanGetStartedAction: LoanGetStartedAction = payload => ({
  type: LoanAction.GET_STARTED,
  ...payload,
});

const loanGetSuccessAction: LoanGetSuccessAction = payload => ({
  type: LoanAction.GET_SUCCESS,
  ...payload,
});

const loanGetFailureAction: LoanGetFailureAction = payload => ({
  type: LoanAction.GET_FAILURE,
  ...payload,
});

export const loanLogoutAction: LoanLogoutAction = () => ({
  type: LoanAction.LOGOUT,
});
