import { v4 as uuidv4 } from 'uuid';

import { createDefaultActionProgress, isCurrentAction } from './utils';
import { isAxiosAbortError, isUnauthorizedError } from 'modules/api';
import { DefaultApiResponse } from 'modules/api/types';
import { api, newAbortController } from 'modules/api';
import { Dispatch, RootState } from 'modules/redux';
import { CreateDefaultAction } from './types';
import { isError } from 'modules/utils';
import { timeout } from '../utils';

//import { API } from 'modules/api';
//import sleep from 'sleep-promise';

export const createDefaultAction =
  <Request, Response>({
    actionFailure,
    actionProgressDownload,
    actionProgressUpload,
    actionStarted,
    actionSuccess,
    endpoint,
    isActive,
    isAuthorized,
    isPresent,
    request,
    throwable,
  }: CreateDefaultAction<Request, Response>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const startTime = new Date().getTime();
    const actionId = uuidv4();
    let state = getState();
    try {
      if (!isAuthorized(state) || isActive({ request, state })) return;

      const controller = newAbortController();

      const onDownloadProgress = createDefaultActionProgress<Request>({
        action: actionProgressDownload,
        actionId,
        dispatch,
        getState,
        isActive,
        isAuthorized,
        isPresent,
        request,
      });

      const onUploadProgress = createDefaultActionProgress<Request>({
        action: actionProgressUpload,
        actionId,
        dispatch,
        getState,
        isActive,
        isAuthorized,
        isPresent,
        request,
      });

      dispatch(actionStarted({ actionId, controller, request }));

      //eslint-disable-next-line
      // if ((endpoint as any) === API.POST_PASSWORD) {
      //   throw new Error('TEST');
      // }

      //eslint-disable-next-line
      // if ((endpoint as any) === API.POST_MESSAGE) {
      //   await sleep(10000);
      // }

      const response: DefaultApiResponse = await api(
        endpoint({
          controller,
          dispatch,
          getState,
          onDownloadProgress,
          onUploadProgress,
          request,
          token: state.auth.data?.token as string,
        }),
      );

      await timeout(startTime, 1000);
      state = getState();

      if (
        isCurrentAction({
          actionId,
          isActive,
          isAuthorized,
          isPresent,
          request,
          state,
        })
      ) {
        dispatch(actionSuccess({ actionId, data: response.data, request }));

        return response.data;
      }
    } catch (error) {
      await timeout(startTime, 1000);
      state = getState();

      if (
        !isUnauthorizedError(error) &&
        !isAxiosAbortError(error) &&
        isCurrentAction({
          actionId,
          isActive,
          isAuthorized,
          isPresent,
          request,
          state,
        })
      ) {
        dispatch(
          actionFailure({
            actionId,
            error: isError(error) ? error : new Error('Unexpected Error'),
            request,
          }),
        );

        if (throwable) {
          throw isError(error) ? error : new Error('Unexpected Error');
        }
      }
    }
  };
