import { Dispatch, RootState } from 'modules/redux';
import { middlewareNavigate } from 'modules/router';
import { authLogoutAction } from 'modules/auth';

enum OnfidoTest {
  DISABLED = 'disabled',
  LOGOUT = 'logout',
  NAVIGATION = 'navigation',
}

const test = OnfidoTest.DISABLED as OnfidoTest;

type TestOnfido = (dispatch: Dispatch, getState: () => RootState) => void;

const testOnfido: TestOnfido = (dispatch, getState) => {
  const { cases, platform } = getState();
  switch (true) {
    case platform.isAdmin && test === OnfidoTest.LOGOUT:
      setTimeout(() => dispatch(authLogoutAction()), 7000);
      break;
    case platform.isAdmin && test === OnfidoTest.NAVIGATION:
      setTimeout(
        () => middlewareNavigate(dispatch, `/${cases.caseId}/status`),
        7000,
      );
      break;
  }
};

export default testOnfido;
