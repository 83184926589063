import { produce } from 'immer';

import { Notifications, NotificationsActionType } from '../../types';
import { NotificationsAction } from '../../constants';

function notificationsRegisterReducer(
  state: Notifications,
  action: NotificationsActionType,
) {
  switch (action.type) {
    case NotificationsAction.REGISTER_FAILURE:
      return state;

    case NotificationsAction.REGISTER_STARTED:
      return state;

    case NotificationsAction.REGISTER_SUCCESS:
      return produce(state, (draftState: Notifications) => {
        draftState.token = action.result.value;
      });

    default:
      return state;
  }
}

export default notificationsRegisterReducer;
