import { docuploadCloseAction, docuploadDestroyAction } from '../../';
import { modalShowAction } from 'modules/modals/actions';
import { ModalType } from 'modules/modals';
import { Dispatch } from 'modules/redux';

type ShowDocupload = (props: {
  caseId: number;
  dispatch: Dispatch;
  messageId: number;
  uploadURL: string;
}) => string;

const showDocupload: ShowDocupload = ({ dispatch, ...payload }) =>
  dispatch(
    modalShowAction({
      onClose: () => dispatch(docuploadCloseAction()),
      onDestroy: () => dispatch(docuploadDestroyAction()),
      payload,
      placeholder: false,
      type: ModalType.DOCUMENT_UPLOAD,
    }),
  );

export default showDocupload;
