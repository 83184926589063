import { AppStatus, AppStatusActionType } from './types';
import { AppStatusAction } from './constants';

const initialState = (): AppStatus => {
  return true;
};

function appStatusReducer(state = initialState(), action: AppStatusActionType) {
  switch (action.type) {
    case AppStatusAction.INIT:
      return action.state;

    case AppStatusAction.ACTIVE:
      return true;

    case AppStatusAction.INACTIVE:
      return false;

    default:
      return state;
  }
}

export default appStatusReducer;
