import { v4 as uuidv4 } from 'uuid';

import { docuploadPluginCamera } from '../../../../plugin';
import { timeout } from 'modules/redux/factories/utils';
import { getError, isPresent } from '../../../../utils';
import {
  DocuploadPluginCameraFailureAction,
  DocuploadPluginCameraStartedAction,
  DocuploadPluginCameraSuccessAction,
  DocuploadPluginCameraAction,
} from './types';
import {
  DocuploadFileType,
  DocuploadAction,
  DocuploadError,
} from '../../../../constants';

export const docuploadPluginCameraAction: DocuploadPluginCameraAction = () => {
  return async (dispatch, getState) => {
    const { messageId } = getState().docupload;
    const startTime = new Date().getTime();
    const config = getState().config;
    try {
      if (!getState().auth.data?.token) return;

      dispatch(docuploadPluginCameraStartedAction());

      const result = await docuploadPluginCamera(config);

      await timeout(startTime, 1000);

      isPresent({ messageId, state: getState() }) &&
        dispatch(
          docuploadPluginCameraSuccessAction({
            id: uuidv4(),
            name: 'image.jpg',
            pages: result,
            type: DocuploadFileType.JPEG,
          }),
        );
    } catch (error) {
      await timeout(startTime, 1000);

      isPresent({ messageId, state: getState() }) &&
        dispatch(
          docuploadPluginCameraFailureAction(
            getError(error, DocuploadError.IMAGE),
          ),
        );
    }
  };
};

const docuploadPluginCameraStartedAction: DocuploadPluginCameraStartedAction =
  () => ({
    type: DocuploadAction.PLUGIN_CAMERA_STARTED,
  });

const docuploadPluginCameraSuccessAction: DocuploadPluginCameraSuccessAction =
  file => ({
    type: DocuploadAction.PLUGIN_CAMERA_SUCCESS,
    file,
  });

const docuploadPluginCameraFailureAction: DocuploadPluginCameraFailureAction =
  error => ({
    type: DocuploadAction.PLUGIN_CAMERA_FAILURE,
    error,
  });
