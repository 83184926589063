import { PushNotifications } from '@capacitor/push-notifications';

import { NotificationsStatus } from '../../constants';
import { isNotificationsStatus } from '../../utils';
import { RequestPermissions } from './types';

const requestPermissions: RequestPermissions = async () => {
  const { receive } = await PushNotifications.requestPermissions();
  return isNotificationsStatus(receive) ? receive : NotificationsStatus.DENIED;
};

export default requestPermissions;
