import { useCallback, useRef, useState } from 'react';

import { isAnimation, isAnimationIn, isAnimationOut, isTarget } from './utils';
import { useDesktopScreenLock } from 'modules/hooks/useDesktopScreenLock';
import { UseOverlay } from './types';
import useAnimation from '../useAnimation';
import useKeyDown from '../useKeyDown';

const useOverlay: UseOverlay = ({
  animation,
  disableEscapeKey,
  onDestroy,
  onEscapeKey,
}) => {
  const [animating, setAnimating] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const { lockScreen, unlockScreen } = useDesktopScreenLock();

  useAnimation({
    listener: useCallback(
      (event: AnimationEvent) => {
        if (isTarget(event, ref) && isAnimation(animation, event)) {
          setAnimating(true);
        }
        if (isTarget(event, ref) && isAnimationIn(animation, event)) {
          lockScreen();
        }
      },
      [animation, lockScreen, ref],
    ),
    ref,
    type: 'animationstart',
  });

  useAnimation({
    listener: useCallback(
      (event: AnimationEvent) => {
        if (isTarget(event, ref) && isAnimation(animation, event))
          setAnimating(false);
        if (isTarget(event, ref) && isAnimationOut(animation, event)) {
          onDestroy();
          unlockScreen();
        }
      },
      [animation, onDestroy, ref, unlockScreen],
    ),
    ref,
    type: 'animationend',
  });

  useKeyDown({
    listener: useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Escape' && !animating && !disableEscapeKey) {
          onEscapeKey && onEscapeKey();
          event.stopPropagation();
        }
      },
      [animating, disableEscapeKey, onEscapeKey],
    ),
    ref,
  });

  return { animating, ref };
};

export default useOverlay;
