import React from 'react';
import clsx from 'clsx';

import { ReactComponent as People } from 'modules/theme/images/status/people.svg';
import { StatusStep } from 'modules/states/status';

import './styles.scss';

type StatusPeopleProps = {
  step: StatusStep;
};

const StatusPeople: React.FunctionComponent<StatusPeopleProps> = ({ step }) => {
  const className = clsx('status-people', `${step.toLowerCase()}`);
  return (
    <div className={className}>
      <People />
    </div>
  );
};

export default React.memo(StatusPeople);
