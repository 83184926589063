import React from 'react';

import { useDialogAlert, useDialogConfirm } from 'modules/dialogs';
import { ButtonStyle } from 'components/shared/Button/constants';
import Button from 'components/shared/Button';
import Fieldset from 'components/shared/Fieldset';

import styles from 'routes/debug/styles.module.scss';

const ModalsDebug: React.FunctionComponent = () => {
  const [showDialogConfirm] = useDialogConfirm();
  const [showDialogAlert] = useDialogAlert();

  const handlDialogAlert = () => {
    !!showDialogAlert &&
      showDialogAlert({
        message: 'Dialog alert message',
        title: 'Dialog Alert Title',
      });
  };

  const handleDialogConfirm = () => {
    !!showDialogConfirm &&
      showDialogConfirm({
        message: 'Dialog confirm message',
        title: 'Dialog Confirm Title',
      });
  };

  const handleDialogTest = () => {
    handlDialogAlert();
    handleDialogConfirm();
  };

  return (
    <React.Fragment>
      <div className={styles['dialogs-container']}>
        <Fieldset title="Dialogs">
          <div>
            <Button
              label="Dialog - Alert"
              onClick={handlDialogAlert}
              style={ButtonStyle.PRIMARY}
            />

            <Button
              label="Dialog - Confirm"
              onClick={handleDialogConfirm}
              style={ButtonStyle.PRIMARY}
            />

            <Button
              label="Dialog - Test"
              onClick={handleDialogTest}
              style={ButtonStyle.PRIMARY}
            />
          </div>
        </Fieldset>
      </div>
    </React.Fragment>
  );
};

export default React.memo(ModalsDebug);
