import React from 'react';

import Button, { ButtonSize, ButtonStyle } from 'components/shared/Button';
import Overlay, { OverlayAnimation } from 'components/shared/Overlay';
import { ModalVideoProps, ModalVideoTemplateProps } from './types';
import { usePlatformContext } from 'modules/services/platform';

import styles from './styles.module.scss';

export type { ModalVideoProps } from './types';

const ModalVideoTemplate = React.memo<ModalVideoTemplateProps>(
  ({ animating, onClose, video }) => {
    const { isMobile } = usePlatformContext();
    return (
      <div aria-label="Video" className={styles['modal-video']} role="dialog">
        {!isMobile && (
          <Button
            ariaLabel={'Close Video'}
            disabled={!!animating}
            onClick={onClose}
            title={'Close Video'}
          />
        )}
        <iframe
          allowFullScreen
          frameBorder={0}
          src={`https://player.vimeo.com/video/${video.code}?autoplay=1`}
          title="MyFluent"
        />
        {isMobile && (
          <Button
            chevron={false}
            disabled={!!animating}
            label={'Close Video'}
            onClick={onClose}
            size={ButtonSize.LARGE}
            style={ButtonStyle.PRIMARY}
          />
        )}
      </div>
    );
  },
);

const ModalVideo: React.FunctionComponent<ModalVideoProps> = ({
  disableEscapeKey,
  modalId,
  onClose,
  onDestroy,
  open,
  payload: { video },
  ...modal
}) => {
  const { isMobile } = usePlatformContext();
  return (
    <Overlay
      animation={
        isMobile ? OverlayAnimation.SLIDE_UP : OverlayAnimation.ZOOM_IN
      }
      disableEscapeKey={disableEscapeKey}
      id={modalId}
      onDestroy={onDestroy}
      onEscapeKey={onClose}
      open={open}>
      <ModalVideoTemplate onClose={onClose} video={video} {...modal} />
    </Overlay>
  );
};

export default React.memo(ModalVideo);
