import React from 'react';

import { ModalDocuploadItemProps } from './types';
import ModalDocuploadItemIcon from './icons';
import Button from 'components/shared/Button';

import styles from './styles.module.scss';

const ModalDocuploadItem: React.FunctionComponent<ModalDocuploadItemProps> = ({
  disabled,
  handleDelete,
  file: { id, name, type },
}) => {
  return (
    <li className={styles['modal-document-upload-item']}>
      <ModalDocuploadItemIcon type={type} />
      <p>{name}</p>
      <Button
        aria-label="Delete file"
        disabled={disabled}
        onClick={() => handleDelete(id)}
        title="Delete File"
      />
    </li>
  );
};

export default React.memo(ModalDocuploadItem);
