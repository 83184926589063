import { useCallback, useEffect, useRef, useState } from 'react';

import { isTarget, isTransition } from './utils';
import { convertPixelsToRem } from 'modules/utils';
import { useResizeObserver } from 'modules/hooks/useResizeObserver';
import useTransitionEnd from '../useTransitionEnd';

export const useAutoGrow = (isFocused: boolean, value: string) => {
  const initialHeight = 36;
  const maxHeight = 74;
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [animating, setAnimating] = useState(false);
  const [fullyExpanded, setFullyExpanded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useResizeObserver(ref);

  const adjustHeight = useCallback(
    (textarea: HTMLTextAreaElement) => {
      if (!animating) {
        setFullyExpanded(false);
        const currentHeight = textarea.clientHeight;
        textarea.style.height = 'auto';
        const scrollHeight = textarea.scrollHeight;
        scrollHeight > initialHeight
          ? setIsExpanded(true)
          : setIsExpanded(false);
        textarea.style.height = `${convertPixelsToRem(currentHeight)}rem`;

        if (textarea.clientHeight !== scrollHeight) {
          setAnimating(true);
          requestAnimationFrame(() => {
            textarea.style.height = `${convertPixelsToRem(scrollHeight)}rem`;
          });
        }
      }
    },
    [animating],
  );

  const lockHeight = useCallback(
    (textarea: HTMLTextAreaElement, height: number) => {
      const scrollHeight = textarea.scrollHeight;
      setFullyExpanded(true);
      textarea.style.height = `${convertPixelsToRem(height)}rem`;
      scrollHeight > initialHeight ? setIsExpanded(true) : setIsExpanded(false);
    },
    [],
  );

  useTransitionEnd({
    listener: useCallback(
      (event: TransitionEvent) => {
        if (
          isTarget(event, ref) &&
          isTransition({ transition: 'height', event })
        )
          setAnimating(false);
      },
      [ref],
    ),
    ref,
  });

  useEffect(() => {
    if (ref.current) {
      const textarea = ref.current;

      if (isFocused) {
        if (textarea.scrollHeight <= maxHeight) {
          adjustHeight(textarea);
        } else {
          lockHeight(textarea, maxHeight);
        }
      } else {
        lockHeight(textarea, initialHeight);
      }
    }
  }, [adjustHeight, isFocused, lockHeight, width, value]);

  return {
    animating,
    autoGrowRef: ref,
    fullyExpanded,
    isExpanded,
  };
};
