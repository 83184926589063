import { biometricPluginGetCredentials } from '../plugin';
import { AuthBiometricStatus } from '../constants';
import { AuthApiRequest } from '../types';

const isBiometricStatus = (status: unknown): status is AuthBiometricStatus =>
  Object.values(AuthBiometricStatus).includes(status as AuthBiometricStatus);

export const isBiometricActive = (
  status: unknown,
): status is AuthBiometricStatus =>
  isBiometricStatus(status) &&
  [
    AuthBiometricStatus.FACE,
    AuthBiometricStatus.TOUCH,
    AuthBiometricStatus.UNKNOWN,
  ].includes(status);

export const isBiometricAnswered = async (
  username: string,
): Promise<boolean> => {
  try {
    return !!(await biometricPluginGetCredentials(username));
  } catch {
    return false;
  }
};

export const isBiometricValid = (
  credentials: AuthApiRequest,
  username: string,
): boolean =>
  !!credentials.password &&
  !!credentials.username &&
  credentials.username === username;
