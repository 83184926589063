import { RootState } from 'modules/redux/store';
import isAuthorized from './isAuthorized';
import isActive from './isActive';

type IsAllowed = (state: RootState) => boolean;

const isAllowed: IsAllowed = state =>
  state.platform.isCapacitor &&
  state.auth.isBiometric &&
  !isAuthorized(state) &&
  !isActive(state) &&
  !!state.auth.data?.username;

export default isAllowed;
