import { Dispatch, RootState } from 'modules/redux';
import { configGetAction } from '../actions';

const POLL_TIME = 60 * 1000;

type ConfigTimer = (props: {
  dispatch: Dispatch;
  getState: () => RootState;
}) => number;

const configTimer: ConfigTimer = ({ dispatch, getState }) => {
  const { config } = getState();
  config.timer !== null && window.clearInterval(config.timer);
  return window.setInterval(() => dispatch(configGetAction()), POLL_TIME);
};

export default configTimer;
