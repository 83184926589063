import { useCallback } from 'react';

import { AuthBiometricStatus } from '../constants';
import { authLogoutAction } from '../actions';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseAuthSelector = () => {
  changePassword: boolean;
  handleLogout: () => void;
  isAuthenticated: boolean;
  isBiometric: AuthBiometricStatus | false;
  isError: Error | false;
  isLoading: boolean;
  username: string | null;
};

const useAuthSelector: UseAuthSelector = () => {
  const dispatch: Dispatch = useDispatch();

  const { changePassword, data, isBiometric, isError, isLoading } = useSelector(
    state => state.auth,
    shallowEqual,
  );

  const handleLogout = useCallback(
    () => dispatch(authLogoutAction()),
    [dispatch],
  );

  return {
    changePassword,
    handleLogout,
    isAuthenticated: !!data?.token,
    isBiometric,
    isError,
    isLoading: !!isLoading,
    username: data?.username || null,
  };
};

export default useAuthSelector;
