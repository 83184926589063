import React from 'react';

import { ReactComponent as MaskSVG } from './mask.svg';
import Image from 'components/shared/Image';
import { AvatarType } from './constants';
import RobotImage from './robot.svg';
import StaffImage from './staff.svg';

import styles from './styles.module.scss';

export { AvatarType } from './constants';

type AvatarProps = {
  mask?: boolean;
  name?: string;
  src?: string;
  type?: AvatarType;
};

const Avatar: React.FunctionComponent<AvatarProps> = ({
  mask = false,
  name = 'Avatar',
  src,
  type,
}) => {
  return mask ? (
    <div className={styles['avatar']}>
      <Image
        alt={name}
        fallback={type === AvatarType.ROBOT ? RobotImage : StaffImage}
        src={src}
      />
      <MaskSVG />
    </div>
  ) : (
    <Image
      alt={name}
      fallback={type === AvatarType.ROBOT ? RobotImage : StaffImage}
      src={src}
    />
  );
};

export default React.memo(Avatar);
