import { useCallback } from 'react';

import { keyboardPluginHide, keyboardPluginShow } from './plugin';
import { shallowEqual, useSelector } from 'modules/redux';
import { KeyboardStatus } from './constants';

export type UseKeyboard = () => {
  active: boolean;
  handleHideKeyboard: () => void;
  handleShowKeyboard: () => void;
  offset: number;
  status: KeyboardStatus;
};

const useKeyboard: UseKeyboard = () => {
  const { active, offset, status } = useSelector(
    state => state.keyboard,
    shallowEqual,
  );

  const handleHideKeyboard = useCallback(
    () => active && keyboardPluginHide(),
    [active],
  );

  const handleShowKeyboard = useCallback(
    () => !active && keyboardPluginShow(),
    [active],
  );

  return {
    active,
    handleHideKeyboard,
    handleShowKeyboard,
    offset,
    status,
  };
};

export default useKeyboard;
