import React from 'react';
import clsx from 'clsx';

import { useTeamHeader } from 'modules/states/team';
import { useCase } from 'modules/states/cases';
import HeaderItem from './HeaderItem';

import styles from './styles.module.scss';

const Header: React.FunctionComponent = () => {
  const { data, handleMessage } = useTeamHeader();
  const { isCaseActive } = useCase();

  const classNames = clsx(
    styles.header,
    isCaseActive && data && styles['active-case'],
  );

  return (
    <header className={classNames}>
      {isCaseActive && !!data?.caseManager && (
        <HeaderItem handleMessage={handleMessage} {...data.caseManager} />
      )}
      {isCaseActive && !!data?.adviser && (
        <HeaderItem handleMessage={handleMessage} {...data.adviser} />
      )}
    </header>
  );
};

export default React.memo(Header);
