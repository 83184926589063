import nextId from 'react-id-generator';

import { MessageFrom, MessageType } from '../../../constants';
import { MessageDefault } from '../../../types';

const newMessagePlaceholder = (text: string): MessageDefault => {
  return {
    actionId: null,
    date: new Date(),
    from: MessageFrom.USER,
    isSending: false,
    isSendingError: false,
    messageId: `placeholder-${nextId()}`,
    name: 'Portal',
    placeholder: true,
    read: false,
    text: text,
    type: MessageType.DEFAULT,
  };
};

export default newMessagePlaceholder;
