import { Documents, DocumentsState } from '../types';

type FindDocuments = (
  array: DocumentsState,
  caseId: number | null,
) => Documents | undefined;

const findDocuments: FindDocuments = (array, caseId) =>
  array.find(item => item.caseId === caseId);

export default findDocuments;
