import { matchPath } from 'react-router-dom';

import { ROUTE } from '../constants';

type MatchRoute = (pathA: ROUTE, pathB: string | undefined) => boolean;

const matchRoute: MatchRoute = (pathA, pathB) =>
  !!matchPath(pathA || '', pathB || '');

export default matchRoute;
