import { produce } from 'immer';

import { StatusGetSuccessAction, StatusAction } from 'modules/states/status';
import { LoanAction, LoanGetSuccessAction } from 'modules/states/loan';
import { UserAction, UserGetSuccessAction } from 'modules/states/user';
import { CasesActionType, CasesState } from './types';
import { CasesAction } from './constants';
import {
  updateCasesFromStatus,
  updateCasesFromUser,
  updateCasesFromLoan,
  updateCases,
} from './utils';

const initialState = (): CasesState => {
  return {
    actionId: null,
    caseId: null,
    data: null,
    isError: false,
    isLoading: false,
  };
};

function casesReducer(
  state = initialState(),
  action:
    | CasesActionType
    | ReturnType<LoanGetSuccessAction>
    | ReturnType<StatusGetSuccessAction>
    | ReturnType<UserGetSuccessAction>,
) {
  switch (action.type) {
    case CasesAction.GET_FAILURE:
      return produce(state, (draftState: CasesState) => {
        draftState.actionId = null;
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case CasesAction.GET_STARTED:
      return produce(state, (draftState: CasesState) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isLoading = action.controller || true;
      });

    case CasesAction.GET_SUCCESS:
      return produce(state, (draftState: CasesState) => {
        draftState.actionId = null;
        draftState.isError = false;
        draftState.isLoading = false;
        !draftState.data && (draftState.data = []);
        updateCases(draftState, action.data);
      });

    case CasesAction.RESTORE:
      return produce(state, (draftState: CasesState) => {
        draftState.caseId = action.caseId;
      });

    case CasesAction.SELECT:
      return produce(state, (draftState: CasesState) => {
        draftState.caseId = action.caseId;
      });

    case LoanAction.GET_SUCCESS:
      return produce(state, (draftState: CasesState) => {
        !!draftState.data &&
          updateCasesFromLoan(draftState.data, action.data, action.request);
      });

    case StatusAction.GET_SUCCESS:
      return produce(state, (draftState: CasesState) => {
        !!draftState.data &&
          updateCasesFromStatus(draftState.data, action.data, action.request);
      });

    case UserAction.GET_SUCCESS:
      return produce(state, (draftState: CasesState) => {
        updateCasesFromUser(draftState, action.data);
      });

    case CasesAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default casesReducer;
