import { DialogPluginShow } from './types';

import dialogPluginShowNotifications from './notifications';
import dialogPluginShowConfirm from './confirm';
import dialogPluginShowAlert from './alert';
import { DialogType } from '../constants';

export const dialogPluginShow: DialogPluginShow = dialog => {
  switch (dialog.type) {
    case DialogType.ALERT:
      dialogPluginShowAlert(dialog);
      break;

    case DialogType.CONFIRM:
      dialogPluginShowConfirm(dialog);
      break;

    case DialogType.NOTIFICATIONS:
      dialogPluginShowNotifications(dialog);
      break;
  }
};
