import { OnfidoPluginResponse } from '../types';
import { Dispatch } from 'modules/redux';
import {
  onfidoPluginFailureAction,
  onfidoPluginSuccessAction,
  onfidoPluginCancelAction,
} from '../../actions';

type GetHandlers = (dispatch: Dispatch) => {
  onCancel: () => void;
  onComplete: (data: OnfidoPluginResponse) => void;
  onError: (error: Error) => void;
  onUserExit: () => void;
};

const getHandlers: GetHandlers = dispatch => ({
  onCancel: () => dispatch(onfidoPluginCancelAction()),
  onComplete: data => dispatch(onfidoPluginSuccessAction(data)),
  onError: error => dispatch(onfidoPluginFailureAction(error)),
  onUserExit: () => dispatch(onfidoPluginCancelAction()),
});

export default getHandlers;
