export enum MessagesAction {
  GET_STARTED = 'MESSAGES_GET_STARTED',
  GET_SUCCESS = 'MESSAGES_GET_SUCCESS',
  GET_FAILURE = 'MESSAGES_GET_FAILURE',

  POST_STARTED = 'MESSAGES_POST_STARTED',
  POST_SUCCESS = 'MESSAGES_POST_SUCCESS',
  POST_FAILURE = 'MESSAGES_POST_FAILURE',
  POST_RESET = 'MESSAGES_POST_RESET',

  READ_STARTED = 'MESSAGES_READ_STARTED',
  READ_SUCCESS = 'MESSAGES_READ_SUCCESS',
  READ_FAILURE = 'MESSAGES_READ_FAILURE',

  POLLING_STARTED = 'MESSAGES_POLLING_STARTED',
  POLLING_ENDED = 'MESSAGES_POLLING_ENDED',

  LOGOUT = 'MESSAGES_LOGOUT',
}

export enum MessageFrom {
  USER = 'user',
  FLUENT = 'fluent',
}

export enum MessageType {
  DEFAULT = 'DEFAULT',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  ONFIDO = 'ONFIDO',
}

export enum MessageActionOnfido {
  APPROVED = 'IDV-APPROVED',
  FAILED = 'IDV-FAILED',
  INPROGRESS = 'IDV-INPROGRESS',
  REQUESTED = 'IDV-REQUESTED',
}

export enum MessageActionDocupload {
  APPROVED = 'DOCUPLOAD-APPROVED',
  AWAITING_REVIEW = 'DOCUPLOAD-AWAITINGREVIEW',
  CANCELLED = 'DOCUPLOAD-CANCELLED',
  DECLINED = 'DOCUPLOAD-DECLINED',
  REQUESTED = 'DOCUPLOAD-REQUESTED',
}
