import { Badge } from '@capawesome/capacitor-badge';

import { platform } from 'modules/services/platform';
import { BadgeStatus } from './constants';
import {
  CheckPermissions,
  DecreaseBadge,
  IncreaseBadge,
  CheckSupport,
  ClearBadge,
  GetBadge,
  IsActive,
  SetBadge,
} from './types';

const { isCapacitor } = platform;

const checkPermissions: CheckPermissions = async () => {
  try {
    const result = await Badge.checkPermissions();
    return result.display === BadgeStatus.GRANTED;
  } catch {
    return false;
  }
};

const checkSupport: CheckSupport = async () => {
  try {
    const result = await Badge.isSupported();
    return result.isSupported;
  } catch {
    return false;
  }
};

const isActive: IsActive = async () => {
  const isSupported = await checkSupport();
  const isAuthorized = await checkPermissions();
  return isCapacitor && isSupported && isAuthorized;
};

export const clearBadge: ClearBadge = async () => {
  if (await isActive()) await Badge.clear();
};

export const getBadge: GetBadge = async () => {
  if (await isActive()) {
    const result = await Badge.get();
    return result.count;
  }
  return 0;
};

export const setBadge: SetBadge = async count => {
  if (await isActive()) await Badge.set({ count });
};

export const decreaseBadge: DecreaseBadge = async () => {
  if (await isActive()) await Badge.decrease();
};

export const increaseBadge: IncreaseBadge = async () => {
  if (await isActive()) await Badge.increase();
};
