import { DocumentsGetActionApiRequest } from '../types';
import { RootState } from 'modules/redux/store';
import findDocuments from './findDocuments';

type IsActive = (props: {
  request: DocumentsGetActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ request, state }) =>
  !!findDocuments(state.documents, request.caseId)?.isLoading;

export default isActive;
