import { findMessageFromResponse, getMessagesStats } from '../../../utils';
import { MessagesGetActionApiResponseItem } from '../types';
import updateMessage from './updateMessage';
import { Messages } from '../../../types';
import newMessage from './newMessage';

type UpdateMessages = (
  messages: Messages,
  data: MessagesGetActionApiResponseItem[],
) => void;

const updateMessages: UpdateMessages = (messages, data) => {
  Array.isArray(data) &&
    data.forEach(item => {
      const message = findMessageFromResponse(messages.data as [], item);
      message
        ? updateMessage(message, item)
        : newMessage(messages.data as [], item);
    });
  messages.stats = getMessagesStats(messages.data);
};

export default updateMessages;
