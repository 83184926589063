import { useDialogAlert, useDialogConfirm } from 'modules/dialogs';
import { Dispatch, useDispatch } from 'modules/redux';
import useHandlePreview from './useHandlePreview';
import useHandleCamera from './useHandleCamera';
import useHandleDelete from './useHandleDelete';
import useHandleUpload from './useHandleUpload';
import useHandleClose from './useHandleClose';
import useHandleError from './useHandleError';
import useHandleInput from './useHandleInput';
import { UseHandlers } from './types';

export type {
  UseHandlePreview,
  UseHandleCamera,
  UseHandleDelete,
  UseHandleUpload,
  UseHandleClose,
  UseHandleInput,
  HandlePreview,
  HandleCamera,
  HandleDelete,
  HandleUpload,
  HandleClose,
  HandleInput,
} from './types';

const useHandlers: UseHandlers = ({ disabled, isError, isSendingError }) => {
  const [showDialogConfirm] = useDialogConfirm();
  const [showDialogAlert] = useDialogAlert();
  const dispatch: Dispatch = useDispatch();

  useHandleError({
    dispatch,
    isError,
    isSendingError,
    showDialogAlert,
    showDialogConfirm,
  });

  return {
    handleCamera: useHandleCamera(dispatch),
    handleClose: useHandleClose({ disabled, dispatch }),
    handleDelete: useHandleDelete({ dispatch, showDialogConfirm }),
    handleInput: useHandleInput(dispatch),
    handlePreview: useHandlePreview(dispatch),
    handleUpload: useHandleUpload(dispatch),
  };
};

export default useHandlers;
