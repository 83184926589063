import { produce } from 'immer';

import { Onfido, OnfidoActionType } from '../../types';
import { OnfidoAction } from '../../constants';

function onfidoPluginReducer(state: Onfido, action: OnfidoActionType) {
  switch (action.type) {
    case OnfidoAction.PLUGIN_CANCEL:
      return state;

    case OnfidoAction.PLUGIN_FAILURE:
      return produce(state, (draftState: Onfido) => {
        !!draftState.messageId && (draftState.isError = action.error);
      });

    case OnfidoAction.PLUGIN_SUCCESS:
      return produce(state, (draftState: Onfido) => {
        !!draftState.messageId && (draftState.variant = action.variant);
      });

    default:
      return state;
  }
}

export default onfidoPluginReducer;
