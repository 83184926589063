import React from 'react';

import { DocuploadFileType } from 'modules/docupload/constants';
import { ReactComponent as FILE } from 'modules/theme/images/icons/documents/file.svg';
import { ReactComponent as JPG } from 'modules/theme/images/icons/documents/file-jpg.svg';
import { ReactComponent as PDF } from 'modules/theme/images/icons/documents/file-pdf.svg';
import { ReactComponent as PNG } from 'modules/theme/images/icons/documents/file-png.svg';
import { ReactComponent as IMG } from 'modules/theme/images/icons/documents/file-img.svg';

type ModalDocuploadItemIconProps = {
  type?: DocuploadFileType;
};

const ModalDocuploadItemIcon: React.FunctionComponent<
  ModalDocuploadItemIconProps
> = ({ type }) => {
  switch (type) {
    case DocuploadFileType.JPEG:
      return <JPG />;
    case DocuploadFileType.PDF:
      return <PDF />;
    case DocuploadFileType.PNG:
      return <PNG />;
    case DocuploadFileType.WEBP:
      return <IMG />;
    default:
      return <FILE />;
  }
};

export default ModalDocuploadItemIcon;
