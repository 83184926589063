import { PushNotifications } from '@capacitor/push-notifications';

import { AddListeners, RemoveListeners } from './types';

export const addListeners: AddListeners = async ({
  onRegistration,
  onRegistrationError,
  onReceived,
}) => {
  await removeListeners();
  await PushNotifications.addListener('registrationError', onRegistrationError);
  await PushNotifications.addListener('pushNotificationReceived', onReceived);
  await PushNotifications.addListener('registration', onRegistration);
};

export const removeListeners: RemoveListeners =
  PushNotifications.removeAllListeners;
