import React from 'react';
import { Path, FieldValues, useController } from 'react-hook-form';
import clsx from 'clsx';

import { QuestionTemplateProps } from '../types';
import { useFocus } from './hooks';

import styles from './styles.module.scss';

function Question<T extends FieldValues>({
  children,
  control,
  disabled,
  id,
  label,
  name,
}: QuestionTemplateProps<T>) {
  const {
    field: { onChange, value, onBlur, ref },
    fieldState: { error, invalid },
  } = useController({
    control,
    name: name as Path<T>,
  });

  const { handleBlur, handleFocus, focused } = useFocus(onBlur);

  const className = clsx(
    styles['question'],
    disabled && styles.disabled,
    focused && styles.focused,
  );

  return (
    <div className={className}>
      {!!label && <label htmlFor={id ? id : name}>{label}</label>}
      {children &&
        children({
          invalid,
          onBlur: handleBlur,
          onFocus: handleFocus,
          onChange,
          ref,
          value,
        })}
      {invalid && !!error?.message && <p aria-live="polite">{error.message}</p>}
    </div>
  );
}

export default React.memo(Question) as typeof Question;
