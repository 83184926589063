import { platform } from 'modules/services/platform';

!window.myfluent && (window.myfluent = { listeners: {} });

const { isDevelopment } = platform;

const removeListener = () => {
  if (window.myfluent?.listeners?.theme) {
    window.myfluent.listeners.theme();
    delete window.myfluent.listeners.theme;
  }
};

type AddListener = (
  query: MediaQueryList,
  listener: (event: MediaQueryListEvent) => void,
) => void;

export const addListener: AddListener = (query, listener) => {
  removeListener();
  try {
    query.addEventListener('change', listener);
    if (isDevelopment)
      window.myfluent.listeners.theme = () =>
        query.removeEventListener('change', listener);
  } catch {
    try {
      query.addListener(listener);
      if (isDevelopment)
        window.myfluent.listeners.theme = () => query.removeListener(listener);
    } catch {
      return;
    }
  }
};
