import { produce } from 'immer';

import { Docupload, DocuploadActionType } from '../../../../types';
import { DocuploadAction } from '../../../../constants';

function docuploadPluginFileReducer(
  state: Docupload,
  action: DocuploadActionType,
) {
  switch (action.type) {
    case DocuploadAction.PLUGIN_FILE_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.data.push(action.file);
      });

    default:
      return state;
  }
}

export default docuploadPluginFileReducer;
