import { AuthApiRequest } from '../types';
import {
  biometricPluginVerifyCredentials,
  biometricPluginGetCredentials,
} from '../plugin';

type GetBiometricCredentials = (username: string) => Promise<AuthApiRequest>;

const getBiometricCredentials: GetBiometricCredentials = async username => {
  await biometricPluginVerifyCredentials();
  return await biometricPluginGetCredentials(username);
};

export default getBiometricCredentials;
