import { createDefaultAction } from 'modules/redux/factories';
import { MessagesAction } from '../../constants';
import { isActive, isPresent } from './utils';
import { API } from 'modules/api';
import {
  MessagesReadFailureAction,
  MessagesReadStartedAction,
  MessagesReadSuccessAction,
  MessagesReadAction,
} from './types';

export { default as messagesReadReducer } from './reducer';
export type {
  MessagesReadActionApiRequest,
  MessagesReadActionType,
} from './types';

export const messagesReadAction: MessagesReadAction = request =>
  createDefaultAction({
    actionStarted: messagesReadStartedAction,
    actionSuccess: messagesReadSuccessAction,
    actionFailure: messagesReadFailureAction,
    endpoint: API.POST_MESSAGE_READ,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const messagesReadStartedAction: MessagesReadStartedAction = payload => ({
  type: MessagesAction.READ_STARTED,
  ...payload,
});

const messagesReadSuccessAction: MessagesReadSuccessAction = payload => ({
  type: MessagesAction.READ_SUCCESS,
  ...payload,
});

const messagesReadFailureAction: MessagesReadFailureAction = payload => ({
  type: MessagesAction.READ_FAILURE,
  ...payload,
});
