import docuploadReducer from 'modules/docupload/reducer';
import dialogsReducer from 'modules/dialogs/reducer';
import modalsReducer from 'modules/modals/reducer';
import onfidoReducer from 'modules/onfido/reducer';
import authReducer from 'modules/auth/reducer';

import notificationsReducer from 'modules/services/notifications/reducer';
import certificateReducer from 'modules/services/certificate/reducer';
import orientationReducer from 'modules/services/orientation/reducer';
import jailbreakReducer from 'modules/services/jailbreak/reducer';
import appStatusReducer from 'modules/services/appstatus/reducer';
import keyboardReducer from 'modules/services/keyboard/reducer';
import platformReducer from 'modules/services/platform/reducer';
import networkReducer from 'modules/services/network/reducer';
import versionReducer from 'modules/services/version/reducer';
import configReducer from 'modules/services/config/reducer';
import themeReducer from 'modules/services/theme/reducer';

import documentsReducer from 'modules/states/documents/reducer';
import messagesReducer from 'modules/states/messages/reducer';
import statusReducer from 'modules/states/status/reducer';
import casesReducer from 'modules/states/cases/reducer';
import loanReducer from 'modules/states/loan/reducer';
import teamReducer from 'modules/states/team/reducer';
import userReducer from 'modules/states/user/reducer';
import menuReducer from 'modules/states/menu/reducer';

const reducers = {
  auth: authReducer,

  docupload: docuploadReducer,
  onfido: onfidoReducer,

  notifications: notificationsReducer,
  certificate: certificateReducer,
  orientation: orientationReducer,
  jailbreak: jailbreakReducer,
  appStatus: appStatusReducer,
  keyboard: keyboardReducer,
  platform: platformReducer,
  network: networkReducer,
  version: versionReducer,
  config: configReducer,
  theme: themeReducer,

  documents: documentsReducer,
  messages: messagesReducer,
  status: statusReducer,
  cases: casesReducer,
  loan: loanReducer,
  team: teamReducer,
  user: userReducer,
  menu: menuReducer,

  dialogs: dialogsReducer,
  modals: modalsReducer,
};

export default reducers;
