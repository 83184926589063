import { createDefaultAction } from 'modules/redux/factories';
import { isActive, isPresent } from './utils';
import { AuthAction } from '../../constants';
import { API } from 'modules/api';
import {
  AuthChangePasswordFailureAction,
  AuthChangePasswordStartedAction,
  AuthChangePasswordSuccessAction,
  AuthChangePasswordAction,
} from './types';

export const authChangePasswordAction: AuthChangePasswordAction = request =>
  createDefaultAction({
    actionStarted: authChangePasswordStartedAction,
    actionSuccess: authChangePasswordSuccessAction,
    actionFailure: authChangePasswordFailureAction,
    endpoint: API.POST_PASSWORD,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const authChangePasswordStartedAction: AuthChangePasswordStartedAction =
  payload => ({
    type: AuthAction.CHANGE_PASSWORD_STARTED,
    ...payload,
  });

const authChangePasswordSuccessAction: AuthChangePasswordSuccessAction =
  payload => ({
    type: AuthAction.CHANGE_PASSWORD_SUCCESS,
    ...payload,
  });

const authChangePasswordFailureAction: AuthChangePasswordFailureAction =
  payload => ({
    type: AuthAction.CHANGE_PASSWORD_FAILURE,
    ...payload,
  });
