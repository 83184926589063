import React from 'react';

import { MessageFrom, UseMessagesRoute } from 'modules/states/messages';
import Message from 'components/shared/Message';

import styles from './styles.module.scss';

export { default as MessagesControls } from './MessagesControls';

type MessagesProp = {
  children?: React.ReactNode;
  data: ReturnType<UseMessagesRoute>['data'];
  handleDocupload: ReturnType<UseMessagesRoute>['handleDocupload'];
  handleInView: ReturnType<UseMessagesRoute>['handleInView'];
  handleOnfido: ReturnType<UseMessagesRoute>['handleOnfido'];
};

const Messages: React.FunctionComponent<MessagesProp> = ({
  data,
  ...props
}) => {
  let previousName = '';
  return (
    <div className={styles.messages}>
      {data?.map((message, index) => {
        const showHeader =
          message.from === MessageFrom.USER
            ? false
            : previousName !== message.name;
        previousName = message.name;
        return (
          <Message
            key={index}
            message={message}
            showHeader={showHeader}
            {...props}
          />
        );
      })}
    </div>
  );
};

export default React.memo(Messages);
