import { Dispatch, RootState } from 'modules/redux';
import { networkEventAction } from '../actions';
import { networkPluginStatus } from '../plugin';

const POLL_TIME = 15 * 1000;

type NetworkTimer = (props: {
  dispatch: Dispatch;
  getState: () => RootState;
}) => number;

const networkTimer: NetworkTimer = ({ dispatch, getState }) => {
  let { network } = getState();
  network.timer !== null && window.clearInterval(network.timer);
  return window.setInterval(async () => {
    const status = await networkPluginStatus();
    network = getState().network;
    network.status !== status && dispatch(networkEventAction({ status }));
  }, POLL_TIME);
};

export default networkTimer;
