import { DocumentCase, DocumentsGetActionApiResponseItem } from '../types';
import { convertStringToDate } from 'modules/redux/utils';
import { DocumentType } from '../constants';
import getDocumentId from './getDocumentId';

const newDocumentCase = (
  data: DocumentsGetActionApiResponseItem,
): DocumentCase | null =>
  data.description && data.extension && data.url
    ? {
        date: convertStringToDate(data.dateCreated),
        description: data.description,
        documentId: getDocumentId(data),
        extension: data.extension.toLocaleLowerCase(),
        type: DocumentType.CASE,
        url: data.url,
      }
    : null;

export default newDocumentCase;
