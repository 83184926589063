import { createDefaultAction } from 'modules/redux/factories';
import { onfidoResetErrorAction } from '../../actions';
import { OnfidoAction } from '../../constants';
import { isPresent } from './utils';
import { API } from 'modules/api';
import {
  OnfidoPostFailureAction,
  OnfidoPostStartedAction,
  OnfidoPostSuccessAction,
  OnfidoPostRetryAction,
  OnfidoPostAction,
} from './types';

export const onfidoPostAction: OnfidoPostAction = request =>
  createDefaultAction({
    actionStarted: onfidoPostStartedAction,
    actionSuccess: onfidoPostSuccessAction,
    actionFailure: onfidoPostFailureAction,
    endpoint: API.POST_ONFIDO,
    isActive: ({ state }) => !!state.onfido.isSending,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
  });

const onfidoPostStartedAction: OnfidoPostStartedAction = payload => ({
  type: OnfidoAction.POST_STARTED,
  ...payload,
});

const onfidoPostSuccessAction: OnfidoPostSuccessAction = payload => ({
  type: OnfidoAction.POST_SUCCESS,
  ...payload,
});

const onfidoPostFailureAction: OnfidoPostFailureAction = payload => ({
  type: OnfidoAction.POST_FAILURE,
  ...payload,
});

export const onfidoPostRetryAction: OnfidoPostRetryAction = () => {
  return async (dispatch, getState) => {
    const { caseId, finishURL, messageId, variant } = getState().onfido;
    caseId && finishURL && messageId && variant
      ? dispatch(onfidoPostAction({ caseId, finishURL, messageId, variant }))
      : dispatch(onfidoResetErrorAction());
  };
};
