import { Keyboard as CapacitorKeyboard } from '@capacitor/keyboard';

import { addListeners, hideAccessoryBar } from './utils';
import { platform } from 'modules/services/platform';
import {
  KeyboardPluginHide,
  KeyboardPluginInit,
  KeyboardPluginShow,
} from './types';

const { isCapacitor } = platform;

export const keyboardPluginHide: KeyboardPluginHide = () =>
  isCapacitor && CapacitorKeyboard.hide();

export const keyboardPluginShow: KeyboardPluginShow = () =>
  isCapacitor && CapacitorKeyboard.show();

export const keyboardPluginInit: KeyboardPluginInit = handler => {
  isCapacitor && hideAccessoryBar(true);
  addListeners(handler);
};
