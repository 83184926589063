import { MessagesReadActionApiRequest } from '../types';
import findMessage from '../../../utils/findMessage';
import { RootState } from 'modules/redux/store';

type IsActive = (props: {
  request: MessagesReadActionApiRequest;
  state: RootState;
}) => boolean;

const isActive: IsActive = ({ request, state }) =>
  !!findMessage(state.messages.data, request.caseId, request.messageId)
    ?.isSending;

export default isActive;
