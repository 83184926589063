import { MenuCloseAction, MenuLogoutAction, MenuOpenAction } from './types';
import { MenuAction } from './constants';

export const menuCloseAction: MenuCloseAction = () => ({
  type: MenuAction.CLOSE,
});

export const menuOpenAction: MenuOpenAction = () => ({
  type: MenuAction.OPEN,
});

export const menuLogoutAction: MenuLogoutAction = () => ({
  type: MenuAction.LOGOUT,
});
