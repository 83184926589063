import { Dispatch, RootState } from 'modules/redux';
import { messagesGetAction } from '../actions';
import { findCase } from '../../cases/utils';

const POLL_TIME = 60 * 1000;

const messagesTimer = ({
  dispatch,
  getState,
}: {
  dispatch: Dispatch;
  getState: () => RootState;
}): number => {
  const { messages } = getState();
  messages.timer !== null && window.clearInterval(messages.timer);
  return window.setInterval(() => {
    const { auth, cases } = getState();
    !!auth.data?.token &&
      !!cases.caseId &&
      !!findCase(cases.data, cases.caseId)?.isCaseActive &&
      dispatch(messagesGetAction({ caseId: cases.caseId }));
  }, POLL_TIME);
};

export default messagesTimer;
