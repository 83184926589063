import { DocuploadAction } from '../constants';
import {
  DocuploadResetErrorAction,
  DocuploadDestroyAction,
  DocuploadCancelAction,
  DocuploadLogoutAction,
  DocuploadCloseAction,
} from '../types';

export {
  docuploadPluginPreviewAction,
  docuploadPluginCameraAction,
  docuploadPluginDeleteAction,
  docuploadPluginInputAction,
  docuploadPluginReducer,
  type DocuploadPluginActionType,
} from './documentPluginAction';

export {
  type DocuploadPostActionApiRequest,
  type DocuploadPostActionType,
  docuploadPostReducer,
  docuploadPostAction,
} from './docuploadPostAction';

export {
  type DocuploadUploadActionType,
  docuploadUploadReducer,
  docuploadUploadAction,
} from './docuploadUploadAction';

export {
  type DocuploadShowActionType,
  docuploadShowReducer,
  docuploadShowAction,
} from './docuploadShowAction';

export const docuploadCancelAction: DocuploadCancelAction = () => ({
  type: DocuploadAction.CANCEL,
});

export const docuploadCloseAction: DocuploadCloseAction = () => ({
  type: DocuploadAction.CLOSE,
});

export const docuploadDestroyAction: DocuploadDestroyAction = () => ({
  type: DocuploadAction.DESTROY,
});

export const docuploadLogoutAction: DocuploadLogoutAction = () => ({
  type: DocuploadAction.LOGOUT,
});

export const docuploadResetErrorAction: DocuploadResetErrorAction = () => ({
  type: DocuploadAction.RESET_ERROR,
});
