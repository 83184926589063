import { Middleware } from 'redux';

import { orientationPluginInit, orientationPluginSetClass } from './plugin';
import { orientationChangeAction, orientationInitAction } from './actions';
import { OrientationAction, OrientationStatus } from './constants';
import { AppAction, AppActionType } from 'modules/app';
import { Dispatch, RootState } from 'modules/redux';
import { OrientationActionType } from './types';

const orientationMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AppActionType | OrientationActionType) => {
    let nextAction;

    switch (action.type) {
      case AppAction.INIT:
        nextAction = next(action);
        dispatch(orientationInitAction());
        break;

      case OrientationAction.INIT:
        orientationPluginInit(
          (orientation: OrientationStatus) =>
            getState().orientation !== orientation &&
            dispatch(orientationChangeAction(orientation)),
        );
        orientationPluginSetClass(action.orientation);
        break;

      case OrientationAction.CHANGE:
        orientationPluginSetClass(action.orientation);
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default orientationMiddleware;
