import { OnfidoShowActionPayload } from '../types';
import { RootState } from 'modules/redux/store';

type IsPresent = (props: {
  request: OnfidoShowActionPayload;
  state: RootState;
}) => boolean;

const isPresent: IsPresent = ({ request, state }) =>
  !!state.auth.data?.token && request.messageId === state.onfido.messageId;

export default isPresent;
