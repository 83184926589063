import { MessagesData, MessageStats } from '../types';
import { MessageFrom } from '../constants';

type GetMessagesStats = (messages: MessagesData | null) => MessageStats;

const getMessagesStats: GetMessagesStats = messages => {
  return {
    read:
      messages?.filter(item => item.from === MessageFrom.FLUENT && item.read)
        .length || 0,

    total: messages?.length || 0,

    unread:
      messages?.filter(item => item.from === MessageFrom.FLUENT && !item.read)
        .length || 0,
  };
};

export default getMessagesStats;
