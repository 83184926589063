import { VideoAction } from '../../constants';
import { getVideoModal } from '../../utils';
import { isError } from 'modules/utils';
import { showVideo } from './utils';
import {
  VideoShowFailureAction,
  VideoShowStartedAction,
  VideoShowSuccessAction,
  VideoShowAction,
} from './types';

export const videoShowAction: VideoShowAction = request => {
  return async (dispatch, getState) => {
    const state = getState();
    const isCapacitor = state.platform.isCapacitor;
    try {
      if (!state.auth.data?.token || !!getVideoModal(state)) return;

      dispatch(videoShowStartedAction(request));

      const modalId = showVideo({
        dispatch,
        isCapacitor,
        ...request,
      });

      !isCapacitor &&
        dispatch(
          videoShowSuccessAction({
            modalId,
            ...request,
          }),
        );
    } catch (error) {
      dispatch(
        videoShowFailureAction({
          error: isError(error) ? error : new Error('Unexpected Error'),
          ...request,
        }),
      );
    }
  };
};

const videoShowStartedAction: VideoShowStartedAction = payload => ({
  type: VideoAction.SHOW_STARTED,
  ...payload,
});

export const videoShowSuccessAction: VideoShowSuccessAction = payload => ({
  type: VideoAction.SHOW_SUCCESS,
  ...payload,
});

export const videoShowFailureAction: VideoShowFailureAction = payload => ({
  type: VideoAction.SHOW_FAILURE,
  ...payload,
});
