import { Messages, MessagesState } from '../../../types';

type NewMessages = (array: MessagesState['data'], caseId: number) => Messages;

const newMessages: NewMessages = (array, caseId) => {
  const messages: Messages = {
    actionId: null,
    caseId,
    data: null,
    isDirty: false,
    isError: false,
    isLoading: false,
    isSending: false,
    isSendingError: false,
    stats: { read: 0, total: 0, unread: 0 },
  };
  array.push(messages);
  return messages;
};

export default newMessages;
