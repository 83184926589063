import { useCallback, useEffect, useRef, useState } from 'react';

import { isTarget, isTransition } from './utils';
import { convertPixelsToRem } from 'modules/utils';
import { useResizeObserver } from 'modules/hooks/useResizeObserver';
import useTransitionEnd from '../useTransitionEnd';

export const useAutoGrow = (
  isFocused: boolean,
  setTextAreaSpace: React.Dispatch<React.SetStateAction<string | null>>,
  value: string,
) => {
  const initialHeight = 36;
  const maxHeight = 74;
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [animating, setAnimating] = useState(false);
  const [fullyExpanded, setFullyExpanded] = useState(false);
  const { width } = useResizeObserver(ref);

  const adjustHeight = useCallback(
    (textarea: HTMLTextAreaElement) => {
      if (!animating) {
        setFullyExpanded(false);
        const currentHeight = textarea.clientHeight;
        textarea.style.height = 'auto';
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = `${convertPixelsToRem(currentHeight)}rem`;

        if (textarea.clientHeight !== scrollHeight) {
          setAnimating(true);
          requestAnimationFrame(() => {
            textarea.style.height = `${convertPixelsToRem(scrollHeight)}rem`;
          });
        }
      }
    },
    [animating],
  );

  const lockHeight = useCallback(
    (textarea: HTMLTextAreaElement, height: number) => {
      setFullyExpanded(true);
      textarea.style.height = `${convertPixelsToRem(height)}rem`;
    },
    [],
  );

  useTransitionEnd({
    listener: useCallback(
      (event: TransitionEvent) => {
        if (
          isTarget(event, ref) &&
          isTransition({ transition: 'height', event })
        )
          setAnimating(false);
      },
      [ref],
    ),
    ref,
  });

  useEffect(() => {
    if (ref.current) {
      const textarea = ref.current;

      switch (true) {
        case textarea.clientHeight > 36 && textarea.clientHeight <= 55:
          setTextAreaSpace('medium');
          break;
        case textarea.clientHeight > 55:
          setTextAreaSpace('large');
          break;
        default:
          setTextAreaSpace(null);
          break;
      }

      if (isFocused) {
        if (textarea.scrollHeight <= maxHeight) {
          adjustHeight(textarea);
        } else {
          lockHeight(textarea, maxHeight);
        }
      } else {
        lockHeight(textarea, initialHeight);
      }
    }
  }, [adjustHeight, isFocused, lockHeight, setTextAreaSpace, width, value]);

  return {
    animating,
    autoGrowRef: ref,
    fullyExpanded,
  };
};
