import { produce } from 'immer';

import { Docupload, DocuploadActionType } from '../../types';
import { DocuploadAction } from '../../constants';

function docuploadShowReducer(state: Docupload, action: DocuploadActionType) {
  switch (action.type) {
    case DocuploadAction.SHOW_FAILURE:
      return produce(state, (draftState: Docupload) => {
        draftState.isError = action.error;

        draftState.caseId = null;
        draftState.messageId = null;
        draftState.modalId = null;
        draftState.uploadURL = null;
      });

    case DocuploadAction.SHOW_STARTED:
      return produce(state, (draftState: Docupload) => {
        draftState.isError = false;

        draftState.caseId = action.caseId;
        draftState.messageId = action.messageId;
        draftState.uploadURL = action.uploadURL;
      });

    case DocuploadAction.SHOW_SUCCESS:
      return produce(state, (draftState: Docupload) => {
        draftState.isError = false;

        draftState.modalId = action.modalId;
      });

    default:
      return state;
  }
}

export default docuploadShowReducer;
