import { produce } from 'immer';

import { UserState, UserActionType } from './types';
import { UserAction } from './constants';
import { updateUser } from './utils';

const initialState = (): UserState => {
  return {
    actionId: null,
    data: null,
    isError: false,
    isLoading: false,
  };
};

function userReducer(state = initialState(), action: UserActionType) {
  switch (action.type) {
    case UserAction.GET_FAILURE:
      return produce(state, (draftState: UserState) => {
        draftState.actionId = null;
        draftState.isError = action.error;
        draftState.isLoading = false;
      });

    case UserAction.GET_STARTED:
      return produce(state, (draftState: UserState) => {
        draftState.actionId = action.actionId;
        draftState.isError = false;
        draftState.isLoading = action.controller || true;
      });

    case UserAction.GET_SUCCESS:
      return produce(state, (draftState: UserState) => {
        draftState.actionId = null;
        draftState.isError = false;
        draftState.isLoading = false;
        draftState.data = updateUser(action.data);
      });

    case UserAction.LOGOUT:
      return initialState();

    default:
      return state;
  }
}

export default userReducer;
