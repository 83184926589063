import { useCallback } from 'react';
import { UseFormReset } from 'react-hook-form';

import { messagesSendAction } from '../../../actions';
import { FormProps } from 'components/shared/Form';
import { Dispatch } from 'modules/redux';
import { Model } from '../../../model';

type UseHandleSubmit = (props: {
  caseId: number | null;
  dispatch: Dispatch;
}) => FormProps<Model>['onSubmit'];

const useHandleSubmit: UseHandleSubmit = ({ caseId, dispatch }) => {
  const handleSubmit = useCallback(
    async (values: Model, reset: UseFormReset<Model>) =>
      !!caseId && dispatch(messagesSendAction({ caseId, reset, values })),
    [caseId, dispatch],
  );

  return handleSubmit;
};

export default useHandleSubmit;
