import { createDefaultAction } from 'modules/redux/factories';
import { MessagesAction } from '../../constants';
import { isActive, isPresent } from './utils';
import { API } from 'modules/api';
import {
  MessagesPostFailureAction,
  MessagesPostStartedAction,
  MessagesPostSuccessAction,
  MessagesPostResetAction,
  MessagesPostAction,
} from './types';

export { default as messagesPostReducer } from './reducer';
export type {
  MessagesPostActionApiRequest,
  MessagesPostActionType,
} from './types';

export const messagesPostAction: MessagesPostAction = request =>
  createDefaultAction({
    actionStarted: messagesPostStartedAction,
    actionSuccess: messagesPostSuccessAction,
    actionFailure: messagesPostFailureAction,
    endpoint: API.POST_MESSAGE,
    isActive,
    isAuthorized: state => !!state.auth.data?.token,
    isPresent,
    request: request,
    throwable: true,
  });

const messagesPostStartedAction: MessagesPostStartedAction = payload => ({
  type: MessagesAction.POST_STARTED,
  ...payload,
});

const messagesPostSuccessAction: MessagesPostSuccessAction = payload => ({
  type: MessagesAction.POST_SUCCESS,
  ...payload,
});

const messagesPostFailureAction: MessagesPostFailureAction = payload => ({
  type: MessagesAction.POST_FAILURE,
  ...payload,
});

export const messagesPostResetAction: MessagesPostResetAction = caseId => ({
  type: MessagesAction.POST_RESET,
  caseId,
});
