import { AnalyticsTrackPageViewPayload } from './types';
import { ROUTE } from 'modules/router';

let cache: AnalyticsTrackPageViewPayload | null = null;

type ShouldTrackPageView = (payload: AnalyticsTrackPageViewPayload) => boolean;

const shouldTrackPageView: ShouldTrackPageView = payload => {
  let result = false;
  switch (true) {
    case payload.path === ROUTE.LOGIN:
      result = true;
      break;
    case !!cache?.caseId && !!payload.caseId && cache.caseId !== payload.caseId:
      result = true;
      break;
    case cache?.path !== payload.path:
      result = true;
      break;
  }
  cache = payload;
  return result;
};

export default shouldTrackPageView;
