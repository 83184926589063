import { useCallback } from 'react';

import { useCases } from 'modules/states/cases';
import { useModalCases } from 'modules/modals';

export type UseCasesSwitch = (handleClose?: () => void) => {
  disableSwitch: boolean;
  handleSwitch: () => void;
};

const useCasesSwitch: UseCasesSwitch = handleClose => {
  const [showModalCases] = useModalCases();
  const { cases } = useCases();

  const handleModalCases = useCallback(() => {
    !!showModalCases && showModalCases();
    handleClose && handleClose();
  }, [handleClose, showModalCases]);

  return {
    disableSwitch: !cases || cases.length < 2,
    handleSwitch: handleModalCases,
  };
};

export default useCasesSwitch;
