import { ThemeColor } from '../constants';
import { addListener } from './utils';

const query = window.matchMedia('(prefers-color-scheme: dark)');

export const themePluginColor = (): ThemeColor =>
  query.matches ? ThemeColor.DARK : ThemeColor.LIGHT;

export const themePluginSetClass = (theme: ThemeColor) =>
  document
    .getElementsByTagName('html')[0]
    .classList.toggle('platform-dark', theme === ThemeColor.DARK);

export const themePluginInit = (
  handler: (event: MediaQueryListEvent) => void,
) => addListener(query, handler);
