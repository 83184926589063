import React from 'react';
import clsx from 'clsx';

import { ReactComponent as InitialContainer } from 'modules/theme/images/icons/menu/initial-container.svg';
import { ReactComponent as Cross } from 'modules/theme/images/icons/menu/cross.svg';
import { useAccountSelector } from 'modules/states/account';
import Button from 'components/shared/Button';

import styles from './styles.module.scss';

type AccountProps = {
  animating: boolean;
  handleCloseMenu: () => void;
};

const Account: React.FunctionComponent<AccountProps> = ({
  animating,
  handleCloseMenu,
}) => {
  const { data } = useAccountSelector();
  const classNames = clsx(styles.account, data?.name && styles.loaded);
  return (
    <header className={classNames}>
      <div>
        <InitialContainer />
        {!!data?.name && (
          <React.Fragment>
            <span>{data.name.charAt(0)}</span>
            <p>{data.name}</p>
          </React.Fragment>
        )}
        {!!data?.caseId && <p>Case: {data.caseId}</p>}
        <Button
          ariaControls="header"
          ariaLabel="Close Menu"
          disabled={animating}
          onClick={handleCloseMenu}
          title="Close Menu">
          <Cross />
        </Button>
      </div>
    </header>
  );
};

export default React.memo(Account);
