import React, { useMemo } from 'react';
import clsx from 'clsx';

import ExtensionIcon from './ExtensionIcon';
import { formatDate } from 'modules/date';
import {
  ExternalLinkTarget,
  HyperlinkStyle,
  ExternalLink,
} from 'components/shared/Hyperlinks';
import {
  DocumentExtension,
  DocumentCase,
  DocumentType,
  Document,
} from 'modules/states/documents';

import styles from './styles.module.scss';

type DocumentsTabPanelProps = {
  selectedTab: string | null;
  tabPanel: Document[];
  tabPanelRef: React.MutableRefObject<HTMLUListElement | null>;
};

const DocumentsTabPanel: React.FunctionComponent<DocumentsTabPanelProps> = ({
  tabPanelRef,
  selectedTab,
  tabPanel,
}) => {
  const type = useMemo(() => tabPanel[0].type, [tabPanel]);
  const selectedType = useMemo(() => type === selectedTab, [type, selectedTab]);
  const classNames = clsx(
    styles['documents-tab-panel'],
    selectedType && styles.active,
  );
  return (
    <ul
      aria-labelledby={type}
      className={classNames}
      ref={selectedType ? tabPanelRef : null}
      role="tabpanel"
      tabIndex={selectedType ? 0 : -1}>
      {tabPanel.map((item, key) => {
        return (
          <li key={`${key}-${item.documentId}`}>
            <ExtensionIcon extension={item.extension} />
            <h2>{item.description}</h2>
            <p>{formatDate(item.date, 'dddd DD MMMM YYYY')}</p>
            {item.type === DocumentType.CASE && (
              <ExternalLink
                href={(item as DocumentCase).url}
                label={
                  item.extension === DocumentExtension.WEB ? 'Open' : 'Download'
                }
                style={HyperlinkStyle.PRIMARY}
                target={ExternalLinkTarget.BLANK}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default React.memo(DocumentsTabPanel);
