import React from 'react';
import clsx from 'clsx';

import { FileCapture } from './constants';
import { useActiveAnimation, useHandlers } from './hooks';
import { useRef } from 'react';

export { FileCapture } from './constants';

type FileProps = {
  accept: string;
  capture?: FileCapture;
  disabled?: boolean;
  id?: string;
  label: string;
  multiple?: boolean;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const File: React.FunctionComponent<FileProps> = ({
  accept,
  capture,
  disabled,
  id,
  label,
  multiple,
  name,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { animation, handleActiveAnimation, ref } = useActiveAnimation();
  const { handleClick, handleKeyDown, handleMouseDown } = useHandlers(
    handleActiveAnimation,
    inputRef,
    disabled,
  );
  const labelClassName = clsx(animation && 'active-animation');
  return (
    <div>
      <input
        accept={accept}
        capture={capture}
        disabled={disabled}
        id={id ? id : name}
        multiple={multiple}
        name={name}
        onChange={onChange}
        ref={inputRef}
        tabIndex={-1}
        type="file"
      />
      <label
        className={labelClassName}
        htmlFor={id ? id : name}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        ref={ref}
        tabIndex={disabled ? -1 : 0}>
        {label}
      </label>
    </div>
  );
};

export default React.memo(File);
