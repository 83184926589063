import React from 'react';

import styles from './styles.module.scss';

const NoTeamMembers: React.FunctionComponent = () => {
  return (
    <div className={styles['no-team-members']}>
      <p>You haven't been assigned a team member yet.</p>
    </div>
  );
};

export default React.memo(NoTeamMembers);
