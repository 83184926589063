import { OnfidoAction, OnfidoError, OnfidoVariant } from '../../constants';
import { getOnfidoToken, showOnfido, isAllowed, isPresent } from './utils';
import { isError } from 'modules/utils';
//import { testOnfido } from './utils';
import {
  OnfidoShowFailureAction,
  OnfidoShowStartedAction,
  OnfidoShowSuccessAction,
  OnfidoShowAction,
} from './types';

export const onfidoShowAction: OnfidoShowAction = request => {
  return async (dispatch, getState) => {
    try {
      if (!isAllowed(request, getState())) return;

      dispatch(onfidoShowStartedAction(request));

      const response = await getOnfidoToken({ dispatch, ...request });

      if (isPresent({ request, state: getState() })) {
        const payload = {
          dashboard: getState().config.data.onfidoUI,
          variant: OnfidoVariant.VIDEO,
          ...request,
          ...response,
        };

        dispatch(
          onfidoShowSuccessAction({
            modalId: showOnfido({ dispatch, ...payload }),
            ...payload,
          }),
        );

        //testOnfido(dispatch, getState);
      }
    } catch (error) {
      isPresent({ request, state: getState() }) &&
        dispatch(
          onfidoShowFailureAction({
            error:
              isError(error) && error.message === OnfidoError.GET
                ? error
                : new Error(OnfidoError.OPEN),
          }),
        );
    }
  };
};

const onfidoShowStartedAction: OnfidoShowStartedAction = payload => ({
  type: OnfidoAction.SHOW_STARTED,
  ...payload,
});

const onfidoShowSuccessAction: OnfidoShowSuccessAction = payload => ({
  type: OnfidoAction.SHOW_SUCCESS,
  ...payload,
});

const onfidoShowFailureAction: OnfidoShowFailureAction = payload => ({
  type: OnfidoAction.SHOW_FAILURE,
  ...payload,
});
