import React from 'react';

import { usePlatformContext } from 'modules/services/platform';
import DesktopTemplate from './Desktop';
import MobileTemplate from './Mobile';

const Route: React.FunctionComponent = () => {
  const { isMobile } = usePlatformContext();
  return isMobile ? <MobileTemplate /> : <DesktopTemplate />;
};

export default React.memo(Route);
