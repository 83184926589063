import React from 'react';

import { ButtonStyle } from 'components/shared/Button/constants';
import { useModalCases, useModalVideo } from 'modules/modals';
import Fieldset from 'components/shared/Fieldset';
import Button from 'components/shared/Button';

import styles from 'routes/debug/styles.module.scss';

const ModalsDebug: React.FunctionComponent = () => {
  const [showModalCases] = useModalCases();
  const [showModalVideo] = useModalVideo();

  const handleModalCases = () => {
    !!showModalCases && showModalCases();
  };

  const handleModalVideo = () => {
    !!showModalVideo &&
      showModalVideo({
        payload: {
          video: {
            code: '288128972',
            mp4: 'https://player.vimeo.com/external/288128972.sd.mp4?s=f4576b504d939a132e021f4a40d96d50eda909bc&profile_id=165',
            url: 'https://player.vimeo.com/video/288128972',
          },
        },
      });
  };

  return (
    <React.Fragment>
      <div className={styles['modals-container']}>
        <Fieldset title="Modals">
          <div>
            <Button
              label="Modal - Cases"
              onClick={handleModalCases}
              style={ButtonStyle.PRIMARY}
            />

            <Button
              label="Modal - Video"
              onClick={handleModalVideo}
              style={ButtonStyle.PRIMARY}
            />
          </div>
        </Fieldset>
      </div>
    </React.Fragment>
  );
};

export default React.memo(ModalsDebug);
