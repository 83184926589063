import { useEffect } from 'react';

import { UseKeyDown } from './types';

const useKeyDown: UseKeyDown = ({ listener, ref }) => {
  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('keydown', listener);
    return () => {
      element?.removeEventListener('keydown', listener);
    };
  }, [listener, ref]);
};

export default useKeyDown;
