import { StatusBarColor, StatusBarStyle } from '../constants';
import {
  StatusBarPluginUpdateAndroid,
  StatusBarPluginInitAndroid,
} from './types';
import {
  setBackgroundColor,
  isLightRoute,
  //setSafeArea,
  //initPlugin,
  setStyle,
} from '../utils';

export const statusBarPluginInitAndroid: StatusBarPluginInitAndroid =
  async path => {
    //await initPlugin();
    //await setSafeArea()
    await statusBarPluginUpdateAndroid(path);
  };

export const statusBarPluginUpdateAndroid: StatusBarPluginUpdateAndroid =
  async path => {
    switch (true) {
      case isLightRoute(path):
        await setStyle(StatusBarStyle.Light);
        await setBackgroundColor(StatusBarColor.Light);
        break;
      default:
        await setStyle(StatusBarStyle.Dark);
        await setBackgroundColor(StatusBarColor.Dark);
    }
  };
