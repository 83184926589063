import { NotificationsAction } from '../constants';
import {
  NotificationsReceivedAction,
  NotificationsLogoutAction,
} from '../types';

export { notificationsInitAction } from './notificationsInitAction';

export {
  type NotificationsPostActionApiRequest,
  type NotificationsPostActionType,
  notificationsPostReducer,
  notificationsPostAction,
} from './notificationsPostAction';

export {
  type NotificationsRegisterActionType,
  notificationsRegisterReducer,
  notificationsRegisterAction,
} from './notificationsRegisterAction';

export const notificationsReceivedAction: NotificationsReceivedAction =
  notification => ({
    type: NotificationsAction.RECEIVED,
    notification,
  });

export const notificationsLogoutAction: NotificationsLogoutAction = () => ({
  type: NotificationsAction.LOGOUT,
});
