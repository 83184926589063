import { OnfidoPluginDestroyIOS, OnfidoPluginInitIOS } from './types';
import { onfidoPlugin } from '..';

export const onfidoPluginDestroyIOS: OnfidoPluginDestroyIOS =
  async animated => {
    await onfidoPlugin.reset();
    await onfidoPlugin.destroy({
      animated: animated === false ? false : true,
    });
  };

export const onfidoPluginInitIOS: OnfidoPluginInitIOS = async ({
  onComplete,
  onError,
  ...props
}) => {
  await onfidoPlugin.reset();
  onfidoPlugin.addListener(
    'complete',
    onComplete as (data: Record<string, unknown>) => void,
  );
  onfidoPlugin.addListener('error', data => onError(data.error));
  onfidoPlugin.open(props);
};
