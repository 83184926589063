import { OnfidoGetActionApiResponse } from '../../onfidoGetAction/types';
import { onfidoGetAction } from '../../onfidoGetAction';
import { platform } from 'modules/services/platform';
import { OnfidoError } from '../../../constants';
import { Dispatch } from 'modules/redux';

const { isAndroid, isCapacitor } = platform;

type GetOnfidoToken = (props: {
  caseId: number;
  dispatch: Dispatch;
  messageId: number;
  startURL: string;
}) => Promise<OnfidoGetActionApiResponse['data']>;

const getOnfidoToken: GetOnfidoToken = async ({
  dispatch,
  startURL,
  ...props
}) => {
  try {
    const referrer = isCapacitor
      ? isAndroid
        ? 'android'
        : 'ios'
      : window.location.hostname === 'localhost'
        ? 'local'
        : undefined;
    return await dispatch(onfidoGetAction({ referrer, startURL, ...props }));
  } catch {
    throw new Error(OnfidoError.GET);
  }
};

export default getOnfidoToken;
