import { OnfidoPluginDestroyAndroid, OnfidoPluginInitAndroid } from './types';
import { onfidoPlugin } from '..';

export const onfidoPluginDestroyAndroid: OnfidoPluginDestroyAndroid =
  async () => {
    await onfidoPlugin.reset();
    await onfidoPlugin.destroy();
  };

export const onfidoPluginInitAndroid: OnfidoPluginInitAndroid = async ({
  onComplete,
  onError,
  ...props
}) => {
  await onfidoPlugin.reset();
  onfidoPlugin.addListener(
    'complete',
    onComplete as (data: Record<string, unknown>) => void,
  );
  onfidoPlugin.addListener('error', data => onError(data.error));
  onfidoPlugin.open(props);
};
