import React from 'react';
import clsx from 'clsx';

import { useDocuploadModal } from 'modules/docupload/hooks';
import Overlay, { OverlayAnimation } from 'components/shared/Overlay';
import { usePlatformContext } from 'modules/services/platform';
import { Body, Header, Footer } from './components';
import { ModalDocuploadTemplateProps, ModalDocuploadProps } from './types';

import styles from './styles.module.scss';

export type { ModalDocuploadProps } from './types';

const ModalDocuploadTemplate = React.memo<ModalDocuploadTemplateProps>(
  ({
    animating,
    data,
    disabled,
    handleCamera,
    handleDelete,
    handleInput,
    handlePreview,
    handleUpload,
    isSending,
    isSendingProgress,
    onClose,
  }) => {
    const classNames = clsx(
      styles['modal-document-upload'],
      !isSending && (!!animating || disabled) && styles.disabled,
    );
    return (
      <div aria-label="Document Upload" className={classNames} role="dialog">
        <Header disabled={!!animating || disabled} onClose={onClose} />
        <Body
          disabled={!!animating || disabled}
          files={data}
          handleDelete={handleDelete}
          isSending={isSending}
          isSendingProgress={isSendingProgress}
        />
        <Footer
          disabled={!!animating || disabled}
          files={!!data.length}
          handleCamera={handleCamera}
          handleInput={handleInput}
          handlePreview={handlePreview}
          handleUpload={handleUpload}
        />
      </div>
    );
  },
);

const ModalDocupload: React.FunctionComponent<ModalDocuploadProps> = ({
  disableEscapeKey,
  modalId,
  onDestroy,
  open,
  payload,
  ...modal
}) => {
  const isMobileBreakpoint = window.matchMedia('(max-width: 767px)').matches;
  const { disabled, handleClose, ...props } = useDocuploadModal();
  const { isMobile } = usePlatformContext();
  return (
    <Overlay
      animation={
        isMobile || isMobileBreakpoint
          ? OverlayAnimation.SLIDE_UP
          : OverlayAnimation.ZOOM_IN
      }
      disableEscapeKey={disableEscapeKey}
      id={modalId}
      onDestroy={onDestroy}
      onEscapeKey={handleClose}
      open={open}>
      <ModalDocuploadTemplate
        {...modal}
        {...props}
        disabled={disabled}
        onClose={handleClose}
      />
    </Overlay>
  );
};

export default React.memo(ModalDocupload);
