import React, { useMemo } from 'react';
import clsx from 'clsx';

import { ReactComponent as Logo } from 'modules/theme/images/logos/fluent.svg';
import { shallowEqual, useSelector } from 'modules/redux';
import { useDesktopMenu } from './hooks/useDesktopMenu';
import { useScrollbar } from './hooks/useScrollbar';
import Navigation from './Navigation';

import styles from './styles.module.scss';

const Menu: React.FunctionComponent = () => {
  const { data } = useSelector(state => state.user, shallowEqual);
  const introText = useMemo(() => data && `Hi ${data.firstName}`, [data]);

  const { activeItems, ...props } = useDesktopMenu();
  const { scrollbarActive } = useScrollbar(props.navRef);

  const classNames = clsx(
    styles.menu,
    activeItems?.map(activeItem => styles[`${activeItem}`]),
    scrollbarActive && styles['scrollbar-active'],
  );

  return (
    <div className={classNames}>
      <div>
        <Logo />
        <p>
          {introText?.split('').map((letter, index) => (
            <span
              key={`introText-${index}`}
              style={{
                animationDelay: `${index / 10}s`,
                animationDuration: `${(introText?.split('').length + 1) / 12}s`,
              }}>
              {letter}
            </span>
          ))}
        </p>
        <Navigation {...props} />
      </div>
    </div>
  );
};

export default React.memo(Menu);
