import { useCallback } from 'react';

import { teamGetAction } from '../actions';
import { Team } from '../types';
import {
  shallowEqual,
  useDispatch,
  useSelector,
  Dispatch,
} from 'modules/redux';

export type UseTeamSelector = (caseId: number | null) => {
  data: Team['data'];
  handleRequest: () => void;
  isDirty: boolean;
  isError: Error | false;
  isLoading: boolean;
};

const useTeamSelector: UseTeamSelector = caseId => {
  const dispatch: Dispatch = useDispatch();

  const data = useSelector(
    state => state.team.find(items => items.caseId === caseId),
    shallowEqual,
  );

  const handleRequest = useCallback(
    () => !!caseId && dispatch(teamGetAction({ caseId })),
    [caseId, dispatch],
  );

  return {
    data: data?.data || null,
    handleRequest,
    isDirty: data?.isDirty || false,
    isError: data?.isError || false,
    isLoading: !!data?.isLoading,
  };
};

export default useTeamSelector;
