import { addListener, getOrientation } from './utils';
import { OrientationStatus } from '../constants';

export const orientationPluginGetOrientation = getOrientation;

export const orientationPluginInit = (
  handler: (orientation: OrientationStatus) => void,
) => addListener(handler);

export const orientationPluginSetClass = (orientation: OrientationStatus) =>
  document
    .getElementsByTagName('html')[0]
    .classList.toggle(
      'platform-landscape',
      orientation === OrientationStatus.LANDSCAPE,
    );
