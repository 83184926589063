import { AxiosProgressEvent, CancelTokenSource } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { NotificationsPostActionApiRequest } from 'modules/services/notifications';
import { DocumentsGetActionApiRequest } from 'modules/states/documents';
import { AuthChangePasswordActionApiRequest } from 'modules/auth';
import { DocuploadPostActionApiRequest } from 'modules/docupload';
import { StatusGetActionApiRequest } from 'modules/states/status';
import { LoanGetActionApiRequest } from 'modules/states/loan';
import { TeamGetActionApiRequest } from 'modules/states/team';
import { ApiConfig, DefaultApiRequest } from './types';
import { AuthApiRequest } from 'modules/auth';
import {
  MessagesPostActionApiRequest,
  MessagesReadActionApiRequest,
  MessagesGetActionApiRequest,
} from 'modules/states/messages';
import {
  OnfidoPostActionApiRequest,
  OnfidoGetActionApiRequest,
} from 'modules/onfido';

export enum ApiAbortCodes {
  BAD_REQUEST = StatusCodes.BAD_REQUEST,
  UNAUTHORIZED = StatusCodes.UNAUTHORIZED,
  UNPROCESSABLE_ENTITY = StatusCodes.UNPROCESSABLE_ENTITY,
  NOT_FOUND = StatusCodes.NOT_FOUND,
}

export enum ApiMethod {
  GET = 'get',
  POST = 'post',
}

const HOST = process.env.REACT_APP_API_ROOT;

export const API = {
  GET_CASES: ({
    controller,
    token,
    ...props
  }: DefaultApiRequest): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/user/cases`,
    ...props,
  }),

  GET_CONFIG: ({
    controller,
    ...props
  }: Omit<DefaultApiRequest, 'token'>): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    method: ApiMethod.GET,
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/config`,
    ...props,
  }),

  GET_DOCUMENTS: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: DocumentsGetActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/documents/list/all`,
    ...props,
  }),

  GET_LOAN: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: LoanGetActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/user/loan`,
    ...props,
  }),

  GET_MESSAGES: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: MessagesGetActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/messages`,
    ...props,
  }),

  GET_ONFIDO: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: OnfidoGetActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    params: {
      caseId: request.caseId,
      referrer: request.referrer,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}${request.startURL}`,
    ...props,
  }),

  GET_STATUS: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: StatusGetActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/user/status`,
    ...props,
  }),

  GET_TEAM: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: TeamGetActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/user/team`,
    ...props,
  }),

  GET_USER: ({
    controller,
    token,
    ...props
  }: DefaultApiRequest): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/user`,
    ...props,
  }),

  POST_AUTH: ({
    request,
    ...props
  }: Omit<DefaultApiRequest, 'controller' | 'token'> & {
    request: AuthApiRequest;
  }): ApiConfig => ({
    data: {
      ...request,
      grant_type: 'password',
    },
    method: ApiMethod.POST,
    url: `${HOST}/api/auth/login`,
    ...props,
  }),

  POST_DEVICE: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: NotificationsPostActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    data: request,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.POST,
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/users/register-push-device`,
    ...props,
  }),

  POST_DOCUMENT_UPLOAD: ({
    controller,
    onUploadProgress,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    onUploadProgress?: (event: AxiosProgressEvent) => void;
    request: DocuploadPostActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    data: request.data,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.POST,
    onUploadProgress,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}${request.uploadURL}`,
    ...props,
  }),

  POST_MESSAGE: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: MessagesPostActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    data: request.message,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/plain',
    },
    method: ApiMethod.POST,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/messages`,
    ...props,
  }),

  POST_MESSAGE_READ: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: MessagesReadActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    headers: { Authorization: `Bearer ${token}` },
    method: ApiMethod.GET,
    params: {
      caseId: request.caseId,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/messages/${request.messageId}`,
    ...props,
  }),

  POST_ONFIDO: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: OnfidoPostActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    data: request,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: ApiMethod.POST,
    params: {
      caseId: request.caseId,
      variant: request.variant,
    },
    signal: (controller as AbortController)?.signal,
    url: `${HOST}${request.finishURL}`,
    ...props,
  }),

  POST_PASSWORD: ({
    controller,
    token,
    request,
    ...props
  }: DefaultApiRequest & {
    request: AuthChangePasswordActionApiRequest;
  }): ApiConfig => ({
    cancelToken: (controller as CancelTokenSource)?.token,
    data: {
      passwordNew: request.password,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: ApiMethod.POST,
    signal: (controller as AbortController)?.signal,
    url: `${HOST}/api/user/password`,
    ...props,
  }),
};
