import React from 'react';
import { Provider } from 'react-redux';

import store from 'modules/redux';

import { Context } from 'modules/services/platform';
import { appInitAction } from './actions';
import App from './components/App';

store.dispatch(appInitAction());

function Wrapper() {
  return (
    <Provider store={store}>
      <Context>
        <App />
      </Context>
    </Provider>
  );
}

export default React.memo(Wrapper);
