import React from 'react';

import { ExternalLink, ExternalLinkTarget } from 'components/shared/Hyperlinks';
import { ReactComponent as Logo } from 'modules/theme/images/logos/fluent.svg';
import { QuestionText } from 'components/shared/Questions';
import Form from 'components/shared/Form';
import { LoginProps } from './types';
import {
  InputAutocomplete,
  InputMode,
  InputType,
} from 'components/shared/Inputs';
import Button, {
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from 'components/shared/Button';

import styles from './styles.module.scss';

const Password: React.FunctionComponent<LoginProps> = ({
  defaultValues,
  handleLogin,
  isError,
  isLoading,
  schema,
}) => {
  const disabled = !!isError || isLoading;
  return (
    <Form
      className={styles['login-form']}
      defaultValues={defaultValues}
      onSubmit={handleLogin}
      schema={schema}>
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <Logo />
          <h1>Welcome to MyFluent</h1>
          <p>Please enter your account details</p>
          <QuestionText
            autoComplete={InputAutocomplete.EMAIL}
            control={control}
            inputMode={InputMode.EMAIL}
            label="Email"
            name="username"
            readOnly={disabled}
            type={InputType.EMAIL}
          />
          <QuestionText
            autoComplete={InputAutocomplete.CURRENT_PASSWORD}
            control={control}
            label="Password"
            name="password"
            readOnly={disabled}
            type={InputType.PASSWORD}
          />
          <div>
            <Button
              disabled={disabled || isFormSubmitting}
              label="Login"
              size={ButtonSize.LARGE}
              style={ButtonStyle.PRIMARY_INVERTED}
              type={ButtonType.SUBMIT}
            />
            <ExternalLink
              disabled={disabled || isFormSubmitting}
              href="https://www.myfluent.co.uk/Account/ForgotPassword/"
              label="Forgot Password?"
              target={ExternalLinkTarget.BLANK}
            />
          </div>
        </React.Fragment>
      )}
    </Form>
  );
};

export default React.memo(Password);
