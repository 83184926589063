import { Location } from 'history';

import getBaseRoute from './getBaseRoute';
import { ROUTE } from '../constants';

const pageClass = (location: Location) => {
  const html = document.getElementsByTagName('html')[0];
  const route = getBaseRoute(location.pathname);
  switch (true) {
    case !!route && route !== ROUTE.LOGIN && route !== ROUTE.PASSWORD:
      html.classList.add('platform-authenticated');
      break;
    default:
      html.classList.remove('platform-authenticated');
  }
};

export default pageClass;
