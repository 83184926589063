import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { HyperlinkSize } from './constants';
import { NavigationLinkProps } from './types';
import { ReactComponent as Chevron } from 'modules/theme/images/icons/button/chevron.svg';
import { useActiveAnimation, useHandlers } from './hooks';

import styles from './styles.module.scss';

const NavigationLink: React.FunctionComponent<NavigationLinkProps> = ({
  ariaLabel,
  chevron = true,
  children,
  className,
  disabled,
  label,
  onClick,
  route,
  size = HyperlinkSize.SMALL,
  style,
  title,
  ...props
}) => {
  const { animation, handleActiveAnimation, ref } = useActiveAnimation();
  const { handleClick, handleMouseDown, handleKeyDown } = useHandlers(
    handleActiveAnimation,
    disabled,
    onClick,
  );

  const classNames = clsx(
    style && styles.hyperlink,
    style && styles[`${style}`],
    style && size && styles[`${size}`],
    disabled && 'disabled',
    animation && 'active-animation',
    className,
  );

  return (
    <NavLink
      aria-label={ariaLabel}
      end
      className={classNames}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      ref={ref}
      tabIndex={disabled ? -1 : 0}
      title={title || ariaLabel}
      to={route}
      {...props}>
      {label && <span>{label}</span>}
      {children}
      {style && chevron && <Chevron />}
    </NavLink>
  );
};

export default React.memo(NavigationLink);
