export enum OnfidoAction {
  GET_STARTED = 'ONFIDO_GET_STARTED',
  GET_SUCCESS = 'ONFIDO_GET_SUCCESS',
  GET_FAILURE = 'ONFIDO_GET_FAILURE',

  PLUGIN_SUCCESS = 'ONFIDO_PLUGIN_SUCCESS',
  PLUGIN_FAILURE = 'ONFIDO_PLUGIN_FAILURE',
  PLUGIN_CANCEL = 'ONFIDO_PLUGIN_CANCEL',

  POST_STARTED = 'ONFIDO_POST_STARTED',
  POST_SUCCESS = 'ONFIDO_POST_SUCCESS',
  POST_FAILURE = 'ONFIDO_POST_FAILURE',

  SHOW_STARTED = 'ONFIDO_SHOW_STARTED',
  SHOW_SUCCESS = 'ONFIDO_SHOW_SUCCESS',
  SHOW_FAILURE = 'ONFIDO_SHOW_FAILURE',

  CANCEL = 'ONFIDO_CANCEL',

  CLOSE = 'ONFIDO_CLOSE',

  DESTROY = 'ONFIDO_DESTROY',

  LOGOUT = 'ONFIDO_LOGOUT',

  RESET_ERROR = 'ONFIDO_RESET_ERROR',
}

export enum OnfidoVariant {
  MOTION = 'motion',
  PHOTO = 'standard',
  VIDEO = 'video',
}

export enum OnfidoError {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  GET = 'GET',
  MOUNT = 'MOUNT',
  OPEN = 'OPEN',
  POST = 'POST',
  REFERRER = 'REFERRER',
  SDK = 'SDK',
  TOKEN = 'TOKEN',
  UNKNOWN = 'UNKNOWN',
  VARIANT = 'VARIANT',
}
