import { useCallback, useState } from 'react';
import { Noop } from 'react-hook-form';

export const useFocus = (onBlur: Noop) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = useCallback(() => {
    onBlur();
    setFocused(false);
  }, [onBlur]);

  const handleFocus = useCallback(() => setFocused(true), []);

  return {
    handleBlur,
    handleFocus,
    focused,
  };
};
