import { shallowEqual, useSelector } from 'modules/redux';
import { UseDocuploadModal } from './types';
import useHandlers from './hooks';

const useDocuploadModal: UseDocuploadModal = () => {
  const {
    data,
    isError,
    isLoading,
    isSending,
    isSendingError,
    isSendingProgress,
  } = useSelector(state => state.docupload, shallowEqual);

  const disabled = !!isError || !!isLoading || !!isSending || !!isSendingError;

  const handlers = useHandlers({
    disabled,
    isError,
    isSendingError,
  });

  return {
    data,
    disabled,
    isSending: !!isSending,
    isSendingProgress,
    ...handlers,
  };
};

export default useDocuploadModal;
