import {
  NetworkPollingStartedAction,
  NetworkPollingEndedAction,
  NetworkEventAction,
  NetworkInitAction,
} from './types';
import { NetworkAction } from './constants';

export const networkEventAction: NetworkEventAction = payload => ({
  type: NetworkAction.EVENT,
  ...payload,
});

export const networkInitAction: NetworkInitAction = payload => ({
  type: NetworkAction.INIT,
  ...payload,
});

export const networkPollingStartedAction: NetworkPollingStartedAction = () => ({
  type: NetworkAction.POLLING_STARTED,
});

export const networkPollingEndedAction: NetworkPollingEndedAction = () => ({
  type: NetworkAction.POLLING_ENDED,
});
