import { createDefaultAction } from 'modules/redux/factories';
import { NotificationsAction } from '../../constants';
import { API } from 'modules/api';
import {
  NotificationsPostFailureAction,
  NotificationsPostStartedAction,
  NotificationsPostSuccessAction,
  NotificationsPostAction,
} from './types';

export const notificationsPostAction: NotificationsPostAction = request =>
  createDefaultAction({
    actionStarted: notificationsPostStartedAction,
    actionSuccess: notificationsPostSuccessAction,
    actionFailure: notificationsPostFailureAction,
    endpoint: API.POST_DEVICE,
    isActive: ({ state }) => !!state.notifications.isSending,
    isAuthorized: state =>
      !!state.auth.data?.token && !!state.platform.isCapacitor,
    isPresent: ({ actionId, state }) =>
      actionId === state.notifications.actionId,
    request: request,
  });

const notificationsPostStartedAction: NotificationsPostStartedAction =
  payload => ({
    type: NotificationsAction.POST_STARTED,
    ...payload,
  });

const notificationsPostSuccessAction: NotificationsPostSuccessAction =
  payload => ({
    type: NotificationsAction.POST_SUCCESS,
    ...payload,
  });

const notificationsPostFailureAction: NotificationsPostFailureAction =
  payload => ({
    type: NotificationsAction.POST_FAILURE,
    ...payload,
  });
