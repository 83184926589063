import { produce } from 'immer';

import { Version, VersionActionType } from './types';
import { VersionAction } from './constants';

const initialState = (): Version => {
  return { env: '', version: '' };
};

function versionReducer(state = initialState(), action: VersionActionType) {
  switch (action.type) {
    case VersionAction.INIT:
      return produce(state, (draftState: Version) => {
        !!action.payload.build && (draftState.build = action.payload.build);
        draftState.env = action.payload.env;
        draftState.version = action.payload.version;
      });

    default:
      return state;
  }
}

export default versionReducer;
