import { useCallback } from 'react';

export const useHandlers = (
  disabled?: boolean,
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void,
) => {
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) =>
      !disabled && !!onBlur && onBlur(event),
    [disabled, onBlur],
  );
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      disabled && event.currentTarget.blur();
      !disabled && !!onFocus && onFocus(event);
    },
    [disabled, onFocus],
  );

  return {
    handleBlur,
    handleFocus,
  };
};
