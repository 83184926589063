import { useCallback, useMemo } from 'react';

import { useCasesSelector } from 'modules/states/cases';
import { useUserSelector } from 'modules/states/user';
import { getAccountData } from '../utils';
import { AccountData } from '../types';

export type UseAccountSelector = () => {
  caseId: number | null;
  data: AccountData | null;
  handleError: () => void;
  isError: Error | false;
  isLoading: boolean;
};

const useAccountSelector: UseAccountSelector = () => {
  const {
    data: dataUser,
    handleError: handleUserError,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useUserSelector();

  const {
    caseId,
    data: dataCase,
    handleError: handleCasesError,
    isError: isCasesError,
    isLoading: isCasesLoading,
  } = useCasesSelector();

  const handleError = useCallback(
    () => (isUserError ? handleUserError() : handleCasesError()),
    [handleUserError, handleCasesError, isUserError],
  );

  const isError = useMemo(
    () => (isUserError ? isUserError : isCasesError),
    [isCasesError, isUserError],
  );

  const isLoading = useMemo(
    () => !dataUser || isCasesLoading || isUserLoading,
    [dataUser, isCasesLoading, isUserLoading],
  );

  const data = useMemo(
    () => getAccountData(dataCase, dataUser),
    [dataCase, dataUser],
  );

  return { caseId, data, handleError, isError, isLoading };
};

export default useAccountSelector;
