import React from 'react';

import { ReactComponent as CirclesSVG } from 'modules/theme/images/login/circles.svg';
import { ReactComponent as LoginSVG } from 'modules/theme/images/login/login.svg';
import Password from 'components/desktop/Password';
import { UsePasswordRoute } from 'modules/auth';
import { Dialogs } from 'modules/dialogs';

import styles from './styles.module.scss';

type TemplateProps = ReturnType<UsePasswordRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  ...props
}) => {
  return (
    <React.Fragment>
      <main className={styles.password}>
        <div>
          <Password isLoading={isLoading} {...props} />
          <CirclesSVG />
          <LoginSVG />
        </div>
        {isLoading && <div className={styles.loading}></div>}
      </main>
      <div className={styles['modals']}>
        <Dialogs />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Template);
