export enum ModalAction {
  DESTROY = 'MODAL_DESTROY',
  INSERT = 'MODAL_INSERT',
  HIDE = 'MODAL_HIDE',
  LOGOUT = 'MODAL_LOGOUT',
}

export enum ModalType {
  CASES = 'cases',
  DOCUMENT_UPLOAD = 'document-upload',
  ONFIDO = 'onfido',
  VIDEO = 'video',
}
