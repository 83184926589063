import { useCallback } from 'react';

import { docuploadPluginInputAction } from '../../../actions';
import { HandleInput, UseHandleInput } from './types';

const useHandleInput: UseHandleInput = dispatch => {
  const handleInput: HandleInput = useCallback(
    event => dispatch(docuploadPluginInputAction(event)),
    [dispatch],
  );

  return handleInput;
};

export default useHandleInput;
