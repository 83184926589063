import React from 'react';

import { ReactComponent as CirclesSVG } from 'modules/theme/images/login/circles.svg';
import { ReactComponent as LoginSVG } from 'modules/theme/images/login/login.svg';
import Login from 'components/desktop/Login';
import { UseAuthRoute } from 'modules/auth';
import { Dialogs } from 'modules/dialogs';

import styles from './styles.module.scss';

type TemplateProps = ReturnType<UseAuthRoute>;

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  ...props
}) => {
  return (
    <React.Fragment>
      <main className={styles.login}>
        <div>
          <Login isLoading={isLoading} {...props} />
          <CirclesSVG />
          <LoginSVG />
        </div>
        {isLoading && <div className={styles.loading}></div>}
      </main>
      <div className={styles['modals']}>
        <Dialogs />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Template);
