import { DocuploadError } from '../../constants';
import { isError } from 'modules/utils';

type GetError = (error: unknown) => Error;

export const getError: GetError = error => {
  switch (true) {
    case isError(error) &&
      Object.values(DocuploadError).includes(error.message as DocuploadError):
      return error as Error;
    default:
      return new Error(DocuploadError.UPLOAD);
  }
};
