import { messagesGetAction } from 'modules/states/messages'; //TODO THIS CAUSES ERROR ON IMPORT
import { getCaseRoute, middlewareNavigate, ROUTE } from 'modules/router'; //TODO THIS CAUSES ERROR ON IMPORT
import { casesSelectAction } from 'modules/states/cases';
import { statusGetAction } from 'modules/states/status';
import { Dispatch, RootState } from 'modules/redux';
import { NotificationsType } from '../constants';
import { NotificationsPayload } from '../types';
import showDialog from './showDialog';

type HandleNotification = (props: {
  dispatch: Dispatch;
  getState: () => RootState;
  notification: NotificationsPayload;
}) => void;

const handleNotification: HandleNotification = ({
  dispatch,
  getState,
  notification,
}) => {
  const caseId = notification.data.caseId
    ? Number(notification.data.caseId)
    : getState().cases.caseId;

  if (!!caseId && getState().cases.data?.find(item => item.caseId === caseId))
    switch (notification.data.messageCategory) {
      case NotificationsType.NOTE: {
        const route = getCaseRoute(caseId, ROUTE.MESSAGES);

        switch (true) {
          case route === getState().router.location?.pathname:
            dispatch(messagesGetAction({ caseId }));
            break;

          case caseId === getState().cases.caseId:
            showDialog(dispatch, notification);
            middlewareNavigate(dispatch, route);
            break;

          default:
            showDialog(dispatch, notification);
            dispatch(casesSelectAction({ caseId, route }));
        }
        break;
      }

      case NotificationsType.STATUS: {
        const route = getCaseRoute(caseId, ROUTE.STATUS);

        switch (true) {
          case route === getState().router.location?.pathname:
            dispatch(statusGetAction({ caseId }));
            break;

          case caseId === getState().cases.caseId:
            showDialog(dispatch, notification);
            middlewareNavigate(dispatch, route);
            break;

          default:
            showDialog(dispatch, notification);
            dispatch(casesSelectAction({ caseId, route }));
        }
        break;
      }
    }
};

export default handleNotification;
