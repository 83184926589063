import { useCallback } from 'react';

import { Dispatch } from 'modules/redux';
import {
  authBiometricLoginResetAction,
  authBiometricLoginAction,
  authLoginAction,
  AuthLoginModel,
} from '../../../actions';

type HandleLogin = (credentials: AuthLoginModel) => void;

type UseHandleLogin = (props: {
  dispatch: Dispatch;
  isBiometric: boolean;
  username: string | null;
}) => {
  handleLogin: HandleLogin;
  handleReset: () => void;
};

const useHandleLogin: UseHandleLogin = ({
  dispatch,
  isBiometric,
  username,
}) => {
  const handleLogin: HandleLogin = useCallback(
    credentials =>
      dispatch(
        isBiometric
          ? authBiometricLoginAction(credentials.username)
          : authLoginAction(credentials),
      ),
    [dispatch, isBiometric],
  );

  const handleReset = useCallback(
    () => !!username && dispatch(authBiometricLoginResetAction(username)),
    [dispatch, username],
  );

  return { handleLogin, handleReset };
};

export default useHandleLogin;
