import { App } from '@capacitor/app';

import { getVersionAndroidBuild, getVersionEnvironment } from './utils';
import { platform } from 'modules/services/platform';
import { Version } from './types';

const { isAndroid, isCapacitor } = platform;

const versionPluginWeb = (): Version => {
  return {
    env: getVersionEnvironment(),
    version: process.env.REACT_APP_VERSION || '0.0.0',
  };
};

const versionPluginAndroid = async (): Promise<Version> => {
  const { build, version } = await App.getInfo();
  return {
    build: getVersionAndroidBuild(build),
    env: getVersionEnvironment(),
    version,
  };
};

const versionPluginIOS = async (): Promise<Version> => {
  const { build, version } = await App.getInfo();
  return { build, env: getVersionEnvironment(), version };
};

export const versionPluginInit = async (): Promise<Version> => {
  switch (true) {
    case isCapacitor && isAndroid:
      return await versionPluginAndroid();
    case isCapacitor && !isAndroid:
      return await versionPluginIOS();
    default:
      return versionPluginWeb();
  }
};
