import { useCallback } from 'react';

import { ShowDialog, UseDialogConfirm } from './types';
import { DialogType } from 'modules/dialogs/constants';
import useDialog from '../useDialog';

const useDialogConfirm: UseDialogConfirm = () => {
  const [showDialogDefault, hideDialog] = useDialog();

  const showDialog: ShowDialog = useCallback(
    dialog =>
      showDialogDefault
        ? showDialogDefault({ ...dialog, type: DialogType.CONFIRM })
        : undefined,
    [showDialogDefault],
  );

  const result: ReturnType<UseDialogConfirm> = [
    showDialogDefault ? showDialog : undefined,
    hideDialog,
  ];
  result.hideDialog = hideDialog;
  result.showDialog = showDialogDefault ? showDialog : undefined;
  return result;
};

export default useDialogConfirm;
