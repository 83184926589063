import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

import { pageClass, pageScroll, pageTitle } from './utils';
import { Dispatch, RootState } from 'modules/redux';
import { RouterActionType } from 'modules/router';

const routerMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: RouterActionType) => {
    let nextAction;

    switch (action.type) {
      case LOCATION_CHANGE: {
        pageScroll(action.payload.location);
        pageClass(action.payload.location);
        pageTitle(action.payload.location);
        break;
      }
    }

    return nextAction ? nextAction : next(action);
  };

export default routerMiddleware;
