import { useCallback, useEffect, useRef } from 'react';

import { DocuploadError } from '../../../../../constants';
import { UseHandleErrorSwitch } from './types';

const useHandleErrorSwitch: UseHandleErrorSwitch = ({
  handleDefaultError,
  handlePostError,
}) => {
  const ref = useRef<(error: false | Error) => void>();

  useEffect(() => {
    ref.current = error => {
      if (error && error.message !== DocuploadError.CANCELLED)
        switch (true) {
          case error.message === DocuploadError.POST:
          case error.message === DocuploadError.UPLOAD:
            handlePostError();
            break;
          default:
            handleDefaultError(error);
            break;
        }
    };
  }, [handleDefaultError, handlePostError]);

  const handleErrorSwitch = useCallback(
    (error: false | Error) => ref.current && ref.current(error),
    [],
  );

  return handleErrorSwitch;
};

export default useHandleErrorSwitch;
