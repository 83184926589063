import { SplashScreenHideAction, SplashScreenShowAction } from './types';
import { SplashScreenAction } from './constants';

export const splashScreenHideAction: SplashScreenHideAction = () => ({
  type: SplashScreenAction.HIDE,
});

export const splashScreenShowAction: SplashScreenShowAction = () => ({
  type: SplashScreenAction.SHOW,
});
