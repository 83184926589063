import { DocumentType } from 'modules/states/documents';

export const getLabel = (label: string) => {
  switch (label) {
    case DocumentType.CASE:
      return 'Received';
    case DocumentType.UPLOAD:
      return 'Uploaded';
    default:
      return label;
  }
};
