import { produce } from 'immer';

import { deleteAuthToken, loadAuthData } from './utils';
import { Auth, AuthActionType } from './types';
import { AuthAction } from './constants';
import {
  authBiometricQuestionReducer,
  authBiometricLoginReducer,
  authChangePasswordReducer,
  authLoginReducer,
} from './actions';

const initialState = (): Auth => {
  return {
    actionId: null,
    changePassword: false,
    data: loadAuthData(),
    isBiometric: false,
    isError: false,
    isLoading: false,
  };
};

function authReducer(state = initialState(), action: AuthActionType) {
  switch (action.type) {
    case AuthAction.INIT:
      return produce(state, (draftState: Auth) => {
        draftState.isBiometric = action.isBiometric;
      });

    case AuthAction.BIOMETRIC_LOGIN_FAILURE:
    case AuthAction.BIOMETRIC_LOGIN_STARTED:
    case AuthAction.BIOMETRIC_LOGIN_SUCCESS:
    case AuthAction.BIOMETRIC_LOGIN_STATUS:
    case AuthAction.BIOMETRIC_LOGIN_RESET:
      return authBiometricLoginReducer(state, action);

    case AuthAction.BIOMETRIC_QUESTION_FAILURE:
    case AuthAction.BIOMETRIC_QUESTION_STARTED:
    case AuthAction.BIOMETRIC_QUESTION_SUCCESS:
      return authBiometricQuestionReducer(state, action);

    case AuthAction.CHANGE_PASSWORD_FAILURE:
    case AuthAction.CHANGE_PASSWORD_STARTED:
    case AuthAction.CHANGE_PASSWORD_SUCCESS:
      return authChangePasswordReducer(state, action);

    case AuthAction.LOGIN_CHANGE_PASSWORD:
    case AuthAction.LOGIN_FAILURE:
    case AuthAction.LOGIN_STARTED:
    case AuthAction.LOGIN_SUCCESS:
    case AuthAction.LOGIN_RESET:
      return authLoginReducer(state, action);

    case AuthAction.LOGOUT:
      return deleteAuthToken({
        ...initialState(),
        isBiometric: state.isBiometric,
      });

    default:
      return state;
  }
}

export default authReducer;
