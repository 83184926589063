import { MessagesGetActionApiResponseItemCTA } from '../../types';
import { MessageActionOnfido } from '../../../../constants';
import { MessageOnfido } from '../../../../types';

type UpdateMessageOnfido = (
  data: MessagesGetActionApiResponseItemCTA,
  message: MessageOnfido,
) => void;

const updateMessageOnfido: UpdateMessageOnfido = (data, message) =>
  !!message.callToAction &&
  Object.values(MessageActionOnfido).includes(
    data.action as MessageActionOnfido,
  ) &&
  (message.callToAction.action = data.action as MessageActionOnfido);

export default updateMessageOnfido;
