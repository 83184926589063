import React from 'react';
import clsx from 'clsx';

import Avatar from 'components/shared/Avatar';
import { MessageHeaderProps } from './types';

const MessageHeader: React.FunctionComponent<MessageHeaderProps> = ({
  dateHeader,
  dateTime,
  name,
  showHeader = false,
}) => {
  const className = clsx(!showHeader && 'visually-hidden');
  return (
    <header className={className}>
      <Avatar mask={true} name={name} />
      <h2>{name}</h2>
      <time dateTime={dateTime}>{dateHeader}</time>
    </header>
  );
};

export default React.memo(MessageHeader);
