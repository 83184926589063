import clsx from 'clsx';

import { MessageActionOnfido as MessageAction } from 'modules/states/messages';

import styles from '../../../styles.module.scss';

type GetMessageOnfidoClassName = (props: {
  action: MessageAction;
  active: boolean;
  defaultClassName: string;
  disabled: boolean;
}) => string;

const getMessageOnfidoClassName: GetMessageOnfidoClassName = ({
  action,
  active,
  defaultClassName,
  disabled,
}) =>
  clsx(
    defaultClassName,
    styles['onfido'],
    active && styles['active'],
    disabled && styles['disabled'],
    MessageAction.APPROVED === action && styles['approved'],
    MessageAction.FAILED === action && styles['failed'],
    MessageAction.INPROGRESS === action && styles['inprogress'],
    MessageAction.REQUESTED === action && styles['requested'],
  );

export default getMessageOnfidoClassName;
