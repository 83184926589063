import React from 'react';

import Overlay, { OverlayAnimation } from 'components/shared/Overlay';
import { ModalCasesProps, ModalCasesTemplateProps } from './types';
import { usePlatformContext } from 'modules/services/platform';
import NoActiveCases from 'components/shared/NoActiveCases';
import { useCasesModal } from 'modules/states/cases';
import Button from 'components/shared/Button';
import CaseList from './CaseList';

import styles from './styles.module.scss';

export type { ModalCasesProps } from './types';

const ModalCasesTemplate = React.memo<ModalCasesTemplateProps>(
  ({ animating, onClose }) => {
    const {
      activeCases,
      caseId,
      handleContact,
      handleSelectCase,
      handleShowInactive,
      inactiveCases,
      showInactive,
    } = useCasesModal({
      animating,
      onClose,
    });
    return (
      <div aria-label="Cases" className={styles['modal-cases']} role="dialog">
        <header>
          <div>
            <Button
              ariaLabel="Close Cases"
              disabled={!!animating}
              onClick={onClose}
              title="Close Cases"
            />
            <h2>Cases</h2>
          </div>
        </header>
        {!!activeCases?.length || showInactive ? (
          <CaseList
            activeCases={activeCases}
            caseId={caseId}
            disabled={!!animating}
            handleSelectCase={handleSelectCase}
            inactiveCases={inactiveCases}
          />
        ) : (
          <NoActiveCases
            disabled={!!animating}
            handleContact={handleContact}
            handleShowInactive={handleShowInactive}
            inactiveCases={!!inactiveCases?.length}
          />
        )}
      </div>
    );
  },
);

const ModalCases: React.FunctionComponent<ModalCasesProps> = ({
  disableEscapeKey,
  modalId,
  onClose,
  onDestroy,
  open,
  ...modal
}) => {
  const isMobileBreakpoint = window.matchMedia('(max-width: 767px)').matches;
  const { isMobile } = usePlatformContext();
  return (
    <Overlay
      animation={
        isMobile || isMobileBreakpoint
          ? OverlayAnimation.SLIDE_UP
          : OverlayAnimation.ZOOM_IN
      }
      disableEscapeKey={disableEscapeKey}
      id={modalId}
      onDestroy={onDestroy}
      onEscapeKey={onClose}
      open={open}>
      <ModalCasesTemplate onClose={onClose} {...modal} />
    </Overlay>
  );
};

export default React.memo(ModalCases);
