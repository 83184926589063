import { StatusStep } from 'modules/states/status';

const getStatusText = (step: StatusStep) => {
  switch (step) {
    case StatusStep.CANCELLED:
      return 'Your application has been cancelled.';
    case StatusStep.DECLINED:
      return 'Your application has been declined.';
    case StatusStep.STEP1:
      return "We've posted your quote to you.";
    case StatusStep.STEP2:
      return 'Your case manager is reviewing your documents and should be in touch in the next 48 hours.';
    case StatusStep.STEP3:
      return 'Your case manager is hard at work reviewing and checking your case.';
    case StatusStep.STEP4:
      return "We've sent your case to your lender.";
    case StatusStep.STEP5:
      return 'Your case has completed.';
    case StatusStep.UNKNOWN:
    default:
      return '';
  }
};

export default getStatusText;
