import React from 'react';

import { ReactComponent as SwitchCase } from 'modules/theme/images/icons/menu/switch-case.svg';
import { ReactComponent as Contact } from 'modules/theme/images/icons/menu/contact.svg';
import { ReactComponent as LogOut } from 'modules/theme/images/icons/menu/log-out.svg';
import { useCase, useCasesSwitch } from 'modules/states/cases';
import { NavigationLink } from 'components/shared/Hyperlinks';
import { useAuthSelector } from 'modules/auth';
import Button from 'components/shared/Button';
import { ROUTE } from 'modules/router';

import styles from './styles.module.scss';

type NavigationProps = {
  animating: boolean;
  handleCloseMenu: () => void;
};

const Navigation: React.FunctionComponent<NavigationProps> = ({
  animating,
  handleCloseMenu,
}) => {
  const { disableSwitch, handleSwitch } = useCasesSwitch(handleCloseMenu);
  const { handleLogout } = useAuthSelector();
  const { isCaseActive } = useCase();
  return (
    <nav aria-label="Secondary" className={styles.navigation}>
      <ul>
        {!!handleSwitch && (
          <li>
            <Button
              disabled={animating || disableSwitch}
              label="Switch Case"
              onClick={handleSwitch}>
              <SwitchCase />
            </Button>
          </li>
        )}
        {!isCaseActive && (
          <li>
            <NavigationLink
              disabled={animating}
              label="Contact"
              onClick={handleCloseMenu}
              route={ROUTE.CONTACT}>
              <Contact />
            </NavigationLink>
          </li>
        )}
        <li>
          <Button disabled={animating} label="Log Out" onClick={handleLogout}>
            <LogOut />
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default React.memo(Navigation);
