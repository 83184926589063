import { shallowEqual, useSelector } from 'modules/redux';
import { ConfigData } from './types';

export type UseConfig = () => {
  isDirty: boolean;
  isLoading: boolean;
  message: ConfigData['appMaintenanceMessage'];
  minVersion: ConfigData['appMinimumVersion'];
};

const useConfig: UseConfig = () => {
  const {
    data: { appMaintenanceMessage, appMinimumVersion },
    isDirty,
    isLoading,
  } = useSelector(state => state.config, shallowEqual);
  return {
    isDirty,
    isLoading: !!isLoading,
    message: appMaintenanceMessage,
    minVersion: appMinimumVersion,
  };
};

export default useConfig;
