import React from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuthSelector } from 'modules/auth';
import { history } from 'modules/redux';
import { ROUTE } from './constants';

import RouteDocuments from 'routes/documents';
import RouteMessages from 'routes/messages';
import RoutePassword from 'routes/password';
import RouteContact from 'routes/contact';
import RouteStatus from 'routes/status';
import RouteDebug from 'routes/debug';
import RouteLogin from 'routes/login';
import RouteLoan from 'routes/loan';
import RouteTeam from 'routes/team';
import RouteApp from 'routes/app';

const Component: React.FunctionComponent = () => {
  const { changePassword, isAuthenticated } = useAuthSelector();
  return (
    <Router history={history}>
      {!isAuthenticated ? (
        <Routes>
          <Route index element={<RouteLogin />} />
          <Route path="*" element={<Navigate replace to={ROUTE.LOGIN} />} />
        </Routes>
      ) : changePassword ? (
        <Routes>
          <Route index element={<RoutePassword />} />
          <Route path="*" element={<Navigate replace to={ROUTE.PASSWORD} />} />
        </Routes>
      ) : (
        <RouteApp>
          {({ isAdmin, isCaseActive }) => {
            return (
              <Routes>
                <Route path={ROUTE.STATUS} element={<RouteStatus />} />
                <Route path={ROUTE.STATUS_CASE} element={<RouteStatus />} />

                <Route path={ROUTE.LOAN} element={<RouteLoan />} />
                <Route path={ROUTE.LOAN_CASE} element={<RouteLoan />} />

                {isCaseActive && (
                  <React.Fragment>
                    <Route
                      path={ROUTE.DOCUMENTS}
                      element={<RouteDocuments />}
                    />
                    <Route
                      path={ROUTE.DOCUMENTS_CASE}
                      element={<RouteDocuments />}
                    />

                    <Route path={ROUTE.MESSAGES} element={<RouteMessages />} />
                    <Route
                      path={ROUTE.MESSAGES_CASE}
                      element={<RouteMessages />}
                    />

                    <Route path={ROUTE.TEAM} element={<RouteTeam />} />
                    <Route path={ROUTE.TEAM_CASE} element={<RouteTeam />} />
                  </React.Fragment>
                )}

                <Route path={ROUTE.CONTACT} element={<RouteContact />} />
                <Route path={ROUTE.CONTACT_CASE} element={<RouteContact />} />

                {isAdmin && (
                  <React.Fragment>
                    <Route path={ROUTE.DEBUG} element={<RouteDebug />} />
                    <Route path={ROUTE.DEBUG_CASE} element={<RouteDebug />} />
                  </React.Fragment>
                )}

                <Route
                  path="*"
                  element={<Navigate replace to={ROUTE.STATUS} />}
                />
              </Routes>
            );
          }}
        </RouteApp>
      )}
    </Router>
  );
};

export default Component;
