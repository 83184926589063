import React from 'react';

import Avatar from 'components/shared/Avatar';

import styles from 'routes/debug/styles.module.scss';

type AvatarsDebugProps = Record<string, unknown>;

const AvatarsDebug: React.FunctionComponent<AvatarsDebugProps> = () => {
  return (
    <div className={styles['avatars-container']}>
      <div>
        <Avatar
          mask={true}
          name="Name"
          src="https://fmg-myfluent-middleware.fluent-services.io/cdn/WeeMii/MBI.png"
        />
        <Avatar
          mask={true}
          name="Name"
          src="https://fmg-myfluent-middleware.fluent-services.io/cdn/WeeMii/SPE.png"
        />
        <Avatar mask={true} name="Name" />
      </div>
      <div>
        <div className={styles['no-mask']}>
          <Avatar
            name="Name"
            src="https://fmg-myfluent-middleware.fluent-services.io/cdn/WeeMii/MBI.png"
          />
        </div>
        <div className={styles['no-mask']}>
          <Avatar
            name="Name"
            src="https://fmg-myfluent-middleware.fluent-services.io/cdn/WeeMii/SPE.png"
          />
        </div>
        <div className={styles['no-mask']}>
          <Avatar name="Name" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(AvatarsDebug);
