import { useCallback, useEffect, useState } from 'react';

export const useChromeEmulator = ({
  on,
  off,
}: {
  on?: () => void;
  off?: () => void;
}) => {
  const [emulationActive, setEmulationActive] = useState(
    window.navigator.maxTouchPoints > 0,
  );

  const handleResize = useCallback(() => {
    if (window.navigator.maxTouchPoints > 0 && !emulationActive) {
      // Emulation mode activated
      on && on();
      setEmulationActive(true);
    }

    if (window.navigator.maxTouchPoints === 0 && emulationActive) {
      // Emulation mode deactivated
      off && off();
      setEmulationActive(false);
    }
  }, [emulationActive, off, on]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};

//TODO
