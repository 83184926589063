import getCaseIdFromPath from './getCaseIdFromPath';
import getBaseRoute from './getBaseRoute';
import { ROUTE } from '../constants';

type GetRedirectFromPath = (
  caseId: number | null,
  path: string,
) => string | null;

const getRedirectFromPath: GetRedirectFromPath = (caseId, path) => {
  const id = getCaseIdFromPath(path);
  const route = path !== ROUTE.LOGIN ? getBaseRoute(path) : null;
  return caseId !== id && route
    ? !!caseId && route
      ? `/${caseId.toString()}${route}`
      : route
    : null;
};

export default getRedirectFromPath;
