import React, { useMemo } from 'react';

import { NetworkStatus, useNetwork } from 'modules/services/network';
import { platform } from 'modules/services/platform';
import Button from 'components/shared/Button';

import styles from './styles.module.scss';

const { isAdmin } = platform;

type ErrorMessageProps = { isError: Error | false; onError?: () => void };

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  isError,
  onError,
}) => {
  const network = useNetwork();
  const error = useMemo(
    () =>
      isAdmin && isError
        ? isError.message
        : network === NetworkStatus.OFFLINE
          ? 'We are unable to establish a connection to our server.'
          : 'There has been a technical problem.',
    [isError, network],
  );
  return (
    <main className={styles.error}>
      {onError ? (
        <Button onClick={onError}>
          <span>{error}</span>
          <span>Click here to retry.</span>
        </Button>
      ) : (
        <p>{error}</p>
      )}
    </main>
  );
};

export default React.memo(ErrorMessage);
