import { RootState } from 'modules/redux';
import {
  MessageDocupload,
  findMessage,
  MessageType,
} from 'modules/states/messages';

type GetDocType = (state: RootState) => string | undefined;

const getDocType: GetDocType = state => {
  const messageId = state.docupload.messageId;
  const caseId = state.docupload.caseId;

  const message =
    !!caseId && !!messageId
      ? findMessage(state.messages.data, caseId, messageId)
      : undefined;

  const docType =
    !!message && message.type === MessageType.DOCUMENT_UPLOAD
      ? (message as MessageDocupload).callToAction.docType || undefined
      : undefined;

  return docType;
};

export default getDocType;
