import React from 'react';

import { useVersion } from 'modules/services/version/hooks';
import { platform } from 'modules/services/platform';

import styles from './styles.module.scss';

const { isAdmin } = platform;

const Version: React.FunctionComponent = () => {
  const version = useVersion();
  return isAdmin && !!version.version ? (
    <small className={styles['version']}>
      <React.Fragment>
        <strong>Version: </strong>
        {version.version}
      </React.Fragment>
      {!!version.build && (
        <React.Fragment>
          <br />
          <strong>Build: </strong>
          {version.build}
        </React.Fragment>
      )}
      {!!version.env && (
        <React.Fragment>
          <br />
          <strong>{version.env}</strong>
        </React.Fragment>
      )}
    </small>
  ) : null;
};

export default React.memo(Version);
