import { Location } from 'history';

import { platform } from 'modules/services/platform';
import getBaseRoute from './getBaseRoute';
import { ROUTE } from '../constants';

const { isDesktop } = platform;

const pageScroll = (location: Location) =>
  isDesktop &&
  getBaseRoute(location.pathname) !== ROUTE.MESSAGES &&
  window.scrollTo(0, 0);

export default pageScroll;
