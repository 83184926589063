import React from 'react';
import clsx from 'clsx';

import { NavItemType } from './constants';
import Button from 'components/shared/Button';

import styles from './styles.module.scss';

type NavButtonProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  gradientValue: string;
  handleBlur: (id: string) => void;
  handleClick: () => void;
  handleFocus: (id: string) => void;
  handleMouseOver: (id: string) => void;
  handleMouseOut: (id: string) => void;
  label: string;
  type: NavItemType;
};

const NavButton: React.FunctionComponent<NavButtonProps> = ({
  children,
  disabled,
  gradientValue,
  handleClick,
  handleBlur,
  handleFocus,
  handleMouseOver,
  handleMouseOut,
  label,
  type,
}) => {
  const classNames = clsx(styles[`${type}`]);
  const formattedLabel = label.toLowerCase().replace(/ +/g, '-');

  return handleClick ? (
    <li className={classNames}>
      <Button
        className={styles[`${formattedLabel}`]}
        disabled={disabled}
        label={label}
        onBlur={() => handleBlur(formattedLabel)}
        onClick={handleClick}
        onFocus={() => handleFocus(formattedLabel)}
        onMouseOut={() => handleMouseOut(formattedLabel)}
        onMouseOver={() => handleMouseOver(formattedLabel)}
        title={label}>
        {children}
        <span style={{ backgroundSize: `100% ${gradientValue}` }}></span>
      </Button>
    </li>
  ) : null;
};

export default React.memo(NavButton);
