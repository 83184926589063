import { NotificationsAction, NotificationsStatus } from '../../constants';
import { isError } from 'modules/utils';
import { showDialog } from './utils';
import {
  NotificationsRegisterFailureAction,
  NotificationsRegisterStartedAction,
  NotificationsRegisterSuccessAction,
  NotificationsRegisterAction,
} from './types';
import {
  notificationsPluginRegister,
  notificationsPluginStatus,
} from '../../plugin';

export const notificationsRegisterAction: NotificationsRegisterAction = () => {
  return async (dispatch, getState) => {
    try {
      if (!getState().auth.data?.token || !getState().platform.isCapacitor)
        return;

      dispatch(notificationsRegisterStartedAction());

      const status = await notificationsPluginStatus();

      switch (status) {
        case NotificationsStatus.PROMPT:
          !!getState().auth.data?.token && showDialog(dispatch);
          return;

        case NotificationsStatus.GRANTED:
          !!getState().auth.data?.token && notificationsPluginRegister();
          return;

        default:
          throw new Error(status);
      }
    } catch (error) {
      !!getState().auth.data?.token &&
        dispatch(
          notificationsRegisterFailureAction(
            isError(error) ? error : new Error('Unexpected Error'),
          ),
        );
    }
  };
};

const notificationsRegisterStartedAction: NotificationsRegisterStartedAction =
  () => ({
    type: NotificationsAction.REGISTER_STARTED,
  });

export const notificationsRegisterSuccessAction: NotificationsRegisterSuccessAction =
  result => ({
    type: NotificationsAction.REGISTER_SUCCESS,
    result,
  });

export const notificationsRegisterFailureAction: NotificationsRegisterFailureAction =
  error => ({
    type: NotificationsAction.REGISTER_FAILURE,
    error,
  });
