import { AxiosProgressEvent } from 'axios';

import { CreateDefaultActionProgress, IsCurrentAction } from './types';

export const createDefaultActionProgress: CreateDefaultActionProgress = ({
  action,
  actionId,
  dispatch,
  getState,
  request,
  ...props
}) =>
  action
    ? (event: AxiosProgressEvent) => {
        isCurrentAction({
          actionId,
          request,
          state: getState(),
          ...props,
        }) && dispatch(action({ actionId, event, request }));
      }
    : undefined;

export const isCurrentAction: IsCurrentAction = ({
  actionId,
  isActive,
  isAuthorized,
  isPresent,
  request,
  state,
}) =>
  isAuthorized(state) &&
  isActive({ request, state }) &&
  isPresent({ actionId, request, state });
