import {
  Credentials,
  NativeBiometric,
} from '@capgo/capacitor-native-biometric';

import { localStorageGetObject } from 'modules/storage';
import { AuthBiometricError } from '../../constants';
import { platform } from 'modules/services/platform';

const { isDevelopment } = platform;

type GetCredentials = (username: string) => Promise<Credentials>;

const getCredentials: GetCredentials = async username => {
  try {
    const credentials = isDevelopment
      ? localStorageGetObject('CREDENTIALS')
      : await NativeBiometric.getCredentials({
          server: `com.fluent.fluentview:${username}`,
        });
    if (!credentials) throw new Error();
    return credentials as Credentials;
  } catch {
    throw new Error(AuthBiometricError.KEYCHAIN);
  }
};

export default getCredentials;
